import React from 'react';

export const ArticleSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M18.6782 1.03662H12.8629C12.6471 1.03662 12.4722 1.2115 12.4722 1.42725C12.4722 1.64299 12.6471 1.81787 12.8629 1.81787H18.6782C18.9712 1.81787 19.2188 2.06541 19.2188 2.35846V12.7581C19.2188 13.0512 18.9713 13.2987 18.6782 13.2987H17.7811V4.57639C17.7811 3.84752 17.1881 3.25451 16.4593 3.25451H2.9991V2.35846C2.9991 2.06037 3.2416 1.81787 3.53969 1.81787H9.35504C9.57078 1.81787 9.74566 1.64299 9.74566 1.42725C9.74566 1.2115 9.57078 1.03662 9.35504 1.03662H3.53969C2.81082 1.03662 2.21785 1.62959 2.21785 2.35846V3.25451H1.32184C0.592969 3.25451 0 3.84752 0 4.57639V14.9761C0 15.7049 0.592969 16.2979 1.32184 16.2979H2.61094V18.5725C2.61094 18.7104 2.68363 18.8381 2.80223 18.9085C2.86359 18.9449 2.93254 18.9631 3.00156 18.9631C3.0659 18.9631 3.13035 18.9472 3.18875 18.9154L7.98316 16.2979H16.4592C17.1881 16.2979 17.7811 15.7049 17.7811 14.976V14.08H18.6782C19.407 14.08 20 13.487 20 12.7581V2.35846C20 1.62959 19.407 1.03662 18.6782 1.03662ZM16.9998 14.9761C16.9998 15.2742 16.7573 15.5167 16.4592 15.5167H7.88348C7.81809 15.5167 7.75371 15.5331 7.69629 15.5644L3.39219 17.9142V15.9073C3.39219 15.6915 3.2173 15.5167 3.00156 15.5167H1.32184C1.02375 15.5167 0.78125 15.2742 0.78125 14.9761V4.57639C0.78125 4.2783 1.02375 4.03576 1.32184 4.03576H16.4592C16.7573 4.03576 16.9998 4.27826 16.9998 4.57639V14.9761Z"
        fill="currentColor"
      />
      <path
        d="M11.1101 1.81787H11.1124C11.3281 1.81787 11.5019 1.64299 11.5019 1.42725C11.5019 1.2115 11.3259 1.03662 11.1101 1.03662C10.8944 1.03662 10.7195 1.2115 10.7195 1.42725C10.7195 1.64299 10.8944 1.81787 11.1101 1.81787Z"
        fill="currentColor"
      />
      <path
        d="M13 7.55793C12.5379 6.88956 11.6192 6.71342 10.9592 7.18142L7.56549 9.58821L6.33145 8.71293C6.79094 7.90942 6.57481 6.86966 5.80977 6.32715C5.00188 5.75438 3.88877 5.95315 3.32303 6.77126C2.75754 7.58941 2.95364 8.71705 3.76155 9.29005C4.31592 9.6832 5.01422 9.71268 5.58047 9.42817L7.09196 10.5001L5.58061 11.5718C5.01436 11.2875 4.3161 11.3169 3.76168 11.71C2.95381 12.283 2.7577 13.4105 3.32317 14.2286C3.88891 15.0469 5.00213 15.2456 5.80989 14.6729C6.57483 14.1304 6.79092 13.0906 6.33156 12.2871L7.56561 11.4118L10.9592 13.8184C11.6192 14.2866 12.5379 14.1103 13 13.4419L8.85135 10.5L13 7.55793ZM4.78959 8.75008C4.2784 8.75008 3.86397 8.33033 3.86397 7.81257C3.86397 7.29481 4.27842 6.87506 4.78959 6.87506C5.3009 6.87506 5.71522 7.29481 5.71522 7.81257C5.71522 8.33033 5.3009 8.75008 4.78959 8.75008ZM4.78959 14.1251C4.2784 14.1251 3.86397 13.7054 3.86397 13.1876C3.86397 12.6699 4.2784 12.2501 4.78959 12.2501C5.3009 12.2501 5.71522 12.6699 5.71522 13.1876C5.71522 13.7054 5.3009 14.1251 4.78959 14.1251Z"
        fill="currentColor"
      />
      <rect
        x="0.25"
        y="10.25"
        width="5.5"
        height="0.5"
        stroke="currentColor"
        strokeWidth="0.5"
        strokeMiterlimit="5.49776"
        strokeLinejoin="round"
        strokeDasharray="1 1"
      />
      <rect
        x="10.25"
        y="10.25"
        width="6.5"
        height="0.5"
        stroke="currentColor"
        strokeWidth="0.5"
        strokeMiterlimit="5.49776"
        strokeLinejoin="round"
        strokeDasharray="1 1"
      />
    </g>
  </svg>
);

export default ArticleSvg;
