import React from 'react';

export const HashtagSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M105.24,17.05l-.8,4.18h1.48a.82.82,0,0,1,.84.84.71.71,0,0,1-.24.56.84.84,0,0,1-.6.22h-1.8l-.56,2.88a.77.77,0,0,1-.28.46.75.75,0,0,1-.52.18.91.91,0,0,1-.64-.24.81.81,0,0,1-.24-.6,1.27,1.27,0,0,1,0-.22l.48-2.46H99.82l-.56,2.88a.7.7,0,0,1-.26.46.77.77,0,0,1-.5.18.84.84,0,0,1-.62-.24.81.81,0,0,1-.24-.6,1.27,1.27,0,0,1,0-.22l.48-2.46h-.86a.82.82,0,0,1-.84-.84.74.74,0,0,1,.24-.56.89.89,0,0,1,.6-.22h1.18l.8-4.18H97.68a.82.82,0,0,1-.84-.84.74.74,0,0,1,.24-.56.89.89,0,0,1,.6-.22h1.9l.54-2.78a.88.88,0,0,1,.26-.46.85.85,0,0,1,1.4.68,1.09,1.09,0,0,1,0,.22l-.46,2.34h2.54l.54-2.78a.72.72,0,0,1,.26-.46.91.91,0,0,1,1.16.06.81.81,0,0,1,.24.6,1.09,1.09,0,0,1,0,.22l-.46,2.36h.76a.82.82,0,0,1,.84.84.71.71,0,0,1-.24.56.84.84,0,0,1-.6.22Zm-2.54,4.18.82-4.18H101l-.82,4.18Z"
      transform="translate(-96.44 -12.01)"
      fill="currentColor"
    />
  </svg>
);

export default HashtagSvg;
