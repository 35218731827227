import React from 'react';

export const AnnouncementSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg version="1.1" width="512" height="512" x="0" y="0" viewBox="0 0 512 512">
    <g>
      <g xmlns="http://www.w3.org/2000/svg">
        <g>
          <path
            d="M492,281.979c11.046,0,20-8.954,20-20V60.013c0-19.493-9.515-37.831-25.452-49.056    c-15.938-11.224-36.41-14.003-54.762-7.438c-0.565,0.202-1.121,0.43-1.666,0.682l-160.226,74.19    c-35.275,16.332-72.869,24.729-111.739,24.955l-78.219,0.456C35.854,103.838,0,139.712,0,183.803v52    c0,35.477,23.446,66.018,56,76.295V451.19c0,16.275,6.692,32.176,18.36,43.622c11.312,11.098,26.062,17.168,41.652,17.168    c0.382,0,0.767-0.004,1.15-0.012c15.785-0.301,30.575-6.675,41.646-17.947C169.895,482.733,176,467.803,176,451.979V315.734    c33.757,2.544,67.127,11.478,97.555,26.216l156.064,75.589c0.615,0.298,1.245,0.563,1.887,0.797    c18.376,6.68,38.908,3.968,54.92-7.25C502.439,399.867,512,381.497,512,361.944c0-11.046-8.954-20-20-20c-11.046,0-20,8.954-20,20    c0,6.615-3.107,12.585-8.525,16.381c-5.184,3.631-11.515,4.594-17.496,2.692L290.991,305.95    C255.16,288.596,215.773,278.249,176,275.638V142.753c38.384-2.374,75.545-11.786,110.7-28.062l159.274-73.749    c6.003-1.905,12.355-0.933,17.542,2.721C468.908,47.46,472,53.42,472,60.015v201.966C472,273.025,480.954,281.979,492,281.979z     M136,451.979c0,10.819-8.793,19.789-19.603,19.996c-5.172,0.097-10.167-1.931-14.025-5.717C98.323,462.285,96,456.793,96,451.189    V295.653c0-10.529-8.163-19.255-18.669-19.956C56.398,274.301,40,256.777,40,235.803v-52c0-22.056,17.944-40,40-40h0.117    L136,143.477V451.979z"
            fill="currentColor"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default AnnouncementSvg;
