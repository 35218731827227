import { initializeApp } from 'firebase/app';
import { applyMiddleware, createStore, Reducer } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createEpicMiddleware } from 'redux-observable';

import { config } from '../config/environments.production';
import {
  AppState,
  initEnvironment,
  loadAuth,
  RootAction,
  rootEpic,
  rootReducer,
} from '../modules';
import Authentication from '../services/authentication';
import { BackendService } from '../services/backendService';
import Database from '../services/database';
import Files from '../services/files';
import { Metrics } from '../services/metrics';
import Notifications from '../services/notifications';
import { isDevelopment } from '../utils/randomUtils';

export interface AppDependencies {
  database: Database;
  files: Files;
  auth: Authentication;
  notifications: Notifications;
  backendService: BackendService;
}

export const getConfig = () => {
  let appConfig = config;
  if (isDevelopment()) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const devConfig = require('../config/environments').config;
    // eslint-disable-next-line no-console
    console.log('Connecting to dev');
    appConfig = devConfig;
  }
  return appConfig;
};

export const configureStore = () => {
  const config = getConfig();
  initializeApp(config.firebase);
  const database = new Database();
  const files = new Files();
  const auth = new Authentication(config.azure);
  const notifications = new Notifications(config.cloudMessaging.vapidKey);
  const backendService = new BackendService();
  BackendService.init(config.backend.externalApiUrl, auth, config.cdn.url);
  Metrics.init('unused');

  const epicMiddleware = createEpicMiddleware<
    RootAction,
    RootAction,
    AppState,
    AppDependencies
  >({
    dependencies: {
      database,
      files,
      auth,
      notifications,
      backendService,
    },
  });

  const middlewares = [epicMiddleware];

  const middlewareEnhancer = applyMiddleware(...middlewares);
  const enhancers = [middlewareEnhancer];

  const composedEnhancers = composeWithDevTools(...enhancers);

  const store = createStore(rootReducer as Reducer, composedEnhancers);
  epicMiddleware.run(rootEpic);
  store.dispatch(initEnvironment(config));

  store.dispatch(loadAuth());

  return { store, notifications, auth, config };
};
