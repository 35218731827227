import React from 'react';

export const MuteSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 382.611 382.611"
  >
    <g>
      <g>
        <g>
          <path
            d="M218.011,40.676l-108.8,66.4l-31.2,18h-54.8c-12.8,0-23.2,10.4-23.2,23.2v85.2c-0.4,12.8,10.4,23.6,23.2,23.6h54.8
				l30,17.6c0.4,0.4,0.8,0.4,1.2,0.8l107.6,65.6c3.2,2.4,7.2,3.6,11.6,3.6c11.2,0,20.4-9.2,20.4-20.4v-133.2v-132.4
				c0-3.6-0.8-7.2-2.8-10.4C240.411,38.276,227.611,35.076,218.011,40.676z"
          />
          <path
            d="M348.011,191.076l31.6-31.6c4-4,4-10.4,0-14.8c-4-4-10.4-4-14.8,0l-31.6,31.6l-31.6-31.6c-4-4-10.4-4-14.8,0
				c-4,4-4,10.4,0,14.8l31.6,31.6l-31.6,31.6c-4,4-4,10.4,0,14.8c4,4,10.4,4,14.8,0l31.6-31.6l31.6,31.6c4,4,10.4,4,14.8,0
				c4-4,4-10.4,0-14.8L348.011,191.076z"
          />
        </g>
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
);

export default MuteSvg;
