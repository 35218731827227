import { useMutation, useQuery, useQueryClient } from 'react-query';

import { selectUserType } from '../modules';
import { BackendService, RoleUser } from '../services/backendService';
import Database from '../services/database';
import { useTypedSelector } from '.';
import { activityFeedCacheKey } from './useActivityFeed';
import { articleCacheKey } from './useArticle';
import { unAnsweredQuestionsCacheKey } from './useUnanswered';
import { usersCacheKey } from './useUsers';

function useMe() {
  const cacheKey = ['me'];
  const client = useQueryClient();
  const { data, isFetching, error } = useQuery<RoleUser | undefined>(
    cacheKey,
    () => BackendService.instance.users.me(),
    {
      staleTime: 1000 * 60 * 5,
    },
  );

  const {
    mutate: setNickName,
    data: nickNameRes,
    isSuccess: setNicknameSuccess,
  } = useMutation(
    (val: string) => {
      if (!data) return Promise.resolve();
      /**TODO: DECOUPLE WITH API CALL INSTEAD */
      return Database.instance.usertype.setNickName(val, data.id);
    },
    {
      onSettled: () => {
        client.invalidateQueries(cacheKey);
        client.invalidateQueries(activityFeedCacheKey);
        client.invalidateQueries(unAnsweredQuestionsCacheKey);
        client.invalidateQueries(articleCacheKey);
        client.invalidateQueries(usersCacheKey);
      },

      onMutate: nickName => {
        client.cancelQueries(cacheKey);
        const previous = client.getQueryData<RoleUser | undefined>(cacheKey);
        if (!previous) {
          // eslint-disable-next-line no-console
          console.error('Mutating before possible mutation');
        }
        client.setQueryData<RoleUser | undefined>(cacheKey, existingUser => {
          if (!existingUser) return existingUser;
          return {
            ...existingUser,
            name: nickName,
          };
        });
        // Return the snapshotted value
        return () => client.setQueryData(cacheKey, previous);
      },
    },
  );

  const userType = useTypedSelector(selectUserType);

  return {
    me: data ? { ...userType, ...data } : undefined,
    setNickName,
    nickNameRes,
    setNicknameSuccess,
    firstLetter: data?.name?.substring(0, 1) || '-',
    error,
    isLoading: isFetching,
  };
}

export default useMe;
