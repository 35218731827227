import { createAction } from 'typesafe-actions';

import * as ActionTypes from './actionTypes';

export const fetchArticleBlob = createAction(
  ActionTypes.FETCH_ARTICLE_BLOB,
  action => (articleId: string, path: string) => action({ articleId, path }),
);

export const fetchArticleBlobSuccess = createAction(
  ActionTypes.FETCH_ARTICLE_BLOB_SUCCESS,
  action => (blob: Blob, id: string) => action({ blob, id }),
);

export const fetchArticleBlobError = createAction(
  ActionTypes.FETCH_ARTICLE_BLOB_ERROR,
  action => (error: string) => action({ error }),
);
