import styled, { css } from 'styled-components';

export const ProfileCircle = styled.div<{
  $color?: string;
  $background?: string;
  $radius?: number;
}>`
  ${({ $radius = 40 }) => css`
    border-radius: ${$radius / 1}px;
    height: ${$radius}px;
    width: ${$radius}px;
  `};
  background-color: ${({ $background }) => $background};
  color: ${({ $color = 'white' }) => $color};
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
`;
