import { useIsAuthenticated } from '@azure/msal-react';
import { useState } from 'react';
import { useQuery } from 'react-query';

import { BackendService } from '../services/backendService';
import { useMetrics } from './useMetrics';

function useHeartbeat() {
  const [intervalMs] = useState(5 * 60 * 1000);
  const isAuthenticated = useIsAuthenticated();
  const metrics = useMetrics();

  const { error, isFetching } = useQuery(
    'hearbeat',
    async () => {
      if (isAuthenticated) {
        const res = await BackendService.instance.sendHeartbeat();
        metrics.logEvent('Heartbeat', {});
        return res;
      }
    },
    {
      // Refetch the data every second
      refetchInterval: intervalMs,
    },
  );

  return {
    error,
    isFetching,
  };
}

export default useHeartbeat;
