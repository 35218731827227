import React from 'react';

export const DeleteSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3533_52142)">
      <path
        d="M9.99936 0H5.99935C5.46892 0 4.96021 0.210714 4.58514 0.585786C4.21007 0.960859 3.99935 1.46957 3.99935 2V2.66667H1.33268C1.15587 2.66667 0.986302 2.7369 0.861278 2.86193C0.736254 2.98695 0.666016 3.15652 0.666016 3.33333C0.666016 3.51014 0.736254 3.67971 0.861278 3.80474C0.986302 3.92976 1.15587 4 1.33268 4H2.03535L2.59935 14.1113C2.62783 14.6222 2.85106 15.1028 3.22309 15.4541C3.59512 15.8054 4.08766 16.0008 4.59935 16H11.4047C11.9164 16.0008 12.4089 15.8054 12.781 15.4541C13.153 15.1028 13.3762 14.6222 13.4047 14.1113L13.9634 4H14.666C14.8428 4 15.0124 3.92976 15.1374 3.80474C15.2625 3.67971 15.3327 3.51014 15.3327 3.33333C15.3327 3.15652 15.2625 2.98695 15.1374 2.86193C15.0124 2.7369 14.8428 2.66667 14.666 2.66667H11.9994V2C11.9994 1.46957 11.7886 0.960859 11.4136 0.585786C11.0385 0.210714 10.5298 0 9.99936 0V0ZM5.33269 2C5.33269 1.82319 5.40292 1.65362 5.52795 1.52859C5.65297 1.40357 5.82254 1.33333 5.99935 1.33333H9.99936C10.1762 1.33333 10.3457 1.40357 10.4708 1.52859C10.5958 1.65362 10.666 1.82319 10.666 2V2.66667H5.33269V2ZM12.07 14.0373C12.0605 14.2076 11.986 14.3677 11.862 14.4848C11.738 14.6018 11.5739 14.6669 11.4034 14.6667H4.59402C4.42349 14.6669 4.25934 14.6018 4.13534 14.4848C4.01133 14.3677 3.9369 14.2076 3.92735 14.0373L3.37068 4H12.628L12.07 14.0373Z"
        fill="currentColor"
      />
      <path
        d="M8.00065 6.66699C7.82384 6.66699 7.65427 6.73723 7.52925 6.86225C7.40422 6.98728 7.33398 7.15685 7.33398 7.33366V11.3337C7.33398 11.5105 7.40422 11.68 7.52925 11.8051C7.65427 11.9301 7.82384 12.0003 8.00065 12.0003C8.17746 12.0003 8.34703 11.9301 8.47205 11.8051C8.59708 11.68 8.66732 11.5105 8.66732 11.3337V7.33366C8.66732 7.15685 8.59708 6.98728 8.47205 6.86225C8.34703 6.73723 8.17746 6.66699 8.00065 6.66699Z"
        fill="currentColor"
      />
      <path
        d="M10.6667 6.66699C10.4899 6.66699 10.3203 6.73723 10.1953 6.86225C10.0702 6.98728 10 7.15685 10 7.33366V11.3337C10 11.5105 10.0702 11.68 10.1953 11.8051C10.3203 11.9301 10.4899 12.0003 10.6667 12.0003C10.8435 12.0003 11.0131 11.9301 11.1381 11.8051C11.2631 11.68 11.3333 11.5105 11.3333 11.3337V7.33366C11.3333 7.15685 11.2631 6.98728 11.1381 6.86225C11.0131 6.73723 10.8435 6.66699 10.6667 6.66699Z"
        fill="currentColor"
      />
      <path
        d="M5.33268 6.66699C5.15587 6.66699 4.9863 6.73723 4.86128 6.86225C4.73625 6.98728 4.66602 7.15685 4.66602 7.33366V11.3337C4.66602 11.5105 4.73625 11.68 4.86128 11.8051C4.9863 11.9301 5.15587 12.0003 5.33268 12.0003C5.50949 12.0003 5.67906 11.9301 5.80408 11.8051C5.92911 11.68 5.99935 11.5105 5.99935 11.3337V7.33366C5.99935 7.15685 5.92911 6.98728 5.80408 6.86225C5.67906 6.73723 5.50949 6.66699 5.33268 6.66699Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default DeleteSvg;
