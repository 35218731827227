import {
  getMessaging,
  getToken,
  isSupported,
  Messaging,
  onMessage,
} from 'firebase/messaging';
import { empty, from, Observable, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Metrics } from '../metrics';

export default class Notifications {
  messaging: Messaging | null = null;
  registration?: ServiceWorkerRegistration;

  constructor(private vapidKey: string) {
    this.vapidKey = vapidKey;
    this.init();
  }

  private init = async () => {
    try {
      const shouldSetup = await isSupported();
      if (shouldSetup) {
        this.messaging = getMessaging();

        // usePublicVapidKey(vapidKey);
        // eslint-disable-next-line no-console
        console.log('Messaging is supported');
      } else {
        // eslint-disable-next-line no-console
        console.log('Messaging is not supported');
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error((e as Error).message);
    }
  };

  public setSWRegistration(sw: ServiceWorkerRegistration) {
    this.registration = sw;
  }

  isSupported = () => isSupported();
  getMessaging = () => this.messaging;

  getToken = () => {
    if (!this.messaging) {
      // eslint-disable-next-line no-console
      console.error('Token cannot be found. Messaging not initalized');
      return empty();
    }
    if (!this.registration) {
      Metrics.getLogger().logEvent('PushNotifications.NoRegistration');
      return empty();
    }
    return from(
      getToken(this.messaging, {
        serviceWorkerRegistration: this.registration,
        vapidKey: this.vapidKey,
      }),
    ).pipe(
      catchError(e => {
        // eslint-disable-next-line no-console
        console.log(e.message);
        return empty();
      }),
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public onPushNoticationMessage = (): Observable<any> => {
    const sub = new Subject();
    // TODO ADD METRIC
    // TODO technical debt. Handle push notifications without memory leaks

    this.messaging &&
      onMessage(this.messaging, message => {
        // TODO ADD METRIC
        sub.next(message);
      });
    return sub.asObservable();
  };
}
