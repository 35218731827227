import React from 'react';

export const LogoSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="2448"
    height="686"
    viewBox="0 0 2448 686"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M617.639 436.041C662.658 436.041 692.179 401.35 692.179 349.698V199.892C692.729 188.226 697.751 177.22 706.201 169.159C714.652 161.098 725.882 156.6 737.561 156.6C749.24 156.6 760.47 161.098 768.921 169.159C777.371 177.22 782.393 188.226 782.943 199.892V356.382C782.943 455.264 717.268 520.202 617.639 520.202C518.01 520.202 452.345 455.264 452.345 356.382V199.892C452.345 187.953 457.087 176.504 465.529 168.062C473.97 159.62 485.42 154.878 497.358 154.878C509.297 154.878 520.746 159.62 529.188 168.062C537.63 176.504 542.372 187.953 542.372 199.892V349.698C542.372 401.35 572.63 436.041 617.639 436.041Z"
      fill="currentColor"
    />
    <path
      d="M1092.84 196.207C1093.11 202.025 1092.13 207.832 1089.98 213.244C1087.83 218.656 1084.55 223.549 1080.36 227.597C1076.18 231.646 1071.18 234.758 1065.7 236.726C1060.22 238.695 1054.38 239.475 1048.57 239.015C1034.55 239.015 1019.05 234.582 998.395 234.582C965.915 234.582 944.521 258.934 944.521 296.573V467.774C944.521 479.712 939.779 491.161 931.337 499.603C922.896 508.044 911.446 512.787 899.508 512.787C887.569 512.787 876.12 508.044 867.678 499.603C859.236 491.161 854.494 479.712 854.494 467.774V199.892C854.532 188.385 858.959 177.326 866.873 168.973C874.788 160.619 885.592 155.602 897.08 154.943C908.568 154.285 919.874 158.035 928.691 165.43C937.508 172.825 943.17 183.306 944.521 194.733C954.109 180.206 967.154 168.286 982.484 160.042C997.815 151.799 1014.95 147.49 1032.36 147.503C1061.85 147.513 1092.84 163.001 1092.84 196.207Z"
      fill="currentColor"
    />
    <path
      d="M1172.47 529.401C1166.34 529.555 1160.25 528.427 1154.58 526.089C1148.92 523.751 1143.8 520.255 1139.56 515.825C1135.33 511.394 1132.06 506.128 1129.98 500.362C1127.89 494.596 1127.04 488.459 1127.46 482.343V202.276C1127.19 196.194 1128.15 190.12 1130.29 184.421C1132.43 178.721 1135.7 173.515 1139.91 169.114C1144.12 164.714 1149.17 161.212 1154.77 158.82C1160.37 156.427 1166.39 155.193 1172.48 155.193C1178.57 155.193 1184.59 156.427 1190.19 158.82C1195.79 161.212 1200.84 164.714 1205.05 169.114C1209.25 173.515 1212.53 178.721 1214.66 184.421C1216.8 190.12 1217.77 196.194 1217.49 202.276V482.343C1217.92 488.46 1217.06 494.598 1214.98 500.364C1212.89 506.131 1209.63 511.398 1205.39 515.828C1201.15 520.259 1196.03 523.755 1190.37 526.092C1184.7 528.429 1178.6 529.557 1172.47 529.401Z"
      fill="currentColor"
    />
    <path
      d="M1648.71 340.516C1648.71 455.47 1571.98 534.937 1478.98 534.937C1458.2 535.386 1437.59 531.022 1418.78 522.188C1399.96 513.354 1383.43 500.289 1370.5 484.012V638.322C1370.79 644.462 1369.83 650.597 1367.68 656.355C1365.53 662.113 1362.24 667.375 1357.99 671.823C1353.75 676.27 1348.65 679.81 1343 682.229C1337.35 684.648 1331.27 685.896 1325.12 685.896C1318.97 685.896 1312.89 684.648 1307.24 682.229C1301.59 679.81 1296.49 676.27 1292.25 671.823C1288 667.375 1284.71 662.113 1282.56 656.355C1280.41 650.597 1279.45 644.462 1279.74 638.322V200.149C1279.34 188.354 1283.54 176.865 1291.45 168.108C1299.36 159.35 1310.36 154.007 1322.14 153.207C1333.91 152.406 1345.54 156.211 1354.56 163.818C1363.58 171.425 1369.3 182.24 1370.5 193.98C1383.59 178.036 1400.2 165.347 1419.02 156.913C1437.85 148.479 1458.37 144.53 1478.98 145.377C1576.39 145.327 1648.71 229.417 1648.71 340.516ZM1557.94 341.283C1557.94 281.11 1518.83 233.254 1464.22 233.254C1410.35 233.254 1370.5 281.09 1370.5 341.283C1370.5 399.922 1410.35 446.98 1464.22 446.98C1518.83 446.98 1557.94 399.922 1557.94 341.283Z"
      fill="currentColor"
    />
    <path
      d="M1865.63 145.323C1902.61 144.808 1938.9 155.308 1969.89 175.485C2000.89 195.662 2025.17 224.605 2039.67 258.629C2054.16 292.653 2058.2 330.219 2051.27 366.546C2044.35 402.874 2026.77 436.319 2000.77 462.625C1974.78 488.931 1941.55 506.908 1905.31 514.267C1869.07 521.627 1831.45 518.036 1797.26 503.953C1763.06 489.869 1733.83 465.93 1713.29 435.181C1692.74 404.433 1681.81 368.265 1681.88 331.284C1681.59 282.258 1700.78 235.125 1735.24 200.251C1769.7 165.378 1816.6 145.619 1865.63 145.323ZM1866.37 229.454C1811.76 229.454 1771.91 274.463 1771.91 331.284C1771.91 388.105 1811.76 433.861 1866.37 433.861C1920.24 433.861 1959.35 388.105 1959.35 331.284C1959.35 274.463 1920.24 229.454 1866.37 229.454Z"
      fill="currentColor"
    />
    <path
      d="M2447.1 43.5234V464.886C2447.39 470.846 2446.5 476.805 2444.49 482.422C2442.48 488.039 2439.38 493.206 2435.37 497.625C2431.36 502.045 2426.52 505.632 2421.13 508.182C2415.73 510.731 2409.89 512.193 2403.93 512.484C2397.97 512.775 2392.01 511.89 2386.4 509.878C2380.78 507.866 2375.61 504.768 2371.19 500.759C2366.77 496.751 2363.18 491.911 2360.64 486.517C2358.09 481.122 2356.62 475.278 2356.33 469.318C2343.26 485.099 2326.77 497.689 2308.1 506.129C2289.43 514.569 2269.08 518.637 2248.6 518.022C2154.87 518.022 2078.87 442.018 2078.87 331.324C2078.87 225.062 2150.44 145.363 2248.6 145.363C2268.8 144.724 2288.89 148.485 2307.5 156.384C2326.1 164.282 2342.76 176.131 2356.33 191.109V43.5234C2356.88 31.8575 2361.9 20.8512 2370.36 12.79C2378.81 4.72879 2390.04 0.231445 2401.71 0.231445C2413.39 0.231445 2424.62 4.72879 2433.07 12.79C2441.52 20.8512 2446.55 31.8575 2447.1 43.5234ZM2356.33 331.324C2356.33 274.503 2317.22 229.494 2263.36 229.494C2208.75 229.494 2168.9 274.503 2168.9 331.324C2168.9 388.145 2208.75 433.901 2263.36 433.901C2317.22 433.86 2356.33 388.105 2356.33 331.264V331.324Z"
      fill="currentColor"
    />
    <path
      d="M251.707 27.9561C340.26 27.9561 417.749 68.5426 417.749 113.561C417.749 140.821 397.819 154.148 374.951 154.148C340.997 154.148 316.646 119.468 247.285 119.468C154.299 119.468 98.9524 188.828 98.9524 274.434C98.9524 359.292 154.299 427.895 247.285 427.895C316.646 427.895 340.997 393.952 374.951 393.952C397.819 393.952 417.749 406.491 417.749 434.528C417.749 478.81 340.26 520.134 251.707 520.134C95.2166 520.134 0.807637 409.44 0.807637 274.404C0.757157 137.914 95.2166 27.9561 251.707 27.9561Z"
      fill="currentColor"
    />
    <path
      d="M205.137 319.089C230 319.089 250.156 298.934 250.156 274.071C250.156 249.207 230 229.052 205.137 229.052C180.274 229.052 160.118 249.207 160.118 274.071C160.118 298.934 180.274 319.089 205.137 319.089Z"
      fill="currentColor"
    />
    <path
      d="M335.795 319.088C360.658 319.088 380.814 298.933 380.814 274.07C380.814 249.206 360.658 229.051 335.795 229.051C310.932 229.051 290.776 249.206 290.776 274.07C290.776 298.933 310.932 319.088 335.795 319.088Z"
      fill="currentColor"
    />
  </svg>
);
