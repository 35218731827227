import { useQuery } from 'react-query';

import { BackendService } from '../services/backendService';

export const tenantCacheKey = 'tenant';

export type IndexedTenant = {
  tenantId: string;
  tenantName: string;
  isEnterprise?: boolean | null;
  createdAt?: number | null;
  createdBy?: string | null;
  isPremium?: boolean | null;
};

function useTenant() {
  const { data, isFetching } = useQuery<IndexedTenant>([tenantCacheKey], _ =>
    BackendService.instance.tenant.get(),
  );

  return {
    data,
    isFetching,
  };
}

export default useTenant;
