import React from 'react';
import styled from 'styled-components';

import { Loader } from './_atoms/_Loader';

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: transparent;
  padding: 20px;
`;
export const MainLoader = ({
  children,
  fill,
}: {
  children?: React.ReactNode;
  fill?: string;
}) => (
  <SpinnerContainer>
    <Loader fill={fill} />
    {children && children}
  </SpinnerContainer>
);

export default MainLoader;
