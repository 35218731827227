import { useMutation, useQuery, useQueryClient } from 'react-query';

import {
  BackendService,
  EmailInvitationCreateDTO,
  InvitationRole,
  TopicInvitation,
} from '../services/backendService';
import { useMetrics } from './useMetrics';
import useTenant from './useTenant';

function useInvitation(role: InvitationRole, topic?: TopicInvitation, duration?: number) {
  const metrics = useMetrics();
  const client = useQueryClient();
  const { data: tenantData, isFetching: isFetchingTenant } = useTenant();
  const { data, isFetching } = useQuery(
    [`invitation`, role, topic, duration],
    () => BackendService.instance.invitations.generate(role, topic, duration),
    {
      staleTime: 1000 * 60 * 50,
    },
  );

  const { mutate: bulkInvite, error, isSuccess, isLoading } = useMutation(
    (val: EmailInvitationCreateDTO[]) => {
      metrics.logEvent('Invite.Email', {
        count: val.length,
        role: val[0] && val[0].role,
        topicId: val[0] && val[0].topic?.topicId,
      });
      return BackendService.instance.invitations.bulkInvite(val);
    },
    {
      onSettled: () => {
        client.invalidateQueries('pending-invitations');
      },
    },
  );

  return {
    url:
      tenantData &&
      data &&
      `${
        tenantData.isEnterprise
          ? `https://${tenantData.tenantId}.curipod.com`
          : window.location.origin
      }/join/${data.code}`,
    isFetching: isFetching || isFetchingTenant,
    bulkInvite,
    error,
    isSuccess,
    isLoading,
  };
}

export default useInvitation;
