import { combineEpics } from 'redux-observable';
import { from } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { isOfType } from 'typesafe-actions';

import { RootEpic } from '..';
import { catchHandler, fetchBlob } from '../utils';
import { fetchArticleBlobSuccess } from './actions';
import { FETCH_ARTICLE_BLOB } from './actionTypes';

export const onPDFArticleRequested: RootEpic = (action$, state$, { files }) =>
  action$.pipe(
    filter(isOfType(FETCH_ARTICLE_BLOB)),
    switchMap(({ payload: { articleId, path } }) => {
      return from(files.getArticleDownloadUrl(path)).pipe(
        mergeMap(fetchBlob),
        map(blob => fetchArticleBlobSuccess(blob, articleId)),
      );
    }),
    catchError(catchHandler),
  );

export const articlesRootEpic = combineEpics(onPDFArticleRequested);
