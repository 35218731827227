import { useInfiniteQuery } from 'react-query';

import { BackendService } from '../services/backendService';
import { DiscoverSearchQuery } from '../services/backendService.type';
import { useMetrics } from '.';
import { toSearchParams } from './useDiscoverSearchQueryState';
export const BASE_TEMPLATE_SEARCH_LIMIT = 20;

function useTemplateSearch(query?: DiscoverSearchQuery) {
  const metrics = useMetrics();
  const performSearch = ({ pageParam = undefined }: { pageParam?: number }) =>
    BackendService.instance.discover.query({
      ...query,
      cursor: pageParam,
      limit: BASE_TEMPLATE_SEARCH_LIMIT,
    });

  const {
    data,
    error,
    isFetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(['template-search', query], performSearch, {
    onSuccess: data => {
      metrics.logEvent('Template.Search.ResultsCount', {
        query: query ? toSearchParams(query).toString() : undefined,
        totalResults: data.pages.length > 0 ? data.pages[0].total : 0,
      });
    },
    getNextPageParam: lastPage => lastPage.cursor,
    staleTime: 1000 * 25,
  });

  return {
    items: data,
    error,
    isLoading: isFetching,
    isFetchingMore: isFetchingNextPage,
    canFetchMore: hasNextPage,
    fetchMore: fetchNextPage,
  };
}

export default useTemplateSearch;
