import { combineEpics } from 'redux-observable';
import { empty, from, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { isOfType } from 'typesafe-actions';

import { RootEpic } from '..';
import {
  adminDeleteUserError,
  adminDeleteUserSuccess,
  convertToMentorFailure,
  convertToMentorSuccess,
  receiveUsers,
} from './actions';
import {
  ADMIN_DELETE_USER,
  CONVERT_TO_MENTOR,
  FETCH_USERS,
  SAVE_DEFAULT_LANGUAGE,
  SAVE_SERVICE_NAME,
} from './actionTypes';
export const getUsersEpic: RootEpic = (action$, _, { backendService }) =>
  action$.pipe(
    filter(isOfType(FETCH_USERS)),
    switchMap(() => from(backendService.users.listUsersById())),
    map(data => receiveUsers(data.users, data.byId)),
    catchError(() => empty()),
  );

export const convertUserToMentorEpic: RootEpic = (action$, state$, { backendService }) =>
  action$.pipe(
    filter(isOfType(CONVERT_TO_MENTOR)),
    mergeMap(action =>
      backendService.setClaims(action.payload.uid, action.payload.claims).pipe(
        map(user => convertToMentorSuccess(user)),
        catchError(() => of(convertToMentorFailure(action.payload.uid))),
      ),
    ),
    catchError(() => empty()),
  );

export const saveDefaultLanguageEpic: RootEpic = (action$, _, { database }) =>
  action$.pipe(
    filter(isOfType(SAVE_DEFAULT_LANGUAGE)),
    switchMap(({ payload }) => from(database.setDefaultLanguage(payload))),
    mergeMap(() => empty()),
  );

export const saveServiceNameEpic: RootEpic = (action$, _, { database }) =>
  action$.pipe(
    filter(isOfType(SAVE_SERVICE_NAME)),
    switchMap(({ payload: { name } }) => from(database.setServiceName(name))),
    mergeMap(() => empty()),
  );

export const adminDeleteUserEpic: RootEpic = (action$, state$, { backendService }) =>
  action$.pipe(
    filter(isOfType(ADMIN_DELETE_USER)),
    mergeMap(action =>
      backendService.deleteUser(action.payload.uid).pipe(
        map(() => adminDeleteUserSuccess(action.payload.uid)),
        catchError(() => of(adminDeleteUserError(action.payload.uid))),
      ),
    ),
  );

export const rootAdminEpic = combineEpics(
  getUsersEpic,
  convertUserToMentorEpic,
  saveDefaultLanguageEpic,
  saveServiceNameEpic,
  adminDeleteUserEpic,
);
