import { Button, notification } from 'antd';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useMetrics, useTypedSelector } from '../hooks';
import { getNotifications, NotificationState, removeNotification } from '../modules';
import { notificationStyles } from '../theme';

export const NotificationProvider = ({ children }: { children: React.ReactChild }) => {
  const { t } = useTranslation('home');
  const dispatch = useDispatch();
  const history = useHistory();
  const metrics = useMetrics();
  const notificationState = useTypedSelector(getNotifications);

  const close = useCallback(() => {
    metrics.logEvent('claimchat.notification', { close: true });
    dispatch(removeNotification());
  }, [metrics, dispatch]);

  useEffect(() => {
    if (notificationState.show) {
      const openNotificationWithIcon = (state: NotificationState) => {
        if (state.data.redirectSelfServe) {
          if (state.data.redirectSelfServe === history.location.pathname) {
            // We don't need to show a in app notification when the user is already on that particular route.
            dispatch(removeNotification());
            return;
          }
          const key = `notification-${state.data.id}`;
          metrics.logEvent('Notification', { prompt: true });
          const confirmButton = (
            <Button
              type="primary"
              size="small"
              onClick={() => {
                dispatch(removeNotification());
                metrics.logEvent('Notification', { accept: true });
                history.push(state.data.redirectSelfServe);
                notification.close(key);
              }}
            >
              Open
            </Button>
          );
          notification.info({
            message: state.title,
            description: state.body,
            duration: 30,
            btn: confirmButton,
            key,
            onClose: () => {
              metrics.logEvent('Notification', { accept: false });
              notification.close(key);
            },
            style: notificationStyles,
          });
        } else {
          metrics.logEvent('claimchat.notification', { prompt: true });
          // Old way of handling notifications where we only had one notification for mentors
          const key = `notification-${state.data.id}`;
          const confirmButton = (
            <Button
              type="primary"
              size="small"
              onClick={() => {
                dispatch(removeNotification());
                metrics.logEvent('claimchat.notification', { cancelled: true });
                notification.close(key);
              }}
            >
              {t('Confirm')}
            </Button>
          );
          notification.info({
            message: state.title,
            description: state.body,
            duration: 30,
            btn: confirmButton,
            key,
            onClose: close,
            style: notificationStyles,
          });
        }
      };
      openNotificationWithIcon(notificationState);
    }
  }, [
    close,
    dispatch,
    metrics,
    notificationState.show,
    notificationState.data,
    t,
    notificationState,
    history,
  ]);

  return <>{children}</>;
};
export default NotificationProvider;
