import { createAction } from 'typesafe-actions';

import { MSClaims, RoleUser } from '../../services/backendService';
import { UserType } from '../../services/db';
import { ById } from '.';
import * as ActionTypes from './actionTypes';

export const fetchUsers = createAction(ActionTypes.FETCH_USERS);

export const receiveUsers = createAction(
  ActionTypes.RECEIVE_USERS,
  action => (users: RoleUser[], byId: ById<RoleUser>) => action({ users, byId }),
);

export const receiveUserTypes = createAction(
  ActionTypes.RECEIVE_USERTYPES,
  action => (userTypes: ById<UserType>) => action(userTypes),
);
export const convertToMentor = createAction(
  ActionTypes.CONVERT_TO_MENTOR,
  action => (uid: string, claims: MSClaims) => action({ uid, claims }),
);
export const convertToMentorSuccess = createAction(
  ActionTypes.CONVERT_TO_MENTOR_SUCCESS,
  action => (user: RoleUser) => action(user),
);
export const convertToMentorFailure = createAction(
  ActionTypes.CONVERT_TO_MENTOR_FAILURE,
  action => (uid: string) => action(uid),
);

export const saveDefaultLanguage = createAction(
  ActionTypes.SAVE_DEFAULT_LANGUAGE,
  action => (lang: string) => action(lang),
);

export const saveServiceName = createAction(
  ActionTypes.SAVE_SERVICE_NAME,
  action => (name: string) => action({ name }),
);

export const adminDeleteUser = createAction(
  ActionTypes.ADMIN_DELETE_USER,
  action => (uid: string) => action({ uid }),
);

export const adminDeleteUserSuccess = createAction(
  ActionTypes.ADMIN_DELETE_USER_SUCCESS,
  action => (uid: string) => action({ uid }),
);

export const adminDeleteUserError = createAction(
  ActionTypes.ADMIN_DELETE_USER_ERROR,
  action => (error: string) => action({ error }),
);
