import { useFlag } from '@unleash/proxy-client-react';
import { Drawer, Dropdown, Menu, Modal } from 'antd';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useCallback, useContext, useRef, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, NavLink, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import styled, { css } from 'styled-components';

import { useIsDesktop, useMetrics, useTypedSelector } from '../../../hooks';
import useInfiniteNotificationsList from '../../../hooks/useInfiniteNotificationsList';
import useMe from '../../../hooks/useMe';
import { useOnOutsideClick } from '../../../hooks/useOnOutsideClick';
import useTenants from '../../../hooks/useTenants';
import { ById, getRole, signOut } from '../../../modules';
import { PWAContext } from '../../../providers/PwaInstallProvider';
import { BaseTransition } from '../../../styled/utils';
import theme from '../../../theme';
import { getDestinationTenantFromUrl } from '../../../utils/navigatorUtils';
import { Button } from '../../_atoms/_Button';
import {
  AnalyticsIcon,
  ArrowRightIcon,
  BellIcon,
  ChatBubbleIcon,
  CrossIcon,
  DiscoverIcon,
  ExternalLinkIcon,
  EyeOnIcon,
  FlashIcon,
  GoBackArrowIcon,
  MenuIcon,
  QuestionMarkIcon,
  SignOutIcon,
  TopicsIcon,
  UserIcon,
} from '../../_atoms/_Icons';
import { LogoSvg } from '../../_atoms/_Icons/Svgs';
import InvitationModal from '../../_molecules/InvitationModal/InvitationModal';
import SmartProfile from '../../_molecules/SmartProfile';
import ChevronRight from '../../Icons/ChevronRight';
import WhiteLogo from '../../Icons/WhiteLogo';
import NickNameModal from '../NickNameModal';
import NotificationsList from '../NotificationsList/NotificationsList';

const IconLink = ({
  text,
  to,
  icon,
  unreadMessages,
}: {
  text: string;
  to: string;
  unreadMessages?: number;
  icon: React.ReactNode;
}) => {
  const { url } = useRouteMatch();
  const match = useRouteMatch(`/:tenantId/${to}`);

  const isActive = !!match?.path.includes(to);
  const { t } = useTranslation('navigation');
  const hasUnreadMesages = !!unreadMessages && unreadMessages > 0;
  return (
    <NavLink
      style={{
        height: '100%',
      }}
      tabIndex={0}
      to={`${url}/${to}`}
    >
      <IconLinkContainer>
        <IconWrapper>
          {icon}
          {!isActive && hasUnreadMesages && unreadMessages && (
            <UnreadMessagesTagDesktop>
              {unreadMessages >= 10 ? <Dot /> : unreadMessages}
            </UnreadMessagesTagDesktop>
          )}
        </IconWrapper>
        <p style={{ marginBottom: 0 }}>{t(text)}</p>
      </IconLinkContainer>
    </NavLink>
  );
};

const ActiveIndiactor = styled.div<{ $visible: boolean }>`
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  opacity: 0;
  height: 3px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  background-color: white;
  ${({ $visible }) =>
    $visible &&
    css`
      opacity: 1;
    `}
`;

const Dot = styled.span`
  height: 4px;
  width: 4px;
  border-radius: 4px;
  background: white;
`;

const IconWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  svg {
    height: 20px;
    width: 20px;
  }
`;

const MobileIconLink = ({
  text,
  to,
  icon,
  unreadMessages,
}: {
  text: string;
  to: string;
  unreadMessages?: number;
  icon: React.ReactNode;
}) => {
  const { onClose } = useContext(MobileCloseContext);
  const { url } = useRouteMatch();

  const { t } = useTranslation('Host');
  const match = useRouteMatch(to);
  const isActive = !!match?.path.includes(to);
  const hasUnreadMesages = !!unreadMessages && unreadMessages > 0;
  return (
    <NavLink onClick={onClose} to={`${url}/${to}`}>
      <MobileIconLinkContainer isActive={isActive}>
        <MobileIconWrapper>
          {icon}
          {!isActive && hasUnreadMesages && unreadMessages && (
            <UnreadMessagesTagMobile>
              {unreadMessages >= 10 ? <Dot /> : unreadMessages}
            </UnreadMessagesTagMobile>
          )}
        </MobileIconWrapper>{' '}
        <p style={{ marginBottom: 0 }}>{t(text)}</p>
      </MobileIconLinkContainer>
    </NavLink>
  );
};

const UnreadMessagesTagCore = styled.div`
  height: 16px;
  width: 16px;
  border-radius: 16px;
  background-color: ${theme.colors.green};
  position: absolute;

  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
`;

const UnreadMessagesTagMobile = styled(UnreadMessagesTagCore)`
  top: -7px;
  right: 5px;
`;

const UnreadMessagesTagDesktop = styled(UnreadMessagesTagCore)`
  top: -2px;
  right: -2px;
`;

const UnreadNotificationsTagDesktop = styled(UnreadMessagesTagCore)`
  top: -2px;
  right: -2px;
`;

const MobileIconWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 50px;
  svg {
    height: 20px;
  }
`;

const SmallIconLinkContainer = styled.div`
  position: relative;
  color: white;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SmallIcon = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    height: 26px;
    width: 26px;
  }
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  padding: 8px;
  border-radius: 50px;
  transition: background-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
`;

const RootIconLinkContainer = styled.div`
  position: relative;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 80px;
  padding: 0px 15px;
  & p {
    margin-top: 2px;
    font-weight: bold;
    font-size: 18px;
  }
  svg {
    height: 26px;
    width: 26px;
    margin-right: 5px;
  }
  transition: background-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

const MobileIconLinkContainer = styled.div<{ isActive: boolean }>`
  width: 100%;
  color: white;
  display: flex;
  border-radius: ${({ isActive }) => (isActive ? '7px' : '0px')};
  background-color: ${({ isActive }) =>
    isActive ? 'rgba(255, 255, 255, 0.1)' : 'transparent'};
  align-items: center;
  padding: 20px 10px;
  transition: all 0.2s ease-in;
  & p {
    font-weight: bold;
    font-size: 16px;
  }
  svg {
    height: 20px;
    flex: 0 0 70px;
  }

  @media (max-width: 360px) {
    width: 70%;
    margin-left: 60px !important;
  }
`;

const IconLinkContainer = styled(RootIconLinkContainer)`
  height: 100%;
`;

const MenuItemContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${theme.colors.black};
  max-width: 100%;
  p {
    margin-left: 10px;
    margin-bottom: 0;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 0px);
  }
  span {
    margin-left: 10px;
    margin-bottom: 0;
  }
  svg {
    height: 20px;
    width: 20px;
  }
`;

const ClassesMenuItem = () => {
  const { url } = useRouteMatch();
  const history = useHistory();

  const handlePodsClicked = () => {
    if (isDesktop) {
      history.push(`${url}/topics`);
    }
  };
  const isDesktop = useIsDesktop();
  return (
    <IconLinkContainer onClick={handlePodsClicked}>
      <TopicsIcon />

      <p style={{ marginBottom: 0 }}>Pods</p>
    </IconLinkContainer>
  );
};

const TenantsMenuItem = () => {
  const { data, filteredTenants, switch: switchTenant } = useTenants();
  const activeTenantId = getDestinationTenantFromUrl();
  const { t } = useTranslation('Host');
  const menu = (
    <Menu key="menu">
      <TenantMenuItemContainer>
        <p
          style={{
            marginLeft: 10,
            color: '#5F6368',
            fontSize: 12,
            userSelect: 'none',
            fontWeight: 'bold',
          }}
        >
          {t('Your schools')}
        </p>
      </TenantMenuItemContainer>
      {filteredTenants &&
        filteredTenants.user.availableTenants.map(tenant => {
          return (
            <Menu.Item
              key={tenant.tenantId}
              disabled={activeTenantId === tenant.tenantId}
            >
              <TenantMenuItemContainer
                onClick={() => {
                  if (activeTenantId !== tenant.tenantId) {
                    switchTenant(tenant.tenantId);
                  }
                }}
              >
                <ExternalLinkIcon />
                <p style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>
                  {tenant.tenantName}
                </p>
              </TenantMenuItemContainer>
            </Menu.Item>
          );
        })}
    </Menu>
  );
  const originMath = useRouteMatch('/topics');
  const match = useRouteMatch('/topics/:page');
  const isActive = match?.path.includes('topics') || originMath?.path.includes('topics');

  const isDesktop = useIsDesktop();
  return (
    <DropDownContainer>
      {data ? (
        data.user.availableTenants.length > 1 ? (
          <Dropdown
            overlayStyle={{
              boxShadow: '0px 2px 20px rgba(0, 0, 0, 0.2)',
              overflowY: 'auto',
              maxHeight: 300,
              borderRadius: 10,
            }}
            overlay={menu}
            trigger={isDesktop ? ['hover'] : ['click']}
          >
            <IconLinkContainer style={{ marginRight: 10, borderRadius: 5 }}>
              <p style={{ marginBottom: 0, marginRight: 5 }}>
                {data?.user.availableTenants
                  .find(t => t.tenantId === activeTenantId)
                  ?.tenantName.substring(0, 30)}
              </p>
              <IconWrapperDown>
                <ChevronRight color={theme.colors.white} />
              </IconWrapperDown>
              <ActiveIndiactor $visible={!!isActive} />
            </IconLinkContainer>
          </Dropdown>
        ) : (
          undefined
        )
      ) : (
        undefined
      )}
    </DropDownContainer>
  );
};

const TenantMenuItemContainer = styled(MenuItemContainer)`
  justify-content: flex-start;
  span {
    margin-left: 0px;
  }
  p {
    margin-left: 5px;
  }
  svg {
    margin-left: 0px;
    height: 16px;
    width: 16px;
  }
`;

const DropDownContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 10px 0px;
`;

const IconWrapperDown = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    transform-origin: center;
    transform: rotate(90deg);
    height: 16px;
    width: 16px;
  }
`;

const MainLogo = () => (
  <Link
    style={{
      margin: 0,
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      marginRight: 30,
    }}
    to="/"
  >
    <ShortLogo />
  </Link>
);

const NotificationsMobileMenuItem = () => {
  const { notifications } = useInfiniteNotificationsList();
  const unread = notifications
    ? notifications.pages.flatMap(r => r.items).filter(nt => !nt.received).length
    : undefined;
  const { t } = useTranslation('Host');
  return (
    <MobileIconLink
      unreadMessages={unread && unread > 0 ? unread : undefined}
      key="notifications"
      icon={<BellIcon />}
      text={t('Notifications')}
      to="notifications"
    />
  );
};

const HelpMenuItem = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation('Host');
  const animationProps = {
    initial: { opacity: 0 },
    exit: { opacity: 0 },
    animate: {
      opacity: 1,
    },
    transition: {
      duration: 0.15,
    },
  };
  const metrics = useMetrics();

  const ContainerRef = useRef<HTMLDivElement>(null);
  const isDesktop = useIsDesktop();

  const handleOutSideClick = useCallback(() => {
    setIsOpen(false);
  }, []);

  useOnOutsideClick(ContainerRef, handleOutSideClick);

  const handleWatchHowItWorksCancel = () => {
    setShowHowItWorks(false);
    metrics.logEvent('Games.HelpIcon.ShowHowItWorks.Cancel');
  };

  const [showHowItWorks, setShowHowItWorks] = useState(false);

  const { show } = useIntercom();

  return (
    <NotificationsWrapper>
      <SmallIconLinkContainer onClick={() => setIsOpen(!isOpen)}>
        <SmallIcon>
          <QuestionMarkIcon />
        </SmallIcon>
      </SmallIconLinkContainer>
      <AnimatePresence>
        {isOpen && (
          <ProfileDropdownContainer ref={ContainerRef}>
            <motion.div {...animationProps}>
              <ProfileMenu style={{ maxHeight: 200, overflowY: 'auto' }}>
                <ProfileMenuItem $disableHover>
                  <span style={{ color: '#5F6368', fontSize: 12 }}>
                    {t('Help & troubleshooting')}
                  </span>
                </ProfileMenuItem>
                <ProfileMenuItem
                  onClick={() => {
                    metrics.logEvent('Topbar.HelpIcon.ShowHowItWorks.Open');
                    setShowHowItWorks(true);
                  }}
                >
                  <ProfileMenutItemContainer style={{ justifyContent: 'flex-start' }}>
                    <EyeOnIcon />
                    <span style={{ whiteSpace: 'nowrap' }}>{t('How it works')}</span>
                  </ProfileMenutItemContainer>
                </ProfileMenuItem>
                <ProfileMenuItem
                  onClick={() => {
                    metrics.logEvent('Topbar.HelpIcon.Intercom.Open');
                    show();
                  }}
                >
                  <ProfileMenutItemContainer style={{ justifyContent: 'flex-start' }}>
                    <ChatBubbleIcon />
                    <span style={{ whiteSpace: 'nowrap' }}>{t('Support & help')}</span>
                  </ProfileMenutItemContainer>
                </ProfileMenuItem>
                <HowItWorksModal
                  visible={showHowItWorks}
                  onOk={handleWatchHowItWorksCancel}
                  onCancel={handleWatchHowItWorksCancel}
                  centered
                  bodyStyle={{ padding: 0 }}
                  footer={null}
                  closable={false}
                  destroyOnClose
                  width={isDesktop ? '80%' : '90%'}
                >
                  <iframe
                    style={{ borderRadius: 10 }}
                    width="100%"
                    height="100%"
                    src="https://www.youtube.com/embed/uaPks8C13Ms"
                    title={t('YouTube video player')}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </HowItWorksModal>
              </ProfileMenu>
            </motion.div>
          </ProfileDropdownContainer>
        )}
      </AnimatePresence>
    </NotificationsWrapper>
  );
};

const NotificationsMenuItem = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { notifications } = useInfiniteNotificationsList();
  const ContainerRef = useRef<HTMLDivElement>(null);
  const handleOutSideClick = useCallback(() => {
    setIsOpen(false);
  }, []);

  const animationProps = {
    initial: { opacity: 0 },
    exit: { opacity: 0 },
    animate: {
      opacity: 1,
    },
    transition: {
      duration: 0.15,
    },
  };

  useOnOutsideClick(ContainerRef, handleOutSideClick);
  const unread = notifications
    ? notifications.pages.flatMap(r => r.items).filter(nt => !nt.received).length
    : 0;
  return (
    <NotificationsWrapper>
      <IconClickWrapper onClick={() => setIsOpen(!isOpen)}>
        {unread > 0 && (
          <UnreadNotificationsTagDesktop>
            {unread >= 10 ? <Dot /> : unread}
          </UnreadNotificationsTagDesktop>
        )}
        <BellIcon />
      </IconClickWrapper>
      <AnimatePresence>
        {isOpen && (
          <motion.div {...animationProps}>
            <NotificationsContainer ref={ContainerRef}>
              <NotificationsList onClose={() => setIsOpen(false)} />
            </NotificationsContainer>
          </motion.div>
        )}
      </AnimatePresence>
    </NotificationsWrapper>
  );
};

const IconClickWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 42px;
  height: 42px;
  width: 42px;
  ${BaseTransition}
  svg {
    height: 23px;
    width: 23px;
  }
  &:hover {
    background-color: rgba(192, 192, 192, 0.3);
  }
`;

const NotificationsContainer = styled.div`
  position: absolute;
  top: 65px;
  right: -60px;
  z-index: 1000;
`;

const NotificationsWrapper = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NewProfileMenuItem = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { me } = useMe();
  const match = useRouteMatch('/:tenantId');
  const history = useHistory();
  const dispatch = useDispatch();
  const metrics = useMetrics();
  const { t } = useTranslation('Host');

  const role = useTypedSelector(getRole);
  const [openAdminMenu, setOpenAdminMenu] = useState(false);

  const installContext = useContext(PWAContext);

  const ContainerRef = useRef<HTMLDivElement>(null);

  const handleOutSideClick = useCallback(() => {
    setIsOpen(false);
    metrics.logEvent('TopBar.Profile.Close');
  }, [metrics]);

  useOnOutsideClick(ContainerRef, handleOutSideClick);

  const { filteredTenants: tenants } = useTenants(true);

  const [openNickNameModal, setOpenNickNameModal] = useState(false);

  const [isInviting, setIsInviting] = useState<{
    visible: boolean;
    role: 'student' | 'mentor';
    isSignup?: boolean;
  }>();

  const handleLogout = () => {
    dispatch(signOut());
  };

  const handleClose = () => {
    metrics.logEvent('TopBar.Profile.Close');
    setIsOpen(false);
  };

  const handleOpen = () => {
    metrics.logEvent('TopBar.Profile.Open');
    setIsOpen(true);
  };

  const handleOpenProfile = () => {
    history.push(`${match?.url}/profile`);
    handleClose();
  };
  const handleOpenNickNameModal = () => {
    setOpenNickNameModal(true);
  };
  const handleNickNameDone = () => {
    setOpenNickNameModal(false);
    handleClose();
  };

  const handleOpenFeedback = () => {
    history.push(`${match?.url}/feedback`);
    handleClose();
  };
  const handleOpenNews = () => {
    history.push(`${match?.url}/news`);
    handleClose();
  };

  const handleOpenConfiguration = () => {
    history.push(`${match?.url}/management/configuration`);
    handleClose();
  };
  const handleOpenUsers = () => {
    history.push(`${match?.url}/management/users`);
    handleClose();
  };

  const handleInstallApp = () => {
    if (installContext?.event) {
      metrics.logEvent('Install.Initiate');
      installContext.event.prompt();
    }
  };

  const handleToggleProfileMenu = () => {
    if (isOpen) {
      handleClose();
    } else {
      handleOpen();
    }
  };

  const handleInvite = (
    invite: { visible: boolean; role: 'student' | 'mentor'; isSignup?: boolean },
    from: string,
  ) => {
    metrics.logEvent('Invite.Init', { from });
    handleToggleProfileMenu();
    setIsInviting(invite);
  };

  const animationProps = {
    initial: { opacity: 0 },
    exit: { opacity: 0 },
    animate: {
      opacity: 1,
    },
    transition: {
      duration: 0.15,
    },
  };

  const currentTenantId = getDestinationTenantFromUrl();

  const tenantName = tenants?.user?.availableTenants
    .find(t => t.tenantId === currentTenantId)
    ?.tenantName.substring(0, 30);

  return (
    <NotificationsWrapper style={{ marginRight: 8 }}>
      {isInviting && isInviting.role && (
        <InvitationModal
          visible={!!(isInviting && isInviting.visible)}
          onDone={() => setIsInviting(undefined)}
          role={isInviting?.role}
          isSignup={isInviting?.isSignup}
        />
      )}
      <SmartProfile onClick={handleToggleProfileMenu} />
      {openNickNameModal && (
        <NickNameModal onDone={handleNickNameDone} visible={openNickNameModal} />
      )}
      <AnimatePresence>
        {isOpen && (
          <ProfileDropdownContainer ref={ContainerRef}>
            <motion.div {...animationProps}>
              <ProfileMenu>
                <ProfileMenuItem onClick={handleOpenProfile}>
                  <ProfileInfoItem>
                    <SmartProfile />
                    <h3>{me?.nickName}</h3>
                    <span>{me?.email}</span>
                    <span style={{ color: '#a5a5a5' }}>{tenantName}</span>
                  </ProfileInfoItem>
                </ProfileMenuItem>
                <MenuDivider>
                  <Line />
                </MenuDivider>
                <ProfileMenuItem onClick={handleOpenNickNameModal}>
                  <span>{t('Change nickname')}</span>
                </ProfileMenuItem>
                <ProfileMenuItem onClick={handleOpenProfile}>
                  <span>{t('Preferences and language')}</span>
                </ProfileMenuItem>
                {role === 'admin' && (
                  <ProfileMenuItem>
                    <ProfileMenutItemContainer onClick={() => setOpenAdminMenu(true)}>
                      <span>{t('School settings')}</span>
                      <IconWrapper>
                        <ArrowRightIcon />
                      </IconWrapper>
                    </ProfileMenutItemContainer>
                    <AnimatePresence>
                      {openAdminMenu && (
                        <motion.div {...animationProps}>
                          <ProfileSubMenu>
                            <ProfileMenu style={{ maxHeight: 200, overflowY: 'auto' }}>
                              <ProfileMenuItem $disableHover>
                                <span style={{ color: '#5F6368', fontSize: 12 }}>
                                  {t('School settings')}
                                </span>
                              </ProfileMenuItem>
                              <ProfileMenuItem key={'users'}>
                                <ProfileMenutItemContainer
                                  onClick={handleOpenUsers}
                                  style={{ justifyContent: 'flex-start' }}
                                >
                                  <span>{t('Users')}</span>
                                </ProfileMenutItemContainer>
                              </ProfileMenuItem>
                              <ProfileMenuItem key={'configuration'}>
                                <ProfileMenutItemContainer
                                  onClick={handleOpenConfiguration}
                                  style={{ justifyContent: 'flex-start' }}
                                >
                                  <span>{t('Configuration')}</span>
                                </ProfileMenutItemContainer>
                              </ProfileMenuItem>
                            </ProfileMenu>
                          </ProfileSubMenu>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </ProfileMenuItem>
                )}
                {(role === 'admin' || role === 'mentor') && (
                  <ProfileMenuItem
                    onClick={() =>
                      handleInvite({ visible: true, role: 'mentor' }, 'Topbar')
                    }
                  >
                    <span>
                      {t('Invite teachers to tenantName', {
                        defaultValue: 'Invite teachers to {{tenantName}}',
                        tenantName: tenantName,
                      })}
                    </span>
                  </ProfileMenuItem>
                )}
                <ProfileMenuItem onClick={handleOpenNews}>
                  <span>{t("What's new")}</span>
                </ProfileMenuItem>
                <ProfileMenuItem onClick={handleOpenFeedback}>
                  <span>{t('Got feedback?')}</span>
                </ProfileMenuItem>
                {installContext?.hasEvent && (
                  <ProfileMenuItem onClick={handleInstallApp}>
                    <span>{t('Install the app')}</span>
                  </ProfileMenuItem>
                )}
                <MenuDivider>
                  <Line />
                </MenuDivider>
                <ProfileMenuItem $disableHover style={{ padding: 20 }}>
                  <Button
                    onClick={handleLogout}
                    height={'32px'}
                    width="100%"
                    type="ghost"
                  >
                    {t('Logout')}
                  </Button>
                </ProfileMenuItem>
              </ProfileMenu>
            </motion.div>
          </ProfileDropdownContainer>
        )}
      </AnimatePresence>
    </NotificationsWrapper>
  );
};

const ProfileSubMenu = styled.div`
  position: absolute;
  transform-origin: right;
  width: 100%;
  left: calc(-100% - 2px);
  top: 0;
  z-index: 1001;
  border-top-right-radius: 0px;
`;

const ProfileMenutItemContainer = styled.div<{ $active?: boolean }>`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  color: ${theme.colors.black};

  ${({ $active }) =>
    $active &&
    css`
      color: ${theme.colors.white} !important;
    `}
  svg {
    height: 16px;
    width: 16px;
  }
  & > *:not(:first-child) {
    margin-left: 5px;
  }
  & > *:not(:last-child) {
    margin-right: 5px;
  }
`;

const ProfileInfoItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 30px;

  h3,
  span {
    text-align: center;
    font-weight: bold;
  }
  h3 {
    color: ${theme.colors.black};
  }
  &&span {
    color: #5f6368;
  }
`;

const MenuDivider = styled.div`
  width: 100%;
  padding: 0px 5px;
`;

const Line = styled.div`
  height: 1px;
  background-color: #a5a5a5;
  width: 100%;
`;

const ProfileMenuItem = styled.li<{ $disableHover?: boolean; $active?: boolean }>`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  padding: 5px 20px;

  ${({ $disableHover }) =>
    !$disableHover &&
    css`
      &:hover {
        background-color: ${theme.colors.greyLight};
      }
    `}
  span {
    color: ${theme.colors.black};
    white-space: nowrap;
    font-weight: bold;
  }
  &:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  ${({ $disableHover }) =>
    $disableHover &&
    css`
      cursor: default;
    `}

  ${({ $active }) =>
    $active &&
    css`
      background-color: ${theme.colors.orange};
      span {
        color: ${theme.colors.white} !important;
      }
    `};
`;

const ProfileMenu = styled.ul`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);
  background-color: ${theme.colors.white};
  padding-inline-start: 0px;
  transform-origin: right;
`;

const ProfileDropdownContainer = styled.div`
  position: absolute;
  top: 65px;
  right: 0px;
  transform-origin: right;
  z-index: 1000;
`;

const DiscoverLink = () => {
  const isEnabled = useFlag('discover');
  const { t } = useTranslation('Host');
  if (!isEnabled) return null;
  return (
    <IconLink key="discover" icon={<DiscoverIcon />} text={t('Discover')} to="discover" />
  );
};

const DiscoverLinkMobile = () => {
  const isEnabled = useFlag('discover');
  const { t } = useTranslation('Host');
  if (!isEnabled) return null;
  return (
    <MobileIconLink
      key="discover"
      icon={<DiscoverIcon />}
      text={t('Discover')}
      to="discover"
    />
  );
};

const config = (t: TFunction<'Host'>) => ({
  admin: {
    left: <MainLogo />,
    middle: [
      <IconLink key="q&as" icon={<FlashIcon />} text="Live" to="live/lessons" />,
      <DiscoverLink key="discover" />,
      <ClassesMenuItem key="classes" />,
    ],
    right: [
      <TenantsMenuItem key="tenants" />,
      <NewProfileMenuItem key="profile" />,
      <NotificationsMenuItem key="notifications" />,
      <HelpMenuItem key="help" />,
    ],
  },
  mentor: {
    left: <MainLogo />,
    middle: [
      <IconLink key="q&as" icon={<FlashIcon />} text={t('Live')} to="live/lessons" />,
      <DiscoverLink key="discover" />,
      <ClassesMenuItem key="classes" />,
    ],
    right: [
      <TenantsMenuItem key="tenants" />,
      <NewProfileMenuItem key="profile" />,
      <NotificationsMenuItem key="notifications" />,
      <HelpMenuItem key="help" />,
    ],
  },
  student: {
    left: <MainLogo />,
    middle: [
      <ClassesMenuItem key="classes" />,
      <DiscoverLink key="discover" />,
      <IconLink key="q&as" icon={<FlashIcon />} text={t('Live')} to="live/lessons" />,
    ],
    right: [
      <TenantsMenuItem key="tenants" />,
      <NewProfileMenuItem key="profile" />,
      <NotificationsMenuItem key="notifications" />,
      <HelpMenuItem key="help" />,
    ],
  },
});

const mobileConfig = (t: TFunction<'Host'>) => ({
  admin: {
    middle: [
      <MobileIconLink
        key="q&as"
        icon={<FlashIcon />}
        text={t('My lessons')}
        to="live/lessons"
      />,
      <DiscoverLinkMobile key="discover" />,
      <MobileIconLink
        key="results"
        icon={<AnalyticsIcon />}
        text={t('Results')}
        to="live/results"
      />,
      <MobileIconLink key="topics" icon={<TopicsIcon />} text={t('Pods')} to="topics" />,
      <MobileIconLink key="4" icon={<UserIcon />} text={t('Profile')} to="profile" />,
      <NotificationsMobileMenuItem key="notifications" />,
    ],
    bottom: () => <></>,
  },
  mentor: {
    middle: [
      <MobileIconLink
        key="q&as"
        icon={<FlashIcon />}
        text={t('My lessons')}
        to="live/lessons"
      />,
      <DiscoverLinkMobile key="discover" />,
      <MobileIconLink
        key="results"
        icon={<AnalyticsIcon />}
        text={t('Results')}
        to="live/results"
      />,
      <MobileIconLink key="topics" icon={<TopicsIcon />} text={t('Pods')} to="topics" />,
      <MobileIconLink key="4" icon={<UserIcon />} text={t('Profile')} to="profile" />,
      <NotificationsMobileMenuItem key="notifications" />,
    ],
    bottom: () => <></>,
  },
  student: {
    middle: [
      <MobileIconLink
        key="q&as"
        icon={<FlashIcon />}
        text={t('My lessons')}
        to="live/lessons"
      />,
      <DiscoverLinkMobile key="discover" />,
      <MobileIconLink
        key="results"
        icon={<AnalyticsIcon />}
        text={t('Results')}
        to="live/results"
      />,
      <MobileIconLink key="topics" icon={<TopicsIcon />} text={t('Pods')} to="topics" />,
      <MobileIconLink key="4" icon={<UserIcon />} text={t('Profile')} to="profile" />,
      <NotificationsMobileMenuItem key="notifications" />,
    ],
    bottom: () => <></>,
  },
});

const maps: ById<string> = {
  search: 'Search',
  feed: 'Feed',
  profile: 'Profile',
  snippets: 'Snippets',
  queue: 'Answer',
  answer: 'Answer',
  review: 'Review',
  notifications: 'Notifications',
  feedback: 'Feedback',
  lessons: 'My lessons',
  results: 'My results',
};
const MobilePageTitlesByRoute = (pathname: string) => {
  const splittedPath = pathname.split('/');
  if (splittedPath.includes('lessons')) return 'My lessons';
  if (splittedPath.includes('results')) return 'My results';
  if (splittedPath.includes('preview')) return 'Back to lesson';
  if (splittedPath.includes('discover')) return 'Discover';
  if (splittedPath.includes('templates')) return 'My lessons';
  return maps[splittedPath[splittedPath.length - 1]] as string;
};

type MobileMenuProps = {
  visible: boolean;
  onClose: () => void;
};
const MobileMenu = ({ visible, onClose }: MobileMenuProps) => {
  const role = useTypedSelector(getRole);
  const { t } = useTranslation('Host');
  const dispatch = useDispatch();
  const handleSignOut = () => dispatch(signOut());
  if (!role) return null;
  return (
    <Drawer
      placement="right"
      closable={false}
      onClose={onClose}
      visible={visible}
      key="left"
      drawerStyle={{ backgroundColor: theme.colors.blueDark, color: 'white' }}
    >
      <MobileCloseContext.Provider value={{ onClose }}>
        <MobileMenuItemsContainer>
          <div>
            <HeaderMenuContainer key="1">
              <MainLogo />
              <IconClickWrapper onClick={onClose}>
                <CrossIcon />
              </IconClickWrapper>
            </HeaderMenuContainer>
            {mobileConfig(t)[role].middle}
          </div>
          <div>
            {mobileConfig(t)[role].bottom()}
            <div onClick={handleSignOut}>
              <MobileIconLink
                key="1"
                icon={<SignOutIcon />}
                text={t('Sign out')}
                to="/login"
              />
            </div>
          </div>
        </MobileMenuItemsContainer>
      </MobileCloseContext.Provider>
    </Drawer>
  );
};

const MobileMenuItemsContainer = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const HeaderMenuContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;

  @media (max-width: 360px) {
    justify-content: end;

    a {
      margin: 0px 90px 0px 0px !important;
    }
  }
`;

const ShortLogo = () => {
  return (
    <ShortLogoContainer>
      <LogoSvg />
    </ShortLogoContainer>
  );
};

const ShortLogoContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    height: 60px;
    width: 80px;
    color: ${theme.colors.background};
  }
`;

const MobileCloseContext = React.createContext<{ onClose: () => void }>({
  onClose: () => undefined,
});

const TopBar = () => {
  const { t } = useTranslation('Host');
  const role = useTypedSelector(getRole);
  const isDesktop = useIsDesktop();
  const history = useHistory();
  const match = useRouteMatch('/:tenantId/live/lessons');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const handleMobileMenuClicked = () => {
    setIsMenuOpen(true);
  };

  const location = useLocation();

  const mobileMenuTitle = t(MobilePageTitlesByRoute(location.pathname));

  if (!role) return null;

  const isRoot = !!match?.isExact;

  return isDesktop ? (
    <Container>
      <WidthContainer>
        <GroupContainer $includeMargin>
          <>{config(t)[role].left}</>
          <>{config(t)[role].middle}</>
        </GroupContainer>
        <GroupContainer>{config(t)[role].right}</GroupContainer>
      </WidthContainer>
    </Container>
  ) : (
    <MobileContainer>
      <RootGroupContainer>
        {isRoot && (
          <IconWrapper>
            <WhiteLogo />
          </IconWrapper>
        )}
        {!isRoot && (
          <HoverableIconWrapper onClick={() => history.goBack()}>
            <GoBackArrowIcon />
          </HoverableIconWrapper>
        )}
        <h1
          style={{
            marginLeft: 10,
            marginBottom: 0,
            fontSize: '18px',
            fontWeight: 'bold',
          }}
        >
          {mobileMenuTitle}
        </h1>
      </RootGroupContainer>

      <ClickableIconWrapper onClick={handleMobileMenuClicked}>
        <MenuIcon />
      </ClickableIconWrapper>
      <MobileMenu visible={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
    </MobileContainer>
  );
};

const HoverableIconWrapper = styled(IconWrapper)`
  transition: 0.2s;
  &:hover {
    transform: scale(1.1);
  }
`;
const ClickableIconWrapper = styled.button`
  height: 100%;
  padding: 0 20px;
  border: none;
  background-color: transparent;
  &:focus,
  &:active {
    outline: none;
  }
  cursor: pointer;
  transition: 0.2s;
  &:hover {
    transform: scale(1.1);
  }
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    height: 28px;
    width: 28px;
  }
`;

const WidthContainer = styled.div`
  color: white;
  display: flex;
  height: 60px;
  width: 100%;
  padding: 0px 20px;
  justify-content: space-between;
  align-items: center;
`;

const Container = styled.div`
  z-index: 2000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.colors.orange};
  box-shadow: 0px 4px 4px rgb(0, 0, 0, 0.1);
  height: 60px;
  min-height: 60px;
`;

const HowItWorksModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 10px;
    box-shadow: none;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80vh;
    background-color: transparent;
  }

  .ant-modal-body {
    height: 100%;
    width: 100%;
    box-shadow: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ant-modal {
    height: 100vh;
    padding: 20px;
  }
`;

const MobileContainer = styled(Container)`
  position: fixed;
  height: 60px;
  padding: 14px 20px 14px 20px;
  justify-content: space-between;
  align-items: center;
  color: white;
  h1 {
    color: white;
  }
`;

const RootGroupContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const GroupContainer = styled(RootGroupContainer)<{
  $includeMargin?: boolean;
}>`
  & > * {
    ${({ $includeMargin }) =>
      $includeMargin &&
      css`
        margin: 0 10px;
      `}
  }

  & > *:last-child {
    margin: 0px !important;
  }
`;

export default TopBar;
