import { AuthenticationResult } from '@azure/msal-common';

import { Auth } from '../../providers/AuthSubscriber';
import { MSClaims, UserTenant } from '../../services/backendService';
import { RootAction } from '..';
import { AppState } from '../rootReducer';
import {
  AUTH_LOADED_NO_USER,
  AZURE_LOAD_END,
  GOT_ID_TOKEN_SUCCESS,
  LOAD_AUTH,
  SET_USER,
  TENANTS_RECEIVED,
} from './actionTypes';

export type Claims = {
  admin?: boolean;
  mentor?: boolean;
  tenantId?: string;
  tenantAdmin?: boolean;
  provider?: string;
};

interface UserState {
  auth: Partial<Auth> | null;
  claims?: Claims;
  isLoading: boolean;
  isLoadingAzure: boolean;
  authResult?: AuthenticationResult;
  errorCode?: string;
  errorMessage?: string;
  tenants?: UserTenant;
  roles?: MSClaims;
}

const initState: UserState = {
  isLoading: true,
  isLoadingAzure: true,
  auth: null,
};
export const userReducer = (
  state: UserState = initState,
  action: RootAction,
): UserState => {
  switch (action.type) {
    case AZURE_LOAD_END: {
      return {
        ...state,
        isLoadingAzure: false,
      };
    }
    case GOT_ID_TOKEN_SUCCESS: {
      return {
        ...state,
        authResult: action.payload.result,
      };
    }
    case SET_USER:
      return {
        ...state,
        isLoading: false,
        auth: action.payload.auth,
        claims: action.payload.claims,
      };
    case LOAD_AUTH:
      return {
        ...state,
        isLoading: true,
        auth: null,
      };
    case AUTH_LOADED_NO_USER:
      return {
        ...state,
        isLoading: false,
        auth: null,
      };

    case TENANTS_RECEIVED:
      return {
        ...state,
        tenants: action.payload.user,
        roles: action.payload.roles,
      };
    default:
      return state;
  }
};

// selectors
export const getAuth = (state: AppState) => state.user.claims;
export const getIsLoadingAzure = (state: AppState) => state.user.isLoadingAzure;
export const getIsLoadingAuth = (state: AppState) => state.user.isLoading;

export const getUserId = (state: AppState) => state.user.authResult?.uniqueId;
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
export const getEmail = (state: AppState) => state.user.authResult?.idTokenClaims.email;

export const getIsAuthenticated = (state: AppState) => !!state.user.authResult?.uniqueId;

export const getIsMentor = (state: AppState) => !!state.user.roles?.mentor;
export const getIsTenantAdmin = (state: AppState) => !!state.user.roles?.admin;

export const getIsStudent = (state: AppState) => !!state.user.roles?.student;

export const getActiveTenant = (state: AppState) => state.user.tenants?.activeTenantId;

export const getErrorMessage = (state: AppState) => {
  const { errorMessage, errorCode } = state.user;
  return {
    errorMessage,
    errorCode,
  };
};

export const getRole = (state: AppState) => {
  const isMentor = getIsMentor(state);
  const isAdmin = getIsTenantAdmin(state);
  const isStudent = getIsStudent(state);

  if (isStudent) return 'student';
  if (isMentor) return 'mentor';
  if (isAdmin) return 'admin';
  return undefined;
};
