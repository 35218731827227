export const PollSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="28"
    height="29"
    viewBox="0 0 28 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.6665 15.75H1.6665C1.33498 15.75 1.01704 15.8817 0.78262 16.1161C0.5482 16.3505 0.416504 16.6685 0.416504 17V27C0.416504 27.3315 0.5482 27.6495 0.78262 27.8839C1.01704 28.1183 1.33498 28.25 1.6665 28.25H6.6665C6.99802 28.25 7.31597 28.1183 7.55039 27.8839C7.78481 27.6495 7.9165 27.3315 7.9165 27V17C7.9165 16.6685 7.78481 16.3505 7.55039 16.1161C7.31597 15.8817 6.99802 15.75 6.6665 15.75ZM5.4165 25.75H2.9165V18.25H5.4165V25.75ZM26.6665 10.75H21.6665C21.335 10.75 21.017 10.8817 20.7826 11.1161C20.5482 11.3505 20.4165 11.6685 20.4165 12V27C20.4165 27.3315 20.5482 27.6495 20.7826 27.8839C21.017 28.1183 21.335 28.25 21.6665 28.25H26.6665C26.998 28.25 27.316 28.1183 27.5504 27.8839C27.7848 27.6495 27.9165 27.3315 27.9165 27V12C27.9165 11.6685 27.7848 11.3505 27.5504 11.1161C27.316 10.8817 26.998 10.75 26.6665 10.75ZM25.4165 25.75H22.9165V13.25H25.4165V25.75ZM16.6665 0.75H11.6665C11.335 0.75 11.017 0.881696 10.7826 1.11612C10.5482 1.35054 10.4165 1.66848 10.4165 2V27C10.4165 27.3315 10.5482 27.6495 10.7826 27.8839C11.017 28.1183 11.335 28.25 11.6665 28.25H16.6665C16.998 28.25 17.316 28.1183 17.5504 27.8839C17.7848 27.6495 17.9165 27.3315 17.9165 27V2C17.9165 1.66848 17.7848 1.35054 17.5504 1.11612C17.316 0.881696 16.998 0.75 16.6665 0.75ZM15.4165 25.75H12.9165V3.25H15.4165V25.75Z"
      fill="currentColor"
    />
  </svg>
);
