import React from 'react';

export const SignOutSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 5H6L6 19H9V21H6C4.9 21 4 20.1 4 19V5C4 3.9 4.9 3 6 3H9V5ZM11.33 11L13.91 8.41L12.5 7L7.5 12L12.5 17L13.91 15.59L11.33 13H20V11H11.33Z"
      fill="currentColor"
    />
  </svg>
);

export default SignOutSvg;
