export const ConnectDotsSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_4033_49583)">
      <path
        d="M17.4468 13.3181C16.2402 13.3181 15.2272 14.1597 14.962 15.2864L8.17383 15.2868C8.07703 14.6034 7.81098 13.961 7.39629 13.4094L13.4052 7.40056C13.7956 7.64314 14.2558 7.78373 14.7483 7.78373C16.1562 7.78373 17.3015 6.63838 17.3015 5.23053C17.3015 3.82268 16.1562 2.67732 14.7483 2.67732C13.3405 2.67732 12.1951 3.82268 12.1951 5.23053C12.1951 5.72307 12.3357 6.18322 12.5782 6.57369L6.56949 12.5824C6.01809 12.1678 5.37582 11.9017 4.69246 11.805V5.059C5.81922 4.79381 6.66078 3.78076 6.66078 2.5742C6.66078 1.16635 5.51543 0.0209961 4.10758 0.0209961C2.69973 0.0209961 1.55434 1.16631 1.55434 2.57416C1.55434 3.78088 2.39605 4.79396 3.52301 5.05904V11.8055C1.53398 12.0903 0 13.805 0 15.8715C0 18.1363 1.84273 19.9788 4.10773 19.9788C6.17402 19.9788 7.88859 18.445 8.17328 16.4561L14.9619 16.4558C15.227 17.5827 16.2401 18.4245 17.4468 18.4245C18.8546 18.4245 20 17.2791 20 15.8713C20 14.4634 18.8546 13.3181 17.4468 13.3181ZM1.16941 15.8715C1.16941 14.2513 2.48754 12.9331 4.10773 12.9331C4.89277 12.9331 5.63051 13.2387 6.18512 13.7937C6.74004 14.3486 7.04566 15.0865 7.04566 15.8715C7.04566 17.4915 5.7277 18.8094 4.10773 18.8094C2.48754 18.8094 1.16941 17.4915 1.16941 15.8715ZM14.7483 3.8467C15.5113 3.8467 16.1321 4.46744 16.1321 5.23049C16.1321 5.99353 15.5113 6.61428 14.7483 6.61428C13.9853 6.61428 13.3645 5.99353 13.3645 5.23049C13.3645 4.46744 13.9853 3.8467 14.7483 3.8467ZM2.72375 2.57416C2.72375 1.81111 3.34453 1.19037 4.10754 1.19037C4.87055 1.19037 5.49133 1.81111 5.49133 2.57416C5.49133 3.33721 4.87055 3.95795 4.10754 3.95795C3.34453 3.95795 2.72375 3.33721 2.72375 2.57416ZM17.4468 17.2551C16.6838 17.2551 16.063 16.6344 16.063 15.8713C16.063 15.1083 16.6838 14.4875 17.4468 14.4875C18.2098 14.4875 18.8306 15.1083 18.8306 15.8713C18.8306 16.6344 18.2098 17.2551 17.4468 17.2551Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
