import React from 'react';

export const DisclaimerSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M12.8047 3.55469H8.11719C7.79359 3.55469 7.53125 3.81703 7.53125 4.14062C7.53125 4.46422 7.79359 4.72656 8.11719 4.72656H12.8047C13.1283 4.72656 13.3906 4.46422 13.3906 4.14062C13.3906 3.81703 13.1283 3.55469 12.8047 3.55469Z"
        fill="currentColor"
      />
      <path
        d="M12.8047 5.89844H8.11719C7.79359 5.89844 7.53125 6.16078 7.53125 6.48438C7.53125 6.80797 7.79359 7.07031 8.11719 7.07031H12.8047C13.1283 7.07031 13.3906 6.80797 13.3906 6.48438C13.3906 6.16078 13.1283 5.89844 12.8047 5.89844Z"
        fill="currentColor"
      />
      <path
        d="M12.8047 8.24219H3.42969C3.10609 8.24219 2.84375 8.50453 2.84375 8.82812C2.84375 9.15172 3.10609 9.41406 3.42969 9.41406H12.8047C13.1283 9.41406 13.3906 9.15172 13.3906 8.82812C13.3906 8.50453 13.1283 8.24219 12.8047 8.24219Z"
        fill="currentColor"
      />
      <path
        d="M10.6805 10.5859H3.42969C3.10609 10.5859 2.84375 10.8483 2.84375 11.1719C2.84375 11.4955 3.10609 11.7578 3.42969 11.7578H10.6805C11.0041 11.7578 11.2665 11.4955 11.2665 11.1719C11.2665 10.8483 11.0041 10.5859 10.6805 10.5859Z"
        fill="currentColor"
      />
      <path
        d="M15.7734 10.5859V1.75781C15.7734 0.78625 14.9494 0 13.9766 0H5.77336C5.6248 0 5.47199 0.05875 5.35742 0.173359L0.669922 4.89992C0.562227 5.00848 0.5 5.15945 0.5 5.3125V18.2422C0.5 19.2114 1.28855 20 2.25781 20C3.49398 20 3.90445 20 3.9027 20C5.69969 20 9.2357 20 15.7734 20C18.3607 20 20.5 17.8698 20.5 15.2734C20.5 12.6888 18.3797 10.5859 15.7734 10.5859ZM5.1875 2.00887V4.72656H2.49227L5.1875 2.00887ZM2.25781 18.8281C1.93473 18.8281 1.67188 18.5653 1.67188 18.2422V5.89844H5.77344C6.09703 5.89844 6.35938 5.63609 6.35938 5.3125V1.17188H13.9766C14.3095 1.17188 14.6016 1.44566 14.6016 1.75781V10.7318C13.3579 11.0476 12.3103 11.8539 11.6814 12.9297H3.42969C3.10609 12.9297 2.84375 13.192 2.84375 13.5156C2.84375 13.8392 3.10609 14.1016 3.42969 14.1016H11.1964C11.0988 14.4762 11.0469 14.8689 11.0469 15.2734H3.42969C3.10609 15.2734 2.84375 15.5358 2.84375 15.8594C2.84375 16.183 3.10609 16.4453 3.42969 16.4453H11.1955C11.4323 17.3616 11.9444 18.1875 12.6753 18.8281H2.25781ZM15.7734 18.8281C13.8466 18.8281 12.2188 17.2003 12.2188 15.2734C12.2188 13.3349 13.8134 11.7578 15.7734 11.7578C17.7335 11.7578 19.3281 13.3349 19.3281 15.2734C19.3281 17.2003 17.7003 18.8281 15.7734 18.8281Z"
        fill="currentColor"
      />
    </g>
    <rect
      x="15.1667"
      y="18"
      width="3.86667"
      height="1.2"
      rx="0.6"
      transform="rotate(-90 15.1667 18)"
      fill="currentColor"
    />
    <circle cx="15.7667" cy="13.268" r="0.6" fill="currentColor" />
    <defs>
      <clipPath id="clip0">
        <rect width="20" height="20" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default DisclaimerSvg;
