import { TypedUseSelectorHook, useSelector } from 'react-redux';

import { AppState } from '../modules';

export const useTypedSelector: TypedUseSelectorHook<AppState> = useSelector;

export * from './useIsDesktop';
export * from './useLongPress';
export * from './useMetrics';
export * from './useNotificationPreferences';
export * from './usePrevious';
export * from './useQuery';
export * from './useWindowSize';
