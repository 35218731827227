import { createAction } from 'typesafe-actions';

import * as ActionTypes from './actionTypes';
import { PublicData } from './reducer';

export const subscribeToPublicData = createAction(ActionTypes.PUBLIC_DATA_SUBSCRIBED);

export const publicDataReceived = createAction(
  ActionTypes.PUBLIC_DATA_RECEIVED,
  action => (state: PublicData) => action(state),
);
