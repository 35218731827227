import React from 'react';

export const ShortLogoSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="652"
    height="652"
    viewBox="0 0 652 652"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M652 326.01C652.003 368.817 643.573 411.205 627.194 450.754C610.814 490.303 586.804 526.238 556.535 556.508C526.267 586.777 490.332 610.788 450.784 627.169C411.235 643.551 368.847 651.981 326.04 651.98C280.291 651.98 226.362 649.687 189.813 618.653C148.083 583.245 142.236 526.992 153.658 476.254C158.832 450.848 170.086 427.075 186.455 406.968C202.824 386.861 223.822 371.021 247.651 360.802C268.988 352.366 291.756 348.134 314.699 348.339C360.376 348.339 388.601 361.775 411.841 372.838C428.105 380.58 441.927 387.16 457.581 387.16C483.142 387.16 505.431 372.294 505.431 341.765C505.431 291.39 418.761 245.944 319.668 245.944C159.092 245.944 56.9085 349.409 41.0831 484.425C16.8908 440.917 2.98422 392.446 0.429566 342.729C-2.12509 293.013 6.74021 243.372 26.3456 197.613C45.951 151.854 75.7753 111.193 113.532 78.7483C151.289 46.3033 195.975 22.9363 244.162 10.4392C292.35 -2.05782 342.76 -3.35291 391.526 6.65327C440.292 16.6595 486.119 37.7012 525.491 68.1647C564.864 98.6281 596.738 137.704 618.667 182.396C640.596 227.087 651.998 276.208 652 325.99V326.01ZM267.578 571.764C295.399 571.764 317.952 549.211 317.952 521.39C317.952 493.568 295.399 471.015 267.578 471.015C239.757 471.015 217.203 493.568 217.203 521.39C217.203 549.211 239.757 571.764 267.578 571.764ZM464.177 521.389C464.177 549.21 441.624 571.764 413.803 571.764C385.982 571.764 363.428 549.21 363.428 521.389C363.428 493.568 385.982 471.015 413.803 471.015C441.624 471.015 464.177 493.568 464.177 521.389Z"
      fill="currentColor"
    />
  </svg>
);

export default ShortLogoSvg;
