import React from 'react';

export const SlideHeaderSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="31"
    height="30"
    viewBox="0 0 31 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2812_50012)">
      <path
        d="M27.9805 9H2.35547C1.14922 9 0.167969 9.98125 0.167969 11.1875V14.3125C0.167969 15.5188 1.14922 16.5 2.35547 16.5H27.9805C29.1867 16.5 30.168 15.5188 30.168 14.3125V11.1875C30.168 9.98125 29.1867 9 27.9805 9Z"
        fill="currentColor"
      />
      <path
        d="M26.418 19.5H3.91797C3.50397 19.5 3.16797 20.172 3.16797 21C3.16797 21.828 3.50397 22.5 3.91797 22.5H26.418C26.832 22.5 27.168 21.828 27.168 21C27.168 20.172 26.832 19.5 26.418 19.5Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default SlideHeaderSvg;
