import React from 'react';

export const ChartSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3531_51938)">
      <path
        d="M3.99935 8.66699H1.33268C1.15587 8.66699 0.986302 8.73723 0.861278 8.86225C0.736253 8.98728 0.666016 9.15685 0.666016 9.33366V14.667C0.666016 14.8438 0.736253 15.0134 0.861278 15.1384C0.986302 15.2634 1.15587 15.3337 1.33268 15.3337H3.99935C4.17616 15.3337 4.34573 15.2634 4.47075 15.1384C4.59578 15.0134 4.66602 14.8438 4.66602 14.667V9.33366C4.66602 9.15685 4.59578 8.98728 4.47075 8.86225C4.34573 8.73723 4.17616 8.66699 3.99935 8.66699ZM3.33268 14.0003H1.99935V10.0003H3.33268V14.0003ZM14.666 6.00033H11.9993C11.8225 6.00033 11.653 6.07056 11.5279 6.19559C11.4029 6.32061 11.3327 6.49018 11.3327 6.66699V14.667C11.3327 14.8438 11.4029 15.0134 11.5279 15.1384C11.653 15.2634 11.8225 15.3337 11.9993 15.3337H14.666C14.8428 15.3337 15.0124 15.2634 15.1374 15.1384C15.2624 15.0134 15.3327 14.8438 15.3327 14.667V6.66699C15.3327 6.49018 15.2624 6.32061 15.1374 6.19559C15.0124 6.07056 14.8428 6.00033 14.666 6.00033ZM13.9993 14.0003H12.666V7.33366H13.9993V14.0003ZM9.33268 0.666992H6.66602C6.4892 0.666992 6.31963 0.73723 6.19461 0.862254C6.06959 0.987279 5.99935 1.15685 5.99935 1.33366V14.667C5.99935 14.8438 6.06959 15.0134 6.19461 15.1384C6.31963 15.2634 6.4892 15.3337 6.66602 15.3337H9.33268C9.50949 15.3337 9.67906 15.2634 9.80409 15.1384C9.92911 15.0134 9.99935 14.8438 9.99935 14.667V1.33366C9.99935 1.15685 9.92911 0.987279 9.80409 0.862254C9.67906 0.73723 9.50949 0.666992 9.33268 0.666992ZM8.66602 14.0003H7.33268V2.00033H8.66602V14.0003Z"
        fill="currentColor"
      />
      <g clipPath="url(#clip1_3531_51938)">
        <path
          d="M126.072 45.9375C122.395 45.9375 119.414 48.918 119.414 52.5955V111.711C119.401 122.738 110.468 131.675 99.4402 131.685H-87.3761C-98.4034 131.675 -107.337 122.738 -107.35 111.711V-61.7892C-107.337 -72.8139 -98.4034 -81.7501 -87.3761 -81.7631H-28.2605C-24.583 -81.7631 -21.6025 -84.7436 -21.6025 -88.4211C-21.6025 -92.096 -24.583 -95.0791 -28.2605 -95.0791H-87.3761C-105.753 -95.0583 -120.645 -80.1663 -120.666 -61.7892V111.714C-120.645 130.091 -105.753 144.983 -87.3761 145.004H99.4402C117.817 144.983 132.709 130.091 132.73 111.714V52.5955C132.73 48.918 129.75 45.9375 126.072 45.9375Z"
          fill="currentColor"
        />
        <path
          d="M130.094 -112.211C118.393 -123.912 99.4226 -123.912 87.7217 -112.211L-31.0609 6.57194C-31.8749 7.38599 -32.4627 8.39508 -32.7696 9.50301L-48.3899 65.8956C-49.0322 68.2077 -48.3795 70.6836 -46.6837 72.382C-44.9854 74.0777 -42.5095 74.7305 -40.1974 74.0907L16.1952 58.4678C17.3031 58.1609 18.3122 57.5731 19.1263 56.7591L137.906 -62.0261C149.589 -73.7348 149.589 -92.6892 137.906 -104.398L130.094 -112.211ZM-16.5538 10.8996L80.6606 -86.3173L112.013 -54.965L14.796 42.252L-16.5538 10.8996ZM-22.8164 23.4666L2.23164 48.5172L-32.4159 58.1167L-22.8164 23.4666ZM128.491 -71.4409L121.43 -64.3798L90.0754 -95.7348L97.1391 -102.796C103.638 -109.295 114.177 -109.295 120.676 -102.796L128.491 -94.9831C134.98 -88.476 134.98 -77.9454 128.491 -71.4409Z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);

export default ChartSvg;
