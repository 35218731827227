import { useContext } from 'react';

import { getActiveTenant, getAuth, getRole } from '../modules';
import { AuthenticationContext } from '../providers/AuthenticationProvider';
import { useTypedSelector } from '.';

function useIsSignedIn() {
  const auth = useTypedSelector(getAuth);
  const role = useTypedSelector(getRole);
  const tenantId = useTypedSelector(getActiveTenant);
  const authService = useContext(AuthenticationContext);

  const account = authService?.getActiveAccount();

  return {
    activeAccount: account?.idTokenClaims,
    // We need the loader as long as we are using firestore "signInWithCustomToken"
    // Because of a rerender tick, it could happen that msal says we 're authenticated but the state is not in redux yet, hence role is undefined
    isSignedIntoFirebase: !!(auth !== null && role !== undefined && tenantId),
  };
}

export default useIsSignedIn;
