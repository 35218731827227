import { NotificationPreferences } from '../../services/db';
import { RootAction } from '..';
import { ById } from '../Admin';
import { AppState } from '../rootReducer';
import { SET_USER_TYPE } from './actionTypes';

export interface UserTypeState {
  id: string;
  instanceIds?: ById<string>;
  uid: string;
  nickName?: string;
  lastSeenInfoBanner?: Date;
  notificationsSkipped?: number;
  allowDiscussionPublishing?: boolean;
  notificationPreferences?: NotificationPreferences;
}

export const userTypeReducer = (
  state = {},
  action: RootAction,
): Partial<UserTypeState> => {
  switch (action.type) {
    case SET_USER_TYPE:
      return action.payload;
    default:
      return state;
  }
};

// selectors

export const selectUserType = (state: AppState) => state.userType;
