import React from 'react';

export const SendSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="21"
    height="18"
    viewBox="0 0 21 18"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0.00999999 18L21 9L0.00999999 0L0 7L15 9L0 11L0.00999999 18Z" />
  </svg>
);

export default SendSvg;
