import React from 'react';

export const MenuSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3642_48732)">
      <path
        d="M1.45898 5.92934H22.5414C23.3458 5.92934 24 5.27516 24 4.4707C24 3.66624 23.3458 3.01172 22.5414 3.01172H1.45898C0.654525 3.01172 0 3.66624 0 4.4707C0 5.27516 0.654525 5.92934 1.45898 5.92934Z"
        fill="currentColor"
      />
      <path
        d="M22.5414 10.541H1.45898C0.654525 10.541 0 11.1955 0 12C0 12.8045 0.654525 13.4586 1.45898 13.4586H22.5414C23.3458 13.4586 24 12.8045 24 12C24 11.1955 23.3458 10.541 22.5414 10.541Z"
        fill="currentColor"
      />
      <path
        d="M22.5414 18.0703H1.45898C0.654525 18.0703 0 18.7252 0 19.5296C0 20.3341 0.654525 20.9882 1.45898 20.9882H22.5414C23.3458 20.9882 24 20.3341 24 19.5296C24 18.7252 23.3458 18.0703 22.5414 18.0703Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default MenuSvg;
