import { User } from '@firebase/auth';
import { combineEpics } from 'redux-observable';
import { empty, from, Observable, Observer, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { isOfType } from 'typesafe-actions';

import { Metrics } from '../../services/metrics';
import { RootEpic } from '..';
import { catchHandler } from '../utils';
import { TENANTS_RECEIVED } from '.';
import { authLoadedNoUser, resetAction, setUser } from './actions';
import { LOAD_AUTH, SIGN_OUT } from './actionTypes';
import { getRole } from './reducer';

export const signoutEpic: RootEpic = (action$, _, { auth }) =>
  action$.pipe(
    filter(isOfType(SIGN_OUT)),
    tap(() => Metrics.logout()),
    mergeMap(() => from(auth.logout())),
    tap(() => localStorage.clear()),
    map(() => resetAction()),
    catchError(catchHandler),
  );

export const onRolesReceived: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(isOfType(TENANTS_RECEIVED)),
    tap(() => {
      // This is the safest place to be sure that we know the users current role
      Metrics.setRole(getRole(state$.value));
    }),
    mergeMap(() => empty()),
    catchError((_, obs) => {
      return obs;
    }),
  );

export const onLoadAuth: RootEpic = (action$, state$, { auth }) =>
  action$.pipe(
    filter(isOfType(LOAD_AUTH)),
    switchMap(() => {
      const coldObs$ = new Observable((observer: Observer<User | null>) => {
        return auth.getAuth().onAuthStateChanged(
          user => observer.next(user),
          err => observer.error(err),
          () => observer.complete(),
        );
      });
      return coldObs$.pipe(
        switchMap(user => {
          if (!user) {
            return of(authLoadedNoUser());
          } else {
            return of(setUser(user, {}));
          }
        }),
      );
    }),
    catchError((_, obs) => {
      return obs;
    }),
  );

export const rootUserEpic = combineEpics(signoutEpic, onLoadAuth, onRolesReceived);
