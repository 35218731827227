import React from 'react';

export const AnalyticsSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.5833 19.1665H19.1666V7.08318C19.1666 6.85318 18.98 6.6665 18.75 6.6665H16.25C16.02 6.6665 15.8333 6.85318 15.8333 7.08318V19.1665H14.1666V12.0832C14.1666 11.8532 13.9799 11.6665 13.7499 11.6665H11.2499C11.0199 11.6665 10.8332 11.8532 10.8332 12.0832V19.1665H9.16656V10.4165C9.16656 10.1865 8.97988 9.99982 8.74988 9.99982H6.24988C6.01988 9.99982 5.8332 10.1865 5.8332 10.4165V19.1665H4.16652V14.5832C4.16652 14.3532 3.97984 14.1665 3.74984 14.1665H1.25C1.02 14.1665 0.83332 14.3532 0.83332 14.5832V19.1665H0.41668C0.18668 19.1665 0 19.3532 0 19.5832C0 19.8132 0.18668 19.9999 0.41668 19.9999H1.25H3.75H6.25H8.75H11.25H13.75H16.25H18.75H19.5833C19.8133 19.9999 20 19.8132 20 19.5832C20 19.3532 19.8133 19.1665 19.5833 19.1665Z"
      fill="currentColor"
    />
    <path
      d="M17.5 0C16.5808 0 15.8333 0.7475 15.8333 1.66668C15.8333 1.975 15.9233 2.26 16.0691 2.50836L13.3416 5.23586C13.0933 5.09 12.8083 5 12.5 5C11.9775 5 11.5158 5.24668 11.21 5.62418L9.16249 5.04C9.16249 5.02668 9.16667 5.01418 9.16667 5C9.16667 4.08082 8.41917 3.33332 7.49999 3.33332C6.58081 3.33332 5.83331 4.08082 5.83331 5C5.83331 5.2425 5.88749 5.47082 5.98081 5.67832L3.37663 7.75582C3.12167 7.59582 2.82249 7.5 2.49999 7.5C1.58081 7.5 0.833313 8.2475 0.833313 9.16668C0.833313 10.0859 1.58081 10.8334 2.49999 10.8334C3.41917 10.8334 4.16667 10.0859 4.16667 9.16668C4.16667 8.87418 4.08417 8.6025 3.95085 8.36336L6.50335 6.32754C6.78253 6.53754 7.12503 6.66672 7.50003 6.66672C8.11085 6.66672 8.64085 6.33254 8.93085 5.8409L10.8617 6.39172C10.8458 6.48172 10.8333 6.57254 10.8333 6.66672C10.8333 7.5859 11.5808 8.3334 12.5 8.3334C13.4192 8.3334 14.1667 7.5859 14.1667 6.66672C14.1667 6.3584 14.0767 6.0734 13.9309 5.82504L16.6592 3.09672C16.9067 3.2434 17.1917 3.3334 17.5 3.3334C18.4192 3.3334 19.1667 2.5859 19.1667 1.66672C19.1667 0.7475 18.4192 0 17.5 0Z"
      fill="currentColor"
    />
  </svg>
);

export default AnalyticsSvg;
