import React from 'react';

export const BrowserSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.9163 0.416992H2.08328C0.934982 0.416992 0 1.35197 0 2.50031V16.6667C0 17.815 0.934982 18.75 2.08328 18.75H12.0831C12.3131 18.75 12.4998 18.5633 12.4998 18.3333C12.4998 18.1033 12.3131 17.9167 12.0831 17.9167H2.08328C1.39411 17.9167 0.833304 17.3558 0.833304 16.6667V4.58359H19.1663V13.3334C19.1663 13.5634 19.3529 13.7501 19.5829 13.7501C19.8129 13.7501 19.9996 13.5634 19.9996 13.3334V2.50031C19.9996 1.35197 19.0646 0.416992 17.9163 0.416992ZM19.1663 3.75029H0.833304V2.50031C0.833304 1.81114 1.39411 1.25034 2.08328 1.25034H17.9163C18.6055 1.25034 19.1663 1.81114 19.1663 2.50031V3.75029H19.1663Z"
      fill="currentColor"
    />
    <path
      d="M2.08327 2.91684C2.31339 2.91684 2.49994 2.73029 2.49994 2.50017C2.49994 2.27005 2.31339 2.0835 2.08327 2.0835C1.85315 2.0835 1.6666 2.27005 1.6666 2.50017C1.6666 2.73029 1.85315 2.91684 2.08327 2.91684Z"
      fill="currentColor"
    />
    <path
      d="M3.74992 2.91684C3.98005 2.91684 4.1666 2.73029 4.1666 2.50017C4.1666 2.27005 3.98005 2.0835 3.74992 2.0835C3.5198 2.0835 3.33325 2.27005 3.33325 2.50017C3.33325 2.73029 3.5198 2.91684 3.74992 2.91684Z"
      fill="currentColor"
    />
    <path
      d="M5.41658 2.91684C5.6467 2.91684 5.83325 2.73029 5.83325 2.50017C5.83325 2.27005 5.6467 2.0835 5.41658 2.0835C5.18646 2.0835 4.99991 2.27005 4.99991 2.50017C4.99991 2.73029 5.18646 2.91684 5.41658 2.91684Z"
      fill="currentColor"
    />
    <path
      d="M19.8779 15.5382L14.0447 9.70498C13.9255 9.58666 13.7472 9.5508 13.5905 9.61498C13.4347 9.67916 13.3331 9.83166 13.3331 9.99998V18.3331C13.3331 18.5015 13.4347 18.654 13.5905 18.7173C13.7472 18.7831 13.9247 18.7473 14.0447 18.6273L15.3072 17.3648L16.3297 19.3565C16.3805 19.4565 16.4697 19.5307 16.5755 19.564C16.6163 19.5765 16.6588 19.5832 16.7005 19.5832C16.768 19.5832 16.8347 19.5665 16.8963 19.534L18.5288 18.6665C18.7288 18.5607 18.808 18.3132 18.7046 18.1107L17.7613 16.2499H19.5829C19.7513 16.2499 19.9038 16.1482 19.9679 15.9924C20.0329 15.8365 19.9971 15.6574 19.8779 15.5382ZM17.083 15.4165C16.938 15.4165 16.8038 15.4915 16.728 15.6148C16.6522 15.7382 16.6455 15.8923 16.7113 16.0215L17.7763 18.1231L16.878 18.6006L15.7872 16.4765C15.7264 16.3582 15.613 16.2757 15.4814 16.2548C15.4597 16.2515 15.4381 16.2498 15.4164 16.2498C15.3072 16.2498 15.2006 16.2931 15.1214 16.3723L14.1664 17.3273V11.0058L18.5771 15.4165H17.083V15.4165Z"
      fill="currentColor"
    />
  </svg>
);

export default BrowserSvg;
