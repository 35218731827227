import React from 'react';

export const MentorSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="82"
    height="85"
    viewBox="0 0 82 85"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M57.3806 22.5657C63.6119 22.5657 68.6634 17.5142 68.6634 11.2828C68.6634 5.05148 63.6119 0 57.3806 0C51.1492 0 46.0977 5.05148 46.0977 11.2828C46.0977 17.5142 51.1492 22.5657 57.3806 22.5657Z"
      fill="currentColor"
    />
    <path
      d="M57.3841 29.2119C47.8014 29.2119 39.6097 34.776 35.7457 42.9677C32.3454 41.113 28.4814 40.1856 24.4628 40.1856C11.1707 40.1856 0.351562 51.0048 0.351562 64.2969V76.5071C0.351562 80.6802 3.75191 84.0805 7.92502 84.0805C12.0981 84.0805 15.4984 80.6802 15.4984 76.5071V64.2969C15.4984 59.351 19.5169 55.3325 24.4628 55.3325C29.4087 55.3325 33.4273 59.351 33.4273 64.2969V76.5071C33.4273 80.6802 36.8276 84.0805 41.0007 84.0805C45.1738 84.0805 48.5741 80.6802 48.5741 76.5071V64.2969V53.3232C48.5741 48.3773 52.5927 44.3587 57.5386 44.3587C62.4845 44.3587 66.503 48.3773 66.503 53.3232V76.5071C66.503 80.6802 69.9034 84.0805 74.0765 84.0805C78.2496 84.0805 81.6498 80.6802 81.6498 76.5071V53.3232C81.4953 40.0311 70.6762 29.2119 57.3841 29.2119Z"
      fill="currentColor"
    />
    <path
      d="M24.3102 33.5393C30.5415 33.5393 35.593 28.4878 35.593 22.2565C35.593 16.0251 30.5415 10.9736 24.3102 10.9736C18.0788 10.9736 13.0273 16.0251 13.0273 22.2565C13.0273 28.4878 18.0788 33.5393 24.3102 33.5393Z"
      fill="currentColor"
    />
  </svg>
);

export default MentorSvg;
