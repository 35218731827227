import './polyfills';
import './index.css';
import './singletons/i18n';

import { notification } from 'antd';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import smoothscroll from 'smoothscroll-polyfill';

import App from './App';
import { alertNewVersionAvailable, updateServiceWorker } from './modules';
import ErrorBoundary from './providers/ErrorBoundary';
import SupportedBrowserProvider from './providers/SupportedBrowserProvider';
import * as serviceWorker from './serviceWorker';
import { configureStore } from './store';
import { BeforeFirstRenderCheckRedirectInHash } from './utils/loginUtils';

// kick off the polyfill for smooth scrolling on safari and other browsers
smoothscroll.polyfill();

notification.config({
  top: 65,
});

BeforeFirstRenderCheckRedirectInHash(window.location.hash);

const initialize = async () => {
  const { store, notifications, auth, config } = configureStore();

  const renderApp = (children: React.ReactNode) => {
    render(
      <ErrorBoundary>
        <SupportedBrowserProvider>
          <Provider store={store}>{children}</Provider>
        </SupportedBrowserProvider>
      </ErrorBoundary>,
      document.getElementById('root'),
    );
  };

  renderApp(<App auth={auth} config={config} />);

  if ('serviceWorker' in navigator) {
    serviceWorker.register(notifications, {
      onUpdate: reg => {
        store.dispatch(alertNewVersionAvailable(reg));
        store.dispatch(updateServiceWorker());
      },
      onServiceWorkerRegistered: () => undefined,
    });
  }
};

initialize();
