import React from 'react';

export const ArrowRightSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="120"
    height="120"
    viewBox="0 0 120 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M87.4939 54.6925L48.0218 19.2709C47.1563 18.4879 46.1267 17.8664 44.9923 17.4423C43.8578 17.0182 42.641 16.7998 41.4121 16.7998C40.1831 16.7998 38.9663 17.0182 37.8318 17.4423C36.6974 17.8664 35.6678 18.4879 34.8023 19.2709C33.0684 20.8362 32.0952 22.9536 32.0952 25.1606C32.0952 27.3677 33.0684 29.485 34.8023 31.0503L67.7578 60.624L34.8023 90.1976C33.0684 91.7629 32.0952 93.8803 32.0952 96.0873C32.0952 98.2943 33.0684 100.412 34.8023 101.977C35.6722 102.751 36.7039 103.364 37.8381 103.78C38.9723 104.195 40.1869 104.406 41.4121 104.4C42.6372 104.406 43.8518 104.195 44.986 103.78C46.1203 103.364 47.1519 102.751 48.0218 101.977L87.4939 66.5554C88.3664 65.7788 89.059 64.8548 89.5316 63.8368C90.0043 62.8187 90.2476 61.7268 90.2476 60.624C90.2476 59.5211 90.0043 58.4292 89.5316 57.4111C89.059 56.3931 88.3664 55.4691 87.4939 54.6925Z"
      fill="currentColor"
    />
  </svg>
);

export default ArrowRightSvg;
