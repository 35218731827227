import styled from 'styled-components';

import theme from '../theme';

export const CircleButton = styled.button<{
  backgroundColor: string;
  color?: string;
}>`
  color: ${({ color }) => (color ? color : theme.colors.white)};
  background-color: ${({ backgroundColor }) => backgroundColor};
  border: none;
  border-radius: 1000px;
  padding: 10px 10px;
  font-weight: 600;
  display: flex;
  font-size: 16px;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  cursor: pointer;
  touch-action: manipulation;

  svg {
    height: 16px;
    width: 16px;
  }

  &:active {
    box-shadow: none;
  }
  &:focus {
    outline: none;
  }
  &:hover {
    cursor: pointer;
    /* background-color: #ffac68; */
    color: ${theme.colors.white};
  }
`;
