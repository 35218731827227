import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router';

interface OpenRouteAutoRedirectProps extends RouteProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: any;
}

const OpenRouteAutoRedirect = ({
  component: Component,
  ...rest
}: OpenRouteAutoRedirectProps) => {
  return (
    <Route
      {...rest}
      render={props => {
        return (
          <>
            <AuthenticatedTemplate>
              <Redirect to="/" />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
              <Component {...props} />
            </UnauthenticatedTemplate>
          </>
        );
      }}
    />
  );
};

export default OpenRouteAutoRedirect;
