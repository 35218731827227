import React from 'react';

export const HomeSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M16.6406 19.3741H3.35938C2.36859 19.3741 1.5625 18.568 1.5625 17.5772V8.12329C1.5625 7.77813 1.8423 7.49829 2.1875 7.49829C2.5327 7.49829 2.8125 7.77813 2.8125 8.12329V17.5772C2.8125 17.8788 3.05781 18.1241 3.35938 18.1241H16.6406C16.9422 18.1241 17.1875 17.8788 17.1875 17.5772V8.12329C17.1875 7.77813 17.4673 7.49829 17.8125 7.49829C18.1577 7.49829 18.4375 7.77813 18.4375 8.12329V17.5772C18.4375 18.568 17.6314 19.3741 16.6406 19.3741Z"
        fill="currentColor"
      />
      <path
        d="M19.3752 10.3107C19.2153 10.3107 19.0554 10.2497 18.9333 10.1276L11.1603 2.35467C10.5207 1.71498 9.47984 1.71498 8.84016 2.35467L1.06719 10.1276C0.823125 10.3717 0.427383 10.3717 0.18332 10.1276C-0.0607813 9.88357 -0.0607813 9.48783 0.18332 9.24377L7.95629 1.47076C9.08332 0.343731 10.9172 0.343731 12.0442 1.47076L19.8171 9.24373C20.0613 9.48783 20.0613 9.88354 19.8171 10.1276C19.6952 10.2497 19.5352 10.3107 19.3752 10.3107Z"
        fill="currentColor"
      />
      <path
        d="M12.5 19.374H7.5C7.1548 19.374 6.875 19.0942 6.875 18.749V13.2021C6.875 12.1252 7.75117 11.249 8.82812 11.249H11.1719C12.2488 11.249 13.125 12.1252 13.125 13.2021V18.749C13.125 19.0942 12.8452 19.374 12.5 19.374ZM8.125 18.124H11.875V13.2021C11.875 12.8145 11.5596 12.499 11.1719 12.499H8.82812C8.44043 12.499 8.125 12.8145 8.125 13.2021V18.124Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default HomeSvg;
