import { Spin } from 'antd';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Virtuoso } from 'react-virtuoso';
import styled from 'styled-components';

import { useIsDesktop } from '../../../hooks';
import useInfiniteNotificationsList from '../../../hooks/useInfiniteNotificationsList';
import { GenericNotification } from '../../../services/backendService';
import { LoaderContainer } from '../../../styled/LoaderContainer';
import { UnreadTag } from '../../../styled/UnreadTag';
import theme from '../../../theme';
import { CrossIcon } from '../../_atoms/_Icons';

const NotificationsList = ({ onClose }: { onClose: () => void }) => {
  const history = useHistory();
  const { t } = useTranslation('home');
  const {
    notifications,
    markAsRead,
    isLoading,
    canFetchMore,
    fetchMore,
  } = useInfiniteNotificationsList();
  const isDesktop = useIsDesktop();
  const handleMarkAllRead = () => {
    markAsRead(
      notifications ? notifications.pages.flatMap(nt => nt.items.map(n => n.id)) : [],
    );
    onClose();
  };

  const handleNotificationSelected = (notification: GenericNotification) => {
    markAsRead([notification.id]);
    if (notification.payload.redirectSelfServe) {
      history.push(notification.payload.redirectSelfServe);
    }
  };

  const emptyState = (
    <LoaderContainer>
      <p style={{ color: theme.colors.black, fontSize: 16, fontWeight: 600 }}>
        {t('No notifications yet')} :)
      </p>
    </LoaderContainer>
  );

  const allItems = useMemo(
    () => (notifications ? notifications.pages.flatMap(p => p.items) : []),
    [notifications],
  );

  const onFetchNext = async () => {
    if (!canFetchMore) return;
    return fetchMore();
  };

  return (
    <Container>
      {isDesktop && (
        <Header>
          <span>{t('Notifications')}</span>
          <IconWrapper onClick={() => onClose()}>
            <CrossIcon />
          </IconWrapper>
        </Header>
      )}
      <Body>
        {isLoading && !notifications && (
          <LoaderContainer>
            <Spin />
          </LoaderContainer>
        )}
        {allItems && (
          <Virtuoso
            data={allItems || []}
            overscan={5}
            style={{ height: '300px' }}
            endReached={onFetchNext}
            components={{
              Footer: () => <>{emptyState}</>,
            }}
            itemContent={(index, notification) => (
              <NotificationBody
                key={notification.id}
                received={notification.received}
                onClick={() => handleNotificationSelected(notification)}
              >
                <NotificationTextContainer>
                  <SpreadRow>
                    <p>{notification.payload.title}</p>
                  </SpreadRow>
                  <SpreadRow>
                    <p style={{ fontWeight: 'normal' }}>{notification.payload.body}</p>
                  </SpreadRow>

                  {notification.sentTime && (
                    <span>{dayjs(notification.sentTime).fromNow()}</span>
                  )}
                </NotificationTextContainer>
                {!notification.received && (
                  <NotificationUnreadContainer>
                    <UnreadTag />
                  </NotificationUnreadContainer>
                )}
              </NotificationBody>
            )}
          />
        )}
      </Body>
      <Footer onClick={handleMarkAllRead}>
        <span>{t('Mark all as read')}</span>
      </Footer>
    </Container>
  );
};

const NotificationTextContainer = styled.div`
  flex: 1;
`;
const NotificationUnreadContainer = styled.div`
  flex: 0;
  width: 40px;
  display: flex;
  align-items: center;
`;

const ItemRow = styled.div`
  display: flex;
  width: 100%;
`;

const SpreadRow = styled(ItemRow)`
  justify-content: space-between;
  align-items: center;
`;

const IconWrapper = styled.div`
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  &:hover {
    background-color: #f9f9f9;
  }
`;

const Container = styled.div`
  background-color: white;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  width: 310px;
  @media (max-width: 992px) {
    width: 100%;
    border-radius: 0;
  }
`;
const Header = styled.div`
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px;
  color: ${theme.colors.black};
  border-bottom: 1px solid #c1c8d7;
  span {
    font-size: 16px;
    font-weight: 600;
  }
  svg {
    color: #d9d9d9;
  }
`;

const Body = styled.div`
  max-height: 400px;
  @media (max-width: 992px) {
    max-height: 100%;
    height: 100%;
    padding-bottom: 80px;
  }
  overflow-y: auto;
  width: 100%;
  p {
    color: ${theme.colors.black};
    font-size: 14px;
    font-weight: 700;
    margin: 0;
  }
  span {
    color: rgb(165, 165, 165);
    font-size: 12px;
  }
`;
const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${theme.colors.black};

  span {
    font-size: 14px;
    font-weight: 600;
  }
  padding: 20px;
  border-top: 1px solid #c1c8d7;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  &:hover {
    background-color: #f9f9f9;
  }
  @media (max-width: 992px) {
    position: fixed;
    bottom: 0;
    background-color: ${theme.colors.white};
    z-index: 1000;
    width: 100%;
  }
`;

const NotificationBody = styled.div<{ received?: boolean }>`
  background-color: ${({ received }) => (received ? 'white' : 'rgba(51,160,221, 0.15)')};
  border-bottom: 1px solid #c1c8d7;
  padding: 10px 20px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  &:hover {
    background-color: ${({ received }) =>
      received ? '#f9f9f9' : 'rgba(51,160,221, 0.25)'};
  }
  display: flex;
`;

export default NotificationsList;
