import React from 'react';

export const UndoSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M17.8044 12.4815C17.8044 16.6272 14.4314 20 10.2859 20H7.6245C7.18346 20 6.82606 19.6427 6.82606 19.2016V18.9355C6.82606 18.4944 7.18342 18.137 7.6245 18.137H10.2859C13.4043 18.137 15.9415 15.5998 15.9415 12.4815C15.9415 9.36315 13.4043 6.82594 10.2859 6.82594H5.37574L8.75738 10.2076C9.12059 10.5707 9.12059 11.1617 8.75738 11.5248C8.40495 11.877 7.79159 11.8764 7.43995 11.5248L2.46843 6.55314C2.2925 6.37721 2.19554 6.14328 2.19554 5.8944C2.19554 5.6457 2.2925 5.41164 2.46843 5.2358L7.44021 0.263929C7.79185 -0.0879762 8.40574 -0.0879762 8.75742 0.263929C9.12063 0.626914 9.12063 1.21807 8.75742 1.58101L5.37579 4.96291H10.2859C14.4314 4.96291 17.8044 8.33584 17.8044 12.4815Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default UndoSvg;
