import React from 'react';

export const AppreciateSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="30"
    height="31"
    viewBox="0 0 30 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.6168 16.9187C25.6471 22.3797 21.2089 26.8426 15.7038 26.8868C10.1986 26.9311 5.71121 22.54 5.68084 17.0789C5.65047 11.6179 10.0887 7.15504 15.5938 7.11077C21.099 7.06651 25.5864 11.4577 25.6168 16.9187Z"
      fill="#F99746"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.1501 25.7185C20.6559 25.6757 25.0945 21.3534 25.0642 16.0644C25.0564 14.7055 24.9432 13.4066 24.4052 12.2398C25.3263 13.6789 25.6727 15.3802 25.6832 17.195C25.7135 22.484 21.2749 26.8063 15.7691 26.8492C11.6779 26.881 8.14867 24.5409 6.59283 21.1663C8.35614 23.9213 11.5335 25.7467 15.1501 25.7185Z"
      fill="#D84C42"
    />
    <path
      d="M14.9734 16.1851C14.9734 18.128 13.3984 19.7029 11.4556 19.7029C9.51277 19.7029 7.93781 18.128 7.93781 16.1851C7.93781 14.2423 9.51277 12.6674 11.4556 12.6674C13.3984 12.6674 14.9734 14.2423 14.9734 16.1851Z"
      fill="#F9F3EB"
    />
    <path
      d="M13.8702 16.1818C13.8702 17.499 12.8023 18.5669 11.4851 18.5669C10.1679 18.5669 9.10004 17.499 9.10004 16.1818C9.10004 14.8646 10.1679 13.7968 11.4851 13.7968C12.8023 13.7968 13.8702 14.8646 13.8702 16.1818Z"
      fill="#282F33"
    />
    <path
      d="M23.3432 16.1851C23.3432 18.128 21.7682 19.7029 19.8254 19.7029C17.8826 19.7029 16.3076 18.128 16.3076 16.1851C16.3076 14.2423 17.8826 12.6674 19.8254 12.6674C21.7682 12.6674 23.3432 14.2423 23.3432 16.1851Z"
      fill="#F9F3EB"
    />
    <path
      d="M22.2501 16.1885C22.2501 17.5179 21.1723 18.5957 19.8429 18.5957C18.5134 18.5957 17.4357 17.5179 17.4357 16.1885C17.4357 14.859 18.5134 13.7812 19.8429 13.7812C21.1723 13.7812 22.2501 14.859 22.2501 16.1885Z"
      fill="#282F33"
    />
    <path
      d="M20.4107 6.71965C20.6395 7.34387 19.8908 8.19228 18.7384 8.61464C17.586 9.037 16.4664 8.87336 16.2376 8.24914C16.0088 7.62492 16.7575 6.77651 17.9099 6.35415C19.0623 5.9318 20.1819 6.09544 20.4107 6.71965Z"
      fill="#F99746"
    />
    <path
      d="M16.5855 4.39536C17.379 4.85758 17.3348 6.41216 16.4869 7.86761C15.639 9.32307 14.3085 10.1282 13.5151 9.66602C12.7216 9.2038 12.7658 7.64922 13.6137 6.19377C14.4616 4.73832 15.7921 3.93314 16.5855 4.39536Z"
      fill="#F99746"
    />
    <path
      opacity="0.9"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5905 4.3625C17.3839 4.82472 17.3398 6.3793 16.4919 7.83476C16.0985 8.50999 15.6013 9.04526 15.102 9.37895C15.4121 9.07762 15.7064 8.70282 15.9581 8.27071C16.806 6.81526 16.8502 5.26068 16.0567 4.79846C15.6314 4.55067 15.0517 4.66712 14.4747 5.05272C15.2092 4.33908 16.0326 4.0375 16.5905 4.3625ZM19.9348 6.66182C20.1636 7.28604 19.4148 8.13445 18.2625 8.55681C17.6238 8.79088 16.9952 8.84497 16.5227 8.74041C16.9632 9.08874 17.8645 9.12604 18.7867 8.78803C19.939 8.36567 20.6878 7.51726 20.459 6.89304C20.357 6.61478 20.078 6.42804 19.6979 6.34394C19.8079 6.43087 19.8891 6.53716 19.9348 6.66182Z"
      fill="#D84C42"
    />
    <path
      d="M16.9959 19.547C17.0992 19.3438 16.8963 19.119 16.7148 19.1826C16.3951 19.2946 15.982 19.3622 15.5308 19.3622C15.1097 19.3622 14.7217 19.3033 14.4119 19.2044C14.2384 19.149 14.0668 19.3588 14.1654 19.5517C14.4582 20.1244 14.982 20.5059 15.5795 20.5059C16.1786 20.5059 16.7036 20.1223 16.9959 19.547Z"
      fill="#282F33"
    />
    <path
      d="M14.8003 20.0801C14.922 20.3496 15.3253 20.4137 15.5721 20.4137C15.9891 20.4315 16.3732 20.1273 16.3732 20.1273C16.3732 20.1273 15.642 19.5434 14.8003 20.0801Z"
      fill="#D84C42"
    />
    <path
      d="M15.5601 21.5696C15.4417 21.2432 15.0725 20.9933 14.4786 21.1423C13.7776 21.3182 13.3037 23.1827 13.3037 23.1827C13.3037 23.1827 13.0855 25.4262 12.2252 25.9333C11.6502 26.2722 13.1695 25.856 12.399 26.1881C11.8759 26.4136 10.9134 26.4403 11.2882 26.7599C11.5832 27.0116 10.6428 26.9959 11.2781 27.049C11.9193 27.1026 12.3587 26.9298 12.9792 26.819C13.6708 26.6955 13.8534 26.9918 14.4912 26.6823C15.9621 25.9686 14.9836 24.493 15.5082 23.0535C15.7247 22.4597 15.7404 22.0673 15.5601 21.5696Z"
      fill="#D84C42"
    />
    <path
      d="M15.6469 22.3067C15.5552 21.7512 14.9823 21.5255 14.6189 21.5885C14.2555 21.6515 14.1304 21.8899 13.9281 22.2467C13.7176 22.6177 13.3304 23.0735 13.3304 23.0735C13.3304 23.0735 12.7162 24.7198 11.856 25.2269C11.281 25.5659 12.2815 26.0206 11.511 26.3527C10.9879 26.5782 10.35 26.7959 10.7248 27.1156C11.0198 27.3672 10.6403 27.3712 11.2756 27.4242C11.9168 27.4778 12.3562 27.305 12.9767 27.1942C13.6683 27.0707 14.2574 27.5615 14.9609 27.3649C15.4522 27.2276 15.6665 26.4172 15.6665 26.4172C15.6665 26.4172 15.73 22.8097 15.6469 22.3067Z"
      fill="#D84C42"
    />
    <path
      d="M15.4478 22.0652C15.3935 21.8385 15.1832 21.2512 14.6194 21.5191C14.0557 21.7869 13.8174 22.8821 13.6087 23.5636C13.4813 23.9794 12.7853 24.8235 12.4895 25.3601C12.1516 25.9731 13.0798 25.2626 12.241 25.757C11.6803 26.0875 11.2571 26.1208 10.5058 26.4446C9.99563 26.6645 9.90742 27.1607 10.2729 27.4724C10.5605 27.7178 10.7507 27.867 11.3702 27.9187C11.9954 27.9709 12.4239 27.8025 13.0289 27.6944C13.7033 27.574 14.2109 27.7573 14.8328 27.4555C16.2671 26.7596 15.0684 25.7228 15.2365 24.3566C15.3094 23.7643 15.551 22.4961 15.4478 22.0652Z"
      fill="#FFA55B"
    />
    <path
      d="M14.4988 27.7776C16.2821 27.4458 15.4591 25.6539 15.4591 25.6539C15.4591 25.6539 15.3969 27.0899 14.4988 27.3468C13.2791 27.6957 9.93995 27.0343 9.93995 27.0343C9.93995 27.0343 10.7807 27.8682 11.4002 27.92C12.0254 27.9722 12.4539 27.8037 13.0589 27.6957C13.7333 27.5753 13.8498 28.0622 14.4988 27.7776Z"
      fill="#D84C42"
    />
    <path
      d="M12.6847 26.5844C12.4692 26.1097 12.4159 24.8594 12.4159 24.8594C12.4159 24.8594 10.5208 25.4509 10.0694 25.9015C9.52767 26.4423 9.49918 27.559 9.92062 28.3473C10.3599 29.1689 11.2313 29.6605 12.0487 29.3933C12.8661 29.1263 14.1291 27.876 14.1291 27.876C14.1291 27.876 13.082 27.4597 12.6847 26.5844Z"
      fill="#61B195"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.519 24.4766C12.6781 24.4789 12.7982 24.6302 12.7873 24.8145L12.4992 24.8104C12.7873 24.8145 12.7873 24.8145 12.7873 24.8145L12.7872 24.8172C12.787 24.8204 12.7868 24.826 12.7865 24.8335C12.786 24.8487 12.7854 24.872 12.7853 24.902C12.7851 24.9621 12.7868 25.0476 12.7945 25.1463C12.8103 25.3492 12.8297 25.9506 13.0796 26.3752C13.288 26.7293 13.5377 26.9661 13.8227 27.12C13.9622 27.1953 14.091 27.2462 14.1845 27.278C14.231 27.2939 14.2683 27.3048 14.2932 27.3117C14.3056 27.3151 14.3148 27.3174 14.3206 27.3188L14.3264 27.3203C14.4809 27.3549 14.5757 27.5295 14.5373 27.7104C14.4989 27.8916 14.3422 28.0106 14.1874 27.9763L14.2538 27.6633C14.1874 27.9763 14.1874 27.9763 14.1874 27.9763L14.1853 27.9758L14.1816 27.975C14.1787 27.9743 14.1748 27.9733 14.17 27.9722C14.1603 27.9698 14.1469 27.9663 14.1302 27.9617C14.0967 27.9526 14.0499 27.9387 13.9929 27.9193C13.8795 27.8807 13.724 27.8193 13.5545 27.7278C13.2214 27.5479 12.8353 27.1945 12.5648 26.7109C12.2773 26.1969 12.2379 25.4837 12.2191 25.2431C12.2095 25.1199 12.2074 25.0133 12.2077 24.9367C12.2078 24.8983 12.2086 24.8672 12.2093 24.8449C12.2097 24.8338 12.2101 24.8249 12.2104 24.8184L12.2108 24.8104L12.2109 24.8078L12.211 24.8065C12.211 24.8065 12.211 24.8062 12.4971 24.8103L12.211 24.8065C12.2219 24.6222 12.3598 24.4743 12.519 24.4766Z"
      fill="#282F33"
    />
    <path
      d="M15.6516 21.5696C15.7699 21.2432 16.1391 20.9933 16.733 21.1423C17.434 21.3182 17.9079 23.1827 17.9079 23.1827C17.9079 23.1827 18.1261 25.4262 18.9864 25.9333C19.5614 26.2722 18.0421 25.856 18.8126 26.1881C19.3357 26.4136 20.2982 26.4403 19.9234 26.7599C19.6284 27.0116 20.5688 26.9959 19.9335 27.049C19.2924 27.1026 18.8529 26.9298 18.2324 26.819C17.5408 26.6955 17.3582 26.9918 16.7204 26.6823C15.2495 25.9686 16.228 24.493 15.7034 23.0535C15.487 22.4597 15.4712 22.0673 15.6516 21.5696Z"
      fill="#D84C42"
    />
    <path
      d="M15.5647 22.3067C15.6564 21.7512 16.2293 21.5255 16.5927 21.5885C16.9561 21.6515 17.0812 21.8899 17.2836 22.2467C17.494 22.6177 17.8812 23.0735 17.8812 23.0735C17.8812 23.0735 18.4954 24.7198 19.3556 25.2269C19.9306 25.5659 18.9301 26.0206 19.7006 26.3527C20.2237 26.5782 20.8616 26.7959 20.4868 27.1156C20.1918 27.3672 20.5713 27.3712 19.936 27.4242C19.2949 27.4778 18.8554 27.305 18.2349 27.1942C17.5433 27.0707 16.9542 27.5615 16.2507 27.3649C15.7594 27.2276 15.5451 26.4172 15.5451 26.4172C15.5451 26.4172 15.4816 22.8097 15.5647 22.3067Z"
      fill="#D84C42"
    />
    <path
      d="M15.7638 22.0652C15.8181 21.8385 16.0284 21.2512 16.5922 21.5191C17.1559 21.7869 17.3942 22.8821 17.6029 23.5636C17.7303 23.9794 18.4263 24.8235 18.7221 25.3601C19.06 25.9731 18.1318 25.2626 18.9706 25.757C19.5313 26.0875 19.9545 26.1208 20.7058 26.4446C21.216 26.6645 21.3042 27.1607 20.9388 27.4724C20.6511 27.7178 20.4609 27.867 19.8415 27.9187C19.2162 27.9709 18.7877 27.8025 18.1827 27.6944C17.5083 27.574 17.0007 27.7573 16.3788 27.4555C14.9445 26.7596 16.1432 25.7228 15.9751 24.3566C15.9023 23.7643 15.6606 22.4961 15.7638 22.0652Z"
      fill="#FFA55B"
    />
    <path
      d="M16.7128 27.7776C14.9295 27.4458 15.7525 25.6539 15.7525 25.6539C15.7525 25.6539 15.8147 27.0899 16.7129 27.3468C17.9325 27.6957 21.2717 27.0343 21.2717 27.0343C21.2717 27.0343 20.4309 27.8682 19.8114 27.92C19.1862 27.9722 18.7577 27.8037 18.1527 27.6957C17.4783 27.5753 17.3618 28.0622 16.7128 27.7776Z"
      fill="#D84C42"
    />
    <path
      d="M18.5269 26.5844C18.7424 26.1097 18.7957 24.8594 18.7957 24.8594C18.7957 24.8594 20.6908 25.4509 21.1422 25.9015C21.6839 26.4423 21.7124 27.559 21.291 28.3473C20.8517 29.1689 19.9803 29.6605 19.1629 29.3933C18.3455 29.1263 17.0825 27.876 17.0825 27.876C17.0825 27.876 18.1296 27.4597 18.5269 26.5844Z"
      fill="#61B195"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.6926 24.4766C18.5335 24.4789 18.4134 24.6302 18.4243 24.8145L18.7124 24.8104C18.4243 24.8145 18.4243 24.8145 18.4243 24.8145L18.4244 24.8172C18.4246 24.8204 18.4248 24.826 18.4251 24.8335C18.4256 24.8487 18.4262 24.872 18.4263 24.902C18.4265 24.9621 18.4248 25.0476 18.4171 25.1463C18.4013 25.3492 18.3819 25.9506 18.132 26.3752C17.9236 26.7293 17.6739 26.9661 17.3889 27.12C17.2494 27.1953 17.1206 27.2462 17.0272 27.278C16.9806 27.2939 16.9433 27.3048 16.9185 27.3117C16.906 27.3151 16.8968 27.3174 16.891 27.3188L16.8852 27.3203C16.7307 27.3549 16.6359 27.5295 16.6743 27.7104C16.7127 27.8916 16.8694 28.0106 17.0242 27.9763L16.9578 27.6633C17.0242 27.9763 17.0242 27.9763 17.0242 27.9763L17.0264 27.9758L17.03 27.975C17.0329 27.9743 17.0368 27.9733 17.0416 27.9722C17.0513 27.9698 17.0647 27.9663 17.0815 27.9617C17.1149 27.9526 17.1618 27.9387 17.2187 27.9193C17.3321 27.8807 17.4876 27.8193 17.6571 27.7278C17.9902 27.5479 18.3763 27.1945 18.6468 26.7109C18.9343 26.1969 18.9737 25.4837 18.9925 25.2431C19.0021 25.1199 19.0042 25.0133 19.0039 24.9367C19.0038 24.8983 19.0031 24.8672 19.0023 24.8449C19.0019 24.8338 19.0015 24.8249 19.0012 24.8184L19.0008 24.8104L19.0007 24.8078L19.0006 24.8065C19.0006 24.8065 19.0006 24.8062 18.7145 24.8103L19.0006 24.8065C18.9897 24.6222 18.8518 24.4743 18.6926 24.4766Z"
      fill="#282F33"
    />
    <path
      d="M10.3171 4.55566C10.3171 4.55566 10.405 5.37547 10.7471 5.71759C11.0892 6.0597 11.909 6.14754 11.909 6.14754C11.909 6.14754 11.0892 6.23537 10.7471 6.57748C10.405 6.9196 10.3171 7.73941 10.3171 7.73941C10.3171 7.73941 10.2293 6.9196 9.8872 6.57748C9.54508 6.23537 8.72528 6.14754 8.72528 6.14754C8.72528 6.14754 9.54508 6.0597 9.8872 5.71759C10.2293 5.37547 10.3171 4.55566 10.3171 4.55566Z"
      fill="#F99746"
    />
    <path
      d="M23.8323 24.3959C23.8323 24.3959 23.9202 25.2157 24.2623 25.5578C24.6044 25.8999 25.4242 25.9877 25.4242 25.9877C25.4242 25.9877 24.6044 26.0756 24.2623 26.4177C23.9202 26.7598 23.8323 27.5796 23.8323 27.5796C23.8323 27.5796 23.7445 26.7598 23.4024 26.4177C23.0603 26.0756 22.2405 25.9877 22.2405 25.9877C22.2405 25.9877 23.0603 25.8999 23.4024 25.5578C23.7445 25.2157 23.8323 24.3959 23.8323 24.3959Z"
      fill="#F99746"
    />
    <path
      d="M12.1589 13.8296C12.1589 13.8296 12.2534 14.7108 12.6211 15.0785C12.9888 15.4463 13.87 15.5407 13.87 15.5407C13.87 15.5407 12.9888 15.6351 12.6211 16.0028C12.2534 16.3705 12.1589 17.2517 12.1589 17.2517C12.1589 17.2517 12.0645 16.3705 11.6968 16.0028C11.3291 15.6351 10.4479 15.5407 10.4479 15.5407C10.4479 15.5407 11.3291 15.4463 11.6968 15.0785C12.0645 14.7108 12.1589 13.8296 12.1589 13.8296Z"
      fill="white"
    />
    <path
      d="M20.5294 13.8296C20.5294 13.8296 20.6238 14.7108 20.9915 15.0785C21.3593 15.4463 22.2405 15.5407 22.2405 15.5407C22.2405 15.5407 21.3593 15.6351 20.9915 16.0028C20.6238 16.3705 20.5294 17.2517 20.5294 17.2517C20.5294 17.2517 20.435 16.3705 20.0672 16.0028C19.6995 15.6351 18.8183 15.5407 18.8183 15.5407C18.8183 15.5407 19.6995 15.4463 20.0672 15.0785C20.435 14.7108 20.5294 13.8296 20.5294 13.8296Z"
      fill="white"
    />
    <path
      d="M10.3171 16.5256C10.3171 16.5256 10.3673 16.9935 10.5625 17.1887C10.7577 17.3839 11.2256 17.4341 11.2256 17.4341C11.2256 17.4341 10.7577 17.4842 10.5625 17.6794C10.3673 17.8747 10.3171 18.3425 10.3171 18.3425C10.3171 18.3425 10.267 17.8747 10.0718 17.6794C9.87653 17.4842 9.40869 17.4341 9.40869 17.4341C9.40869 17.4341 9.87653 17.3839 10.0718 17.1887C10.267 16.9935 10.3171 16.5256 10.3171 16.5256Z"
      fill="white"
    />
    <path
      d="M18.6811 16.5258C18.6811 16.5258 18.7312 16.9936 18.9265 17.1888C19.1217 17.3841 19.5895 17.4342 19.5895 17.4342C19.5895 17.4342 19.1217 17.4843 18.9265 17.6795C18.7312 17.8748 18.6811 18.3426 18.6811 18.3426C18.6811 18.3426 18.631 17.8748 18.4357 17.6795C18.2405 17.4843 17.7726 17.4342 17.7726 17.4342C17.7726 17.4342 18.2405 17.3841 18.4357 17.1888C18.631 16.9936 18.6811 16.5258 18.6811 16.5258Z"
      fill="white"
    />
    <path
      d="M22.948 1.46313C22.948 1.46313 23.1333 3.19271 23.8551 3.91449C24.5769 4.63627 26.3064 4.82158 26.3064 4.82158C26.3064 4.82158 24.5769 5.00688 23.8551 5.72866C23.1333 6.45044 22.948 8.18002 22.948 8.18002C22.948 8.18002 22.7627 6.45044 22.0409 5.72866C21.3191 5.00688 19.5895 4.82158 19.5895 4.82158C19.5895 4.82158 21.3191 4.63627 22.0409 3.91449C22.7627 3.19271 22.948 1.46313 22.948 1.46313Z"
      fill="#F99746"
    />
  </svg>
);

export default AppreciateSvg;
