import dayjs from 'dayjs';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useIsDesktop, useMetrics, useTypedSelector } from '../hooks';
import { getServiceWorker } from '../modules';
import theme from '../theme';
import { isMac } from '../utils/navigatorUtils';

const ADD_2_HOME_SCREEN = 'a2hs';

export const PWAContext = React.createContext<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  { event: any; hasEvent: boolean } | undefined
>(undefined);

interface UserChoice {
  timestamp: number;
  userChoice: boolean;
}

export const PwaInstallProvider = ({ children }: { children: React.ReactChild }) => {
  const { isNewVersionAvailable } = useTypedSelector(getServiceWorker);
  const { t } = useTranslation('Host');
  const metrics = useMetrics();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [installEvent, setInstallEvent] = useState<any>();

  const [, setShowPrompt] = useState<boolean>(false);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onInstall = (e: any) => {
      // For older browsers
      e.preventDefault();
      // TODO ADD METRIC
      // See if the app is already installed, in that case, do nothing
      if (
        (window.matchMedia && window.matchMedia('(display-mode: standalone)').matches) ||
        window.navigator.standalone === true
      ) {
        return false;
      }
      // eventRef.current = e;
      setInstallEvent(e);
      // Set the state variable to make button visible
      const userChoice = localStorage.getItem(ADD_2_HOME_SCREEN);
      if (!userChoice) {
        setTimeout(() => {
          setShowPrompt(true);
        }, 2000);
      } else {
        const choiceObj = JSON.parse(userChoice!) as UserChoice;
        if (dayjs().valueOf() > choiceObj.timestamp) {
          setTimeout(() => {
            setShowPrompt(true);
          }, 2000);
        }
      }
    };

    const appinstalled = () => {
      metrics.logEvent('appinstalled');
    };
    window.addEventListener('beforeinstallprompt', onInstall);
    window.addEventListener('appinstalled', appinstalled);
    return () => {
      window.removeEventListener('beforeinstallprompt', onInstall);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSuccess = () => {
    window.location.reload();
    metrics.logEvent('Upgrade.Accepted');
  };

  const isDesktop = useIsDesktop();

  const shortCutText = isMac ? 'Cmd+R' : 'Ctrl+R';

  const callToActionText = isDesktop
    ? `Press ${shortCutText} or click here to reload`
    : 'Click here to reload';
  return (
    <PWAContext.Provider
      value={installEvent ? { event: installEvent, hasEvent: true } : undefined}
    >
      {children}
      <AnimatePresence>
        {isNewVersionAvailable && (
          <motion.div
            key="pwaInstallModal"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <InstallContainer onClick={onSuccess}>
              <ExclamationContainer>
                <span>!</span>
              </ExclamationContainer>
              <p style={{ whiteSpace: 'break-spaces' }}>
                {t(
                  'newAppVersion',
                  `A new version of Curipod is available. ${callToActionText}`,
                )}
              </p>
            </InstallContainer>
          </motion.div>
        )}
      </AnimatePresence>
    </PWAContext.Provider>
  );
};

const ExclamationContainer = styled.div`
  color: ${theme.colors.white};
  border-radius: 50px;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.colors.orange};
  flex: none;
  margin-right: 10px;
  span {
    font-weight: bold;
  }
`;

const InstallContainer = styled.div`
  position: fixed;
  bottom: 2%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  max-width: 90%;
  border-left: 5px solid ${theme.colors.orange};
  background-color: ${theme.colors.white};
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  display: flex;
  align-items: center;
  padding: 15px 15px 15px 10px;
  border-radius: 5px;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  @media screen and (max-width: 600px) {
    width: 90%;
  }
  @media screen and (min-width: 500px) {
    max-width: 600px;
    bottom: 2%;
    p {
      white-space: nowrap;
    }
  }

  p {
    color: ${theme.colors.black};
    font-weight: bold;
    flex: 1;
    margin-bottom: 0px;
    word-break: break-word;
  }
`;

export default PwaInstallProvider;
