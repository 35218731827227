import React from 'react';
export interface Props {
  color: string;
  size?: number;
}
const ChevronRight = (props: Props) => {
  const { color } = props;
  return (
    <svg
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.83 5.28995L2.59 1.04995C2.49704 0.95622 2.38644 0.881826 2.26458 0.831057C2.14272 0.780288 2.01202 0.75415 1.88 0.75415C1.74799 0.75415 1.61729 0.780288 1.49543 0.831057C1.37357 0.881826 1.26297 0.95622 1.17 1.04995C0.983753 1.23731 0.879211 1.49076 0.879211 1.75495C0.879211 2.01913 0.983753 2.27259 1.17 2.45995L4.71 5.99995L1.17 9.53995C0.983753 9.72731 0.879211 9.98076 0.879211 10.2449C0.879211 10.5091 0.983753 10.7626 1.17 10.9499C1.26344 11.0426 1.37426 11.116 1.4961 11.1657C1.61794 11.2155 1.7484 11.2407 1.88 11.2399C2.01161 11.2407 2.14207 11.2155 2.26391 11.1657C2.38575 11.116 2.49656 11.0426 2.59 10.9499L6.83 6.70995C6.92373 6.61699 6.99813 6.50638 7.04889 6.38453C7.09966 6.26267 7.1258 6.13196 7.1258 5.99995C7.1258 5.86794 7.09966 5.73723 7.04889 5.61537C6.99813 5.49351 6.92373 5.38291 6.83 5.28995Z"
        fill={color || 'currentColor'}
      />
    </svg>
  );
};

export default ChevronRight;
