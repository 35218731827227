import React from 'react';

export const ChatBubbleSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17 9H7C6.73478 9 6.48043 9.10536 6.29289 9.29289C6.10536 9.48043 6 9.73478 6 10C6 10.2652 6.10536 10.5196 6.29289 10.7071C6.48043 10.8946 6.73478 11 7 11H17C17.2652 11 17.5196 10.8946 17.7071 10.7071C17.8946 10.5196 18 10.2652 18 10C18 9.73478 17.8946 9.48043 17.7071 9.29289C17.5196 9.10536 17.2652 9 17 9ZM13 13H7C6.73478 13 6.48043 13.1054 6.29289 13.2929C6.10536 13.4804 6 13.7348 6 14C6 14.2652 6.10536 14.5196 6.29289 14.7071C6.48043 14.8946 6.73478 15 7 15H13C13.2652 15 13.5196 14.8946 13.7071 14.7071C13.8946 14.5196 14 14.2652 14 14C14 13.7348 13.8946 13.4804 13.7071 13.2929C13.5196 13.1054 13.2652 13 13 13ZM12 2C10.6868 2 9.38642 2.25866 8.17316 2.7612C6.95991 3.26375 5.85752 4.00035 4.92893 4.92893C3.05357 6.8043 2 9.34784 2 12C1.99126 14.3091 2.79079 16.5485 4.26 18.33L2.26 20.33C2.12124 20.4706 2.02725 20.6492 1.98987 20.8432C1.9525 21.0372 1.97342 21.2379 2.05 21.42C2.13306 21.5999 2.2677 21.7511 2.43685 21.8544C2.60599 21.9577 2.802 22.0083 3 22H12C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 9.34784 20.9464 6.8043 19.0711 4.92893C17.1957 3.05357 14.6522 2 12 2V2ZM12 20H5.41L6.34 19.07C6.52625 18.8826 6.63079 18.6292 6.63079 18.365C6.63079 18.1008 6.52625 17.8474 6.34 17.66C5.03059 16.352 4.21517 14.6305 4.03269 12.7888C3.85021 10.947 4.31194 9.09901 5.33923 7.55952C6.36651 6.02004 7.89579 4.88436 9.66651 4.34597C11.4372 3.80759 13.3399 3.8998 15.0502 4.60691C16.7606 5.31402 18.1729 6.59227 19.0464 8.22389C19.92 9.85551 20.2009 11.7395 19.8411 13.555C19.4814 15.3705 18.5033 17.005 17.0735 18.1802C15.6438 19.3554 13.8508 19.9985 12 20V20Z"
      fill="currentColor"
    />
  </svg>
);

export default ChatBubbleSvg;
