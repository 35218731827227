import React from 'react';

export const HeartFilledSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.6875 1.17188C13.6022 1.17188 12.6072 1.51578 11.7302 2.19406C10.8894 2.84434 10.3296 3.67258 10 4.27484C9.67043 3.67254 9.11063 2.84434 8.2698 2.19406C7.39277 1.51578 6.39777 1.17188 5.3125 1.17188C2.28391 1.17188 0 3.6491 0 6.93414C0 10.4831 2.84934 12.9113 7.16285 16.5872C7.89535 17.2114 8.72563 17.919 9.58859 18.6737C9.70234 18.7733 9.84844 18.8281 10 18.8281C10.1516 18.8281 10.2977 18.7733 10.4114 18.6737C11.2745 17.9189 12.1047 17.2114 12.8376 16.5868C17.1507 12.9113 20 10.4831 20 6.93414C20 3.6491 17.7161 1.17188 14.6875 1.17188Z"
      fill="currentColor"
    />
  </svg>
);

export default HeartFilledSvg;
