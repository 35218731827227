import {
  AccountInfo,
  AuthenticationResult,
  Configuration,
  PopupRequest,
  PublicClientApplication,
  RedirectRequest,
} from '@azure/msal-browser';
import {
  Auth,
  browserSessionPersistence,
  getAuth,
  setPersistence,
  signInWithCustomToken,
} from 'firebase/auth';

import { generateRedirectPayload, isEnterprise, isPR } from '../../utils/loginUtils';
import { BackendService } from '../backendService';

export function inIframe(): boolean {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export default class Authentication {
  private auth: Auth;
  private pca: PublicClientApplication;

  private tenantSubDomain: string;
  private tenant: string;
  private signInPolicy: string;
  private signUpPolicy: string;

  private joinPolicy: string;
  private clientId: string;
  private redirectUri: string;
  private currentPolicy: string;

  private idTokenRaw: string;
  constructor({
    tenant,
    clientId,
    signInPolicy,
    enterpriseSignInPolicy,
    enterpriseJoinPolicy,
    signUpPolicy,
    joinPolicy,
    redirectUri,
    prRedirectUri,
    enterpriseRedirectUri,
  }: {
    tenant: string;
    clientId: string;
    signInPolicy: string;
    signUpPolicy: string;
    enterpriseJoinPolicy: string;
    enterpriseSignInPolicy: string;
    joinPolicy: string;
    scopes: string[];
    redirectUri: string;
    enterpriseRedirectUri: string;
    prRedirectUri: string;
  }) {
    this.auth = getAuth();
    // Setting firebase to session only to support using different tabs for different tenants. The firebase auth firebasetoken is the one that carries this logic.
    setPersistence(this.auth, browserSessionPersistence);

    this.tenantSubDomain = tenant.split('.')[0];
    this.tenant = tenant;
    this.signInPolicy = isEnterprise() ? enterpriseSignInPolicy : signInPolicy;
    this.signUpPolicy = signUpPolicy;
    this.joinPolicy = isEnterprise() ? enterpriseJoinPolicy : joinPolicy;
    this.clientId = clientId;
    this.redirectUri = isEnterprise()
      ? enterpriseRedirectUri
      : isPR()
      ? prRedirectUri
      : redirectUri;

    const signInAuthority = this.createAuthority(
      this.tenantSubDomain,
      this.tenant,
      this.signInPolicy,
    );
    // Msal Configurations
    const config: Configuration = {
      auth: {
        authority: signInAuthority,
        // https://github.com/AzureAD/microsoft-authentication-library-common-for-objc/issues/454
        knownAuthorities: [signInAuthority],
        clientId,
        redirectUri,
        navigateToLoginRequestUrl: false,
      },

      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
      },
    };
    this.currentPolicy = this.signInPolicy;
    this.pca = new PublicClientApplication(config);
    this.setPCAOnCachedPolicy();

    this.auth.useDeviceLanguage();
    this.idTokenRaw = '';
  }

  public getActiveAccount() {
    return this.getCachedActiveAccount();
  }

  private setPCAOnCachedPolicy() {
    const account = this.getCachedActiveAccount();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const cachedAcr = account?.idTokenClaims?.acr;

    if (cachedAcr && cachedAcr !== this.currentPolicy) {
      this.currentPolicy = cachedAcr;
      this.pca = new PublicClientApplication({
        auth: {
          authority: this.createAuthority(this.tenantSubDomain, this.tenant, cachedAcr),
          knownAuthorities: [
            this.createAuthority(this.tenantSubDomain, this.tenant, cachedAcr),
          ],
          clientId: this.clientId,
          redirectUri: this.redirectUri,
          navigateToLoginRequestUrl: false,
        },
        cache: {
          cacheLocation: 'localStorage',
          storeAuthStateInCookie: false,
        },
      });
    }
  }

  private createAuthority(tenantSubDomain: string, tenant: string, policy: string) {
    return `https://${tenantSubDomain}.b2clogin.com/tfp/${tenant}/${policy}/`;
  }

  public async login(request?: RedirectRequest | PopupRequest) {
    const signInAuthority = this.createAuthority(
      this.tenantSubDomain,
      this.tenant,
      this.signInPolicy,
    );
    const pca = new PublicClientApplication({
      auth: {
        authority: signInAuthority,
        knownAuthorities: [signInAuthority],
        clientId: this.clientId,
        redirectUri: this.redirectUri,
        navigateToLoginRequestUrl: false,
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
      },
    });
    this.pca = pca;
    if (inIframe()) {
      return this.pca.loginPopup(request);
    } else {
      return this.pca.loginRedirect(request);
    }
  }

  public async join(request?: RedirectRequest | PopupRequest) {
    const signInAuthority = this.createAuthority(
      this.tenantSubDomain,
      this.tenant,
      this.joinPolicy,
    );
    const pca = new PublicClientApplication({
      auth: {
        authority: signInAuthority,
        knownAuthorities: [signInAuthority],
        clientId: this.clientId,
        redirectUri: this.redirectUri,
        navigateToLoginRequestUrl: false,
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
      },
    });
    this.pca = pca;
    if (inIframe()) {
      return pca.loginPopup(request);
    } else {
      return pca.loginRedirect(request);
    }
  }
  public async signUp(request?: RedirectRequest | PopupRequest) {
    if (isEnterprise() || isPR()) throw new Error('SignUp enterprise is not allowed');
    const signInAuthority = this.createAuthority(
      this.tenantSubDomain,
      this.tenant,
      this.signUpPolicy,
    );
    const pca = new PublicClientApplication({
      auth: {
        authority: signInAuthority,
        knownAuthorities: [signInAuthority],
        clientId: this.clientId,
        redirectUri: this.redirectUri,
        navigateToLoginRequestUrl: false,
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
      },
    });
    this.pca = pca;
    if (inIframe()) {
      return pca.loginPopup(request);
    } else {
      return pca.loginRedirect(request);
    }
  }

  public setActiveAccount(result: AuthenticationResult | null) {
    if (!result) {
      localStorage.removeItem('@snapmentor/activeAccount');
    } else {
      this.pca.setActiveAccount(result.account);
      localStorage.setItem('@snapmentor/activeAccount', JSON.stringify(result.account));
    }
  }

  private getCachedActiveAccount() {
    try {
      const accounts = this.pca.getAllAccounts();
      if (accounts.length === 1) {
        return accounts[0];
      }
      const activeAccountCache = localStorage.getItem('@snapmentor/activeAccount');
      if (!activeAccountCache) return undefined;
      return JSON.parse(activeAccountCache) as AccountInfo;
    } catch (e) {
      return undefined;
    }
  }

  private getIdp(account?: AccountInfo) {
    if (!account) return undefined;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const idp = account?.idTokenClaims?.idp;
    if (idp === 'google.com') {
      // use the email as google login supports autoselecting
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      return account?.idTokenClaims?.email;
    }
    if (idp === 'feide.no') {
      // we dont have login hint params in the token unfortunately
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      return undefined;
    }
    return undefined;
  }

  private getDomainHint(account?: AccountInfo) {
    if (!account) return undefined;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const idp = account?.idTokenClaims?.idp;
    if (idp === 'google.com') {
      // use the email as google login supports autoselecting
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      return 'google.com';
    }
    if (idp === 'feide.no') {
      // we dont have login hint params in the token unfortunately
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      return 'feide.no';
    }
    return undefined;
  }

  public getIdToken = async (forceRefresh = false) => {
    const account = this.getCachedActiveAccount();

    try {
      this.setPCAOnCachedPolicy();
      const request = {
        scopes: ['openid', 'profile'],
        account,
        forceRefresh,
        loginHint: this.getIdp(account),
        domainHint: this.getDomainHint(account),
      };
      // eslint-disable-next-line no-console
      const response = await this.pca.acquireTokenSilent(request);

      this.idTokenRaw = response.idToken;

      return response.idToken;
    } catch (error) {
      if (isEnterprise() || isPR()) {
        const oauthStateToken = await BackendService.instance.authToken.generate();
        if (oauthStateToken.token) {
          const state = generateRedirectPayload(
            oauthStateToken.token,
            window.location.href,
          );

          await this.login({
            state,
            scopes: ['openid', 'profile'],
            extraQueryParameters: {
              ui_locales: 'en',
              appOrigin: oauthStateToken.token,
            },
          });
        }
      } else {
        // acquireTokenSilent can fail for a number of reasons, fallback to interaction
        await this.login({
          state: generateRedirectPayload('', window.location.href),
          scopes: ['openid', 'profile'],
          loginHint: this.getIdp(account),
          domainHint: this.getDomainHint(account),
          extraQueryParameters: {
            ui_locales: 'en',
          },
        });
      }
      return null;
    }
  };

  public async logout() {
    const account = this.getCachedActiveAccount();
    await this.pca.logoutRedirect({
      account,
      postLogoutRedirectUri: `${window.location.origin}/logout`,
    });
  }

  public getAuthProvider = () => this.pca;

  public getAuth = () => this.auth;

  public signInWithCustomToken = (token: string) => {
    try {
      return signInWithCustomToken(this.auth, token);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);

      throw e;
    }
  };
}
