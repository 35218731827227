import { createAction } from 'typesafe-actions';

import * as ActionTypes from './actionTypes';
import { UserTypeState } from './reducer';

export const subscribeToUserType = createAction(
  ActionTypes.SUBSCRIBE,
  action => (uid: string) => action(uid),
);
export const setUserType = createAction(
  ActionTypes.SET_USER_TYPE,
  action => (data: Partial<UserTypeState>) => action(data),
);

export const skipNotifications = createAction(ActionTypes.NOTIFICATIONS_SKIPPED);
