export const CloudSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="36"
    height="25"
    viewBox="0 0 36 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M30.9283 11.0986C31.9715 5.40785 27.5873 0.195312 21.8516 0.195312C18.5372 0.195312 15.5712 1.95358 13.9368 4.67518C11.7074 3.9548 9.41521 4.26181 7.52672 5.56187C5.63925 6.86117 4.512 8.91838 4.42074 11.1684C1.89869 12.3566 0.25 14.8678 0.25 17.627C0.25 18.0995 0.296348 18.5722 0.387676 19.0321C1.05206 22.3769 4.01277 24.8047 7.42773 24.8047H28.0039C31.9994 24.8047 35.25 21.5848 35.25 17.627C35.25 14.8028 33.5366 12.2534 30.9283 11.0986ZM28.0039 22.7539H7.42773C4.625 22.7539 2.30078 20.482 2.30078 17.627C2.30078 15.4907 3.70468 13.5582 5.79422 12.8182L6.52225 12.5604L6.47542 11.7894C6.36529 9.97788 7.19306 8.28127 8.68965 7.25103C10.2722 6.16151 12.2137 6.08625 13.9908 6.89207L14.9145 7.31091L15.3435 6.39188C16.519 3.87346 19.0736 2.24609 21.8516 2.24609C25.8094 2.24609 29.0293 5.46603 29.0293 9.42383C29.0293 10.5946 28.7823 11.3034 28.5092 12.2419C29.2738 12.6388 29.3976 12.7123 29.655 12.8005C31.7749 13.5266 33.1992 15.4663 33.1992 17.627C33.1992 20.454 30.8686 22.7539 28.0039 22.7539Z"
      fill="currentColor"
    />
  </svg>
);
