import { Select } from 'antd';
import styled from 'styled-components';

import theme from '../theme';

export const SelectStyled = styled(Select)`
  && .ant-select-selector {
    border: 1px solid #d9d9d9;
    border-radius: 5px !important;
    color: ${theme.colors.black};
    font-family: 'Nunito';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 130%;
    padding: 10px 10px;
    mix-blend-mode: normal;
    min-height: 60px;
  }

  && .ant-select-selection-search {
    margin-inline-start: 0px !important;
  }
  && .ant-select-selection-placeholder {
    color: #a5a5a5;
  }
  && .ant-select-selection-item {
    border-radius: 20px;
    background-color: ${theme.colors.green};
    color: white;
    padding: 0 10px;
    // height: 35px;
    display: flex;
    align-items: center;
  }
  && .ant-select-selection-item-remove {
    color: white;
  }
`;

export const ModalSelect = styled(Select)`
  && .ant-select-selector {
    border: 1px solid #d9d9d9;
    border-radius: 5px !important;
    color: ${theme.colors.black};
    font-family: 'Nunito';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    padding: 0px 10px;
    mix-blend-mode: normal;
  }

  && .ant-select-selection-search {
    margin-inline-start: 0px !important;
  }
  && .ant-select-selection-placeholder {
    color: #a5a5a5;
  }
`;
