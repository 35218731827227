import React from 'react';

export const ShareSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 9C11.0079 9 10.1294 9.48612 9.58288 10.2304L6.8685 8.87712C6.9535 8.5995 7 8.30513 7 8C7 7.69487 6.9535 7.4005 6.8685 7.12288L9.58288 5.76963C10.1294 6.51388 11.0079 7 12 7C13.6543 7 15 5.65425 15 4C15 2.34575 13.6543 1 12 1C10.3457 1 9 2.34575 9 4C9 4.30512 9.0465 4.5995 9.1315 4.87712L6.41713 6.23037C5.87063 5.48612 4.99213 5 4 5C2.34575 5 1 6.34575 1 8C1 9.65425 2.34575 11 4 11C4.99213 11 5.87063 10.5139 6.41713 9.76962L9.1315 11.1229C9.0465 11.4005 9 11.6949 9 12C9 13.6543 10.3457 15 12 15C13.6543 15 15 13.6543 15 12C15 10.3457 13.6543 9 12 9ZM12 2C13.1027 2 14 2.89725 14 4C14 5.10275 13.1027 6 12 6C10.8973 6 10 5.10275 10 4C10 2.89725 10.8973 2 12 2ZM4 10C2.89725 10 2 9.10275 2 8C2 6.89725 2.89725 6 4 6C5.10275 6 6 6.89725 6 8C6 9.10275 5.10275 10 4 10ZM12 14C10.8973 14 10 13.1027 10 12C10 10.8973 10.8973 10 12 10C13.1027 10 14 10.8973 14 12C14 13.1027 13.1027 14 12 14Z"
      fill="currentColor"
    />
  </svg>
);

export default ShareSvg;
