import React from 'react';

export const FlashSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 192 192"
  >
    <g>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="m158.612 76.1a8 8 0 0 0 -6.983-4.1h-47.838l8.193-62.6a8 8 0 0 0 -14.747-5.232l-63.728 103.642a8 8 0 0 0 6.815 12.19h47.052l-7.363 62.7a8 8 0 0 0 14.762 5.121l63.671-103.63a8 8 0 0 0 .166-8.091zm-58.476 72.433 4.18-35.595a8 8 0 0 0 -7.945-8.938h-41.736l36.628-59.569-4.519 34.531a8 8 0 0 0 7.932 9.038h42.649z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default FlashSvg;
