import { Crop } from 'react-image-crop';

export type CropConfig = {
  height: number;
  width: number;
  naturalHeight: number;
  naturalWidth: number;
  x: number;
  y: number;
};

export function cropToNaturalPx(
  crop: Crop,
  naturalImage: { height: number; width: number },
  renderedImage: { height: number; width: number },
) {
  if (crop && crop.y !== undefined && crop.x !== undefined && crop.height && crop.width) {
    const scaleX = renderedImage.width ? naturalImage.width / renderedImage.width : 0;
    const scaleY = renderedImage.height ? naturalImage.height / renderedImage.height : 0;

    return {
      height: Number((crop.height * scaleY).toFixed(0)),
      width: Number((crop.width * scaleX).toFixed(0)),
      naturalHeight: Number(naturalImage.width.toFixed(0)),
      naturalWidth: Number(naturalImage.height.toFixed(0)),
      x: Number((crop.x * scaleX).toFixed(0)),
      y: Number((crop.y * scaleY).toFixed(0)),
    };
  }
  return undefined;
}

export function naturalPxToCrop(
  naturalImage: { height: number; width: number },
  renderedImage: { height: number; width: number },
  cropConfig: CropConfig,
) {
  const scaleX = naturalImage.width ? renderedImage.width / naturalImage.width : 0;
  const scaleY = naturalImage.height ? renderedImage.height / naturalImage.height : 0;

  return {
    height: Number((cropConfig.height * scaleY).toFixed(0)),
    width: Number((cropConfig.width * scaleX).toFixed(0)),
    naturalHeight: Number(naturalImage.width.toFixed(0)),
    naturalWidth: Number(naturalImage.height.toFixed(0)),
    x: Number((cropConfig.x * scaleX).toFixed(0)),
    y: Number((cropConfig.y * scaleY).toFixed(0)),
  };
}

export function dataURLtoFile(dataurl: string, filename: string) {
  const arr = dataurl.split(',');
  if (arr[0]) {
    const s = arr[0].match(/:(.*?);/);
    if (s) {
      const mime = s[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n) {
        u8arr[n - 1] = bstr.charCodeAt(n - 1);
        n -= 1; // to make eslint happy
      }
      return new File([u8arr], filename, { type: mime });
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }
}
