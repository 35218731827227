import styled from 'styled-components';

export const IconWrapper = styled.div<{
  height?: string;
  width?: string;
  color?: string;
  cursor?: string;
}>`
  cursor: ${({ cursor }) => (cursor ? cursor : 'pointer')};
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    height: ${({ height }) => (height ? height : '14px')};
    width: ${({ width }) => (width ? width : '14px')};
    ${({ color }) => color && `color: ${color}`};
  }
`;
