export const config = {
  firebase: {
    apiKey: 'AIzaSyBXCYWsZ_lsaw4gsuLRknu7w4NoiWuxU1U',
    authDomain: 'app.curipod.com',
    databaseURL: 'https://snapmentor-prod.firebaseio.com',
    projectId: 'snapmentor-prod',
    storageBucket: 'snapmentor-prod.appspot.com',
    messagingSenderId: '139743671678',
    appId: '1:139743671678:web:c97ee1cbfab43887',
    measurementId: 'G-V5F9PZCX67',
  },
  cloudMessaging: {
    vapidKey:
      'BNg04rRat94mcemMQgPwQe35EaJvOf9LkkaKqNoUa3Yyx4c6WEKShKb6JudmK38VLpmubwKfe781BRYSRnezhrg',
  },
  backend: {
    externalApiUrl: 'https://api.curipod.com',
  },
  cdn: {
    url: 'https://images-cdn.curipod.com',
  },
  appVersion: process.env.REACT_APP_VERSION,
  azure: {
    tenant: 'snapmentorprod.onmicrosoft.com',
    tenantName: 'snapmentorprod',
    clientId: '47e477d5-a501-44cd-8599-817b011b2a81',
    signInPolicy: 'B2C_1A_signin',
    joinPolicy: 'B2C_1A_join',
    signUpPolicy: 'B2C_1A_signup',
    enterpriseSignInPolicy: 'B2C_1A_enterprise_signin',
    enterpriseJoinPolicy: 'B2C_1A_enterprise_join',
    scopes: [
      'https://snapmentorprod.onmicrosoft.com/47e477d5-a501-44cd-8599-817b011b2a81/User.Read',
    ],
    enterpriseRedirectUri: 'https://id.curipod.com',
    prRedirectUri: '',
    redirectUri: 'https://app.curipod.com/oauth/callback',
  },
  onboarding: {
    version: 2,
  },
  whatsNew: {
    version: 8,
  },
  game: {
    url: 'curi.live',
  },
  externalLinks: {
    howItWorks: 'https://curipod.com/how-it-works',
    snapmentorSchool: 'help',
  },
  unleash: {
    url: 'https://unleash-proxy-7epohblsoa-ey.a.run.app/proxy',
    clientKey: '7epohblsoa',
    appName: 'app-curipod',
    refreshInterval: 60 * 5,
  },
};
