import React from 'react';

export const MinimizeSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 512 512"
  >
    <g>
      <g xmlns="http://www.w3.org/2000/svg">
        <g>
          <g>
            <path
              d="M505.752,6.248c-8.331-8.331-21.839-8.331-30.17,0L320,161.83V64c0-11.782-9.551-21.333-21.333-21.333     c-11.782,0-21.333,9.551-21.333,21.333v149.333c0,0.007,0.001,0.015,0.001,0.022c0.001,0.695,0.037,1.39,0.105,2.083     c0.031,0.318,0.091,0.627,0.136,0.94c0.054,0.375,0.098,0.75,0.171,1.122c0.071,0.359,0.17,0.708,0.259,1.061     c0.081,0.322,0.151,0.645,0.248,0.964c0.105,0.346,0.234,0.68,0.356,1.018c0.114,0.318,0.219,0.639,0.349,0.953     c0.131,0.316,0.284,0.618,0.43,0.926c0.152,0.323,0.296,0.649,0.465,0.966c0.158,0.295,0.338,0.575,0.509,0.861     c0.186,0.311,0.361,0.626,0.564,0.929c0.211,0.316,0.447,0.613,0.674,0.917c0.19,0.253,0.365,0.513,0.568,0.759     c0.892,1.087,1.889,2.085,2.977,2.977c0.246,0.202,0.506,0.378,0.759,0.568c0.304,0.228,0.601,0.463,0.917,0.674     c0.303,0.203,0.618,0.379,0.929,0.564c0.286,0.171,0.566,0.351,0.861,0.509c0.317,0.169,0.643,0.313,0.966,0.465     c0.308,0.145,0.611,0.299,0.926,0.43c0.314,0.13,0.635,0.235,0.953,0.349c0.338,0.122,0.672,0.251,1.018,0.356     c0.318,0.096,0.642,0.167,0.964,0.248c0.353,0.089,0.701,0.188,1.061,0.259c0.372,0.074,0.748,0.118,1.122,0.171     c0.314,0.045,0.622,0.104,0.94,0.136c0.693,0.068,1.388,0.105,2.083,0.105c0.007,0,0.015,0.001,0.022,0.001H448     c11.782,0,21.333-9.551,21.333-21.333c0-11.782-9.551-21.333-21.333-21.333h-97.83L505.752,36.418     C514.083,28.087,514.083,14.58,505.752,6.248z"
              fill="currentColor"
            />
            <path
              d="M234.56,296.562c-0.031-0.318-0.091-0.627-0.136-0.94c-0.054-0.375-0.098-0.75-0.171-1.122     c-0.071-0.359-0.17-0.708-0.259-1.061c-0.081-0.322-0.151-0.645-0.248-0.964c-0.105-0.346-0.234-0.68-0.356-1.018     c-0.114-0.318-0.219-0.639-0.349-0.953c-0.131-0.316-0.284-0.618-0.43-0.926c-0.152-0.323-0.296-0.649-0.465-0.966     c-0.158-0.295-0.338-0.575-0.509-0.861c-0.186-0.311-0.361-0.626-0.564-0.929c-0.211-0.316-0.447-0.613-0.674-0.917     c-0.19-0.253-0.365-0.513-0.568-0.759c-0.892-1.087-1.889-2.085-2.977-2.977c-0.246-0.202-0.506-0.378-0.759-0.568     c-0.304-0.228-0.601-0.463-0.917-0.674c-0.303-0.203-0.618-0.379-0.929-0.564c-0.286-0.171-0.566-0.351-0.861-0.509     c-0.317-0.169-0.643-0.313-0.966-0.465c-0.308-0.145-0.611-0.299-0.926-0.43c-0.314-0.13-0.635-0.235-0.953-0.349     c-0.338-0.122-0.672-0.251-1.018-0.356c-0.318-0.096-0.642-0.167-0.964-0.248c-0.353-0.089-0.701-0.188-1.061-0.259     c-0.372-0.074-0.748-0.118-1.122-0.171c-0.314-0.045-0.622-0.104-0.94-0.136c-0.7-0.069-1.402-0.106-2.105-0.106l0,0H64     c-11.782,0-21.333,9.551-21.333,21.333C42.667,310.449,52.218,320,64,320h97.83L6.248,475.582c-8.331,8.331-8.331,21.839,0,30.17     c8.331,8.331,21.839,8.331,30.17,0L192,350.17V448c0,11.782,9.551,21.333,21.333,21.333c11.782,0,21.333-9.551,21.333-21.333     V298.667l0,0C234.667,297.964,234.629,297.262,234.56,296.562z"
              fill="currentColor"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default MinimizeSvg;
