import { AxiosResponse } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { getUserId } from '../modules';
import { generateUniqueId } from '../modules/utils';
import { BackendService } from '../services/backendService';
import { dataURLtoFile } from '../utils/imageCropUtils';
import { useTypedSelector } from '.';

export const useProfilePhoto = () => {
  const cacheKey = 'profile-photo';
  const userId = useTypedSelector(getUserId);

  const client = useQueryClient();

  const { data, isFetching } = useQuery<string | undefined, string>(
    [cacheKey],
    async () => (await BackendService.instance.profile.getProfileImageUrl()).data,
    {
      retry: false,
      staleTime: Infinity,
    },
  );

  const { mutate: upload, isLoading: isUploading } = useMutation(
    async ({ imgData }: { imgData: string }) => {
      if (userId) {
        const profileImageId = userId + '_' + generateUniqueId();
        const data = await BackendService.instance.profile.getUploadUrl(profileImageId);
        const url = data.data;
        const file = dataURLtoFile(imgData, profileImageId);
        if (file) {
          await new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open('PUT', url, true);
            xhr.setRequestHeader('Content-Type', file.type);

            xhr.onreadystatechange = function() {
              if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                  resolve(xhr.response);
                } else {
                  reject(xhr.response);
                }
              }
            };
            xhr.onerror = function(response) {
              reject(response);
            };
            xhr.send(file);
          });
          return BackendService.instance.profile.updateProfileImageId(profileImageId);
        }
      }
      return Promise.reject();
    },
    {
      onSuccess: async (profileImageId: AxiosResponse<string>) => {
        if (profileImageId) {
          await BackendService.instance.discover.updateCreatorProfileImage(
            profileImageId.data,
          );
        }
        client.invalidateQueries(cacheKey);
      },
    },
  );

  return {
    photo: data,
    upload,
    isLoading: isFetching,
    isUploading,
  };
};
