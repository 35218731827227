import React from 'react';

export const CheckSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="18"
    height="14"
    viewBox="0 0 18 14"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5.99991 11.1698L1.82991 6.99984L0.409912 8.40984L5.99991 13.9998L17.9999 1.99984L16.5899 0.589844L5.99991 11.1698Z" />
  </svg>
);

export default CheckSvg;
