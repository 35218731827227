import React from 'react';

export const IdeasSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.99945 2.34326C9.67586 2.34326 9.41351 2.60561 9.41351 2.9292C9.41351 3.25279 9.67586 3.51514 9.99945 3.51514C11.9383 3.51514 13.5157 5.09225 13.5157 7.03076C13.5157 7.35436 13.778 7.6167 14.1016 7.6167C14.4252 7.6167 14.6875 7.35436 14.6875 7.03076C14.6875 4.44607 12.5845 2.34326 9.99945 2.34326Z"
      fill="currentColor"
    />
    <path
      d="M8.48964 0.157448C5.84237 0.710613 3.69589 2.83655 3.13108 5.50026C2.65417 7.74956 3.24187 10.0078 4.74347 11.6958C5.47749 12.521 5.89843 13.6101 5.89843 14.6871V15.859C5.89843 16.6387 6.40886 17.3011 7.113 17.5305C7.34456 18.8987 8.53206 19.9996 9.99999 19.9996C11.4675 19.9996 12.6553 18.899 12.887 17.5305C13.5911 17.3011 14.1016 16.6387 14.1016 15.859V14.6871C14.1016 13.608 14.5239 12.5319 15.2907 11.6571C16.4131 10.3766 17.0312 8.73362 17.0312 7.03089C17.0312 2.59475 12.9707 -0.778723 8.48964 0.157448ZM9.99999 18.8278C9.24679 18.8278 8.5864 18.3095 8.34253 17.6129H11.6574C11.4136 18.3095 10.7532 18.8278 9.99999 18.8278ZM12.9297 15.859C12.9297 16.1821 12.6668 16.4449 12.3437 16.4449H7.65624C7.33315 16.4449 7.0703 16.1821 7.0703 15.859V15.2731H12.9297V15.859ZM14.4094 10.8847C13.5923 11.817 13.0895 12.9412 12.9619 14.1012H7.03819C6.91077 12.9406 6.40929 11.8053 5.61913 10.917C4.36765 9.51011 3.87862 7.62436 4.27749 5.74335C4.74308 3.54749 6.53214 1.7637 8.72937 1.3046C12.4803 0.52073 15.8594 3.3376 15.8594 7.03089C15.8594 8.44932 15.3444 9.81796 14.4094 10.8847Z"
      fill="currentColor"
    />
    <path
      d="M1.75781 7.03076H0.585938C0.262344 7.03076 0 7.29311 0 7.6167C0 7.94029 0.262344 8.20264 0.585938 8.20264H1.75781C2.08141 8.20264 2.34375 7.94029 2.34375 7.6167C2.34375 7.29311 2.08141 7.03076 1.75781 7.03076Z"
      fill="currentColor"
    />
    <path
      d="M2.00052 4.10108L1.17188 3.27245C0.943096 3.04362 0.57208 3.04362 0.343252 3.27245C0.114424 3.50128 0.114424 3.87226 0.343252 4.10108L1.17188 4.92972C1.40067 5.15855 1.77169 5.15858 2.00052 4.92972C2.22935 4.70089 2.22935 4.32991 2.00052 4.10108Z"
      fill="currentColor"
    />
    <path
      d="M2.00052 10.3037C1.77169 10.0749 1.40067 10.0749 1.17188 10.3037L0.343252 11.1323C0.114424 11.3612 0.114424 11.7321 0.343252 11.961C0.572041 12.1898 0.943057 12.1898 1.17188 11.961L2.00052 11.1323C2.22935 10.9035 2.22935 10.5325 2.00052 10.3037Z"
      fill="currentColor"
    />
    <path
      d="M19.4141 7.03076H18.2422C17.9186 7.03076 17.6562 7.29311 17.6562 7.6167C17.6562 7.94029 17.9186 8.20264 18.2422 8.20264H19.4141C19.7377 8.20264 20 7.94029 20 7.6167C20 7.29311 19.7377 7.03076 19.4141 7.03076Z"
      fill="currentColor"
    />
    <path
      d="M19.6568 3.27245C19.428 3.04362 19.057 3.04362 18.8281 3.27245L17.9995 4.10108C17.7707 4.32991 17.7707 4.70089 17.9995 4.92971C18.2283 5.1585 18.5993 5.15854 18.8281 4.92971L19.6568 4.10108C19.8856 3.87225 19.8856 3.50128 19.6568 3.27245Z"
      fill="currentColor"
    />
    <path
      d="M19.6568 11.1323L18.8281 10.3037C18.5994 10.0749 18.2283 10.0749 17.9995 10.3037C17.7707 10.5325 17.7707 10.9035 17.9995 11.1323L18.8281 11.961C19.0569 12.1898 19.4279 12.1898 19.6568 11.961C19.8856 11.7321 19.8856 11.3612 19.6568 11.1323Z"
      fill="currentColor"
    />
  </svg>
);

export default IdeasSvg;
