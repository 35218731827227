import React from 'react';

const WhiteLogo = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M40 20.0006C40.0002 22.6268 39.483 25.2273 38.4781 27.6536C37.4732 30.0799 36.0002 32.2845 34.1433 34.1416C32.2863 35.9986 30.0817 37.4717 27.6554 38.4767C25.2291 39.4816 22.6287 39.9988 20.0025 39.9987C17.1958 39.9987 13.8872 39.8581 11.645 37.9541C9.08487 35.7819 8.72612 32.3308 9.42687 29.2181C9.7443 27.6594 10.4347 26.2009 11.4389 24.9673C12.4432 23.7338 13.7314 22.762 15.1933 22.1351C16.5023 21.6176 17.8991 21.3579 19.3067 21.3705C22.109 21.3705 23.8406 22.1948 25.2663 22.8735C26.2641 23.3485 27.1121 23.7522 28.0725 23.7522C29.6406 23.7522 31.0081 22.8401 31.0081 20.9672C31.0081 17.8767 25.6908 15.0886 19.6115 15.0886C9.76023 15.0886 3.49132 21.4362 2.52044 29.7193C1.03625 27.0501 0.183081 24.0764 0.0263538 21.0263C-0.130374 17.9763 0.41351 14.9308 1.61629 12.1235C2.81908 9.3162 4.64879 6.82167 6.96515 4.83118C9.28151 2.84069 12.023 1.40713 14.9793 0.640443C17.9356 -0.126247 21.0282 -0.2057 24.02 0.408176C27.0118 1.02205 29.8232 2.31296 32.2387 4.18188C34.6543 6.0508 36.6097 8.44809 37.955 11.1899C39.3004 13.9317 39.9999 16.9453 40 19.9994V20.0006ZM16.4158 35.0778C18.1226 35.0778 19.5063 33.6941 19.5063 31.9873C19.5063 30.2805 18.1226 28.8969 16.4158 28.8969C14.709 28.8969 13.3254 30.2805 13.3254 31.9873C13.3254 33.6941 14.709 35.0778 16.4158 35.0778ZM28.4771 31.9871C28.4771 33.6939 27.0935 35.0776 25.3867 35.0776C23.6799 35.0776 22.2962 33.6939 22.2962 31.9871C22.2962 30.2803 23.6799 28.8967 25.3867 28.8967C27.0935 28.8967 28.4771 30.2803 28.4771 31.9871Z"
      fill="white"
    />
  </svg>
);

export default WhiteLogo;
