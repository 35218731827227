import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-locize-backend';
import XHR from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

import { isDevelopment } from '../utils/randomUtils';

dayjs.extend(updateLocale);
dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);

const LANGUAGE_DETECTOR_KEY = 'i18nextLng';
export const getDefaultLanguageFromLocalStorage = () => {
  const initLang = localStorage.getItem(LANGUAGE_DETECTOR_KEY);
  return initLang;
};

export const SUPPORTED_LANGAUGES = [
  { value: 'en', displayName: 'English' },
  { value: 'nb-NO', displayName: 'Norsk' },
];

const initOptions = {
  fallbackLng: ['en'],
  debug: false,
  interpolation: {
    escapeValue: false, // not needed for react!!
  },
  saveMissing: true, // auto add missing translations during development
  backend: {},
  // react i18next special options (optional)
  // override if needed - omit if ok with defaults
  /*
  react: {
    bindI18n: 'languageChanged',
    bindI18nStore: '',
    transEmptyNodeValue: '',
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
    useSuspense: true,
  }
  */
};

if (isDevelopment()) {
  // Localhost loads/saves resources from the network.
  i18n.use(Backend);
  initOptions.backend = {
    // Locize specific keys
    projectId: '82081248-4597-485a-8a16-ca9695f6957d',
    apiKey: process.env.REACT_APP_LOCIZE_API_KEY,
    referenceLng: 'en',
    allowedAddOrUpdateHosts: ['localhost', 'app-dev.curipod.com'],
  };
  initOptions.saveMissing = true;
} else {
  // Staging and production use local resources.
  i18n.use(XHR);
  initOptions.backend = {
    loadPath: `${window.origin}/locales/{{lng}}/{{ns}}.json`,
  };
}
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(initOptions);

i18n.on('languageChanged', (lang: string) => {
  if (lang === 'nb' || lang === 'no' || lang === 'nn') {
    i18n.changeLanguage('nb-NO');
    return;
  }
  if (lang === 'en-GB' || lang === 'en-US') {
    i18n.changeLanguage('en');
    return;
  }
  const langKey = lang.split('-')[0];

  if (langKey === 'nb') {
    import(`dayjs/locale/nb`).then(() => {
      dayjs.locale(langKey);
      dayjs.updateLocale('nb', {
        relativeTime: {
          future: 'om %s',
          past: '%s',
          s: function() {
            return `0m`;
          },
          m: function() {
            return `1m`;
          },
          mm: function(number: string) {
            return `${number}m`;
          },
          h: function() {
            return `1t`;
          },
          hh: function(number: string) {
            return `${number}t`;
          },
          d: function() {
            return `1d`;
          },
          dd: function(number: string) {
            return `${number}d`;
          },
          M: function() {
            return `1 mnd`;
          },
          MM: function(number: string) {
            return `${number} mnd`;
          },
          y: function() {
            return `1 år`;
          },
          yy: function(number: string) {
            return `${number}å`;
          },
        },
      });
    });
  }
  if (langKey === 'en') {
    dayjs.locale(langKey);
    dayjs.updateLocale('en', {
      relativeTime: {
        future: 'in %s',
        past: '%s',
        s: function() {
          return `just now`;
        },
        m: function() {
          return `1m`;
        },
        mm: function(number: string) {
          return `${number}m`;
        },
        h: function() {
          return `1h`;
        },
        hh: function(number: string) {
          return `${number}h`;
        },
        d: function() {
          return `1d`;
        },
        dd: function(number: string) {
          return `${number}d`;
        },
        M: function() {
          return `1mo`;
        },
        MM: function(number: string) {
          return `${number}mo`;
        },
        y: function() {
          return `1y`;
        },
        yy: function(number: string) {
          return `${number}y`;
        },
      },
    });
  }

  localStorage.setItem(LANGUAGE_DETECTOR_KEY, lang);
});

export default i18n;
