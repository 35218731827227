import React from 'react';

export const GameSettingsSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4324_64297)">
      <path
        d="M0.641026 3.63896H3.99444C4.2788 4.71981 5.26419 5.5193 6.43299 5.5193C7.6018 5.5193 8.58718 4.71981 8.87154 3.63896H19.359C19.713 3.63896 20 3.35195 20 2.99793C20 2.64391 19.713 2.3569 19.359 2.3569H8.87145C8.58709 1.27605 7.60171 0.476562 6.43291 0.476562C5.2641 0.476562 4.27872 1.27605 3.99436 2.3569H0.641026C0.287009 2.3569 0 2.64391 0 2.99793C0 3.35195 0.287009 3.63896 0.641026 3.63896ZM6.43299 1.75861C7.11632 1.75861 7.67231 2.3146 7.67231 2.99793C7.67231 3.68126 7.11632 4.23725 6.43299 4.23725C5.74966 4.23725 5.19368 3.68126 5.19368 2.99793C5.19368 2.3146 5.74966 1.75861 6.43299 1.75861Z"
        fill="currentColor"
      />
      <path
        d="M19.359 9.35983H15.0863C14.802 8.27898 13.8166 7.47949 12.6478 7.47949C11.479 7.47949 10.4936 8.27898 10.2092 9.35983H0.641026C0.287009 9.35983 0 9.64684 0 10.0009C0 10.3549 0.287009 10.6419 0.641026 10.6419H10.2093C10.4937 11.7227 11.4791 12.5222 12.6479 12.5222C13.8167 12.5222 14.8021 11.7227 15.0864 10.6419H19.3591C19.7131 10.6419 20.0001 10.3549 20.0001 10.0009C20.0001 9.64684 19.713 9.35983 19.359 9.35983ZM12.6479 11.2402C11.9645 11.2402 11.4085 10.6842 11.4085 10.0009C11.4085 9.31753 11.9645 8.76154 12.6479 8.76154C13.3312 8.76154 13.8872 9.31753 13.8872 10.0009C13.8872 10.6842 13.3312 11.2402 12.6479 11.2402Z"
        fill="currentColor"
      />
      <path
        d="M19.359 16.3618H8.87145C8.58709 15.281 7.60171 14.4814 6.43291 14.4814C5.2641 14.4814 4.27872 15.281 3.99436 16.3618H0.641026C0.287009 16.3618 0 16.6488 0 17.0028C0 17.3568 0.287009 17.6438 0.641026 17.6438H3.99444C4.2788 18.7247 5.26419 19.5242 6.43299 19.5242C7.6018 19.5242 8.58718 18.7247 8.87154 17.6438H19.359C19.713 17.6438 20 17.3568 20 17.0028C20 16.6488 19.713 16.3618 19.359 16.3618ZM6.43299 18.2421C5.74966 18.2421 5.19368 17.6861 5.19368 17.0028C5.19368 16.3195 5.74966 15.7635 6.43299 15.7635C7.11632 15.7635 7.67231 16.3195 7.67231 17.0028C7.67231 17.6861 7.11632 18.2421 6.43299 18.2421Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default GameSettingsSvg;
