import { AuthenticationResult } from '@azure/msal-common';
import { createAction } from 'typesafe-actions';

import { Auth } from '../../providers/AuthSubscriber';
import { MSClaims, UserTenant } from '../../services/backendService';
import * as ActionTypes from './actionTypes';
import { Claims } from './reducer';

export const setUser = createAction(
  ActionTypes.SET_USER,
  action => (auth: Auth, claims: Claims) => action({ auth, claims }),
);

export const loadAuth = createAction(ActionTypes.LOAD_AUTH);
export const authLoadedNoUser = createAction(ActionTypes.AUTH_LOADED_NO_USER);
export const signOut = createAction(ActionTypes.SIGN_OUT);
export const resetAction = createAction(ActionTypes.RESET);
export const azureLoadEnd = createAction(ActionTypes.AZURE_LOAD_END);
export const tenantsReceived = createAction(
  ActionTypes.TENANTS_RECEIVED,
  action => (data: { user: UserTenant; roles: MSClaims }) => action(data),
);

export const acquiredIdTokenSuccess = createAction(
  ActionTypes.GOT_ID_TOKEN_SUCCESS,

  action => (result: AuthenticationResult) => action({ result }),
);
