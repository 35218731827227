import React from 'react';

export const IdeaSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M40 9.37352C38.7056 9.37352 37.6562 10.4229 37.6562 11.7173C37.6562 13.0116 38.7056 14.061 40 14.061C47.7553 14.061 54.0648 20.3695 54.0648 28.1235C54.0648 29.4179 55.1142 30.4673 56.4085 30.4673C57.7029 30.4673 58.7523 29.4179 58.7523 28.1235C58.7523 17.7848 50.3401 9.37352 40 9.37352Z"
      fill="currentColor"
    />
    <path
      d="M33.9571 0.630046C23.368 2.8427 14.7821 11.3465 12.5229 22.0013C10.6152 30.9985 12.966 40.0313 18.9724 46.7836C21.9085 50.0841 23.5922 54.4408 23.5922 58.7488V63.4363C23.5922 66.555 25.634 69.2046 28.4505 70.1222C29.3768 75.5949 34.1268 79.9986 39.9985 79.9986C45.8687 79.9986 50.6199 75.5963 51.5465 70.1222C54.363 69.2046 56.4047 66.555 56.4047 63.4361V58.7486C56.4047 54.4322 58.0941 50.1279 61.1613 46.6285C65.651 41.5066 68.1235 34.9347 68.1235 28.1238C68.1235 10.3793 51.8815 -3.11464 33.9571 0.630046ZM39.9985 75.3113C36.9857 75.3113 34.3441 73.2382 33.3687 70.4519H46.6282C45.6529 73.2382 43.0113 75.3113 39.9985 75.3113ZM51.7172 63.4363C51.7172 64.7286 50.6658 65.78 49.3735 65.78H30.6235C29.3312 65.78 28.2797 64.7286 28.2797 63.4363V61.0925H51.7172V63.4363ZM57.6363 43.539C54.3676 47.2682 52.3565 51.765 51.8462 56.405H28.1513C27.6416 51.7625 25.6357 47.2215 22.4751 43.6682C17.4691 38.0407 15.513 30.4977 17.1085 22.9736C18.9708 14.1902 26.1271 7.05505 34.916 5.21864C49.9197 2.08317 63.436 13.3507 63.436 28.1238C63.436 33.7975 61.3763 39.2721 57.6363 43.539Z"
      fill="currentColor"
    />
    <path
      d="M7.03125 28.1235H2.34375C1.04937 28.1235 0 29.1729 0 30.4673C0 31.7617 1.04937 32.811 2.34375 32.811H7.03125C8.32563 32.811 9.375 31.7617 9.375 30.4673C9.375 29.1729 8.32563 28.1235 7.03125 28.1235Z"
      fill="currentColor"
    />
    <path
      d="M8.00305 16.4046L4.68852 13.09C3.77336 12.1747 2.2893 12.1747 1.37398 13.09C0.458672 14.0053 0.458672 15.4893 1.37398 16.4046L4.68852 19.7191C5.60367 20.6344 7.08773 20.6346 8.00305 19.7191C8.91836 18.8038 8.91836 17.3199 8.00305 16.4046Z"
      fill="currentColor"
    />
    <path
      d="M8.00305 41.215C7.08773 40.2997 5.60367 40.2997 4.68852 41.215L1.37398 44.5296C0.458672 45.4449 0.458672 46.9288 1.37398 47.8441C2.28914 48.7594 3.7732 48.7596 4.68852 47.8441L8.00305 44.5296C8.91836 43.6143 8.91836 42.1304 8.00305 41.215Z"
      fill="currentColor"
    />
    <path
      d="M77.6562 28.1235H72.9688C71.6744 28.1235 70.625 29.1729 70.625 30.4673C70.625 31.7617 71.6744 32.811 72.9688 32.811H77.6562C78.9506 32.811 80 31.7617 80 30.4673C80 29.1729 78.9506 28.1235 77.6562 28.1235Z"
      fill="currentColor"
    />
    <path
      d="M78.6281 13.09C77.7129 12.1747 76.2289 12.1747 75.3135 13.09L71.999 16.4046C71.0837 17.3199 71.0837 18.8038 71.999 19.7191C72.9141 20.6342 74.3982 20.6344 75.3135 19.7191L78.6281 16.4046C79.5434 15.4892 79.5434 14.0053 78.6281 13.09Z"
      fill="currentColor"
    />
    <path
      d="M78.6281 44.5296L75.3135 41.215C74.3984 40.2997 72.9143 40.2997 71.999 41.215C71.0837 42.1304 71.0837 43.6143 71.999 44.5296L75.3135 47.8441C76.2287 48.7593 77.7128 48.7594 78.6281 47.8441C79.5434 46.9288 79.5434 45.4449 78.6281 44.5296Z"
      fill="currentColor"
    />
  </svg>
);

export default IdeaSvg;
