import React from 'react';

export const GoBackArrowSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.5603 10.5598C22.5602 10.5598 22.5601 10.5598 22.5601 10.5598H6.19468C5.89123 10.5555 5.64862 10.306 5.6529 10.0026C5.65491 9.86143 5.71112 9.7264 5.80998 9.62558L10.8218 4.62471C11.3834 4.05903 11.3834 3.1461 10.8218 2.58041C10.2513 2.03049 9.348 2.03049 8.77752 2.58041L0.369499 10.9774C0.303142 11.0441 0.244204 11.1178 0.193663 11.1972C0.145389 11.2754 0.101339 11.3561 0.0617721 11.439L0.0068006 11.6039C0.00473981 11.6442 0.00473981 11.6845 0.0068006 11.7248C-0.00190623 11.8162 -0.00190623 11.9082 0.0068006 11.9996C-0.00226687 12.0946 -0.00226687 12.1903 0.0068006 12.2853V12.4063V12.5381C0.0301906 12.5996 0.0596598 12.6584 0.0947448 12.714L0.149716 12.8239C0.198969 12.8909 0.254147 12.9535 0.314579 13.0107L8.7226 21.4188C9.29308 21.9687 10.1964 21.9687 10.7669 21.4188C11.3273 20.8568 11.3273 19.9474 10.7669 19.3854L5.80998 14.3736C5.59752 14.1569 5.60092 13.8089 5.81766 13.5965C5.91848 13.4976 6.05346 13.4413 6.19468 13.4394H22.5601C23.3553 13.4394 23.9999 12.7949 24 11.9997C24.0001 11.2045 23.3554 10.5598 22.5603 10.5598Z"
      fill="currentColor"
    />
  </svg>
);

export default GoBackArrowSvg;
