import ConfigProvider from 'antd/lib/config-provider';
import nbNO from 'antd/lib/locale/nb_NO';
import { useTranslation } from 'react-i18next';

export const TranslationProvider = ({ children }: { children: React.ReactChild }) => {
  const { i18n } = useTranslation();
  return (
    <ConfigProvider locale={i18n.language === 'nb-NO' ? nbNO : undefined}>
      {children}
    </ConfigProvider>
  );
};
