import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query';

import { BackendService } from '../services/backendService';

function useInfiniteNotificationsList() {
  const cacheKey = `notifications`;
  const client = useQueryClient();

  const fetchNotifications = ({ pageParam = undefined }) =>
    BackendService.instance.notifications.list(pageParam);

  const {
    data,
    error,
    isFetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery([cacheKey], fetchNotifications, {
    getNextPageParam: lastPage => lastPage.cursor,
    staleTime: 1000 * 25,
    refetchInterval: 1000 * 30,
    refetchOnReconnect: true,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const { mutate: markAsRead } = useMutation<void, string, string[]>(
    ids => BackendService.instance.notifications.markAsRead(ids),
    {
      onSuccess: () => client.invalidateQueries(cacheKey),
    },
  );

  return {
    notifications: data,
    isFetchingMore: isFetchingNextPage,
    canFetchMore: hasNextPage,
    fetchMore: fetchNextPage,
    markAsRead,
    error,
    isLoading: isFetching,
  };
}

export default useInfiniteNotificationsList;
