import React from 'react';

export const LiveAnswerSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M54.0625 49.8438C53.1997 49.8438 52.5 50.5433 52.5 51.4062V62.1875C52.5 66.4953 48.9953 70 44.6875 70H27.8175C26.9548 70 26.2556 70.6989 26.255 71.5614L26.2528 74.668L22.5234 70.9388C22.282 70.3845 21.7295 70 21.0905 70H10.9375C6.62969 70 3.125 66.4953 3.125 62.1875V38.125C3.125 33.8172 6.62969 30.3125 10.9375 30.3125H26.7188C27.5816 30.3125 28.2812 29.613 28.2812 28.75C28.2812 27.887 27.5816 27.1875 26.7188 27.1875H10.9375C4.90656 27.1875 0 32.0941 0 38.125V62.1875C0 68.2184 4.90656 73.125 10.9375 73.125H20.2903L26.7077 79.5423C27.0066 79.8413 27.4061 80 27.8128 80C28.0139 80 28.2169 79.9611 28.4098 79.8812C28.9937 79.6397 29.3745 79.0703 29.375 78.4386L29.3787 73.125H44.6875C50.7184 73.125 55.625 68.2184 55.625 62.1875V51.4062C55.625 50.5433 54.9253 49.8438 54.0625 49.8438Z"
      fill="currentColor"
    />
    <path
      d="M57.2898 0H53.3353C40.8128 0 30.625 10.1878 30.625 22.7103C30.625 35.2328 40.8128 45.4205 53.3352 45.4205H57.2897C59.2442 45.4205 61.1788 45.1722 63.0537 44.6816L68.8955 50.52C69.1944 50.8186 69.5938 50.9773 70.0003 50.9773C70.2017 50.9773 70.4048 50.9384 70.5981 50.8583C71.1819 50.6164 71.5625 50.0467 71.5625 49.4148V40.3748C74.0358 38.3719 76.0903 35.8417 77.5322 33.0152C79.1698 29.8048 80 26.3378 80 22.7103C80 10.1878 69.8122 0 57.2898 0ZM69.0598 38.3648C68.6681 38.66 68.4375 39.1222 68.4375 39.613V45.6442L64.6139 41.8227C64.3164 41.5255 63.9173 41.3653 63.5092 41.3653C63.3561 41.3653 63.2014 41.388 63.0505 41.4344C61.1906 42.0059 59.2525 42.2956 57.2898 42.2956H53.3353C42.5358 42.2956 33.75 33.5097 33.75 22.7103C33.75 11.9109 42.5359 3.125 53.3353 3.125H57.2898C68.0892 3.125 76.875 11.9109 76.875 22.7103C76.875 28.9183 74.0266 34.6242 69.0598 38.3648Z"
      fill="currentColor"
    />
    <path
      d="M62.3548 17.0967C62.1294 13.8256 59.4925 11.1887 56.2213 10.9633C54.3669 10.8359 52.5972 11.4614 51.2427 12.7259C49.9067 13.9729 49.1406 15.7358 49.1406 17.5625C49.1406 18.4254 49.8403 19.125 50.7031 19.125C51.5659 19.125 52.2656 18.4254 52.2656 17.5625C52.2656 16.5845 52.6597 15.6781 53.375 15.0104C54.0897 14.3434 55.0237 14.0133 56.0066 14.0811C57.7297 14.1998 59.1186 15.5887 59.2373 17.3117C59.3573 19.0519 58.2156 20.5897 56.5228 20.9684C55.1512 21.2753 54.1934 22.4672 54.1934 23.8667V27.6206C54.1934 28.4836 54.8931 29.1831 55.7559 29.1831C56.6189 29.1831 57.3184 28.4836 57.3183 27.6206V23.9915C60.4667 23.2309 62.5791 20.35 62.3548 17.0967Z"
      fill="currentColor"
    />
    <path
      d="M56.8547 32.6966C56.5641 32.406 56.1609 32.2388 55.75 32.2388C55.3391 32.2388 54.9359 32.406 54.6453 32.6966C54.3547 32.9872 54.1875 33.3903 54.1875 33.8013C54.1875 34.2138 54.3548 34.6169 54.6453 34.9075C54.9359 35.1981 55.3391 35.3638 55.75 35.3638C56.1609 35.3638 56.5641 35.1981 56.8547 34.9075C57.1453 34.6153 57.3125 34.2138 57.3125 33.8013C57.3125 33.3903 57.1452 32.9872 56.8547 32.6966Z"
      fill="currentColor"
    />
    <path
      d="M42.9688 48.4375H10C9.13719 48.4375 8.4375 49.137 8.4375 50C8.4375 50.863 9.13719 51.5625 10 51.5625H42.9688C43.8317 51.5625 44.5312 50.863 44.5312 50C44.5312 49.137 43.8316 48.4375 42.9688 48.4375Z"
      fill="currentColor"
    />
    <path
      d="M44.0733 57.6453C43.7828 57.3547 43.3797 57.1875 42.9688 57.1875C42.5578 57.1875 42.1547 57.3547 41.8641 57.6453C41.5733 57.9359 41.4062 58.3391 41.4062 58.75C41.4062 59.1609 41.5734 59.5641 41.8641 59.8547C42.1547 60.1453 42.5578 60.3125 42.9688 60.3125C43.3797 60.3125 43.7828 60.1453 44.0733 59.8547C44.3641 59.5641 44.5312 59.1625 44.5312 58.75C44.5312 58.3391 44.3639 57.9359 44.0733 57.6453Z"
      fill="currentColor"
    />
    <path
      d="M36.823 57.1875H10C9.13719 57.1875 8.4375 57.887 8.4375 58.75C8.4375 59.613 9.13719 60.3125 10 60.3125H36.823C37.6859 60.3125 38.3855 59.613 38.3855 58.75C38.3855 57.887 37.6858 57.1875 36.823 57.1875Z"
      fill="currentColor"
    />
    <path
      d="M32.8125 39.6875H10C9.13719 39.6875 8.4375 40.387 8.4375 41.25C8.4375 42.113 9.13719 42.8125 10 42.8125H32.8125C33.6755 42.8125 34.375 42.113 34.375 41.25C34.375 40.387 33.6753 39.6875 32.8125 39.6875Z"
      fill="currentColor"
    />
  </svg>
);

export default LiveAnswerSvg;
