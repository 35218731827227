import React from 'react';

export const YoutubeSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="31"
    height="30"
    viewBox="0 0 31 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.637 6.41992H7.69699C5.17699 6.41992 3.16699 8.45992 3.16699 10.9499V19.0199C3.16699 21.5399 5.20699 23.5499 7.69699 23.5499H22.637C25.157 23.5499 27.167 21.5099 27.167 19.0199V10.9499C27.167 8.45992 25.127 6.41992 22.637 6.41992ZM12.017 18.6899V11.3099L18.317 14.9999L12.017 18.6899Z"
      fill="currentColor"
    />
  </svg>
);

export default YoutubeSvg;
