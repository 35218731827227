import React from 'react';

export const PassSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.4291 1.98152C18.795 1.34733 17.935 0.991121 17.0382 0.991225C16.1409 0.988638 15.2799 1.34547 14.6476 1.98209L3.01515 13.6135C2.91864 13.7107 2.84584 13.8289 2.80232 13.9588L1.0361 19.2575C0.881955 19.7202 1.13214 20.2203 1.5949 20.3744C1.68468 20.4043 1.7787 20.4196 1.87329 20.4197C1.96809 20.4195 2.06231 20.4043 2.15235 20.3747L7.45105 18.6084C7.58119 18.565 7.69943 18.4918 7.79635 18.3947L19.4288 6.76238C20.749 5.44227 20.7492 3.30179 19.4291 1.98152ZM18.18 5.5145L12.5 11.5L6.6995 16.995L3.26948 18.1404L4.41134 14.7147L15.8963 3.23431C16.5277 2.60417 17.5504 2.6052 18.1806 3.23659C18.4816 3.53821 18.6512 3.94653 18.6525 4.37265C18.6536 4.80105 18.4835 5.21211 18.18 5.5145Z"
      fill="currentColor"
    />
    <rect
      x="2.2684"
      y="1.20776"
      width="25.3837"
      height="1.5"
      rx="0.75"
      transform="rotate(45 2.2684 1.20776)"
      fill="currentColor"
    />
  </svg>
);

export default PassSvg;
