import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

import MainLoader from '../components/Loader';

const OauthCallback = () => {
  const [shouldRedirect, setShouldRedirect] = useState(false);

  useEffect(() => {
    const ref = setTimeout(() => {
      setShouldRedirect(true);
    }, 5000);

    return () => {
      clearTimeout(ref);
      setShouldRedirect(false);
    };
  }, []);
  if (shouldRedirect) return <Redirect to="/" />;
  return <MainLoader />;
};

export default OauthCallback;
