export const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
export const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
export const isIos = !!navigator.userAgent.match(/iPad|iPhone|iPod/i);

export const unSupportedUserAgents = ['FBAN', 'FBAV', 'musical_ly'];

// example android tiktok: Mozilla/5.0 (Linux; Android 10; CLT-L29 Build/HUAWEICLT-L29; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/83.0.4103.106 Mobile Safari/537.36 trill_2022305030 JsSdk/1.0 NetType/MOBILE Channel/googleplay AppName/musical_ly app_version/23.5
// example ios tiktok: Mozilla/5.0 (iPhone; CPU iPhone OS 15_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148 musical_ly_24.1.0 JsSdk/2.0 NetType/WIFI Channel/App Store ByteLocale/en Region/NO ByteFullLocale/en isDarkMode/0 WKWebView/1 BytedanceWebview/
export const isUnSupportedBrowser = () =>
  unSupportedUserAgents.some(agent => navigator.userAgent.includes(agent));

export const ControlKey = isMac ? 'Meta' : 'Control';
export const ControlKeyName = isMac ? 'metaKey' : 'ctrlKey';
export const isSafariOrIos = isSafari || isIos;

export const getOrigin = () => {
  try {
    return window.location.origin;
  } catch (e) {
    // this is now in the service worker
    // eslint-disable-next-line no-console
    console.log('Using origin for service worker');
    // eslint-disable-next-line no-restricted-globals
    return self.origin;
  }
};

export const getDestinationTenantFromUrl = () => {
  const destinationTenantId = window.location.pathname.split('/')[1];
  if (!destinationTenantId) {
    throw new Error(
      'getDestinationTenantFromUrl Should not be called untill we have a destination tenant',
    );
  }
  if (destinationTenantId === 'join') {
    throw new Error('Should not use join as a tenant destination');
  }
  if (destinationTenantId === 'fromtemplate') {
    throw new Error('Should not use join as a tenant destination');
  }
  if (destinationTenantId === 'share') {
    throw new Error('Should not use share as a tenant destination');
  }
  return destinationTenantId;
};

export const gentlyCheckDestinationTenantUrl = () => {
  try {
    return getDestinationTenantFromUrl();
  } catch (e) {
    return undefined;
  }
};
