export const BeforeFirstRenderCheckRedirectInHash = (hash: string) => {
  const redirect = getRedirectFromHash(hash);
  if (redirect) {
    setRedirect(redirect);
  }
};

export function isEnterprise() {
  const enterpriseEnv = process.env.REACT_APP_ENTERPRISE;
  const isEnterprise = enterpriseEnv === 'true';
  return isEnterprise;
}
export function isPR() {
  const env = process.env.REACT_APP_PR;
  const isPr = env === 'true';
  return isPr;
}

export const getRedirectFromHash = (hash: string) => {
  if (!hash.includes('redirectStart')) return undefined;

  const startPlaceholder = 'redirectStart';
  const endPlaceholder = 'redirectEnd';
  const redirect = hash.substring(
    hash.lastIndexOf(startPlaceholder) + startPlaceholder.length,
    hash.indexOf(endPlaceholder),
  );
  return redirect;
};

export const generateRedirectPayload = (existingState: string, from: string) =>
  `${existingState}|redirectStart${from}redirectEnd`;

const REDIRECT_KEY = 'onRedirectFrom__snapmentor__v1';

export const setRedirect = (redirect: string) =>
  localStorage.setItem(REDIRECT_KEY, redirect);
export const checkHasRedirect = () => localStorage.getItem(REDIRECT_KEY);

export const removeRedirect = () => localStorage.removeItem(REDIRECT_KEY);

export const generateRedirect = (redirect: string) =>
  decodeURIComponent(redirect).replace(window.location.origin, '');
