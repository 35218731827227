import React from 'react';

export const RotateSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M17.955 10.8838L9.11625 2.04507C8.6275 1.55757 7.83751 1.55757 7.34876 2.04507L2.04504 7.3488C1.55754 7.83629 1.55754 8.62879 2.04504 9.11629L10.8837 17.955C11.1275 18.1988 11.4475 18.32 11.7675 18.32C12.0875 18.32 12.4075 18.1975 12.6512 17.955L17.955 12.6513C18.4425 12.1638 18.4425 11.3713 17.955 10.8838ZM2.92878 8.23254L8.23251 2.92881L9.11625 3.81256L3.81253 9.11629L2.92878 8.23254ZM10 15.3038L4.69627 10L10 4.69631L15.3037 10L10 15.3038ZM11.7675 17.0713L10.8837 16.1875L16.1875 10.8838L17.0712 11.7675L11.7675 17.0713Z"
        fill="currentColor"
      />
      <path
        d="M6.23754 19.064C6.23504 19.049 6.24004 19.034 6.23754 19.0177L5.61254 16.1177C5.53879 15.7802 5.20379 15.5652 4.86879 15.639C4.53129 15.7115 4.31754 16.044 4.38879 16.3815L4.71379 17.8902C2.55379 16.3927 1.25004 13.9565 1.25004 11.2502C1.25004 10.9052 0.970038 10.6252 0.625038 10.6252C0.280038 10.6252 3.8147e-05 10.9052 3.8147e-05 11.2502C3.8147e-05 14.2202 1.36754 16.8977 3.64254 18.6427L1.83754 18.7515C1.49379 18.7727 1.23129 19.0677 1.25129 19.4127C1.27129 19.744 1.54629 20.0002 1.87504 20.0002C1.88754 20.0002 1.90004 20.0002 1.91254 19.999L5.66254 19.774C5.66879 19.774 5.67379 19.7702 5.68129 19.769C5.70629 19.7665 5.73254 19.7665 5.75754 19.7615C5.78379 19.7552 5.80379 19.739 5.82879 19.7302C5.86504 19.7177 5.90129 19.7065 5.93379 19.6877C5.94504 19.6815 5.95504 19.6777 5.96629 19.6702C5.99254 19.6527 6.01254 19.6302 6.03629 19.609C6.06629 19.5815 6.09379 19.5552 6.11879 19.5227C6.13879 19.4965 6.15629 19.469 6.17129 19.439C6.17629 19.429 6.18754 19.4265 6.19129 19.4165C6.19629 19.4065 6.19504 19.3965 6.19879 19.3865C6.20879 19.3627 6.21504 19.3377 6.22254 19.3127C6.23129 19.2815 6.23629 19.2515 6.24004 19.2202C6.24129 19.194 6.24504 19.169 6.24504 19.1427C6.24504 19.1327 6.25004 19.124 6.24879 19.1127C6.24754 19.0952 6.23879 19.0815 6.23754 19.064Z"
        fill="currentColor"
      />
      <path
        d="M16.3575 1.35758L18.1625 1.24883C18.5062 1.22758 18.7687 0.932584 18.7487 0.587584C18.7287 0.243833 18.47 -0.0211666 18.0875 0.00133343L14.3375 0.226333C14.3212 0.227583 14.3075 0.236333 14.2912 0.238833C14.275 0.241333 14.26 0.236333 14.2437 0.238833C14.23 0.241333 14.22 0.251333 14.2062 0.255083C14.1812 0.262583 14.1575 0.270083 14.1337 0.280083C14.1075 0.291333 14.0837 0.305083 14.0587 0.320083C14.035 0.333833 14.0125 0.347583 13.99 0.365083C13.9712 0.380083 13.9537 0.395084 13.9362 0.412584C13.9162 0.432584 13.9 0.453834 13.8825 0.476334C13.8637 0.500084 13.8487 0.523833 13.8337 0.550083C13.8275 0.562583 13.815 0.570083 13.81 0.582583C13.805 0.592583 13.8062 0.602584 13.8025 0.612584C13.7925 0.636334 13.7862 0.661333 13.7787 0.686333C13.77 0.717583 13.765 0.747583 13.7612 0.780083C13.7587 0.806333 13.755 0.831334 13.755 0.857584C13.755 0.867584 13.75 0.877584 13.7512 0.887584C13.7525 0.903834 13.7612 0.917584 13.7625 0.932584C13.765 0.948834 13.76 0.965083 13.7625 0.981333L14.3875 3.88133C14.4525 4.17508 14.7112 4.37508 14.9987 4.37508C15.0425 4.37508 15.0875 4.37133 15.1312 4.36133C15.4687 4.28883 15.6825 3.95633 15.6112 3.61883L15.2862 2.10883C17.4462 3.60758 18.75 6.04383 18.75 8.75008C18.75 9.09508 19.03 9.37508 19.375 9.37508C19.72 9.37508 20 9.09508 20 8.75008C20 5.78008 18.6325 3.10258 16.3575 1.35758Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default RotateSvg;
