import retry from 'async-retry';
import axios, { AxiosError } from 'axios';
import { from } from 'rxjs';

import pkg from '../../package.json';
import { GraphData } from '../components/_organisms/WordcloudAndGraph/WordcloudAndGraph';
import { toSearchParams } from '../hooks/useDiscoverSearchQueryState';
import { IndexedTenant } from '../hooks/useTenant';
import { ById, Claims } from '../modules';
import i18n from '../singletons/i18n';
import { isEnterprise } from '../utils/loginUtils';
import {
  gentlyCheckDestinationTenantUrl,
  getDestinationTenantFromUrl,
} from '../utils/navigatorUtils';
import Authentication from './authentication';
import {
  DiscoveredTemplate,
  DiscoverSearchQuery,
  DiscoverSearchResults,
  Folder,
  FolderEditOrCreateDTO,
  IndexedTemplate,
  Template,
  TemplateEditOrCreateDTO,
} from './backendService.type';
import { ChatRoomEntity, Message, UserType } from './db';
import Files from './files';
import { Metrics } from './metrics';
export interface ClaimDiscussionResponse {
  chatRoomId: string;
}

export interface NamesByIdsResponse {
  [id: string]: {
    id: string;
    name?: string;
  };
}
export interface ClaimAbandonedSessionResponse {
  chatRoomId: string;
}

const getAuthHeader = (token: string, destinationTenantId?: string) => {
  const base: ById<string> = {
    Authorization: `Bearer ${token}`,
    'x-app-version': `v${pkg.version}`,
  };
  if (isEnterprise()) {
    base['x-api-enterprise'] = 'true';
  }
  if (destinationTenantId) {
    base['x-api-tenant'] = destinationTenantId;
  }
  return base;
};

export class BackendService {
  static instance: BackendService;
  url = '';
  auth: Authentication | null = null;
  cdnUrl = '';
  constructor() {
    if (BackendService.instance) {
      return BackendService.instance;
    }
    BackendService.instance = this;
    return this;
  }

  static init(url: string, auth: Authentication, cdnURL: string) {
    BackendService.instance.url = url;
    BackendService.instance.auth = auth;
    BackendService.instance.cdnUrl = cdnURL;

    if (process.env.NODE_ENV !== 'production' && process.env.REACT_APP_LOCAL === 'true') {
      BackendService.instance.url = 'http://localhost:3010';
    }
  }

  private async getToken(forceRefresh = false) {
    const token = await this.auth?.getIdToken(forceRefresh);
    if (!token) throw Error('Error refreshing token');
    return token;
  }

  private async retryWithFreshTokenIf401<T>(cb: (token: string) => Promise<T>) {
    try {
      const token = await this.getToken();
      return await cb(token);
    } catch (error) {
      if ((error as AxiosError).response?.status === 401) {
        // In case of 401, the token might be invalidated by the server, so we need a fresh one and retry
        // This might attempt a redirect
        const token = await this.getToken(true);
        return await cb(token);
      }
      if ((error as AxiosError).response?.status === 500) {
      }
      throw error;
    }
  }

  public async joinTenant(invitationCode: string) {
    return this.retryWithFreshTokenIf401(async token => {
      const res = await axios.post<{
        roles: MSClaims;
        tenantId: string;
      }>(
        `${this.url}/v0.5/auth/selfServeJoin`,
        { invitationCode },
        {
          headers: getAuthHeader(token),
        },
      );
      return res.data;
    });
  }

  public async copyTemplate(templateId: string, tenantId: string) {
    const tenants = await this.retrieveCurrentTenants(false);
    const destinationTenantId = tenants.user.activeTenantId;
    return this.retryWithFreshTokenIf401(async token => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const res = await axios.post<{ template: Template; errors: any[] }>(
        `${this.url}/v0.5/templates/copyTemplate`,
        { templateId, tenantId },
        {
          headers: getAuthHeader(token, destinationTenantId),
        },
      );
      return { ...res.data, tenantId: destinationTenantId };
    });
  }

  public async copyAsTemplate(gameId: string, tenantId: string) {
    const tenants = await this.retrieveCurrentTenants(false);
    const destinationTenantId = tenants.user.activeTenantId;
    return this.retryWithFreshTokenIf401(async token => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const res = await axios.post<{ template: Template; errors: any[] }>(
        `${this.url}/v0.5/games/copyGameAsTemplate`,
        { gameId, tenantId },
        {
          headers: getAuthHeader(token, destinationTenantId),
        },
      );
      return { ...res.data, tenantId: destinationTenantId };
    });
  }

  public async editTenantName(name: string) {
    return this.retryWithFreshTokenIf401(async token => {
      const res = await axios.put<{ tenantId: string; tenantName: string }>(
        `${this.url}/v0.5/tenants/${gentlyCheckDestinationTenantUrl()}/editName`,
        {
          name,
        },
        {
          headers: getAuthHeader(token, gentlyCheckDestinationTenantUrl()),
        },
      );
      return res.data;
    });
  }
  public async retrieveCurrentTenants(hasDestination = true) {
    return this.retryWithFreshTokenIf401(async token => {
      const res = await axios.get<{
        user: UserTenant;
        firebaseToken: string;
        roles: MSClaims;
      }>(`${this.url}/v0.5/tenants/`, {
        // Pass on the x-api-header to the api if we think we know the destination tenant
        headers: getAuthHeader(
          token,
          hasDestination ? gentlyCheckDestinationTenantUrl() : undefined,
        ),
      });
      return res.data;
    });
  }

  public async switchTenant(tenantId: string) {
    return this.retryWithFreshTokenIf401(async token => {
      const res = await axios.post<UserTenant>(
        `${this.url}/v0.5/tenants/switch`,
        { tenantId },
        {
          headers: getAuthHeader(token, getDestinationTenantFromUrl()),
        },
      );
      return res.data;
    });
  }

  invitations = {
    listPending: async (topicId: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<Invitation[]>(
          `${this.url}/v0.5/invitations/pending/${topicId}`,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    revoke: async (code: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<void>(
          `${this.url}/v0.5/invitations/revoke`,
          { code },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    bulkInvite: async (invitations: EmailInvitationCreateDTO[]) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<Invitation[]>(
          `${this.url}/v0.5/invitations/invite`,
          { invitations },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    generate: async (
      role: InvitationRole,
      topic?: TopicInvitation,
      duration?: number,
    ) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<Invitation>(
          `${this.url}/v0.5/invitations/generate`,
          {
            role,
            topic,
            // Null should be a never expiring invitationLink
            expireHours: duration === -1 ? null : duration,
          },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
  };
  profile = {
    getProfileImageUrl: async () => {
      return this.retryWithFreshTokenIf401(async token => {
        return await axios.get<string>(
          `${this.url}/v0.5/files/profile/getProfileImageUrl`,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
      });
    },
    getUploadUrl: async (imageId: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        return await axios.post<string>(
          `${this.url}/v0.5/files/profile/uploadUrl`,
          { imageId: imageId },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
      });
    },
    updateProfileImageId: async (profileImageId: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        return await axios.post<string>(
          `${this.url}/v0.5/tenants/updateProfileImageId`,
          { profileImageId: profileImageId },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
      });
    },
  };

  authToken = {
    generate: async () => {
      const res = await axios.post<{ token: string }>(
        `${
          process.env.NODE_ENV !== 'production'
            ? 'https://api-test.snapmentor.no'
            : this.url
        }/v0/auth/tokens/generate`,
        { origin: window.location.origin, version: pkg.version },
      );
      return res.data;
    },
    validate: async (token: string) => {
      const res = await axios.get<{ isValid: boolean; redirectUri: string }>(
        `${this.url}/v0/auth/tokens/validate?token=${token}`,
      );
      return res.data;
    },
  };

  discover = {
    admin: {
      changeTags: async ({
        adminTags,
        adminLanguage,
        templateId,
      }: {
        templateId: string;
        adminTags: string[];
        adminLanguage?: string;
      }) => {
        return this.retryWithFreshTokenIf401(async token => {
          const res = await axios.post<DiscoveredTemplate>(
            `${this.url}/v0.5/discover/admin/edit`,
            {
              templateId,
              adminTags,
              adminLanguage,
            },
            {
              headers: getAuthHeader(token, getDestinationTenantFromUrl()),
            },
          );
          return res.data;
        });
      },
      retrieve: async (templateId: string) => {
        return this.retryWithFreshTokenIf401(async token => {
          const res = await axios.get<DiscoveredTemplate>(
            `${this.url}/v0.5/discover/admin/${templateId}`,
            {
              headers: getAuthHeader(token, getDestinationTenantFromUrl()),
            },
          );
          return res.data;
        });
      },
      list: async () => {
        return this.retryWithFreshTokenIf401(async token => {
          const res = await axios.get<DiscoveredTemplate[]>(
            `${this.url}/v0.5/discover/admin/list`,
            {
              headers: getAuthHeader(token, getDestinationTenantFromUrl()),
            },
          );
          return res.data;
        });
      },
      approve: async (body: { templateId: string; tenantId: string }) => {
        return this.retryWithFreshTokenIf401(async token => {
          const res = await axios.post(`${this.url}/v0.5/discover/admin/approve`, body, {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          });
          return res.data;
        });
      },
      reject: async (body: { templateId: string }) => {
        return this.retryWithFreshTokenIf401(async token => {
          const res = await axios.put(`${this.url}/v0.5/discover/admin/reject`, body, {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          });
          return res.data;
        });
      },
    },
    query: async (query: DiscoverSearchQuery) => {
      return this.retryWithFreshTokenIf401(async token => {
        const queryString = query ? `?${toSearchParams(query).toString()}` : '';

        const res = await axios.get<DiscoverSearchResults>(
          `${this.url}/v0.5/discover/${queryString}`,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    getTemplateThumbnail: async (_: string, fileId: string) => {
      const res = await axios.request<Blob>({
        method: 'get',
        url: `${this.cdnUrl}/${fileId}.jpeg`,
        responseType: 'blob',
      });
      const objectUrl = URL.createObjectURL(res.data);
      return objectUrl;
    },
    getCreatorThumbnail: async (fileId: string) => {
      const res = await axios.request<Blob>({
        method: 'get',
        url: `${this.cdnUrl}/${fileId}.jpeg`,
        responseType: 'blob',
      });
      const objectUrl = URL.createObjectURL(res.data);
      return objectUrl;
    },
    getTemplateCreatorThumbnail: async (templateId: string, uid: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.request<Blob>({
          method: 'get',
          url: `${this.url}/v0.5/discover/${templateId}/creatorThumbnail?uid=${uid}`,
          responseType: 'blob',
          headers: getAuthHeader(token, getDestinationTenantFromUrl()),
        });
        const objectUrl = URL.createObjectURL(res.data);
        return objectUrl;
      });
    },
    publishTemplate: async (templateId: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post(
          `${this.url}/v0.5/discover/discovertemplate`,
          { templateId },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    getTemplates: async () => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get(`${this.url}/v0.5/discover/`, {
          headers: getAuthHeader(token, getDestinationTenantFromUrl()),
        });
        return res.data;
      });
    },
    getIndexedTemplate: async (templateId: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<IndexedTemplate>(
          `${this.url}/v0.5/discover/${templateId}`,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    getFrontPageTemplates: async () => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<{
          data: ById<IndexedTemplate[]>;
          orderedGroups: { id: string; name: string }[];
        }>(`${this.url}/v0.5/discover/explore?lang=${i18n.language}`, {
          headers: getAuthHeader(token, getDestinationTenantFromUrl()),
        });
        return res.data;
      });
    },
    addToMyLessons: async (templateId: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<Template>(
          `${this.url}/v0.5/discover/addToMyLessons`,
          { templateId },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    updateCreatorProfileImage: async (profileImageId: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        await axios.post(
          `${this.url}/v0.5/creator/updateThumbnail`,
          { profileImageId: profileImageId },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
      });
    },
    retrieveOnboardingTemplates: async (language: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<DiscoveredTemplate[]>(
          `${this.url}/v0.5/discover/onboardingTemplates?language=${language}`,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
  };

  users = {
    classes: async () => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<{
          byId: ById<{
            topic: TopicDTO;
            role: TopicRole;
          }>;
          list: string[];
        }>(`${this.url}/v0.5/users/me/classes`, {
          headers: getAuthHeader(token, getDestinationTenantFromUrl()),
        });
        return res.data;
      });
    },
    me: async () => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<RoleUser>(`${this.url}/old/users/me`, {
          headers: getAuthHeader(token, getDestinationTenantFromUrl()),
        });
        return res.data;
      });
    },
    listMyFollows: async () => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<{
          byId: ById<MyFollowing>;
        }>(`${this.url}/v0.5/users/me/follows`, {
          headers: getAuthHeader(token, getDestinationTenantFromUrl()),
        });
        return res.data.byId;
      });
    },
    getDidYouKnows: async () => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<DidYouKnow>(`${this.url}/old/users/me/didyouknow`, {
          headers: getAuthHeader(token, getDestinationTenantFromUrl()),
        });
        return res.data;
      });
    },
    markDidYouKnowAsSeen: async (key: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<void>(
          `${this.url}/old/users/me/markDidYouKnowAsSeen`,
          {
            key,
          },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    getKudos: async () => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<number>(`${this.url}/old/users/me/kudos`, {
          headers: getAuthHeader(token, getDestinationTenantFromUrl()),
        });
        return res.data;
      });
    },
    onboardingStatus: async () => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<OnboardingStatus>(
          `${this.url}/old/users/me/onboardingstatus`,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    retrieveStatistics: async () => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<ImpactStatistics>(
          `${this.url}/old/users/me/statistics`,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    deleteMe: async () => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<{ success: boolean }>(
          `${this.url}/v0/users/me/delete`,
          {},
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    listUsersById: async () => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<{ users: RoleUser[]; byId: ById<RoleUser> }>(
          `${this.url}/v0.5/users`,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
  };

  public async getTagsByTopicId(topicId: string) {
    return this.retryWithFreshTokenIf401(async token => {
      const res = await axios.get<{ tagsById: ById<Tag>; list: Tag[] }>(
        `${this.url}/v0.5/tags?topicId=${topicId}`,
        {
          headers: getAuthHeader(token, getDestinationTenantFromUrl()),
        },
      );
      return res.data;
    });
  }

  public async deleteTag(tagId: string) {
    return this.retryWithFreshTokenIf401(async token => {
      const res = await axios.delete<{ tagId: string }>(
        `${this.url}/v0.5/tags/${tagId}`,
        {
          headers: getAuthHeader(token, getDestinationTenantFromUrl()),
        },
      );
      return res.data;
    });
  }

  public async createNewTag(name: string, topicId: string) {
    return this.retryWithFreshTokenIf401(async token => {
      const res = await axios.post<Tag>(
        `${this.url}/v0.5/tags/`,
        { name, topicId },
        {
          headers: getAuthHeader(token, getDestinationTenantFromUrl()),
        },
      );
      return res.data;
    });
  }

  public async search(
    topicId: string,
    query?: string,
    filter?: SearchFilter,
    cursor?: number,
    limit?: number,
  ) {
    return this.retryWithFreshTokenIf401(async token => {
      const res = await axios.post<SearchResponse>(
        `${this.url}/v0.5/topics/${topicId}/search`,
        {
          query,
          filter,
          limit,
          cursor,
        },
        {
          headers: getAuthHeader(token, getDestinationTenantFromUrl()),
        },
      );
      Metrics.getLogger().logEvent('api_search', {
        query,
        results: res.data.hits.hits.length,
      });
      return res.data; //.hits.hits.map(hit => hit._source);
    });
  }

  public async getTags(topicId: string) {
    return this.retryWithFreshTokenIf401(async token => {
      const res = await axios.get<TagsResponse>(
        `${this.url}/v0.5/topics/${topicId}/search/aggregations`,
        {
          headers: getAuthHeader(token, getDestinationTenantFromUrl()),
        },
      );
      return res.data;
    });
  }

  hostActivation = {
    get: async () => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<HostActivationResponse>(
          `${this.url}/v0.5/users/me/activationEvents`,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    logEvent: async (eventName: HostActivationEventName) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<HostActivationResponse>(
          `${this.url}/v0.5/users/me/activationEvents/`,
          {
            name: eventName,
          },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
  };

  public updateTopics = async (topics: TopicSaveDTO[]) => {
    return this.retryWithFreshTokenIf401(async token => {
      const res = await axios.put<TopicSaveResponseDTO>(
        `${this.url}/v0.5/topics/bulk`,
        { topics },
        {
          headers: getAuthHeader(token, getDestinationTenantFromUrl()),
        },
      );
      return res.data;
    });
  };

  public sendHeartbeat = async () => {
    return this.retryWithFreshTokenIf401(async token => {
      const res = await axios.get<{ success: boolean }>(`${this.url}/v0/heartbeat`, {
        headers: getAuthHeader(token, getDestinationTenantFromUrl()),
      });
      return res.data;
    });
  };

  public countOnlineUsers = async () => {
    return this.retryWithFreshTokenIf401(async token => {
      const res = await axios.get<{ onlineCount: number }>(
        `${this.url}/v0/heartbeat/online`,
        {
          headers: getAuthHeader(token, getDestinationTenantFromUrl()),
        },
      );
      return res.data;
    });
  };

  public deleteUser = (uid: string) =>
    from(
      this.retryWithFreshTokenIf401(async token => {
        const res = await axios.delete(`${this.url}/old/users/${uid}`, {
          headers: getAuthHeader(token, getDestinationTenantFromUrl()),
        });
        return res.data;
      }),
    );

  public setClaims = (uid: string, claims: MSClaims) =>
    from(
      this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<RoleUser>(
          `${this.url}/old/setClaims`,
          {
            uid,
            claims,
          },
          { headers: getAuthHeader(token, getDestinationTenantFromUrl()) },
        );
        return res.data;
      }),
    );

  tenant = {
    get: async () => {
      return this.retryWithFreshTokenIf401(async token => {
        const tenantId = getDestinationTenantFromUrl();
        const res = await axios.get<IndexedTenant>(
          `${this.url}/v0.5/tenants/${tenantId}`,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
  };

  gameFiles = {
    importPresentation: async (
      templateId: string,
      fileId: string,
      fileExtension: string,
    ) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post(
          `${this.url}/v0.5/templates/${templateId}/importPresentation`,
          {
            presentationFilePath: fileId,
            presentationFileExtension: fileExtension,
          },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    getCroppedImage: async (
      fileId: string,
      cropConfig?: CropConfig | null,
      isPublicTemplate?: boolean,
    ) => {
      return this.retryWithFreshTokenIf401(async token => {
        const query = cropConfig
          ? `?h=${cropConfig.height}&w=${cropConfig.width}&x=${cropConfig.x}&y=${cropConfig.y}`
          : undefined;
        const url = isPublicTemplate
          ? `${this.url}/v0.5/discover/files/${fileId}${query ? query : ''}`
          : `${this.url}/v0.5/game_images/files/${fileId}${query ? query : ''}`;
        const res = await axios.request<Blob>({
          method: 'get',
          url,
          responseType: 'blob',
          headers: getAuthHeader(token, getDestinationTenantFromUrl()),
        });
        const objectUrl = URL.createObjectURL(res.data);
        return objectUrl;
      });
    },
  };
  blueprint = {
    duplicateTemplate: async (templateId: string, parentId: string | null) => {
      const tenantId = getDestinationTenantFromUrl();
      return this.retryWithFreshTokenIf401(async token => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const res = await axios.post<{ template: Template; errors: any[] }>(
          `${this.url}/v0.5/templates/copyTemplate`,
          { templateId, tenantId, parentId },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    retrieveTemplate: (templateId: string): Promise<Template | undefined> => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<Template | undefined>(
          `${this.url}/v0.5/templates/${templateId}`,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    listTemplates: (parentId?: string | null): Promise<Template[]> => {
      let queryString = '';
      if (parentId) {
        queryString += '?parentId=' + parentId;
      }
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<Template[]>(
          `${this.url}/v0.5/templates${queryString}`,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    listFolders: () => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<Folder[]>(`${this.url}/v0.5/folders`, {
          headers: getAuthHeader(token, getDestinationTenantFromUrl()),
        });
        return res.data;
      });
    },
    newFolder: (data: FolderEditOrCreateDTO) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<Folder>(`${this.url}/v0.5/folders`, data, {
          headers: getAuthHeader(token, getDestinationTenantFromUrl()),
        });
        return res.data;
      });
    },
    saveFolder: (changes: FolderEditOrCreateDTO) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.put<Folder>(
          `${this.url}/v0.5/folders/${changes.id}`,
          { changes: changes },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    newTemplate: async (data?: TemplateEditOrCreateDTO | null) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<Template>(
          `${this.url}/v0.5/templates`,
          {
            ...data,
          },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    templateAutoSave: async (data?: TemplateEditOrCreateDTO | null) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.put<Template>(
          `${this.url}/v0.5/templates/${data?.id}/autoSave`,
          {
            ...data,
          },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },

    deleteTemplate: (templateId: string): Promise<string> => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.delete(`${this.url}/v0.5/templates/${templateId}`, {
          headers: getAuthHeader(token, getDestinationTenantFromUrl()),
        });
        return res.data;
      });
    },
    deleteFolder: (folderId: string): Promise<string[]> => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.delete(`${this.url}/v0.5/folders/${folderId}`, {
          headers: getAuthHeader(token, getDestinationTenantFromUrl()),
        });
        return res.data;
      });
    },
    createGameFromTemplate: (templateId: string, isFromDiscover?: boolean) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post(
          `${this.url}/v0.5/templates/createGame/${templateId}`,
          {
            gameLanguage: i18n.language,
            isFromDiscover: isFromDiscover,
          },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
  };
  game = {
    kickParticipant: async ({
      gameId,
      participantId,
    }: {
      gameId: string;
      participantId: string;
    }) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post(
          `${this.url}/v0.5/games/${gameId}/kickParticipant`,
          { participantId },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    retrieveMindmapResults: async ({
      gameId,
      roundId,
    }: {
      gameId: string;
      roundId: string;
    }) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<GraphData>(
          `${this.url}/v0.5/games/${gameId}/rounds/${roundId}/mindmapresults`,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    retrievePoll: async ({ gameId, roundId }: { gameId: string; roundId: string }) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<RoundOptions[]>(
          `${this.url}/v0.5/games/${gameId}/rounds/${roundId}/poll`,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    retrieveWordcloudResults: async ({
      gameId,
      roundId,
    }: {
      gameId: string;
      roundId: string;
    }) => {
      /**@deprecated */
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<Score[]>(
          `${this.url}/v0.5/games/${gameId}/rounds/${roundId}/wordcloudresults`,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );

        return res.data;
      });
    },
    retrieveWordcloud: async ({
      gameId,
      roundId,
    }: {
      gameId: string;
      roundId: string;
    }) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<Score[]>(
          `${this.url}/v0.5/games/${gameId}/rounds/${roundId}/wordcloud`,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );

        return res.data;
      });
    },
    initVotingRound: async (roundId: string, gameId: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<{ path: string }>(
          `${this.url}/v0.5/games/${gameId}/rounds/${roundId}/initVoting`,
          {},
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    retrieveResults: async (gameId: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<ActivityResults>(
          `${this.url}/v0.5/games/${gameId}/results`,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    autoSave: async (data: GameEditOrCreateDTO) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<Game>(
          `${this.url}/v0.5/games/autoSave`,
          {
            ...data,
          },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    dismissQuestion: ({
      responseId,
      roundId,
      gameId,
      dismiss,
    }: {
      responseId: string;
      roundId: string;
      gameId: string;
      dismiss: boolean;
    }) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<void>(
          `${this.url}/v0.5/games/${gameId}/rounds/${roundId}/responses/${responseId}/dismiss`,
          { dismiss },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    generateNewPin: (gameId: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<Game>(
          `${this.url}/v0.5/games/${gameId}/init`,
          {},
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    exportCSV: (roundId: string, gameId: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<{ url: string }>(
          `${this.url}/v0.5/games/${gameId}/exportCSV/${roundId}`,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    duplicateGame: (gameId: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<Game>(
          `${this.url}/v0.5/games/${gameId}/duplicate`,
          {},
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    createDemoGame: () => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<Game>(
          `${this.url}/v0.5/templates/demogame`,
          {},
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    createPreviewGame: (
      templateId: string,
      isDemoGame: boolean,
      isFromDiscover: boolean,
    ) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<Game>(
          `${this.url}/v0.5/templates/${templateId}/preview${
            isDemoGame ? '?isDemoGame=true' : ''
          }`,
          { isFromDiscover: isFromDiscover },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    deleteGame: (gameId: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.delete(`${this.url}/v0.5/games/${gameId}`, {
          headers: getAuthHeader(token, getDestinationTenantFromUrl()),
        });
        return res.data;
      });
    },
    editGame: (
      {
        askTitle,
        askDuration,
        voteDuration,
        voteTitle,
        type,
        activity,
      }: {
        askTitle: string;
        voteTitle: string;
        askDuration: number;
        voteDuration: number;
        type: RoundType;
        activity: GameActivity;
      },
      gameId: string,
    ) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.put<Game>(
          `${this.url}/v0.5/games/${gameId}/edit`,
          {
            askTitle,
            voteTitle,
            askDuration,
            voteDuration,
            type,
            activity,
          },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    list: (topicId?: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<Game[]>(
          `${this.url}/v0.5/games/list${topicId ? '?topicId=' + topicId : ''}`,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    listResults: (templateId?: string | null) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<Game[]>(
          `${this.url}/v0.5/games/results${templateId ? `${templateId}` : ''}`,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    listResponsesForRound: ({ gameId, roundId }: { gameId: string; roundId: string }) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<Response[]>(
          `${this.url}/v0.5/games/${gameId}/rounds/${roundId}/listResponses`,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    retrieveLeaderBoard: (roundId: string, gameId: string, includeAll?: boolean) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<LeaderBoard>(
          `${this.url}/v0.5/games/${gameId}/leaderboard/${roundId}${
            includeAll ? '?includeAll=true' : ''
          }`,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    retrieve: (gameId?: string | undefined) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<Game>(`${this.url}/v0.5/games/${gameId}`, {
          headers: getAuthHeader(token, getDestinationTenantFromUrl()),
        });
        return res.data;
      });
    },
    createWithConfig: (data: GameCreateDTO) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<Game>(`${this.url}/v0.5/games/autoSave`, data, {
          headers: getAuthHeader(token, getDestinationTenantFromUrl()),
        });
        return res.data;
      });
    },
    end: (gameId: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<void>(
          `${this.url}/v0.5/games/${gameId}/endGame`,
          {},
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    setAllRoundsAsCompleted: (gameId: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<Game>(
          `${this.url}/v0.5/games/${gameId}/setAllRoundsAsCompleted`,
          {},
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    moveQuestionsToPod: ({
      topicId,
      ids,
      gameId,
    }: {
      topicId: string;
      ids: string[];
      gameId: string;
    }) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<{ success: string[]; failure: string[] }>(
          `${this.url}/v0.5/games/${gameId}/copyToPod`,
          {
            topicId,
            ids,
          },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    findPinByGameId: (gameId: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<GamePinRegistration>(
          `${this.url}/v0.5/games/${gameId}/pin`,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
  };

  notifications = {
    list: async (cursor?: number) => {
      return this.retryWithFreshTokenIf401(async token => {
        let url = `${this.url}/v0/notifications/list?limit=20`;
        if (cursor) url = url + `&cursor=${cursor}`;
        const res = await axios.get<{
          items: GenericNotification[];
          nextCursor?: number;
        }>(url, {
          headers: getAuthHeader(token, getDestinationTenantFromUrl()),
        });
        return {
          items: res.data.items.sort((a, b) => b.sentTime! - a.sentTime!),
          cursor: res.data.nextCursor,
        };
      });
    },
    markAsRead: async (ids: string[]) => {
      if (ids.length === 0) return;
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<void>(
          `${this.url}/v0/notifications/markAsRead`,
          { ids },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
  };

  feedback = {
    submit: async (message: string, accepted?: boolean) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post(
          `${this.url}/v0/feedback`,
          {
            message,
            accepted,
          },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
  };

  topics = {
    getAdminStatistics: async (topicId: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<{
          activeStudents: number;
          uid?: string | undefined;
          minutesSaved?: number | undefined;
        }>(`${this.url}/v0.5/topics/${topicId}/adminstatistics`, {
          headers: getAuthHeader(token, getDestinationTenantFromUrl()),
        });
        return res.data;
      });
    },
    sendPrivateThankYou: async (to: string, topicId: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post(
          `${this.url}/v0.5/topics/${topicId}/thankYou`,
          { to },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    changeRole: async (newRole: TopicRole, uid: string, topicId: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.put(
          `${this.url}/v0.5/topics/${topicId}/members/${uid}/role`,
          { newRole },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    removeMember: async (userToRemove: string, topicId: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post(
          `${this.url}/v0.5/topics/${topicId}/removeMember/${userToRemove}`,
          {},
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    listMembers: async (topicId: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<ById<{ id: string; role: TopicRole }>>(
          `${this.url}/v0.5/topics/${topicId}/members`,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    activityFeed: async (topicId: string, cursor?: number) => {
      return this.retryWithFreshTokenIf401(async token => {
        let url = `${this.url}/v0.5/topics/${topicId}/activityFeed?limit=20`;
        if (cursor) url = url + `&cursor=${cursor}`;
        const res = await axios.get<PaginatedFeedArticlesResponsePerformance>(url, {
          headers: getAuthHeader(token, getDestinationTenantFromUrl()),
        });
        return res.data;
      });
    },
    create: async (name: string, description?: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<TopicDTO>(
          `${this.url}/v0.5/topics`,
          {
            displayName: name,
            description,
          },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    list: async (all?: boolean, includeMembersCount?: boolean) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<TopicDTO[]>(
          `${this.url}/v0.5/topics?${all ? 'all=true&' : ''}${
            includeMembersCount ? 'includeMembersCount=true&' : ''
          }`,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    follow: async (topicId: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<{ topicId: string }>(
          `${this.url}/v0.5/topics/${topicId}/follow`,
          {},
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    unFollow: async (topicId: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post(
          `${this.url}/v0.5/topics/${topicId}/unfollow`,
          {},
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    retrieve: async (topicId: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<TopicDTO>(`${this.url}/v0.5/topics/${topicId}`, {
          headers: getAuthHeader(token, getDestinationTenantFromUrl()),
        });
        return res.data;
      });
    },
    save: async (data: {
      topicId: string;
      name: string;
      description?: string | null;
      isPrivate: boolean;
      disabled: boolean;
    }) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.put<TopicDTO>(
          `${this.url}/v0.5/topics/${data.topicId}`,
          {
            displayName: data.name,
            description: data.description,
            isPrivate: data.isPrivate,
            disabled: data.disabled,
          },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
  };

  reactions = {
    add: async (data: {
      type: string;
      entityId: string;
      parentId?: string;
      entityType: string;
    }) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<ReactionDTO>(`${this.url}/v0.5/reactions`, data, {
          headers: getAuthHeader(token, getDestinationTenantFromUrl()),
        });
        return res.data;
      });
    },
    update: async (type: string, reactionId: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.put<ReactionDTO>(
          `${this.url}/v0.5/reactions/${reactionId}`,
          { type },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    delete: async (reactionId: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.delete<void>(`${this.url}/v0.5/reactions/${reactionId}`, {
          headers: getAuthHeader(token, getDestinationTenantFromUrl()),
        });
        return res.data;
      });
    },
  };

  articles = {
    follow: async (id: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post(
          `${this.url}/v0/articles/${id}/follow`,
          {},
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    unFollow: async (id: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post(
          `${this.url}/v0/articles/${id}/unfollow`,
          {},
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    addComment: async (
      {
        text,
        imageData,
        disclaimer,
        isAnonymous,
      }: { text?: string; imageData?: string; disclaimer?: string; isAnonymous: boolean },
      articleId: string,
    ) => {
      return this.retryWithFreshTokenIf401(async token => {
        const imgId = `${new Date().getTime()}-1`;

        const res = await axios.post<ArticleComment>(
          `${this.url}/v0/articles/${articleId}/comments`,
          {
            text: text || undefined,
            imageId: imageData && imgId,
            disclaimer,
            isAnonymous,
          },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        if (imageData) {
          await Files.instance.uploadCommentImage(imgId, res.data.id, imageData);
        }
        return res.data;
      });
    },
    editComment: async (data: {
      text: string;
      commentId: string;
      articleId: string;
      disclaimer?: string;
      isAnonymous: boolean;
    }) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.put<ArticleComment>(
          `${this.url}/v0/articles/${data.articleId}/comments/${data.commentId}`,
          {
            text: data.text,
            isAnonymous: data.isAnonymous,
            disclaimer:
              data.disclaimer && data.disclaimer.length > 0 ? data.disclaimer : undefined,
          },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    deleteComment: async (commentId: string, articleId: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.delete(
          `${this.url}/v0/articles/${articleId}/comments/${commentId}`,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    recommended: async () => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<ArticleDTO[]>(`${this.url}/v0/articles/recommended`, {
          headers: getAuthHeader(token, getDestinationTenantFromUrl()),
        });
        return res.data;
      });
    },
    feed: async () => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<FeedArticle[]>(`${this.url}/v0/articles/feed`, {
          headers: getAuthHeader(token, getDestinationTenantFromUrl()),
        });
        return res.data;
      });
    },
    unanswered: async (topicId: string, cursor?: number) => {
      let url = `${this.url}/v0.5/topics/${topicId}/unansweredQuestions?limit=5`;
      if (cursor) url = url + `&cursor=${cursor}`;
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<PaginatedFeedArticlesResponsePerformance>(url, {
          headers: getAuthHeader(token, getDestinationTenantFromUrl()),
        });
        return res.data;
      });
    },
    passUnanswered: async (articleId: string, expiresAt: number | null) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<{ articleId: string }>(
          `${this.url}/v0/articles/${articleId}/pass`,
          { expiresAt },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    contributions: async () => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<{
          articles: ArticleDTO[];
          total: number;
          totalClaps: number;
          totalDiscussions: number;
        }>(`${this.url}/v0/articles/contributions`, {
          headers: getAuthHeader(token, getDestinationTenantFromUrl()),
        });
        return res.data;
      });
    },
    delete: async (articleId: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.delete<{ success: boolean }>(
          `${this.url}/v0/articles/${articleId}`,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    applaud: async (claps: number, articleId: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        await axios.post(
          `${this.url}/v0/articles/${articleId}/applaud`,
          {
            claps,
          },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return;
      });
    },
    update: (body: ArticlePut) =>
      from(
        this.retryWithFreshTokenIf401(async token => {
          const { id, ...rest } = body;
          const res = await axios.put<ArticleDTO>(`${this.url}/v0/articles/${id}`, rest, {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          });
          return res.data;
        }),
      ),

    // promiseSearch: async (query?: string) => {
    //   if (!query) return undefined;
    //   return this.retryWithFreshTokenIf401(async token => {
    //     const res = await axios.post<{
    //       hits: { hits: { _source: ArticleDocument }[] };
    //     }>(
    //       `${this.url}/v0/articles/search`,
    //       {
    //         query: {
    //           multi_match: {
    //             query,
    //             fields: ['title', 'text', 'authorName', 'authorEmail', 'labels'],
    //           },
    //         },
    //       },
    //       {
    //         headers: getAuthHeader(token, getDestinationTenantFromUrl()),
    //       },
    //     );
    //     Metrics.getLogger().logEvent('api_search', {
    //       query,
    //       results: res.data.hits.hits.length,
    //     });
    //     return res.data.hits.hits.map(hit => hit._source);
    //   });
    // },
    // search: (query: string) =>
    //   from(
    //     this.retryWithFreshTokenIf401(async token => {
    //       const res = await axios.post<{
    //         hits: { hits: { _source: ArticleDocument }[] };
    //       }>(
    //         `${this.url}/v0/articles/search`,
    //         {
    //           query: {
    //             multi_match: {
    //               query,
    //               fields: ['title', 'text', 'authorName', 'authorEmail', 'labels'],
    //             },
    //           },
    //         },
    //         {
    //           headers: getAuthHeader(token, getDestinationTenantFromUrl()),
    //         },
    //       );
    //       return res.data.hits.hits.map(hit => hit._source);
    //     }),
    //   ),

    list: () =>
      from(
        this.retryWithFreshTokenIf401(async token => {
          const res = await axios.get<ArticleDTO[]>(`${this.url}/v0/articles`, {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          });
          return res.data;
        }),
      ),

    retrieveClaps: async (articleId: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<{
          claps: number;
          totalClaps: number;
          articleId: string;
        }>(`${this.url}/v0/articles/${articleId}/applauds`, {
          headers: getAuthHeader(token, getDestinationTenantFromUrl()),
        });
        return res.data;
      });
    },
    retrieve: (articleId: string) =>
      from(
        this.retryWithFreshTokenIf401(async token => {
          const res = await axios.get<ArticleDTO>(
            `${this.url}/v0/articles/${articleId}`,
            {
              headers: getAuthHeader(token, getDestinationTenantFromUrl()),
            },
          );
          return res.data;
        }),
      ),
    retrievePromise: async (articleId: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<FeedArticle>(`${this.url}/v0/articles/${articleId}`, {
          headers: getAuthHeader(token, getDestinationTenantFromUrl()),
        });
        return res.data;
      });
    },
    retrieveByDiscussionId: async (discussionId: string) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.get<ArticleDTO>(
          `${this.url}/v0/articles/byDiscussionId/${discussionId}`,
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    updateSnippet: async (
      {
        title,
        tags,
        topicId,
      }: {
        topicId: string;
        title: string;
        tags?: { tagsToRemove: string[]; tagsToCreate: Tag[]; tagsToAdd: Tag[] };
      },
      articleId: string,
    ) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.put<ArticleDTO>(
          `${this.url}/v0/articles/${articleId}`,
          {
            title,
          },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        if (tags) {
          const tagsToAdd = tags.tagsToAdd.map(tag => tag.id);
          const tagsToCreate = tags.tagsToCreate.map(tag => tag.name);
          try {
            await retry(
              async () => {
                await this.articles.tagArticle(
                  topicId,
                  articleId,
                  tagsToAdd,
                  tags.tagsToRemove,
                  tagsToCreate,
                );
              },
              {
                retries: 3,
              },
            );
          } catch (e) {
            throw e;
          }
        }
        return res.data;
      });
    },
    createPublicQuestion: async (
      question: string,
      topic: TopicDTO,
      isAnonymous: boolean,
      images: { data: string; id: number }[],
      type: ArticleType,
      tags?: Tag[],
    ) => {
      return this.retryWithFreshTokenIf401(async token => {
        const parsedImages = images.map((img, i) => ({
          id: `${new Date().getTime()}-${i}`,
          data: img.data,
          timestamp: new Date().getTime(),
        }));

        const res = await axios.post<ArticleDTO>(
          `${this.url}/v0/articles/`,
          {
            status: 'published',
            title: question,
            labels: [topic.displayName],
            type: type,
            topicId: topic.id,
            isAnonymous,
            questionData: {
              images: parsedImages.map(img => ({ id: img.id, timestamp: img.timestamp })),
            },
          },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        await Promise.all(
          parsedImages.map(async img => {
            try {
              await retry(
                async () => {
                  await Files.instance.uploadSnippetImage(img.id, res.data.id, img.data);
                },
                {
                  retries: 3,
                },
              );
            } catch (e) {
              throw e;
            }
          }),
        );
        if (tags) {
          const tagsToAdd = tags
            .filter(tag => !tag.id.startsWith('new'))
            .map(tag => tag.id);
          const tagsToCreate = tags
            .filter(tag => tag.id.startsWith('new'))
            .map(tag => tag.name);
          try {
            await retry(
              async () => {
                await this.articles.tagArticle(
                  topic.id,
                  res.data.id,
                  tagsToAdd,
                  [],
                  tagsToCreate,
                );
              },
              {
                retries: 3,
              },
            );
          } catch (e) {
            throw e;
          }
        }

        return res.data;
      });
    },
    createFromDiscussion: async (body: {
      title: string;
      labels: string[];
      topicId: string;
      discussionConversion: ArticleDiscussionPost;
      isAnonymous: boolean;
    }) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<ArticleDTO>(
          `${this.url}/v0/articles/`,
          { ...body, status: 'published' },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
    create: async ({
      title,
      topicId,
      isAnonymous,
      thumbnailPreview,
      type,
    }: {
      title: string;
      topicId?: string;
      isAnonymous?: boolean;
      thumbnailPreview?: string;
      type: ArticleType;
    }) => {
      return this.retryWithFreshTokenIf401(async token => {
        const imageId = new Date().getTime().toString();
        const res = await axios.post<ArticleDTO>(
          `${this.url}/v0/articles`,
          {
            title,
            topicId,
            isAnonymous,
            labels: [],
            thumbnailId: thumbnailPreview ? imageId : undefined,
            type: type,
            status: type === 'image' ? 'published' : undefined,
          },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        if (thumbnailPreview) {
          try {
            await retry(
              async () => {
                await Files.instance.uploadSnippetImage(
                  imageId,
                  res.data.id,
                  thumbnailPreview,
                );
              },
              {
                retries: 3,
              },
            );
          } catch (e) {
            throw e;
          }
        }
        return res.data;
      });
    },
    tagArticle: async (
      topicId: string,
      articleId: string,
      tagsToAdd: string[],
      tagsToRemove: string[],
      tagsToCreate: string[],
    ) => {
      try {
        const createdTags = await Promise.all(
          tagsToCreate.map(async tag => {
            return this.createNewTag(tag, topicId);
          }),
        );

        const addTags = tagsToAdd.concat(createdTags.map(res => res.id));
        const res = await this.articles.addAndRemoveArticleTags(
          addTags,
          tagsToRemove,
          articleId,
        );
        return res;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        throw e;
      }
    },
    addAndRemoveArticleTags: async (
      tagsToAdd: string[],
      tagsToRemove: string[],
      articleId: string,
    ) => {
      return this.retryWithFreshTokenIf401(async token => {
        const res = await axios.post<{ tagIds: string[] }>(
          `${this.url}/v0/articles/${articleId}/tag`,
          {
            tagsToAdd: tagsToAdd.length > 0 ? tagsToAdd : undefined,
            tagsToRemove: tagsToRemove.length > 0 ? tagsToRemove : undefined,
          },
          {
            headers: getAuthHeader(token, getDestinationTenantFromUrl()),
          },
        );
        return res.data;
      });
    },
  };
}

export type PaginatedFeedArticlesResponsePerformance = {
  items: string[];
  byId: ById<FeedArticle>;
  cursor?: number;
  count: number;
};

export type PaginatedFeedArticlesResponse = {
  items: FeedArticle[];
  cursor?: number;
  count: number;
};

export type LookupTenant = { tenantId: string; tenantName: string };

export type UserTenant = {
  activeTenantId: string;
  availableTenantIds: string[];
  availableTenants: { tenantId: string; tenantName: string }[];
};

export type Invitation = {
  code: string; // The code used to verify. Will also be its Id
  tenantId: string; // The tenantId this code belongs to
  expiresAt: number | null; // If null means it will never expire
  createdBy: string;
  role: UserRole;
  email: string | null;
  topic?: TopicInvitation;
};

export type InvitationRole = 'mentor' | 'student';

export type TopicRole = 'follower' | 'moderator' | 'owner';

export type TopicInvitation = {
  topicId: string;
  type: TopicRole;
};

export type EmailInvitationCreateDTO = {
  email: string;
  role: UserRole;
  topic?: TopicInvitation;
};

export type RoundResult = {
  questions?: Response[];
  responses?: Response[];
  id: string;
  votes?: Vote[];
};

export type Vote = {
  id: string;
  selectedQuestionId: string;
  selectedResponseId?: string;
  questionAId: string;
  questionBId: string;
  responseAId?: string;
  responseBId?: string;
  votedBy: string;
};

export type Participant = {
  nickName: string;
  name?: string;
  id: string;
  token: string; // the token passed with all api requests from the anonymous user. Generated in the monolith
  isKicked?: boolean;
};

export type GamePinRegistration = {
  pin: string;
  tenantId: string;
  createdAt: number;
  gameId: string;
};

export type Game = {
  id: string;
  topicId?: string | null;
  pin: string | null;
  title?: string | null;
  isPlaying: boolean;
  isComplete: boolean;
  isPreviewGame?: boolean | null;
  isFromDiscover?: boolean | null;
  isDemoGame?: boolean | null;
  templateId?: string | null;
  areAllRoundsCompleted?: boolean | null;
  roundStartsAt: number | null;
  currentRound: string | null;
  lastPlayedAt: number | null;
  createdAt: number | null;
  activity?: GameActivity;
  host: {
    uid: string;
  };
  rounds: Round[] | null;
  roundResults?: RoundResult[]; // included when complete
  participants?: Participant[]; // included when complete
  requireRealNames?: boolean;
};

export type GameCreateDTO = {
  rounds: Round[];
  topicId?: string | null;
};

export type Score = {
  score: number;
  questionId: string;
  question: Response;
  responseId: string;
  response: Response;
};

export type Response = {
  id: string;
  question: string;
  response: string;
  uid: string;
  timestamp?: number;
  dismissed?: boolean;
};

export type LeaderBoard = Score[];

export type GameActivity = 'kickstart' | 'summarize' | 'brainstorm' | 'answer';
export type RoundType =
  | 'questions'
  | 'voting'
  | 'answers'
  | 'ideas'
  | 'slide'
  | 'canvas'
  | 'responsegrid'
  | 'relations'
  | 'network' /**@deprecated in favour of wordcloud*/
  | 'mindmap' /**@deprecated in favour of wordcloud*/
  | 'wordcloud'
  | 'poll'
  | 'podium';

export type CropConfig = {
  height: number;
  width: number;
  naturalHeight: number;
  naturalWidth: number;
  x: number;
  y: number;
};

export type RoundMediaDrawingFile = {
  id: string;
  created: number;
  mimeType: string;
};

export type RoundMedia = {
  fileId: string;
  fileMimeType: string;
  fileAltText?: string | null;
  cropConfig?: CropConfig | null;
  backgroundDrawingFileId?: string | null;
  backgroundDrawingConfigFiles?: RoundMediaDrawingFile[] | null;
};

export type SlideType = 'header' | 'bullets' | 'image' | 'fullscreen_media';
export type SlideBullet = {
  id: string;
  text: string;
};
export type RoundOptions = {
  id: string;
  displayName: string;
  count?: number;
  color?: string;
};

export type SlideContent = {
  type: SlideType;
  title: string | null;
  subtitle: string | null;
  bullets: SlideBullet[] | null;
};

export type Round = {
  id: string;
  type: RoundType;
  title?: string | null;
  durationSeconds?: number | null; // transactional
  durationSecondsConfig?: number | null; // configurable
  wasPlayed?: boolean | null; // transactional
  dataReferenceRoundId?: string | null;
  media?: RoundMedia | null;
  content?: SlideContent | null;
  roundOptions?: RoundOptions[] | null;
  responseLimit?: number | null;
};

export type NotificationType =
  | 'chatRoomAvailableForClaim'
  | 'openUrl'
  | 'relevantContent'
  | 'reminder'
  | 'achievement'
  | 'newMessage'; // should say something about how the frontend should handle the type of notification and also how they relate to settings

export type GenericNotification = {
  id: string;
  receiver: string; // Will be used to lookup the token(s) for the devices and the email
  received: boolean; // acknowledges if the notification was received. Mark as read
  receivedTime: number | null;
  sentTime: number | null;
  payload: {
    // This is data actually transmitted in the notification payload
    type: NotificationType;
    title: string;
    body: string;
    disableInApp?: string; // boolean
    redirect?: string;
    redirectSelfServe?: string;
  };
};

export type ArticleDiscussionPost = {
  discussionId: string;
  includedMessages: { id: string; changedText?: string }[];
};

export type UserRole = 'admin' | 'mentor' | 'student';

export type ArticleStatus =
  | 'parsing'
  | 'draft'
  | 'review'
  | 'published'
  | 'rejected'
  | 'archived';
export type ArticleDTO = {
  id: string;
  title: string;
  labels: string[];
  text: string | null;
  createdAt: number;
  updatedAt: number;
  status: ArticleStatus;
  authorId: string;
  topicId: string | null;
  path: string;
  totalClaps: number;
  totalViews: number;
  type: ArticleType;
  discussion: ArticleDiscussionData | null;
  questionData?: QuestionData | null;
  hasComments: boolean | null;
  comments: ArticleComment[] | null;
  isAnonymous?: boolean | null;
  thumbnailPreview?: string | null;
  thumbnailId?: string | null;
  hasReactions?: boolean | null;
  reactions?: ReactionDTO[] | null;
  tagIds?: string[] | null;
  tags?: Tag[];
  colorConfig?: ProfileLogoConfig;
};

const logos = [
  ':curipod_stars:',
  ':curipod_thanks:',
  ':curipod_hearts:',
  ':curipod_normal:',
] as const;

export type LogoType = typeof logos[number];

export type ProfileLogoConfig = {
  color: string;
  logo: LogoType;
};

export type QuestionData = {
  images: {
    id: string;
    timestamp: number;
  }[];
};

export type ArticleComment = {
  createdAt: number;
  updatedAt: number;
  text: string;
  createdBy: string;
  id: string;
  articleId: string;
  isAnonymous?: boolean | null;
  imageId?: string | null;
  disclaimer?: string | null;
  reactions?: ReactionDTO[] | null;
  colorConfig?: ProfileLogoConfig;
  createdByNameFallback?: string;
};

export type ReactionDTO = {
  id: string;
  type:
    | ':snapmentor_like:'
    | ':snapmentor_love:'
    | ':snapmentor_stars:'
    | ':snapmentor_brain_explosion:'
    | ':snapmentor_appreciate:'
    | string; // unicode or custom emoji
  createdBy: string;
  timestamp: number;
  count: number;
  entityType: ReactionEntityType;
  entityId: string;
  parentId?: string | null;
};

export type ReactionEntityType = 'article' | 'comment' | 'message';

export type ArticleType =
  | 'pdf'
  | 'image'
  | 'discussion'
  | 'onboarding'
  | 'question'
  | 'post';

export type FeedArticle = ArticleDTO & {
  topic: TopicDTO | null;
  userClaps: number;
};

export type ArticleDiscussionData = {
  id: string;
  studentId: string;
  mentorId: string;
  messages: Message[];
};

export type IndexedArticle = ArticleDTO & {
  authorName: string;
  authorEmail: string;
  tagNames: string[];
  commentText: string;
};

// Tags should be the name of the tag, not the id :)
export type SearchFilter = {
  tags?: string[];
};

export type SearchResponse = {
  hits: {
    total: {
      value: number | undefined;
    };
    hits: {
      _source: IndexedArticle;
      _score: number;
    }[];
  };
  took: number; // Milliseconds
  aggregations: {
    [type: string]: {
      buckets: { key: string; doc_count: number }[];
    };
  };
};
export type TagsResponse = {
  hits: {
    _source: IndexedArticle;
    _score: number;
  }[];
  aggregations:
    | {
        contentTypes:
          | {
              buckets: { key: string; doc_count: number }[];
            }
          | undefined;
        tags: { buckets: { key: string; doc_count: number }[] } | undefined;
      }
    | undefined;
};

export type ArticleDocument = {
  id: string;
  title: string;
  labels: string[];
  text: string;
  createdAt: number;
  updatedAt: number;
  authorName: string;
  authorId: string;
  authorEmail: string;
  path: string;
  totalClaps: number;
  type: ArticleType;
  isAnonymous?: boolean | null;
};

export type ArticlePut = {
  id: string;
  text?: string;
  title?: string;
  labels?: string[];
  status?: ArticleStatus;
};

export type OAuthProvider = 'vipps' | 'feide' | 'google';

export type ClaimsUser = {
  uid: string;
  customClaims: Claims;
  email?: string;
  name?: string;
  metadata: ById<string>;
  userType?: UserType;
};

export type APIClaims = {
  mentor: boolean;
  tenantAdmin: boolean;
};

export interface VippsToken {
  access_token: string;
  expires_in: number;
  id_token: string;
  scope: 'openid';
  token_type: 'bearer';
}

export type AvailableSubscriptionDTO = {
  id: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  product: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  prices: any[];
  unit?: { type: 'minutes'; amount: number };
};

export type MSClaims = {
  admin: boolean;
  mentor: boolean;
  student: boolean;
};
export type RoleUser = {
  id: string;
  name: string;
  createdAt: string;
  email?: string;
  roles: MSClaims;
};

export type AvailableFilterDTO = {
  key: string;
  doc_count: number;
};

export type AvailableFilterResponse = {
  labels: AvailableFilterDTO[];
  authors: AvailableFilterDTO[];
  articleTypes: AvailableFilterDTO[];
};

export type ImpactStatistics = {
  unResolvedDiscussions: number;
  totalClaps: number;
  totalViews: number;
  totalArticles: number;
  topArticles: {
    clapped: ArticleDTO[];
    viewed: ArticleDTO[];
    top: ArticleDTO[];
  };
  studentsHelped: number;
  resolvedDiscussions: number;
};

export type TopicDTO = {
  id: string;
  displayName: string;
  description?: string | null;
  disabled: boolean;
  isPrivate: boolean;
  deleted: boolean;
  createdBy: string;
  owners: string[];
  membersCount?: number;
};

export type TopicCreateDTO = Omit<TopicDTO, 'id'>;

export type TopicSaveDTO = TopicCreateDTO | TopicDTO;

export type TopicSaveResponseDTO =
  | TopicDTO
  | {
      id?: string;
      name: string;
      action: 'save' | 'create';
      success: boolean;
    };

export type OnboardingStatus = {
  hasCreatedDiscussion: boolean;
  hasSetNickName: boolean;
  hasEndedChat: boolean;
  hasCreatedSnippet: boolean;
  isFollowingSomething: boolean;
};

export type PotentialSnippet = ChatRoomEntity & {
  messageData: {
    total: number;
    lastMessage?: Message;
    discussionId: string;
  };
};

export type DidYouKnow = {
  [didYouKnowKey: string]: number;
};

export type MyFollowing = {
  topic?: TopicDTO;
  uid: string;
  timestamp: number;
  entityId: string;
  type: 'topic' | 'article';
};

export type Tag = {
  id: string;
  name: string;
  topicId: string;
  color?: string;
};

export type GameEditOrCreateDTO = {
  rounds?: Round[];
  topicId?: string | null;
  title?: string | null;
  gameId?: string | null;
  currentRound?: string | null;
  modifiedAt?: number | null;
  roundStartsAt?: number | null;
  isPlaying?: boolean;
  lastPlayedAt?: number | null;
  gameIds?: string[];
  requireRealNames?: boolean;
};

export type ActivityResults = {
  totalParticipants: number;
  activeParticipants: number;
  participants: ById<Participant>;
  roundsResults: ActivityRoundResult[];
};

export type ActivityRoundResult = {
  rounds: Round[];
  scores: Score[];
  wasPlayed: boolean;
  activeParticipants: number;
};

export enum HostActivationEventNameEnum {
  play_demo = 'play_demo',
  create_game = 'create_game',
  host_game = 'host_game',
  host_dismiss = 'host_dismiss',
}

export const HostActivationEventNameEnums = Object.values(HostActivationEventNameEnum);

export const ValidHostActivationEventNameEnums = HostActivationEventNameEnums.filter(
  val => val !== HostActivationEventNameEnum.host_dismiss,
);

export type HostActivationEventName = keyof typeof HostActivationEventNameEnum;

export type HostActivationEvent = {
  createdAt: number;
  name: HostActivationEventName;
  uid: string;
};

export type HostActivationResponse = {
  isDismissed: boolean; // Easy to use for the frontend to avoid rendering the progression bar.
  events: HostActivationEvent[]; // Easy to use for frontend to match progress components to data.
};
