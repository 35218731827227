import React from 'react';

export const DiscoverSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.625 0C13.2125 0 11.25 1.9625 11.25 4.375C11.25 6.62 14.76 10.5962 15.16 11.0425C15.2788 11.1737 15.4475 11.25 15.625 11.25C15.8025 11.25 15.9712 11.1737 16.09 11.0425C16.49 10.5962 20 6.62 20 4.375C20 1.9625 18.0375 0 15.625 0ZM15.625 6.25C14.59 6.25 13.75 5.41 13.75 4.375C13.75 3.34 14.59 2.5 15.625 2.5C16.66 2.5 17.5 3.34 17.5 4.375C17.5 5.41 16.66 6.25 15.625 6.25Z"
      fill="currentColor"
    />
    <path
      d="M0.3925 7.3425C0.15625 7.4375 0 7.6675 0 7.92375V19.375C0 19.5825 0.10375 19.7762 0.275 19.8925C0.38 19.9625 0.50125 20 0.625 20C0.70375 20 0.7825 19.985 0.8575 19.955L6.25 17.7975V5L0.3925 7.3425Z"
      fill="currentColor"
    />
    <path
      d="M17.02 11.8774C16.665 12.2724 16.1562 12.4999 15.625 12.4999C15.0938 12.4999 14.585 12.2724 14.23 11.8774C14.1025 11.7362 13.9387 11.5499 13.75 11.3287V19.9999L19.6075 17.6574C19.845 17.5637 20 17.3324 20 17.0774V7.88867C18.9763 9.62242 17.6075 11.2224 17.02 11.8774Z"
      fill="currentColor"
    />
    <path
      d="M10.3937 6.1575L7.5 5V17.7975L12.5 19.7975V9.76C11.7013 8.68375 10.8675 7.3875 10.3937 6.1575Z"
      fill="currentColor"
    />
  </svg>
);

export default DiscoverSvg;
