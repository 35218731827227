import { useMutation } from 'react-query';

import { getUserId } from '../modules';
import Database from '../services/database';
import { NotificationPreferences } from '../services/db';
import { useTypedSelector } from '.';

export const useNotificationPreferences = () => {
  const userId = useTypedSelector(getUserId);

  const { mutate: setNotificationPreferences } = useMutation<
    void,
    string,
    NotificationPreferences
  >((prefs: NotificationPreferences) =>
    Database.instance.usertype.setNotificationPreferences(userId!, prefs),
  );

  return {
    setNotificationPreferences,
  };
};
