import {
  AuthenticationResult,
  EventError,
  EventMessage,
  EventType,
} from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { User } from '@firebase/auth';
import { useUnleashContext } from '@unleash/proxy-client-react';
import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useIntercom } from 'react-use-intercom';

import UserNotFoundModal from '../components/UserNotFoundModal';
import { useTypedSelector } from '../hooks';
import usePreviewGame from '../hooks/usePreviewGame';
import { acquiredIdTokenSuccess, getIsAuthenticated, tenantsReceived } from '../modules';
import { BackendService } from '../services/backendService';
import Database from '../services/database';
import Files from '../services/files';
import { Metrics } from '../services/metrics';
import { gentlyCheckDestinationTenantUrl } from '../utils/navigatorUtils';
import { AuthenticationContext } from './AuthenticationProvider';

export type Auth = User | null;

const AuthSubscriber = ({ children }: { children: React.ReactChild }) => {
  const authService = useContext(AuthenticationContext);
  const { update, shutdown } = useIntercom();
  const { isPreview } = usePreviewGame();
  const dispatch = useDispatch();
  const [error, setError] = useState<EventError>();
  const history = useHistory();
  const updateContext = useUnleashContext();

  const handleSignIn = () => {
    setError(undefined);
    localStorage.clear();
    history.push('/login');
  };
  const handleSignUp = () => {
    setError(undefined);
    localStorage.clear();
    history.push('/signup');
  };

  const handleErrorOk = () => {
    setError(undefined);
    localStorage.clear();
    setTimeout(() => {
      history.push('/');
    }, 1000);
  };
  useEffect(() => {
    if (isPreview) {
      update({
        hideDefaultLauncher: true,
      });
    }
    return () => {
      update({
        hideDefaultLauncher: false,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPreview]);
  useEffect(() => {
    let callbackId: string | null = null;
    if (authService) {
      callbackId = authService
        .getAuthProvider()
        .addEventCallback((message: EventMessage) => {
          if (message.eventType === EventType.LOGOUT_SUCCESS) {
            shutdown();
          }
          if (
            message.eventType === EventType.LOGIN_SUCCESS ||
            message.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
          ) {
            authService.setActiveAccount(message.payload as AuthenticationResult);
            dispatch(acquiredIdTokenSuccess(message.payload as AuthenticationResult));
            const p = message.payload as AuthenticationResult;
            const claims = p.idTokenClaims as {
              sub: string;
              idp: string;
              email?: string;
            };
            update({
              userId: claims.sub,
              email: claims.email,
              customAttributes: {
                idp: claims.idp,
              },
            });
            updateContext({ userId: claims.email });
            Metrics.setEmail(claims.email);
            Metrics.setUid(claims.sub);
            Metrics.setIdp(claims.idp);
          }

          if (message.eventType === EventType.LOGIN_FAILURE) {
            //AADB2C90091 is when the user cancels interaction. Handle it and redirect to the landing page.
            if (message.error?.message.includes('AADB2C90091')) {
              history.push('/');
              return;
            }
            setError(message.error);
          }
        });
    }

    return () => {
      if (callbackId && authService) {
        authService.getAuthProvider().removeEventCallback(callbackId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authService, dispatch, history]);

  const isAuthenticated = useTypedSelector(getIsAuthenticated);

  const { data } = useQuery(
    ['firebase-tenants', isAuthenticated],
    () => {
      if (!isAuthenticated) {
        return Promise.resolve(undefined);
      }
      const tenantFromUrl = gentlyCheckDestinationTenantUrl();
      const destinationTenantIsKnown = tenantFromUrl !== undefined;
      return BackendService.instance.retrieveCurrentTenants(destinationTenantIsKnown);
    },
    { staleTime: 1000 * 60 * 5 },
  );

  useEffect(() => {
    if (data && authService) {
      const fn = async () => {
        const tenantFromUrl = gentlyCheckDestinationTenantUrl();
        const tenantToUse = tenantFromUrl ? tenantFromUrl : data.user.activeTenantId;
        await authService.signInWithCustomToken(data.firebaseToken);
        localStorage.setItem('lastTenant', JSON.stringify(data));
        Metrics.setTenantId(tenantToUse);
        Database.instance.setTenantId(tenantToUse);
        Files.instance.setTenantId(tenantToUse);

        update({
          customAttributes: {
            student: data.roles.student,
            mentor: data.roles.mentor,
            admin: data.roles.admin,
          },
          company: {
            companyId: tenantToUse,
            name: data.user.availableTenants.find(t => t.tenantId === tenantToUse)
              ?.tenantName,
          },
        });

        // Dispatch this to notify listeners that we successfully signed in with firebase.
        // Should be primarily used for getting the defined active tenantId after a successfull authentication and firebase authentication token
        dispatch(
          tenantsReceived({
            user: { ...data.user, activeTenantId: tenantToUse },
            roles: data.roles,
          }),
        );
      };
      fn();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, authService, dispatch]);

  if (authService) {
    return (
      <MsalProvider instance={authService.getAuthProvider()}>
        {error && (
          <UserNotFoundModal
            onClose={handleErrorOk}
            onSignIn={handleSignIn}
            onSignUp={handleSignUp}
            error={error}
            visible={!!error}
          />
        )}
        {children}
      </MsalProvider>
    );
  }
  return null;
};

export default AuthSubscriber;
