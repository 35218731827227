import React from 'react';

export const ProfileSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="80"
    height="81"
    viewBox="0 0 80 81"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M52.0628 37.5945C57.1817 33.8612 60.5152 27.8194 60.5152 21.0128C60.5152 9.70203 51.3131 0.5 40.0023 0.5C28.6916 0.5 19.4895 9.70203 19.4895 21.0128C19.4895 27.8194 22.8228 33.8612 27.9419 37.5945C15.2169 42.4641 6.15625 54.8017 6.15625 69.218C6.15625 75.4389 11.2173 80.5 17.4383 80.5H62.5664C68.7873 80.5 73.8484 75.4389 73.8484 69.218C73.8484 54.8017 64.7878 42.4641 52.0628 37.5945ZM25.6434 21.0128C25.6434 13.0953 32.0848 6.65391 40.0023 6.65391C47.9198 6.65391 54.3612 13.0953 54.3612 21.0128C54.3612 28.9303 47.9198 35.3719 40.0023 35.3719C32.0848 35.3719 25.6434 28.9303 25.6434 21.0128ZM62.5664 74.3461H17.4383C14.6106 74.3461 12.3102 72.0456 12.3102 69.2178C12.3102 53.9481 24.7327 41.5255 40.0025 41.5255C55.2723 41.5255 67.6948 53.948 67.6948 69.2178C67.6947 72.0456 65.3942 74.3461 62.5664 74.3461Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="80" height="80" fill="currentColor" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default ProfileSvg;
