import { combineEpics } from 'redux-observable';
import { empty, from, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, takeUntil } from 'rxjs/operators';
import { isOfType } from 'typesafe-actions';

import { RootEpic } from '..';
import { subscribeToNotifications } from '../Notifications';
import { getUserId } from '../User';
import { SIGN_OUT } from '../User/actionTypes';
import { catchHandler } from '../utils';
import { setUserType } from './actions';
import { NOTIFICATIONS_SKIPPED, SET_USER_TYPE, SUBSCRIBE } from './actionTypes';

export const userTypeSubscriptionEpic: RootEpic = (action$, _, { database }) =>
  action$.pipe(
    filter(isOfType(SUBSCRIBE)),
    switchMap(action =>
      database.usertype
        .get(action.payload)
        .pipe(
          takeUntil(action$.pipe(filter(isOfType(SIGN_OUT)), catchError(catchHandler))),
        ),
    ),
    map(userType => setUserType(userType || {})),
    catchError(catchHandler),
  );

export const userTypeAlreadyWantsNotifications: RootEpic = action$ =>
  action$.pipe(
    filter(isOfType(SET_USER_TYPE)),
    mergeMap(action => {
      if (Object.values(action.payload.instanceIds || {}).length > 0) {
        return of(subscribeToNotifications());
      }
      return empty();
    }),
    catchError(catchHandler),
  );

export const notificationsSkippedEpic: RootEpic = (action$, state$, { database }) =>
  action$.pipe(
    filter(isOfType(NOTIFICATIONS_SKIPPED)),
    switchMap(() => {
      return from(
        database.usertype.setNotificationsSkipped(getUserId(state$.value)!),
      ).pipe(
        mergeMap(() => empty()),
        catchError(catchHandler),
      );
    }),
  );

export const userEpic = combineEpics(
  userTypeSubscriptionEpic,
  userTypeAlreadyWantsNotifications,
  notificationsSkippedEpic,
);
