import { EventError } from '@azure/msal-browser';
import { Modal } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import theme from '../../../src/theme';
import { Button } from '../../components/_atoms/_Button';
import { ArrowDownIcon } from '../../components/_atoms/_Icons';
import AppreciateSvg from '../../components/_atoms/_Icons/Svgs/AppreciateSvg';
import FoldedHandsSvg from '../../components/_atoms/_Icons/Svgs/FoldedHandsSvg';
import StarStruckSvg from '../../components/_atoms/_Icons/Svgs/StarStruckSvg';

const NiceUserError = ({
  error,
  handleSignIn,
  handleSignUp,
  handleClose,
}: {
  error: EventError | undefined;
  handleSignIn: () => void;
  handleSignUp: () => void;
  handleClose: () => void;
}) => {
  const { t } = useTranslation('Host');
  if (!error) return null;
  if (error && error.message) {
    if (error.message.includes('API-SIGN-UP-ASSIGN-TENANT-AND-ROLES')) {
      return (
        <ErrorWrapper>
          <StarStruckSvg />
          <h2>Seems like you already have an account</h2>
          <span>Awesome!</span>
          <Button onClick={handleSignIn}>Log in</Button>
        </ErrorWrapper>
      );
    } else if (error.message.includes('AADB2C90027')) {
      return (
        <ErrorWrapper>
          <FoldedHandsSvg />
          <h2>The domain of your email does not match this space's rules</h2>
          <span>Make sure you use your primary school email</span>
          <Button onClick={handleSignIn}>Log In</Button>
        </ErrorWrapper>
      );
    } else if (error.message.includes('AADB2C99002')) {
      return (
        <ErrorWrapper>
          <AppreciateSvg />
          <h2>It seems like you don't have an account yet</h2>
          <span>
            If you are a student at a school or university you should have received an
            invitation from your teacher or administrator
          </span>
          <Button onClick={handleSignUp}> Sign up now</Button>
        </ErrorWrapper>
      );
    } else {
      return (
        <ErrorWrapper>
          <FoldedHandsSvg />
          <h2>Oh noes! An unexpected error occurred</h2>
          <span>
            {t(
              'SuperSorry',
              'We are super sorry. A notification has been sent to our development team to make sure we sort it out as soon as possible. Please try again',
            )}
          </span>
          <Button onClick={handleClose}>Try again</Button>
        </ErrorWrapper>
      );
    }
  } else {
    return null;
  }
};

export const UserNotFoundModal = ({
  error,
  onSignIn,
  onSignUp,
  onClose,
  visible,
}: {
  error: EventError | undefined;
  visible: boolean;
  onClose: () => void;
  onSignIn: () => void;
  onSignUp: () => void;
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (!error) return null;

  const handleSignIn = () => {
    onSignIn();
  };
  const handleSignUp = () => {
    onSignUp();
  };
  const handleClose = () => {
    onClose();
  };

  return (
    <StyledModal visible={visible} footer={false} closable={false}>
      <NiceUserError
        error={error}
        handleClose={handleClose}
        handleSignIn={handleSignIn}
        handleSignUp={handleSignUp}
      />
      {isExpanded && <p>{error?.message}</p>}
      {isExpanded && (
        <ExpandedErrorMsg>
          If you expected to be signed in straightforward please send frikk@curipod.com a
          screenshot of the page right now and he'll help you asap!
        </ExpandedErrorMsg>
      )}

      <ViewMoreContainer
        $isActive={isExpanded}
        role="button"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <span>{'Expand full message'}</span>
        <ArrowDownIcon />
      </ViewMoreContainer>
    </StyledModal>
  );
};

const ErrorWrapper = styled.span`
  text-align: center;
  display: inline-grid;

  h2 {
    font-weight: bold;
  }

  button {
    place-self: center;
    font-size: 14px;
    margin-top: 40px;
    margin-bottom: 20px;
  }

  svg {
    place-self: center;
    width: 100px;
    height: auto;
    margin-bottom: 10px;
  }
`;

const ExpandedErrorMsg = styled.p`
  text-align: center;
  font-size: 12px;
`;

const ViewMoreContainer = styled.div<{ $isActive?: boolean }>`
  font-weight: bold;
  justify-content: center;
  font-size: 12px;
  color: ${theme.colors.text.grey};
  display: flex;
  align-items: center;
  cursor: pointer;
  span {
    margin-left: 5px;
  }
  svg {
    transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
    color: ${theme.colors.text.grey};
    margin-left: 5px;
    width: 8px;
  }

  ${({ $isActive }) =>
    $isActive &&
    css`
      svg {
        transform: rotate(180deg);
      }
    `};
`;

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 10px;
  }

  .ant-modal-body {
    text-align: center;
  }
`;
export default UserNotFoundModal;
