import {
  ArrowDownSvg,
  ArrowLeftSvg,
  ArticlePDFSvg,
  ArticleSvg,
  BellSvg,
  ChatSvg,
  CheckSvg,
  ClapsSvg,
  CopySvg,
  CrossSvg,
  DeleteSvg,
  ExclamationMarkSvg,
  ExitSvg,
  EyeOffSvg,
  EyeOnSvg,
  FilterSvg,
  HeartEmptySvg,
  HeartFilledSvg,
  LogoSvg,
  MenuSvg,
  PencilSvg,
  PlusSvg,
  QuestionMarkSvg,
  RadioEmptySvg,
  RadioSelectedSvg,
  RefreshSvg,
  ReportsSvg,
  SearchSvg,
  SendSvg,
  SettingsSvg,
  SignOutSvg,
  StarEmptySvg,
  StarFilledSvg,
  TimeSvg,
  UserSvg,
} from './Svgs';
import AnalyticsSvg from './Svgs/AnalyticsSvg';
import AnnouncementSvg from './Svgs/AnnouncementSvg';
import AnswerSvg from './Svgs/AnswerSvg';
import AppreciateSvg from './Svgs/AppreciateSvg';
import ArgumentSvg from './Svgs/ArgumentSvg';
import ArrowRightSvg from './Svgs/ArrowRight';
import ArticleDiscussionSvg from './Svgs/ArticleDiscussionSvg';
import BrainExplosionSvg from './Svgs/BrainExplosionSvg';
import BrowserSvg from './Svgs/BrowserSvg';
import CameraSvg from './Svgs/CameraSvg';
import ChartSvg from './Svgs/ChartSvg';
import ChatBubbleSvg from './Svgs/ChatBubbleSvg';
import CheckfullSvg from './Svgs/CheckFull';
import { CloudSvg } from './Svgs/CloudSvg';
import { ConnectDotsSvg } from './Svgs/ConnectDotsSvg';
import CuripodHearts from './Svgs/CuripodHearts';
import CuripodNormal from './Svgs/CuripodNormal';
import CuripodStars from './Svgs/CuripodStars';
import CuripodThanks from './Svgs/CuripodThanks';
import DebateSvg from './Svgs/DebateSvg';
import DisabledSvg from './Svgs/DisabledSvg';
import DisclaimerSvg from './Svgs/DisclaimerSvg';
import DiscoverSvg from './Svgs/DiscoverSvg';
import DownloadSvg from './Svgs/DownloadSvg';
import DragSvg from './Svgs/DragSvg';
import DrawingsSvg from './Svgs/DrawingsSvg';
import EmailSvg from './Svgs/EmailSvg';
import ExternalLinkSvg from './Svgs/ExternalLinkSvg';
import FlashSvg from './Svgs/FlashSvg';
import FollowSvg from './Svgs/FollowSvg';
import FrozenSvg from './Svgs/FrozenSvg';
import FullScreenSvg from './Svgs/FullScreenSvg';
import GameSettingsSvg from './Svgs/GameSettingsSvg';
import GlobeSvg from './Svgs/GlobeSvg';
import GoBackArrowSvg from './Svgs/GoBackArrowSvg';
import GridSvg from './Svgs/GridSvg';
import HashtagSvg from './Svgs/HashtagSvg';
import HeartEmojiSvg from './Svgs/HeartEmojiSvg';
import HomeSvg from './Svgs/HomeSvg';
import IdeasSvg from './Svgs/IdeasSvg';
import IdeaSvg from './Svgs/IdeaSvg';
import LikeNeutralSvg from './Svgs/LikeNeutralSvg';
import LikeSvg from './Svgs/LikeSvg';
import LinkSvg from './Svgs/LinkSvg';
import LiveAnswerSvg from './Svgs/LiveAnswerSvg';
import LongArrowRightSvg from './Svgs/LongArrowRightSvg';
import MentorSvg from './Svgs/MentorSvg';
import MindmapSvg from './Svgs/MindmapSvg';
import MinimizeSvg from './Svgs/MinimizeSvg';
import MoreSvg from './Svgs/MoreSvg';
import MuteSvg from './Svgs/Mute';
import { NetworkSvg } from './Svgs/NetworkSvg';
import PadlockSvg from './Svgs/PadlockSvg';
import PassSvg from './Svgs/PassSvg';
import PeerfeedbackSvg from './Svgs/PeerfeedbackSvg';
import PictureSvg from './Svgs/PictureSvg';
import PodiumSvg from './Svgs/PodiumSvg';
import { PollSvg } from './Svgs/PollSvg';
import ProfileSvg from './Svgs/ProfileSvg';
import QrCodeSvg from './Svgs/QrCodeSvg';
import QuestionsSvg from './Svgs/QuestionsSvg';
import QueueSvg from './Svgs/QueueSvg';
import QuickPlaySvg from './Svgs/QuickPlaySvg';
import RotateSvg from './Svgs/RotateSvg';
import ScissorSvg from './Svgs/ScissorSvg';
import ShareSvg from './Svgs/ShareSvg';
import SlideBulletsSvg from './Svgs/SlideBulletsSvg';
import SlideHeaderSvg from './Svgs/SlideHeaderSvg';
import SpeakerSvg from './Svgs/Speaker';
import SpeakerBarlessSvg from './Svgs/SpeakerBarless';
import StarStruckSvg from './Svgs/StarStruckSvg';
import SummarizeSvg from './Svgs/SummarizeSvg';
import TopicsSvg from './Svgs/TopicsSvg';
import UndoSvg from './Svgs/UndoSvg';
import UsersSvg from './Svgs/UsersSvg';
import VoidSvg from './Svgs/VoidSvg';
import VotingSvg from './Svgs/VotingSvg';
import YoutubeSvg from './Svgs/YoutubeSvg';

export const AnalyticsIcon = AnalyticsSvg;
export const AnnouncementIcon = AnnouncementSvg;
export const AnswerIcon = AnswerSvg;
export const AppreciateIcon = AppreciateSvg;
export const ArgumentIcon = ArgumentSvg;
export const ArrowDownIcon = ArrowDownSvg;
export const ArrowLeftIcon = ArrowLeftSvg;
export const ArrowRightIcon = ArrowRightSvg;
export const ArticlePDFIcon = ArticlePDFSvg;
export const ArticleDiscussionIcon = ArticleDiscussionSvg;
export const ArticleIcon = ArticleSvg;
export const BellIcon = BellSvg;
export const BrainExplosionIcon = BrainExplosionSvg;
export const BrainstormIcon = IdeaSvg;
export const BrowserIcon = BrowserSvg;
export const CameraIcon = CameraSvg;
export const ChatIcon = ChatSvg;
export const ChatBubbleIcon = ChatBubbleSvg;
export const CheckIcon = CheckSvg;
export const CheckFullIcon = CheckfullSvg;
export const ClapsIcon = ClapsSvg;
export const CloudIcon = CloudSvg;
export const CuripodHeartsIcon = CuripodHearts;
export const CuripodStarsIcon = CuripodStars;
export const CuripodThanksIcon = CuripodThanks;
export const CuripodNormalIcon = CuripodNormal;
export const ChartIcon = ChartSvg;
export const ConnectDotsIcon = ConnectDotsSvg;
export const CopyIcon = CopySvg;
export const CrossIcon = CrossSvg;
export const DebateIcon = DebateSvg;
export const DeleteIcon = DeleteSvg;
export const DisclaimerIcon = DisclaimerSvg;
export const DiscoverIcon = DiscoverSvg;
export const DisabledIcon = DisabledSvg;
export const DownloadIcon = DownloadSvg;
export const DragIcon = DragSvg;
export const DrawingsIcon = DrawingsSvg;
export const EmailIcon = EmailSvg;
export const ExclamationMarkIcon = ExclamationMarkSvg;
export const ExitIcon = ExitSvg;
export const ExternalLinkIcon = ExternalLinkSvg;
export const EyeOffIcon = EyeOffSvg;
export const EyeOnIcon = EyeOnSvg;
export const FilterIcon = FilterSvg;
export const FlashIcon = FlashSvg;
export const FollowIcon = FollowSvg;
export const FrozenIcon = FrozenSvg;
export const FullScreenIcon = FullScreenSvg;
export const GlobeIcon = GlobeSvg;
export const GoBackArrowIcon = GoBackArrowSvg;
export const GridIcon = GridSvg;
export const GameSettingsIcon = GameSettingsSvg;
export const HashtagIcon = HashtagSvg;
export const HeartEmojiIcon = HeartEmojiSvg;
export const HeartEmptyIcon = HeartEmptySvg;
export const HeartFilledIcon = HeartFilledSvg;
export const HomeIcon = HomeSvg;
export const IdeasIcon = IdeasSvg;
export const LikeIcon = LikeSvg;
export const LikeNeutralIcon = LikeNeutralSvg;
export const LinkIcon = LinkSvg;
export const LiveAnswerIcon = LiveAnswerSvg;
export const LogoIcon = LogoSvg;
export const LongArrowRightIcon = LongArrowRightSvg;
export const MentorIcon = MentorSvg;
export const MenuIcon = MenuSvg;
export const MindmapIcon = MindmapSvg;
export const MinimizeIcon = MinimizeSvg;
export const MoreIcon = MoreSvg;
export const MuteIcon = MuteSvg;
export const NetworkIcon = NetworkSvg;
export const PadlockIcon = PadlockSvg;
export const PassIcon = PassSvg;
export const PeerFeedbackIcon = PeerfeedbackSvg;
export const PencilIcon = PencilSvg;
export const PictureIcon = PictureSvg;
export const PodiumIcon = PodiumSvg;
export const PlusIcon = PlusSvg;
export const ProfileIcon = ProfileSvg;
export const QrCodeIcon = QrCodeSvg;
export const QuestionMarkIcon = QuestionMarkSvg;
export const QueueIcon = QueueSvg;
export const QuickPlayIcon = QuickPlaySvg;
export const QuestionsIcon = QuestionsSvg;
export const RadioEmptyIcon = RadioEmptySvg;
export const RadioSelectedIcon = RadioSelectedSvg;
export const RefreshIcon = RefreshSvg;
export const ReportsIcon = ReportsSvg;
export const RotateIcon = RotateSvg;
export const ScissorIcon = ScissorSvg;
export const SearchIcon = SearchSvg;
export const SendIcon = SendSvg;
export const SettingsIcon = SettingsSvg;
export const ShareIcon = ShareSvg;
export const SlideBulletsIcon = SlideBulletsSvg;
export const SlideHeaderIcon = SlideHeaderSvg;
export const SpeakerIcon = SpeakerSvg;
export const BarlessSpeakerIcon = SpeakerBarlessSvg;
export const StarEmptyIcon = StarEmptySvg;
export const StarFilledIcon = StarFilledSvg;
export const StarStruckIcon = StarStruckSvg;
export const SignOutIcon = SignOutSvg;
export const SummarizeIcon = SummarizeSvg;
export const TimeIcon = TimeSvg;
export const TopicsIcon = TopicsSvg;
export const UndoIcon = UndoSvg;
export const UserIcon = UserSvg;
export const UsersIcon = UsersSvg;
export const VoidIcon = VoidSvg;
export const VotingIcon = VotingSvg;
export const PollIcon = PollSvg;
export const YoutubeIcon = YoutubeSvg;
