import { combineReducers } from 'redux';

import { RootAction } from '..';
import { AppState } from '../rootReducer';
import { FETCH_ARTICLE_BLOB, FETCH_ARTICLE_BLOB_SUCCESS } from './actionTypes';

export type ArticleBlobsState = {
  byId: { [id: string]: { id: string; objectUrl: string } };
  loadingById: { [id: string]: boolean };
};

const initialState: ArticleBlobsState = {
  byId: {},
  loadingById: {},
};
const blobsReducer = (state = initialState, action: RootAction): ArticleBlobsState => {
  switch (action.type) {
    case FETCH_ARTICLE_BLOB:
      return {
        ...state,
        loadingById: { ...state.loadingById, [action.payload.articleId]: true },
      };
    case FETCH_ARTICLE_BLOB_SUCCESS: {
      const objectUrl = URL.createObjectURL(action.payload.blob);
      const { id } = action.payload;
      return {
        ...state,
        byId: { ...state.byId, [id]: { id, objectUrl } },
        loadingById: { ...state.loadingById, [id]: false },
      };
    }
    default:
      return state;
  }
};

export const articlesReducer = combineReducers({
  blobs: blobsReducer,
});

// selectors

const getIsLoading = (state: AppState, id?: string) =>
  id ? state.articles.blobs.loadingById[id] : undefined;

const getBlob = (state: AppState, id?: string) =>
  id ? state.articles.blobs.byId[id] : undefined;

export const selectArticleBlobState = (state: AppState, id?: string) => {
  return {
    blob: getBlob(state, id),
    isLoading: getIsLoading(state, id),
  };
};
