import React from 'react';

export const GridSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    enableBackground="new 0 0 32 32"
    height="512"
    viewBox="0 0 32 32"
    width="512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Layer_1">
      <g>
        <path
          d="m27.531 30h-8.062c-1.361 0-2.469-1.108-2.469-2.469v-10.062c0-1.361 1.108-2.469 2.469-2.469h8.062c1.361 0 2.469 1.108 2.469 2.469v10.062c0 1.361-1.108 2.469-2.469 2.469zm-8.062-13c-.259 0-.469.21-.469.469v10.062c0 .259.21.469.469.469h8.062c.259 0 .469-.21.469-.469v-10.062c0-.259-.21-.469-.469-.469z"
          fill="currentColor"
        />
      </g>
      <g>
        <path
          d="m27.531 13h-8.062c-1.361 0-2.469-1.108-2.469-2.469v-6.062c0-1.361 1.108-2.469 2.469-2.469h8.062c1.361 0 2.469 1.108 2.469 2.469v6.062c0 1.361-1.108 2.469-2.469 2.469zm-8.062-9c-.259 0-.469.21-.469.469v6.062c0 .259.21.469.469.469h8.062c.259 0 .469-.21.469-.469v-6.062c0-.259-.21-.469-.469-.469z"
          fill="currentColor"
        />
      </g>
      <g>
        <path
          d="m12.531 17h-8.062c-1.361 0-2.469-1.108-2.469-2.469v-10.062c0-1.361 1.108-2.469 2.469-2.469h8.062c1.361 0 2.469 1.108 2.469 2.469v10.062c0 1.361-1.108 2.469-2.469 2.469zm-8.062-13c-.259 0-.469.21-.469.469v10.062c0 .259.21.469.469.469h8.062c.259 0 .469-.21.469-.469v-10.062c0-.259-.21-.469-.469-.469z"
          fill="currentColor"
        />
      </g>
      <g>
        <path
          d="m12.531 30h-8.062c-1.361 0-2.469-1.108-2.469-2.469v-6.062c0-1.361 1.108-2.469 2.469-2.469h8.062c1.361 0 2.469 1.108 2.469 2.469v6.062c0 1.361-1.108 2.469-2.469 2.469zm-8.062-9c-.259 0-.469.21-.469.469v6.062c0 .259.21.469.469.469h8.062c.259 0 .469-.21.469-.469v-6.062c0-.259-.21-.469-.469-.469z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);

export default GridSvg;
