import {
  addDoc,
  collection,
  doc,
  Firestore,
  getDoc,
  getFirestore,
  orderBy,
  query,
  runTransaction,
  setDoc,
  Timestamp,
  updateDoc,
  where,
} from 'firebase/firestore';
import { collectionData, docData } from 'rxfire/firestore';
import { empty, of } from 'rxjs';
import { catchError, delay, map, mergeMap, retryWhen, take } from 'rxjs/operators';

import { Game, Response, Vote } from '../backendService';
import { NotificationPreferences, UserType } from '../db';
import { GeneralUserFeedback, Participant, WhatsNewState } from './types';
export default class Database {
  private store: Firestore;
  private tenantId: string;
  private now = () => Timestamp.now();

  static instance: Database;

  constructor() {
    const db = getFirestore();
    this.store = db;
    const tenantId = 'not-initialised';
    this.tenantId = tenantId;

    if (Database.instance) {
      return Database.instance;
    }
    Database.instance = this;
    return this;
  }

  public setTenantId(tenantId: string) {
    this.tenantId = tenantId;
  }

  private root() {
    if (this.tenantId === 'not-initialised') {
      throw new Error('Tenant not initialised');
    }
    return doc(this.store, 'tenants', this.tenantId);
  }
  private usertypeRef() {
    return collection(this.root(), 'usertype');
  }

  private publicRef() {
    return collection(this.root(), 'public');
  }

  public submitGeneralUserFeedback = (feedback: GeneralUserFeedback) => {
    return addDoc(collection(this.root(), 'generalUserFeedback'), {
      ...feedback,
      createdAt: this.now(),
    });
  };

  game = {
    voteListener: (path: string) => {
      return collectionData(query(collection(this.store, path))).pipe(
        map(docs => docs as { count: number }[]),
        map(res => res.reduce((acc, cur) => acc + cur.count, 0)),
        catchError(() => {
          return empty();
        }),
      );
    },
    roundCompletedByParticipants: (gameId: string, roundId: string) => {
      const responsesRef = collection(
        this.root(),
        'games',
        gameId,
        'rounds',
        roundId,
        'roundCompletedByParticipant',
      );
      const q = query(responsesRef);
      return collectionData(q).pipe(
        map(docs => docs as { uid: string; timestamp: number }[]),
        catchError(() => {
          return empty();
        }),
      );
      // return fromCollectionRef(
      //   this.root()
      //     .collection('games')
      //     .doc(gameId)
      //     .collection('rounds')
      //     .doc(roundId)
      //     .collection('roundCompletedByParticipant'),
      // ).pipe(
      //   map(col =>
      //     col.docs.map(doc => {
      //       const d = doc.data() as { uid: string; timestamp: number };
      //       return d;
      //     }),
      //   ),
      //   catchError(e => {

      //     return empty();
      //   }),
      // );
    },
    listGameParticipants: (gameId: string) => {
      const responsesRef = collection(this.root(), 'games', gameId, 'participants');
      const q = query(responsesRef, where('isKicked', '==', false));
      return collectionData(q).pipe(
        map(docs => docs as Participant[]),
        catchError(() => {
          return empty();
        }),
      );
      // return fromCollectionRef(
      //   this.root()
      //     .collection('games')
      //     .doc(gameId)
      //     .collection('participants'),
      // ).pipe(
      //   map(col =>
      //     col.docs.map(doc => {
      //       const d = doc.data() as Participant;
      //       return d;
      //     }),
      //   ),
      //   catchError(e => {

      //     return empty();
      //   }),
      // );
    },
    listResponses: (gameId: string, roundId: string) => {
      const responsesRef = collection(
        this.root(),
        'games',
        gameId,
        'rounds',
        roundId,
        'responses',
      );
      const q = query(responsesRef, orderBy('timestamp', 'desc'));
      return collectionData(q).pipe(
        map(docs => docs as Response[]),
        catchError(() => {
          return empty();
        }),
      );
      // return fromCollectionRef(
      //   this.root()
      //     .collection('games')
      //     .doc(gameId)
      //     .collection('rounds')
      //     .doc(roundId)
      //     .collection('responses')
      //     .orderBy('timestamp', 'desc'),
      // ).pipe(
      //   map(col =>
      //     col.docs.map(doc => {
      //       const d = doc.data() as Response;
      //       return d;
      //     }),
      //   ),
      //   catchError(e => {
      //     return empty();
      //   }),
      // );
    },
    listVotes: (gameId: string, roundId: string) => {
      return collectionData(
        collection(this.root(), 'games', gameId, 'rounds', roundId, 'votes'),
      ).pipe(
        map(docs => docs as Vote[]),
        catchError(() => {
          return empty();
        }),
      );
      // return fromCollectionRef(
      //   this.root()
      //     .collection('games')
      //     .doc(gameId)
      //     .collection('rounds')
      //     .doc(roundId)
      //     .collection('votes'),
      // ).pipe(
      //   map(col =>
      //     col.docs.map(doc => {
      //       const d = doc.data() as Vote;
      //       return d;
      //     }),
      //   ),
      //   catchError(e => {
      //     return empty();
      //   }),
      // );
    },
    onChange: (gameId: string) => {
      return docData(doc(this.root(), 'games', gameId)).pipe(map(d => d as Game));
      // return fromDocRef(
      //   this.root()
      //     .collection('games')
      //     .doc(gameId),
      // ).pipe(map(d => d.data() as Game));
    },
  };

  whatsnew = {
    hasSeen: {
      get: async (uid: string) => {
        const d = await getDoc(doc(this.root(), 'whatsnew', uid));
        return d.data() as WhatsNewState | undefined;
        // return doc.data()
        // const d = await this.root()
        //   .collection('whatsnew')
        //   .doc(uid)
        //   .get();
      },
      set: async (uid: string, val: number) => {
        await setDoc(doc(this.root(), 'whatsnew', uid), {
          hasSeenVersion: val,
          timestamp: new Date().getTime(),
        });
        // await this.root()
        //   .collection('whatsnew')
        //   .doc(uid)
        //   .set({
        //     hasSeenVersion: val,
        //     timestamp: new Date().getTime(),
        //   });
      },
    },
  };

  usertype = {
    get: (uid: string) =>
      docData(doc(this.usertypeRef(), uid)).pipe(
        mergeMap(usertype => {
          if (!usertype)
            throw Error(`Something is wrong. Cannot find usertype: (${uid})`);
          let lastSeenInfoBanner = usertype.lastSeenInfoBanner;
          if (lastSeenInfoBanner) lastSeenInfoBanner = lastSeenInfoBanner.toDate();
          return of({
            ...usertype,
            lastSeenInfoBanner,
          } as UserType);
        }),
        retryWhen(errors => errors.pipe(delay(1000), take(3))),
      ),
    // fromDocRef(this.usertypeRef().doc(uid)).pipe(
    //   mergeMap(d => {
    //     const usertype = d.data();
    //     if (!usertype)
    //       throw Error(`Something is wrong. Cannot find usertype: (${uid})`);
    //     let lastSeenInfoBanner = usertype.lastSeenInfoBanner;
    //     if (lastSeenInfoBanner) lastSeenInfoBanner = lastSeenInfoBanner.toDate();
    //     return of({
    //       ...usertype,
    //       lastSeenInfoBanner,
    //     } as UserType);
    //   }),
    //   retryWhen(errors => errors.pipe(delay(1000), take(3))),
    // ),
    setNotificationsSkipped: async (uid: string) => {
      await updateDoc(doc(this.usertypeRef(), uid), {
        notificationsSkipped: new Date().getTime(),
      });
      // await this.usertypeRef()
      //   .doc(uid)
      //   .update({ notificationsSkipped: new Date().getTime() });
    },
    setNotificationToken: async (token: string, uid: string) => {
      try {
        await runTransaction(this.store, async t => {
          const dataRef = doc(this.usertypeRef(), uid);
          const existingInstances = await t.get(dataRef);
          const data = existingInstances.exists() && existingInstances.data();
          let instanceIds = (data && data.instanceIds) || {};
          const exists = Object.values(instanceIds).some(id => id === token);
          if (!exists) {
            instanceIds = { ...instanceIds, [new Date().getTime()]: token };
          }
          await t.update(dataRef, { instanceIds });
        });
      } catch (e) {
        throw e;
      }
      // this.store.runTransaction(async t => {
      //   //Run transactional because of multiple devices
      //   const existingInstances = await t.get(this.usertypeRef().doc(uid));
      //   const data = existingInstances.exists && existingInstances.data();
      //   let instanceIds = (data && data.instanceIds) || {};
      //   const exists = Object.values(instanceIds).some(id => id === token);
      //   if (!exists) {
      //     instanceIds = { ...instanceIds, [new Date().getTime()]: token };
      //   }
      //   await t.update(this.usertypeRef().doc(uid), { instanceIds });
      // });
    },
    setNickName: async (nickName: string, uid: string) => {
      await updateDoc(doc(this.usertypeRef(), uid), { nickName });
      // await this.usertypeRef()
      //   .doc(uid)
      //   .update({ nickName });
    },
    setLastSeenInfoBanner: async (uid: string) => {
      await updateDoc(doc(this.usertypeRef(), uid), { lastSeenInfoBanner: this.now() });
      // await this.usertypeRef()
      //   .doc(uid)
      //   .update({ lastSeenInfoBanner: this.now() });
    },
    setNotificationPreferences: async (
      uid: string,
      preferences: NotificationPreferences,
    ) => {
      await updateDoc(doc(this.usertypeRef(), uid), {
        notificationPreferences: preferences,
      });

      // await this.usertypeRef()
      //   .doc(uid)
      //   .update({
      //     notificationPreferences: preferences,
      //   });
    },
  };

  public getPublic = () =>
    collectionData(this.publicRef()).pipe(
      map(docs =>
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        docs.reduce<{ [id: string]: any }>((acc, cur) => ({ ...acc, [cur.id]: cur }), {}),
      ),
    );
  // fromCollectionRef(this.root().collection('public')).pipe(
  //   map(snap =>
  //     // eslint-disable-next-line @typescript-eslint/no-explicit-any
  //     snap.docs.reduce<{ [id: string]: any }>(
  //       (acc, cur) => ({ ...acc, [cur.id]: cur.data() }),
  //       {},
  //     ),
  //   ),
  // );

  public setDefaultLanguage = (lang: string) => {
    return setDoc(doc(this.publicRef(), 'defaultLanguage'), { lang });
    // return this.publicRef()
    //   .doc('defaultLanguage')
    //   .set({ lang });
  };

  public setServiceName = (name: string) => {
    return updateDoc(doc(this.publicRef(), 'custom'), { name });
    // return this.publicRef()
    //   .doc('custom')
    //   .update({ name });
  };
}
