import React from 'react';

export const StarStruckSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="30"
    height="31"
    viewBox="0 0 30 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.7846 17.7835C25.8176 23.7265 20.9877 28.5833 14.9967 28.6314C9.00568 28.6796 4.12219 23.9009 4.08914 17.9579C4.05609 12.0149 8.88599 7.15809 14.877 7.10993C20.8681 7.06176 25.7515 11.8405 25.7846 17.7835Z"
      fill="#FFA154"
    />
    <path
      d="M20.0918 6.12314C20.3408 6.80244 19.526 7.72574 18.2719 8.18537C17.0179 8.645 15.7994 8.46692 15.5504 7.78761C15.3014 7.1083 16.1162 6.18501 17.3703 5.72538C18.6244 5.26575 19.8428 5.44383 20.0918 6.12314Z"
      fill="#FFA154"
    />
    <path
      d="M15.685 3.62941C16.5485 4.13242 16.5004 5.82421 15.5777 7.40812C14.655 8.99203 13.207 9.86827 12.3436 9.36526C11.4801 8.86224 11.5282 7.17045 12.4509 5.58654C13.3736 4.00263 14.8216 3.12639 15.685 3.62941Z"
      fill="#FFA154"
    />
    <path
      d="M8.20502 19.4309H21.6719C21.4851 22.9219 18.5999 25.6567 15.104 25.6567H14.9377C11.4469 25.6567 8.53152 22.9961 8.21316 19.5198L8.20502 19.4309Z"
      fill="#282F33"
    />
    <path
      d="M11.0818 23.4548C11.698 24.2909 13.4914 24.9077 15.1254 24.9132C18.1243 24.9233 19.1442 23.4548 19.1442 23.4548C19.1442 23.4548 14.8335 20.2905 11.0818 23.4548Z"
      fill="#D84C42"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.4654 27.488C20.4571 27.4413 25.2875 22.7375 25.2545 16.9817C25.2418 14.7745 24.5158 12.7331 23.2875 11.0576C24.9191 12.909 25.9118 15.3214 25.927 17.9662C25.9608 23.8578 21.131 28.6717 15.1393 28.7183C10.8985 28.7514 7.21223 26.3873 5.40802 22.9138C7.36911 25.6987 10.6983 27.5173 14.4654 27.488Z"
      fill="#D84C42"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.9461 3.39367C16.8095 3.89668 16.7615 5.58847 15.8388 7.17239C15.4109 7.90691 14.87 8.48925 14.3268 8.85242C14.6642 8.52458 14.9842 8.11687 15.2581 7.64684C16.1808 6.06293 16.2288 4.37114 15.3654 3.86813C14.9024 3.59838 14.2712 3.72528 13.6431 4.14525C14.4425 3.36831 15.3389 3.03992 15.9461 3.39367ZM19.5855 5.89595C19.8345 6.57526 19.0197 7.49855 17.7656 7.95818C17.0708 8.21286 16.3868 8.27175 15.8727 8.15807C16.3521 8.53709 17.3328 8.57766 18.3364 8.20984C19.5905 7.75021 20.4053 6.82691 20.1563 6.1476C20.0453 5.84469 19.7415 5.64144 19.3277 5.54995C19.4474 5.64456 19.5358 5.76026 19.5855 5.89595Z"
      fill="#D84C42"
    />
    <path
      d="M22.5425 8.89389C22.3956 7.84822 20.9964 7.60151 20.5006 8.53385L19.7786 9.89176C19.5569 10.3087 19.092 10.5355 18.6269 10.4535L17.1123 10.1864C16.0724 10.0031 15.4054 11.2575 16.1389 12.0171L17.2073 13.1234C17.5353 13.4631 17.6073 13.9754 17.3856 14.3924L16.6636 15.7503C16.1679 16.6826 17.1548 17.7046 18.1039 17.2417L19.4862 16.5675C19.9106 16.3605 20.4201 16.4504 20.7481 16.79L21.8165 17.8963C22.55 18.6559 23.827 18.0331 23.68 16.9874L23.466 15.4645C23.4002 14.9968 23.6431 14.5401 24.0676 14.3331L25.4498 13.6589C26.3989 13.196 26.2012 11.789 25.1613 11.6057L23.6467 11.3386C23.1817 11.2566 22.8223 10.8845 22.7566 10.4168L22.5425 8.89389Z"
      fill="#FDDA5D"
    />
    <path
      d="M16.0795 11.9626C16.0795 11.9626 16.5692 12.4336 17.042 13.1818C17.2161 13.4574 17.1845 13.9415 17.042 14.2095L16.32 15.5674C15.6472 16.8328 16.9867 18.2198 18.2747 17.5916L19.657 16.9174C19.9298 16.7843 20.2573 16.8421 20.4682 17.0604L21.5365 18.1667C22.532 19.1976 24.2651 18.3523 24.0656 16.9332L23.8516 15.4103C23.8093 15.1096 23.8632 14.9248 24.0081 14.6356C24.2321 14.1887 25.0266 13.8616 25.0266 13.8616C25.0266 13.8616 24.2608 13.9465 23.8799 14.1896C23.4991 14.4327 23.3192 15.0582 23.4084 15.6928L23.4991 17.1071C23.5935 17.7793 22.6542 18.076 22.0438 17.5916C21.4334 17.1071 21.0449 16.3386 20.1861 16.1969C18.5179 15.9218 17.5813 17.4602 17.042 16.6242C16.392 15.6165 18.0068 14.474 17.4319 13.227C17.1373 12.5879 16.0795 11.9626 16.0795 11.9626Z"
      fill="#D84C42"
    />
    <path
      d="M24.994 13.8734C26.5886 13.5002 26.6103 11.6526 25.1994 11.4014L23.6853 11.1317C23.3864 11.0785 23.1558 10.8389 23.1141 10.5382L22.9026 9.01486C22.7056 7.5954 20.8073 7.25735 20.1323 8.52152L19.4823 9.71007C19.3393 9.97787 19.0856 10.4758 18.7868 10.4226C19.4177 10.5349 19.5706 10.2754 19.8725 9.71007L20.6359 8.52152C20.9556 7.9227 22.2729 8.1386 22.3662 8.81098L22.5867 10.1172C22.6748 10.752 22.8549 11.4468 23.4858 11.5591L25.4876 11.9154C26.2385 12.1569 25.944 13.4832 24.994 13.8734Z"
      fill="#D84C42"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.7052 10.779C20.9526 10.9018 21.0536 11.2018 20.9308 11.4492C20.6246 12.0662 19.9559 12.4159 19.2745 12.3154L17.753 12.091C17.4798 12.0507 17.291 11.7966 17.3313 11.5234C17.3716 11.2502 17.6257 11.0614 17.8989 11.1017L19.4204 11.3261C19.6733 11.3634 19.9214 11.2336 20.0351 11.0047C20.1578 10.7573 20.4578 10.6563 20.7052 10.779Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.5413 11.3181C17.7275 11.1141 18.0437 11.0998 18.2477 11.286L19.2881 12.2358C19.7893 12.7083 19.9293 13.4499 19.6349 14.0726C19.5168 14.3223 19.2188 14.429 18.9691 14.3109C18.7195 14.1929 18.6128 13.8948 18.7308 13.6452C18.8396 13.4151 18.7886 13.1413 18.6046 12.9659L17.5734 12.0245C17.3695 11.8383 17.3551 11.522 17.5413 11.3181Z"
      fill="white"
    />
    <path
      d="M7.45716 8.89389C7.60412 7.84822 9.00329 7.60151 9.49903 8.53385L10.221 9.89176C10.4427 10.3087 10.9077 10.5355 11.3728 10.4535L12.8873 10.1864C13.9272 10.0031 14.5942 11.2575 13.8607 12.0171L12.7924 13.1234C12.4643 13.4631 12.3923 13.9754 12.614 14.3924L13.3361 15.7503C13.8318 16.6826 12.8448 17.7046 11.8958 17.2417L10.5135 16.5675C10.0891 16.3605 9.57959 16.4504 9.25155 16.79L8.18322 17.8963C7.4497 18.6559 6.17273 18.0331 6.31969 16.9874L6.53372 15.4645C6.59945 14.9968 6.35658 14.5401 5.93214 14.3331L4.54986 13.6589C3.60078 13.196 3.79852 11.789 4.83842 11.6057L6.35298 11.3386C6.81803 11.2566 7.1774 10.8845 7.24312 10.4168L7.45716 8.89389Z"
      fill="#FDDA5D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.56554 9.51714C8.839 9.55557 9.02952 9.8084 8.99109 10.0819L8.77705 11.6048C8.68119 12.287 8.15698 12.8298 7.4786 12.9494L5.96404 13.2165C5.6921 13.2644 5.43277 13.0828 5.38482 12.8109C5.33686 12.5389 5.51845 12.2796 5.7904 12.2317L7.30495 11.9646C7.55669 11.9202 7.75121 11.7188 7.78679 11.4656L8.00082 9.94268C8.03926 9.66923 8.29209 9.4787 8.56554 9.51714Z"
      fill="white"
    />
    <path
      d="M13.9202 11.9626C13.9202 11.9626 13.4305 12.4336 12.9577 13.1818C12.7836 13.4574 12.8152 13.9415 12.9577 14.2095L13.6797 15.5674C14.3525 16.8328 13.0131 18.2198 11.725 17.5916L10.3427 16.9174C10.0699 16.7843 9.74237 16.8421 9.53149 17.0604L8.46316 18.1667C7.46767 19.1976 5.73463 18.3523 5.93408 16.9332L6.14811 15.4103C6.19036 15.1096 6.13652 14.9248 5.99155 14.6356C5.76756 14.1887 4.97308 13.8616 4.97308 13.8616C4.97308 13.8616 5.73892 13.9465 6.11977 14.1896C6.50063 14.4327 6.68049 15.0582 6.59129 15.6928L6.50063 17.1071C6.40616 17.7793 7.34547 18.076 7.95588 17.5916C8.56628 17.1071 8.95477 16.3386 9.81363 16.1969C11.4818 15.9218 12.4184 17.4602 12.9577 16.6242C13.6077 15.6165 11.9929 14.474 12.5678 13.227C12.8624 12.5879 13.9202 11.9626 13.9202 11.9626Z"
      fill="#D84C42"
    />
    <path
      d="M5.00572 13.8734C3.4111 13.5002 3.38943 11.6526 4.8003 11.4014L6.31441 11.1317C6.61329 11.0785 6.8439 10.8389 6.88564 10.5382L7.09708 9.01486C7.29411 7.5954 9.19242 7.25735 9.86736 8.52152L10.5174 9.71007C10.6604 9.97787 10.914 10.4758 11.2129 10.4226C10.582 10.5349 10.4291 10.2754 10.1272 9.71007L9.36384 8.52152C9.04414 7.9227 7.72684 8.1386 7.63351 8.81098L7.41298 10.1172C7.32487 10.752 7.14485 11.4468 6.51389 11.5591L4.51213 11.9154C3.76123 12.1569 4.05573 13.4832 5.00572 13.8734Z"
      fill="#D84C42"
    />
  </svg>
);

export default StarStruckSvg;
