export class Metrics {
  static instance: Metrics;

  constructor() {
    if (Metrics.instance) {
      return Metrics.instance;
    }
    Metrics.instance = this;
    return this;
  }

  static getLogger() {
    return {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      logEvent: (event: string, properties?: any) => {
        return { event, properties };
      },
      logGameEvent: (
        event: string,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        properties?: any,
        isPreview?: boolean | null,
        isDemoGame?: boolean | null,
      ) => {
        return { event, properties, isPreview, isDemoGame };
      },
    };
  }

  static identifyUrlUser(distinctId?: string) {
    return distinctId;
  }

  static init(token: string) {
    return token;
  }

  static setTenantId(tenantId: string) {
    return tenantId;
  }

  static logout() {
    return;
  }

  static setEmail(email?: string) {
    return email;
  }

  static setIdp(idp?: string) {
    return idp;
  }

  static setRole(role?: string) {
    return role;
  }

  static setUid(uid?: string) {
    return uid;
  }
}
