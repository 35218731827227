import { Divider, Select, Tooltip } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useIsDesktop } from '../../../hooks';
import useInvitation from '../../../hooks/useInvitation';
import {
  EmailInvitationCreateDTO,
  TopicInvitation,
} from '../../../services/backendService';
import { ModalSelect, SelectStyled } from '../../../styled/SelectStyled';
import theme from '../../../theme';
import { isEnterprise } from '../../../utils/loginUtils';
import { getViewableRole } from '../../../utils/randomUtils';
import { Button } from '../../_atoms/_Button';
import { CrossIcon, LinkIcon, QuestionMarkIcon } from '../../_atoms/_Icons';
import Label from '../../_atoms/Label';
import { InvitationButton } from '../InvitationLink/InvitationLink';

const { Option } = Select;

const helpfulEmailRegex = new RegExp(
  `[a-zA-Z0-9.!#$%&amp;^_{}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*`,
);

const containsInvalidEmail = (emails: string[]) =>
  emails.some(email => !helpfulEmailRegex.test(email));

const InvitationLinkSettings = ({
  onDone,
  duration,
}: {
  duration?: number;
  onDone: (invitationDuration: number) => void;
}) => {
  const [invitationDuration, setInvitationDuration] = useState(14 * 24);

  useEffect(() => {
    if (duration !== undefined) {
      setInvitationDuration(duration);
    }
  }, [duration]);

  return (
    <>
      <h1>Invitation link settings</h1>
      <div style={{ width: '100%' }}>
        <p style={{ fontWeight: 'bold', color: theme.colors.black }}>Expires in</p>
        <ModalSelect
          style={{ width: '100%' }}
          onChange={e => setInvitationDuration(e as number)}
          value={invitationDuration}
        >
          <Option value={2}>2 hours</Option>
          <Option value={12}>12 hours</Option>
          <Option value={7 * 24}>7 days</Option>
          <Option value={14 * 24}>14 days</Option>
          <Option value={-1}>Never</Option>
        </ModalSelect>
        <div
          style={{
            marginTop: 10,
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
          }}
        >
          <Button onClick={() => onDone(invitationDuration)}>Generate new link</Button>
        </div>
      </div>
    </>
  );
};

function sanitizeEmail(email: string) {
  // When pasting from windows, a newline might look like: \r\n instead of \n. Antd doesnt handle this, and thus we need to handle it
  return email.replace('\r', '');
}

const InvitationModal = ({
  visible,
  onDone,
  role,
  topic,
  isSignup,
  hideValidity = false,
  title,
}: {
  visible: boolean;
  onDone: () => void;
  role: 'student' | 'mentor';
  topic?: TopicInvitation;
  isSignup?: boolean;
  hideValidity?: boolean;
  title?: string;
}) => {
  const isDesktop = useIsDesktop();
  const [val, setVal] = useState<string[]>([]);
  const { t } = useTranslation('Host');
  const [invitationDuration, setInvitationDuration] = useState(
    isEnterprise() ? -1 : 14 * 24,
  );
  const [isEditingInvitation, setIsEditingDuration] = useState(false);

  const {
    url,
    bulkInvite,
    error: inviteError,
    isLoading,
    isSuccess,
    isFetching,
  } = useInvitation(role, topic, invitationDuration);
  const handleSend = () => {
    if (val.length === 0) {
      setError(t('Please enter one or more email addresses to invite new users'));
      return;
    }
    if (containsInvalidEmail(val)) {
      setError(t('Atleast one of the provided emails are not valid'));
      return;
    }
    const payloads = val.map(email => {
      const p: EmailInvitationCreateDTO = {
        email: sanitizeEmail(email),
        role,
        topic,
      };
      return p;
    });
    bulkInvite(payloads);
  };

  useEffect(() => {
    if (inviteError) {
      setError(t('An error occurred, please try again'));
    }
  }, [inviteError, t]);

  const handleChange = (e: string[]) => {
    const hey = new Set([...e]);
    const yo = Array.from(hey);
    setVal(yo);
  };
  const CustomTagRenderer = (props: {
    value: string;
    closable: boolean;
    onClose: () => void;
  }) => {
    const { value, closable, onClose } = props;

    const isValidEmail = helpfulEmailRegex.test(value);
    return (
      <Label
        backgroundColor={isValidEmail ? theme.colors.green : theme.colors.red}
        color={theme.colors.white}
        text={value}
        closable={closable}
        onClose={onClose}
        style={{
          fontSize: 13,
          fontWeight: 'bold',
          marginRight: 5,
          marginTop: 2,
          marginBottom: 2,
        }}
      />
    );
  };

  const [error, setError] = useState<string>();

  useEffect(() => {
    if (isSuccess) {
      onDone();
    }
  }, [isSuccess, onDone]);

  return (
    <ModalStyled
      visible={visible}
      centered
      destroyOnClose
      footer={null}
      closable={false}
      bodyStyle={{ padding: 0 }}
      onCancel={() => onDone()}
      onOk={() => onDone()}
    >
      <ModalContainer>
        <CrossIconWrapper onClick={() => onDone()}>
          <CrossIcon />
        </CrossIconWrapper>
        {isEditingInvitation && (
          <InvitationLinkSettings
            onDone={duration => {
              setInvitationDuration(duration);
              setIsEditingDuration(false);
            }}
          />
        )}
        {!isEditingInvitation && (
          <>
            <h1>{title || t('Invite')}</h1>
            <div style={{ width: '100%' }}>
              <p style={{ fontSize: 16, fontWeight: 'bold' }}>{t('Emails')}</p>
              <SelectStyled
                autoFocus
                mode="tags"
                placeholder={
                  t(
                    'youCanCopy',
                    'You can copy and paste a list of emails...',
                  ) /*TODO: WHY DOES THIS NOT RENDER*/
                }
                tokenSeparators={[',', ' ', '\r\n', '\n', '\t']}
                style={{ width: '100%', maxHeight: '250px', overflowY: 'auto' }}
                value={val}
                onChange={e => handleChange(e as string[])}
                tagRender={({ closable, onClose, value }) => (
                  <CustomTagRenderer
                    closable={closable}
                    onClose={onClose}
                    value={value as string}
                  />
                )}
                dropdownRender={() => <></>}
                dropdownStyle={{ display: 'none' }}
              />
              <p
                style={{
                  fontSize: 14,
                  fontWeight: 'normal',
                  marginTop: 15,
                  marginBottom: 15,
                }}
              >
                {t(
                  'weWillSendAn',
                  'We will send an email invitation with a link to each of the emails above.',
                )}
              </p>
              <HorizontalWrapper
                style={{ justifyContent: 'space-between', marginTop: 10 }}
              >
                <Button type="primary" onClick={handleSend} loading={isLoading}>
                  {isDesktop ? t('Send invitations') : t('Send')}
                </Button>
                {error && (
                  <p
                    style={{
                      flex: 1,
                      fontSize: 14,
                      fontWeight: 'normal',
                      color: theme.colors.red,
                      padding: '0px 30px',
                      marginBottom: 0,
                    }}
                  >
                    {error}
                  </p>
                )}
              </HorizontalWrapper>
            </div>
            <Divider style={{ borderTop: `2px solid #d9d9d9` }} type="horizontal" />
            {role && (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <HorizontalWrapper>
                  <IconWrapper $height={22} $width={22}>
                    <LinkIcon />
                  </IconWrapper>
                  <p
                    style={{
                      marginLeft: 10,
                      fontSize: 16,
                      fontWeight: 'bold',
                      marginBottom: 0,
                      whiteSpace: 'nowrap',
                      flex: 0,
                      marginRight: 3,
                    }}
                  >
                    {t('Invite link')}
                  </p>
                  <Tooltip
                    title={
                      topic
                        ? t('PeopleWithThisLinkJoinPod', {
                            defaultValue:
                              'People with this link can join the pod as {{type}}',
                            type: getViewableRole(topic.type),
                          })
                        : t('People with this link can join this tenant as a teacher')
                    }
                    trigger={isDesktop ? 'hover' : 'click'}
                    zIndex={99999}
                  >
                    <IconWrapper $height={16} $width={16}>
                      <QuestionMarkIcon />
                    </IconWrapper>
                  </Tooltip>
                </HorizontalWrapper>
                <InvitationButton isFetching={isFetching} isSignup={isSignup} url={url} />
              </div>
            )}
            {!hideValidity && (
              <ValidText>
                {invitationDuration === -1 && t('You invitation link will never expire.')}
                {invitationDuration !== -1 &&
                  `${'Your invitation link expires in'} ${
                    invitationDuration > 24
                      ? `${invitationDuration / 24} ${t('days')}`
                      : `${invitationDuration} ${t('hours')}`
                  }. `}
                <HoverableTextButton onClick={() => setIsEditingDuration(true)}>
                  {t('Edit invitation link')}
                </HoverableTextButton>
              </ValidText>
            )}
          </>
        )}
      </ModalContainer>
    </ModalStyled>
  );
};

const HoverableTextButton = styled.span`
  color: ${theme.colors.green};
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  &:hover {
    text-decoration: underline;
  }
`;

const CrossIconWrapper = styled.div`
  color: ${theme.colors.black};
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    height: 20px;
    width: 20px;
  }
  cursor: pointer;
  border-radius: 50px;
  padding: 5px;
  &:hover {
    background: rgba(0, 0, 0, 0.3);
  }
  top: 20px;
  right: 20px;
  position: absolute;
`;

const IconWrapper = styled.div<{ $height: number; $width: number }>`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${theme.colors.black};
  svg {
    height: ${({ $height }) => `${$height}px`};
    width: ${({ $width }) => `${$width}px`};
  }
`;

const HorizontalWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ValidText = styled.p`
  margin-bottom: 0px;
  width: 100%;
  text-align: left;
  margin-top: 10px;
  font-size: 12px;
  color: ${theme.colors.text.darkGrey};
  font-weight: bold;
`;

const ModalContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border-radius: 10px;
  h1 {
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
  }
`;

const ModalStyled = styled(Modal)`
  .ant-modal-content {
    border-radius: 10px;
    box-shadow: none;
  }
  .ant-modal-body {
    box-shadow: none;
    border-radius: 10px;
    background-color: ${theme.colors.background};
  }
`;

export default InvitationModal;
