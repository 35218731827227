import React from 'react';

export const HeartEmojiSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="30"
    height="31"
    viewBox="0 0 30 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.0714 17.5579C26.1052 23.6235 21.1757 28.5804 15.0611 28.6296C8.9465 28.6788 3.9623 23.8015 3.92857 17.736C3.89484 11.6704 8.82434 6.71344 14.9389 6.66428C21.0535 6.61512 26.0377 11.4924 26.0714 17.5579Z"
      fill="#FFA154"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.4325 27.2486C20.5478 27.2009 25.4778 22.4001 25.4441 16.5257C25.4354 15.0166 25.1 13.5835 24.5027 12.2877C25.5255 13.886 26.1198 15.766 26.1314 17.7814C26.1651 23.6559 21.2351 28.4567 15.1199 28.5043C10.5754 28.5397 6.65535 25.9402 4.92743 22.1916C6.88584 25.2521 10.4152 27.2798 14.4325 27.2486Z"
      fill="#D84C42"
    />
    <path
      d="M20.1229 6.2299C20.377 6.92322 19.5454 7.86555 18.2654 8.33467C16.9855 8.80378 15.7419 8.62202 15.4878 7.9287C15.2337 7.23539 16.0653 6.29305 17.3452 5.82394C18.6252 5.35482 19.8687 5.53658 20.1229 6.2299Z"
      fill="#FFA154"
    />
    <path
      d="M15.874 3.64831C16.7552 4.1617 16.7062 5.88837 15.7644 7.50495C14.8227 9.12152 13.3448 10.0158 12.4636 9.50245C11.5823 8.98906 11.6314 7.26238 12.5731 5.64581C13.5149 4.02923 14.9927 3.13492 15.874 3.64831Z"
      fill="#FFA154"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.8802 3.61193C16.7614 4.12531 16.7124 5.85199 15.7706 7.46857C15.3338 8.21849 14.7815 8.81299 14.227 9.18362C14.5714 8.84896 14.8982 8.43272 15.1778 7.95283C16.1195 6.33625 16.1685 4.60957 15.2873 4.09619C14.8148 3.82096 14.1709 3.95031 13.5301 4.37864C14.3459 3.58594 15.2605 3.25094 15.8802 3.61193ZM19.5941 6.16582C19.8483 6.85914 19.0167 7.80147 17.7367 8.27058C17.0275 8.53051 16.3295 8.59061 15.8048 8.4746C16.2941 8.86135 17.295 8.90272 18.3192 8.52735C19.5991 8.05824 20.4307 7.1159 20.1766 6.42258C20.0633 6.11342 19.7532 5.90598 19.3309 5.81261C19.4531 5.90918 19.5434 6.02729 19.5941 6.16582Z"
      fill="#D84C42"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.1988 19.2029L10.1957 19.2059V19.2L7.00187 16.0908C5.77836 14.8998 6.15503 13.0714 7.71892 12.6102C8.86172 12.2732 10.1281 13.122 10.1988 14.2389C10.2695 13.1219 11.5359 12.2731 12.6787 12.6101C14.2426 13.0713 14.6193 14.8997 13.3958 16.0907L10.2019 19.1998V19.206L10.1988 19.2029Z"
      fill="#C61608"
    />
    <path
      d="M8.92953 14.9875C8.97038 15.4072 8.4863 15.6695 8.15706 15.406L7.56636 14.9332C7.00334 14.4826 7.08686 13.6987 7.72537 13.4408C8.20554 13.2469 8.79209 13.5756 8.83975 14.0652L8.92953 14.9875Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.7864 19.203L19.7834 19.2059V19.2002L16.5894 16.0909C15.3659 14.8998 15.7426 13.0714 17.3064 12.6103C18.4497 12.2731 19.7166 13.1227 19.7864 14.2403C19.8561 13.1226 21.1231 12.273 22.2664 12.6101C23.8303 13.0713 24.207 14.8997 22.9835 16.0907L19.7895 19.2V19.2061L19.7864 19.203Z"
      fill="#C61608"
    />
    <path
      d="M10.063 20.4928H19.9403C19.8033 23.0532 17.6872 25.0591 15.1231 25.0591H15.0012C12.4408 25.0591 10.3025 23.1077 10.069 20.558L10.063 20.4928Z"
      fill="#282F33"
    />
    <path
      d="M12.1735 23.4442C12.6255 24.0575 13.9408 24.5099 15.1392 24.5139C17.3388 24.5213 18.0868 23.4442 18.0868 23.4442C18.0868 23.4442 14.9251 21.1234 12.1735 23.4442Z"
      fill="#D84C42"
    />
    <path
      d="M18.5208 15.1482C18.5617 15.5679 18.0776 15.8301 17.7484 15.5666L17.1577 15.0939C16.5946 14.6433 16.6782 13.8593 17.3167 13.6015C17.7968 13.4076 18.3834 13.7362 18.431 14.2258L18.5208 15.1482Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7932 15.6877C12.0275 15.8865 12.0562 16.2375 11.8574 16.4718C11.7729 16.5713 11.456 16.8949 11.1878 17.1686C11.1621 17.1948 11.1365 17.2209 11.1113 17.2466C10.9986 17.3616 10.8928 17.4695 10.8095 17.5552C10.7583 17.6077 10.7172 17.6502 10.6888 17.6801C10.6782 17.6912 10.6705 17.6994 10.6653 17.7051C10.4963 17.9133 10.1965 17.9742 9.95654 17.8363C9.69019 17.6832 9.59836 17.3432 9.75144 17.0768C9.77145 17.042 9.79145 17.0163 9.79595 17.0106C9.79611 17.0104 9.79624 17.0102 9.79636 17.01C9.80401 17.0002 9.81074 16.9922 9.81486 16.9873C9.82332 16.9774 9.83161 16.9682 9.83777 16.9614C9.85064 16.9473 9.86623 16.9307 9.88249 16.9136C9.91562 16.8787 9.96048 16.8323 10.0122 16.7792C10.0971 16.692 10.2047 16.5822 10.3175 16.4672C10.3425 16.4416 10.3678 16.4158 10.3932 16.3899C10.5335 16.2467 10.6761 16.101 10.7919 15.9813C10.8499 15.9214 10.9001 15.8691 10.9394 15.8274C10.9591 15.8066 10.9753 15.7893 10.9879 15.7755C10.9942 15.7687 10.9992 15.7631 11.0031 15.7588L11.0075 15.7538L11.0088 15.7524L11.0091 15.752C11.0091 15.7519 11.0092 15.7519 11.0092 15.7519L11.0092 15.7519C11.208 15.5176 11.559 15.4889 11.7932 15.6877Z"
      fill="white"
      fillOpacity="0.6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.3462 15.8484C21.5804 16.0472 21.6092 16.3982 21.4104 16.6324C21.3258 16.732 21.009 17.0555 20.7408 17.3292C20.7151 17.3554 20.6895 17.3815 20.6643 17.4072C20.5516 17.5222 20.4458 17.6301 20.3624 17.7158C20.3113 17.7683 20.2702 17.8109 20.2418 17.8408C20.2312 17.8519 20.2235 17.86 20.2183 17.8657C20.0493 18.0739 19.7495 18.1348 19.5095 17.9969C19.2432 17.8438 19.1513 17.5038 19.3044 17.2374C19.3244 17.2026 19.3444 17.177 19.3489 17.1712C19.3491 17.171 19.3492 17.1708 19.3493 17.1707C19.357 17.1608 19.3637 17.1528 19.3678 17.148C19.3763 17.138 19.3846 17.1288 19.3907 17.122C19.4036 17.1079 19.4192 17.0913 19.4355 17.0742C19.4686 17.0394 19.5135 16.993 19.5652 16.9398C19.6501 16.8526 19.7577 16.7429 19.8705 16.6278C19.8955 16.6022 19.9208 16.5764 19.9462 16.5505C20.0865 16.4073 20.2291 16.2616 20.3449 16.1419C20.4029 16.082 20.4531 16.0297 20.4924 15.9881C20.5121 15.9673 20.5283 15.9499 20.5409 15.9362C20.5472 15.9293 20.5522 15.9238 20.556 15.9195L20.5605 15.9144L20.5617 15.913L20.5621 15.9126C20.5621 15.9126 20.5622 15.9125 20.5622 15.9125L20.5622 15.9125C20.761 15.6783 21.112 15.6496 21.3462 15.8484Z"
      fill="white"
      fillOpacity="0.6"
    />
  </svg>
);

export default HeartEmojiSvg;
