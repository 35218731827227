import { combineReducers } from 'redux';

import {
  adminReducer,
  environmentReducer,
  notificationReducer,
  RootAction,
} from '../modules';
import { articlesReducer } from './Articles';
import { serverReducer } from './Server';
import { userReducer } from './User';
import { userTypeReducer } from './UserType';

const appReducer = combineReducers({
  notification: notificationReducer,
  userType: userTypeReducer,
  user: userReducer,
  env: environmentReducer,
  server: serverReducer,
  admin: adminReducer,
  articles: articlesReducer,
});

export type AppState = ReturnType<typeof appReducer>;

export const rootReducer = (state: AppState, action: RootAction) => {
  if (action.type === 'RESET_APP') {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
