import { combineReducers } from 'redux';

import { RoleUser } from '../../services/backendService';
import { RootAction } from '..';
import { AppState } from '../rootReducer';
import {
  ADMIN_DELETE_USER_SUCCESS,
  CONVERT_TO_MENTOR,
  CONVERT_TO_MENTOR_FAILURE,
  CONVERT_TO_MENTOR_SUCCESS,
  FETCH_USERS,
  RECEIVE_USERS,
} from './actionTypes';

export type ById<T> = {
  [id: string]: T;
};
interface AdminState {
  hasFetched: HasFetched;
  isLoading: AdminLoading;
  users: RoleUser[];
  usersById: ById<RoleUser>;
}
interface HasFetched {
  users: boolean;
}
interface AdminLoading {
  loadingUsers: boolean;
  convertingMentor: boolean;
}
const initAdminLoading: AdminLoading = {
  loadingUsers: false,
  convertingMentor: false,
};
const initState: AdminState = {
  hasFetched: { users: false },
  users: [],
  usersById: {},
  isLoading: initAdminLoading,
};
const oldAdminReducer = (
  state: AdminState = initState,
  action: RootAction,
): AdminState => {
  switch (action.type) {
    case FETCH_USERS:
      return {
        ...state,
        users: [],
        usersById: {},
        isLoading: {
          ...state.isLoading,
          loadingUsers: true,
        },
      };
    case RECEIVE_USERS:
      //prevent overiding of filteredList
      return {
        ...state,
        users: action.payload.users,
        usersById: action.payload.byId,
        isLoading: {
          ...state.isLoading,
          loadingUsers: false,
        },
        hasFetched: {
          ...state.hasFetched,
          users: true,
        },
      };
    case CONVERT_TO_MENTOR:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          convertingMentor: true,
        },
      };
    case CONVERT_TO_MENTOR_SUCCESS:
      return {
        ...state,
        users: state.users.map(user =>
          user.id === action.payload.id ? action.payload : user,
        ),
        isLoading: {
          ...state.isLoading,
          convertingMentor: false,
        },
      };
    case CONVERT_TO_MENTOR_FAILURE:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          convertingMentor: false,
        },
      };
    case ADMIN_DELETE_USER_SUCCESS: {
      const users = state.users.filter(user => user.id !== action.payload.uid);
      return {
        ...state,
        users,
      };
    }
    default:
      return state;
  }
};

export const adminReducer = combineReducers({
  admin: oldAdminReducer,
});

// selectors
const getAdmin = (state: AppState) => state.admin.admin;
export const getAdminUsers = (state: AppState) => getAdmin(state).users;

export const getHasFetched = (state: AppState) => {
  return getAdmin(state).hasFetched.users;
};
export const getIsLoadingAdmin = (state: AppState) => getAdmin(state).isLoading;
