import React from 'react';

export const QuestionsSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5156 12.4609C13.2999 12.4609 13.125 12.6358 13.125 12.8516V15.5469C13.125 16.6238 12.2488 17.5 11.1719 17.5H6.95437C6.73871 17.5 6.56391 17.6747 6.56375 17.8904L6.5632 18.667L5.63086 17.7347C5.57051 17.5961 5.43238 17.5 5.27262 17.5H2.73438C1.65742 17.5 0.78125 16.6238 0.78125 15.5469V9.53125C0.78125 8.4543 1.65742 7.57812 2.73438 7.57812H6.67969C6.89539 7.57812 7.07031 7.40324 7.07031 7.1875C7.07031 6.97176 6.89539 6.79688 6.67969 6.79688H2.73438C1.22664 6.79688 0 8.02352 0 9.53125V15.5469C0 17.0546 1.22664 18.2812 2.73438 18.2812H5.07258L6.67691 19.8856C6.75164 19.9603 6.85152 20 6.9532 20C7.00348 20 7.05422 19.9903 7.10246 19.9703C7.24844 19.9099 7.34363 19.7676 7.34375 19.6096L7.34469 18.2812H11.1719C12.6796 18.2812 13.9062 17.0546 13.9062 15.5469V12.8516C13.9062 12.6358 13.7313 12.4609 13.5156 12.4609Z"
      fill="currentColor"
    />
    <path
      d="M14.3225 0H13.3338C10.2032 0 7.65625 2.54695 7.65625 5.67758C7.65625 8.8082 10.2032 11.3551 13.3338 11.3551H14.3224C14.8111 11.3551 15.2947 11.293 15.7634 11.1704L17.2239 12.63C17.2986 12.7046 17.3984 12.7443 17.5001 12.7443C17.5504 12.7443 17.6012 12.7346 17.6495 12.7146C17.7955 12.6541 17.8906 12.5117 17.8906 12.3537V10.0937C18.5089 9.59297 19.0226 8.96043 19.383 8.25379C19.7925 7.45121 20 6.58445 20 5.67758C20 2.54695 17.453 0 14.3225 0ZM17.265 9.59121C17.167 9.665 17.1094 9.78055 17.1094 9.90324V11.4111L16.1535 10.4557C16.0791 10.3814 15.9793 10.3413 15.8773 10.3413C15.839 10.3413 15.8004 10.347 15.7626 10.3586C15.2977 10.5015 14.8131 10.5739 14.3225 10.5739H13.3338C10.6339 10.5739 8.4375 8.37742 8.4375 5.67758C8.4375 2.97773 10.634 0.78125 13.3338 0.78125H14.3225C17.0223 0.78125 19.2188 2.97773 19.2188 5.67758C19.2188 7.22957 18.5066 8.65605 17.265 9.59121Z"
      fill="currentColor"
    />
    <path
      d="M15.5872 4.27423C15.5308 3.45646 14.8716 2.79724 14.0538 2.74087C13.5902 2.70904 13.1478 2.8654 12.8091 3.18154C12.4752 3.49329 12.2836 3.934 12.2836 4.39068C12.2836 4.60642 12.4586 4.7813 12.6743 4.7813C12.89 4.7813 13.0649 4.60642 13.0649 4.39068C13.0649 4.14619 13.1634 3.91958 13.3422 3.75267C13.5209 3.58591 13.7544 3.50337 14.0001 3.52033C14.4309 3.55001 14.7781 3.89724 14.8078 4.32798C14.8378 4.76302 14.5524 5.14747 14.1292 5.24216C13.7863 5.31888 13.5468 5.61685 13.5468 5.96673V6.90521C13.5468 7.12095 13.7218 7.29583 13.9375 7.29583C14.1532 7.29583 14.3281 7.12095 14.328 6.90521V5.99794C15.1152 5.80779 15.6432 5.08755 15.5872 4.27423Z"
      fill="currentColor"
    />
    <path
      d="M14.2137 8.17402C14.141 8.10137 14.0402 8.05957 13.9375 8.05957C13.8348 8.05957 13.734 8.10137 13.6613 8.17402C13.5887 8.24668 13.5469 8.34746 13.5469 8.4502C13.5469 8.55332 13.5887 8.6541 13.6613 8.72676C13.734 8.79941 13.8348 8.84082 13.9375 8.84082C14.0402 8.84082 14.141 8.79941 14.2137 8.72676C14.2863 8.65371 14.3281 8.55332 14.3281 8.4502C14.3281 8.34746 14.2863 8.24668 14.2137 8.17402Z"
      fill="currentColor"
    />
    <path
      d="M10.7422 12.1094H2.5C2.2843 12.1094 2.10938 12.2843 2.10938 12.5C2.10938 12.7157 2.2843 12.8906 2.5 12.8906H10.7422C10.9579 12.8906 11.1328 12.7157 11.1328 12.5C11.1328 12.2843 10.9579 12.1094 10.7422 12.1094Z"
      fill="currentColor"
    />
    <path
      d="M11.0183 14.4113C10.9457 14.3387 10.8449 14.2969 10.7422 14.2969C10.6395 14.2969 10.5387 14.3387 10.466 14.4113C10.3933 14.484 10.3516 14.5848 10.3516 14.6875C10.3516 14.7902 10.3934 14.891 10.466 14.9637C10.5387 15.0363 10.6395 15.0781 10.7422 15.0781C10.8449 15.0781 10.9457 15.0363 11.0183 14.9637C11.091 14.891 11.1328 14.7906 11.1328 14.6875C11.1328 14.5848 11.091 14.484 11.0183 14.4113Z"
      fill="currentColor"
    />
    <path
      d="M9.20574 14.2969H2.5C2.2843 14.2969 2.10938 14.4718 2.10938 14.6875C2.10938 14.9032 2.2843 15.0781 2.5 15.0781H9.20574C9.42149 15.0781 9.59637 14.9032 9.59637 14.6875C9.59637 14.4718 9.42145 14.2969 9.20574 14.2969Z"
      fill="currentColor"
    />
    <path
      d="M8.20312 9.92188H2.5C2.2843 9.92188 2.10938 10.0968 2.10938 10.3125C2.10938 10.5282 2.2843 10.7031 2.5 10.7031H8.20312C8.41887 10.7031 8.59375 10.5282 8.59375 10.3125C8.59375 10.0968 8.41883 9.92188 8.20312 9.92188Z"
      fill="currentColor"
    />
  </svg>
);

export default QuestionsSvg;
