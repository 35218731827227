import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';

import TopBar from '../components/_organisms/TopBar';
import Loader from '../components/Loader';
import PrivateRoute from '../hoc/PrivateRoute';
import { useMetrics, useTypedSelector } from '../hooks';
import useTenantValidator from '../hooks/useTenantValidator';
import { getRole } from '../modules';
import { checkHasRedirect, generateRedirect, removeRedirect } from '../utils/loginUtils';
import NotFound from './NotFound';

const AdminConfigurationPage = lazy(() =>
  import('./AdminConfigurationPage' /* webpackChunkName: "AdminConfigurationPage" */),
);

const AskQuestionPage = lazy(() =>
  import('./AskQuestionPage' /* webpackChunkName: "AskQuestionPage" */),
);
const AdminUsersPage = lazy(() =>
  import('./AdminUsersPage' /* webpackChunkName: "AdminUsersPage" */),
);
const AdminDiscoverPage = lazy(() =>
  import('./AdminDiscoverPage' /* webpackChunkName: "AdminDiscoverPage" */),
);

const Curimote = lazy(() => import('./Curimote' /* webpackChunkName: "Curimote" */));

const PodsPage = lazy(() => import('./PodsPage' /* webpackChunkName: "PodsPage" */));
const DiscoverPodsPage = lazy(() =>
  import('./DiscoverPodsPage' /* webpackChunkName: "PodsDiscoverPodsPagePage" */),
);

const PodPage = lazy(() => import('./PodPage' /* webpackChunkName: "PodPage" */));

const GenericFeedbackPage = lazy(() =>
  import('./GenericFeedbackPage' /* webpackChunkName: "GenericFeedbackPage" */),
);
const NotificationsMobilePage = lazy(() =>
  import('./NotificationsMobilePage' /* webpackChunkName: "NotificationsMobilePage" */),
);
const LiveGamePage = lazy(() =>
  import('./LiveGamePage' /* webpackChunkName: "LiveGamePage" */),
);

const LiveCreatorPage = lazy(() =>
  import('./LiveCreatorPage' /* webpackChunkName: "LiveCreatorPage" */),
);

const ProfilePage = lazy(() =>
  import('./ProfilePage' /* webpackChunkName: "ProfilePage" */),
);

const PreviewGamePage = lazy(() =>
  import('./PreviewGamePage' /* webpackChunkName: "PreviewGamePage" */),
);
const DemoGamePage = lazy(() =>
  import('./DemoGamePage' /* webpackChunkName: "DemoGamePage" */),
);

const ResultPage = lazy(() =>
  import('./ResultPage' /* webpackChunkName: "ResultPage" */),
);

const LivePage = lazy(() => import('./LivePage' /* webpackChunkName: "LivePage" */));

const WhatsNewPage = lazy(() =>
  import('./WhatsNewPage' /* webpackChunkName: "WhatsNewPage" */),
);

const LessonPreviewPage = lazy(() =>
  import('./LessonPreviewPage' /* webpackChunkName: "LessonPreviewPage" */),
);
const LessonPreviewMobilePage = lazy(() =>
  import('./LessonPreviewMobilePage' /* webpackChunkName: "LessonPreviewPage" */),
);
const DiscoverPage = lazy(() =>
  import('./DiscoverPage' /* webpackChunkName: "DiscoverPage" */),
);

const RoleRouteRedirecter = () => {
  const role = useTypedSelector(getRole);
  const metrics = useMetrics();
  const redirect = checkHasRedirect();
  const { url } = useRouteMatch();
  if (redirect) {
    removeRedirect();
    metrics.logEvent('Auto.Redirecting', { to: generateRedirect(redirect) });
    return <Redirect to={generateRedirect(redirect)} />;
  }

  switch (role) {
    case 'admin':
      return <Redirect to={`${url}/live/lessons`} />;
    case 'mentor':
      return <Redirect to={`${url}/live/lessons`} />;
    case 'student':
      return <Redirect to={`${url}/topics`} />;
    default:
      return (
        <Centered>
          <Loader />
        </Centered>
      );
  }
};

const OldRouteRedirect = ({ path }: { path: string }) => {
  const match = useRouteMatch('/:tenant');

  return <Redirect to={`${match?.url}${path}`} />;
};

const MainAppRoutes = () => {
  useTenantValidator();
  const { path, url } = useRouteMatch();

  return (
    <>
      <Suspense fallback={null}>
        <Switch>
          <Route path={`${path}/onboarding`} />
          <Route path={`${path}/news`} />
          {/* Don't render the top bar when playing live */}
          <Route path={`${path}/games/:gameId/live`} component={() => null} />
          <Route
            path={`${path}/topics/:topicId/games/:gameId/live`}
            component={() => null}
          />
          <Route path={`${path}/templates/:templateId/creator`} component={() => null} />
          <Route
            path={`${path}/topics/:topicId/templates/:templateId/creator`}
            component={() => null}
          />
          <Route path={`${path}/games/:gameId/curimote`} component={() => null} />
          <Route
            path={`${path}/topics/:topicId/games/:gameId/curimote`}
            component={() => null}
          />
          <Route path={`${path}/demo/:gameId`} component={() => null} />
          <Route path={`${path}/topics/:topicId/demo/:gameId`} component={() => null} />
          <Route
            path={`${path}/templates/:gameId/preview/:previewGameId`}
            component={() => null}
          />
          <Route
            path={`${path}/topics/:topicId/templates/:gameId/preview/:previewGameId`}
            component={() => null}
          />
          <Route path={path}>
            <TopBar />
          </Route>
        </Switch>
      </Suspense>
      <Suspense fallback={<Loader />}>
        <Switch>
          <Route path={path}>
            <Switch>
              <PrivateRoute
                exact
                path={`${path}/home`}
                component={() => <Redirect to={`${url}`} />}
              />
              <PrivateRoute /** @deprecated to be removed */
                exact
                path={`${path}/games`}
                component={() => <Redirect to={`${url}/live/lessons`} />}
              />
              <PrivateRoute path={`${path}/live`} component={LivePage} />
              <PrivateRoute
                path={`${path}/management/configuration`}
                component={AdminConfigurationPage}
              />
              <PrivateRoute
                path={`${path}/management/users`}
                component={AdminUsersPage}
              />
              <PrivateRoute path={`${path}/profile`} component={ProfilePage} />
              <PrivateRoute
                path={`${path}/notifications`}
                component={NotificationsMobilePage}
                redirect="/login"
              />
              <PrivateRoute
                // Remove this when no one is navigating to this route anymore (23.12.2021)
                path={`${path}/discussions`}
                component={() => <OldRouteRedirect path="/" />}
              />
              <PrivateRoute
                // Remove this when no one is navigating to this route anymore (23.12.2021)
                path={`${path}/claim-chat/:id`}
                component={() => <OldRouteRedirect path="/" />}
                redirect="/"
              />
              <PrivateRoute
                path={`${path}/feedback`}
                component={GenericFeedbackPage}
                redirect="/login"
              />

              <PrivateRoute
                path={`${path}/games/:gameId/curimote`}
                component={Curimote}
              />
              <PrivateRoute
                path={`${path}/games/:gameId/live`}
                component={LiveGamePage}
              />
              <PrivateRoute
                path={`${path}/topics/:topicId/games/:gameId/curimote`}
                component={Curimote}
              />
              <PrivateRoute path={`${path}/demo/:gameId/`} component={DemoGamePage} />
              <PrivateRoute
                path={`${path}/topics/:topicId/demo/:gameId`}
                component={DemoGamePage}
              />
              <PrivateRoute
                path={`${path}/templates/:templateId/preview/:previewGameId/`}
                component={PreviewGamePage}
              />
              <PrivateRoute
                path={`${path}/topics/:topicId/templates/:templateId/preview/:previewGameId`}
                component={PreviewGamePage}
              />
              <PrivateRoute
                path={`${path}/templates/:templateId/creator`}
                component={LiveCreatorPage}
              />
              <PrivateRoute path={`${path}/results/:gameId`} component={ResultPage} />
              <PrivateRoute
                exact
                path={`${path}/topics/discover`}
                component={DiscoverPodsPage}
              />
              <PrivateRoute exact path={`${path}/topics`} component={PodsPage} />
              <PrivateRoute
                path={`${path}/topics/:topicId/post`}
                component={AskQuestionPage}
              />
              <PrivateRoute
                path={`${path}/topics/:topicId/templates/:templateId/creator`}
                component={LiveCreatorPage}
              />
              <PrivateRoute
                path={`${path}/topics/:topicId/games/:gameId/live`}
                component={LiveGamePage}
              />
              <PrivateRoute
                path={`${path}/topics/:topicId/results/:gameId`}
                component={ResultPage}
              />
              <PrivateRoute path={`${path}/topics/:topicId`} component={PodPage} />
              <PrivateRoute
                exact
                path={`${path}/`}
                component={RoleRouteRedirecter}
                redirect="/login"
              />
              <PrivateRoute path={`${path}/news`} component={WhatsNewPage} />
              <PrivateRoute
                path={`${path}/templates/:templateId/preview`}
                component={LessonPreviewMobilePage}
              />
              <PrivateRoute
                path={`${path}/discover/:templateId/preview`}
                component={LessonPreviewMobilePage}
              />

              <PrivateRoute
                path={`${path}/admin/discover/:templateId/preview`}
                component={LessonPreviewMobilePage}
              />
              <PrivateRoute
                path={`${path}/templates/:templateId`}
                component={LessonPreviewPage}
              />
              <PrivateRoute
                path={`${path}/discover/:templateId`}
                component={LessonPreviewPage}
              />
              <PrivateRoute path={`${path}/discover`} component={DiscoverPage} />

              <PrivateRoute
                path={`${path}/admin/discover/:templateId`}
                component={LessonPreviewPage}
              />
              <PrivateRoute path={`${path}/admin`} component={AdminDiscoverPage} />

              <Route path="*" component={NotFound} />
            </Switch>
          </Route>
        </Switch>
      </Suspense>
    </>
  );
};

const Centered = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export default MainAppRoutes;
