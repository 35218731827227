import { useMutation, useQueryClient } from 'react-query';

import { BackendService, Game } from '../services/backendService';
import { Template } from '../services/backendService.type';
import { useQuery as useSearchQuery } from './useQuery';
function usePreviewGame(isFromDiscover?: boolean) {
  const { query } = useSearchQuery();
  const client = useQueryClient();
  const gameCacheKey = 'games';
  const isPreview = query.get('isPreview') === 'true';

  const { mutate: createPreviewGame, isLoading: isLoadingPreview } = useMutation(
    async ({
      template,
      templateId,
      isDemoGame,
    }: {
      template?: Template | null;
      templateId: string;
      isDemoGame?: boolean | null;
      onSuccessFunction: (data: Game) => void;
    }) => {
      if (template) {
        await BackendService.instance.blueprint.templateAutoSave({
          id: templateId,
          rounds: template.content.rounds ? template.content.rounds : [],
          type: 'template',
        });
      }
      return BackendService.instance.game.createPreviewGame(
        templateId,
        !!isDemoGame,
        !!isFromDiscover,
      );
    },
    {
      onSettled: () => {
        client.invalidateQueries([gameCacheKey]);
      },
      onSuccess: (data, { onSuccessFunction }) => {
        onSuccessFunction(data);
      },
    },
  );

  return {
    createPreviewGame,
    isPreview,
    isLoadingPreview,
  };
}

export default usePreviewGame;
