import '@webscopeio/react-textarea-autocomplete/style.css';

import FlagProvider from '@unleash/proxy-client-react';
import React, { lazy, Suspense } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import MainLoader from './components/Loader';
import { APPConfig } from './config/environments';
import OpenRouteAutoRedirect from './hoc/OpenRouteAutoRedirect';
import PrivateRoute from './hoc/PrivateRoute';
import useTenants from './hooks/useTenants';
import MainAppRoutes from './pages/MainAppRoutes';
import OauthCallback from './pages/OauthCallbackPage';
import { AuthenticationProvider } from './providers/AuthenticationProvider';
import AuthSubscriber from './providers/AuthSubscriber';
import NotificationProvider from './providers/NotificationProvider';
import OfflineNotificationProvider from './providers/OfflineNotificationProvider';
import PwaInstallProvider from './providers/PwaInstallProvider';
import RootSubscriber from './providers/RootSubscriber';
import { TranslationProvider } from './providers/TranslationProvider';
import Authentication from './services/authentication';
import { isTouchDevice } from './utils/randomUtils';

const LoginPage = lazy(() =>
  import('./pages/LoginPage' /* webpackChunkName: "login-page" */),
);
const ShareRedirectorPage = lazy(() =>
  import('./pages/ShareRedirectorPage' /* webpackChunkName: "share-redirector-page" */),
);
const SignUpPage = lazy(() =>
  import('./pages/SignUpPage' /* webpackChunkName: "signup-page" */),
);
const LogoutPage = lazy(() =>
  import('./pages/LogoutPage' /* webpackChunkName: "logout-page" */),
);
const JoinTenantPage = lazy(() =>
  import('./pages/JoinTenantPage' /* webpackChunkName: "join-tenant-page" */),
);
const CopyGameTemplatePage = lazy(() =>
  import(
    './pages/CopyGameTemplatePage' /* webpackChunkName: "copy-game-template-page" */
  ),
);

const TenantRoute = () => {
  // Should be rendered when we dont know where the users destination tenant is.
  const { data, isLoading } = useTenants();
  if (isLoading && !data) return <MainLoader />;
  if (data && data.user.activeTenantId) {
    return <Redirect to={`/${data.user.activeTenantId}`} />;
  }
  return <Redirect to="/signup" />;
};

const queryClient = new QueryClient();

const dndOptions = isTouchDevice()
  ? {
      delayTouchStart: 150,
      enableMouseEvents: true, // enable for laptops with touch support
    }
  : {};

const App = ({ auth, config }: { auth: Authentication; config: APPConfig }) => {
  return (
    <Suspense fallback={null}>
      <BrowserRouter>
        <FlagProvider config={config.unleash}>
          <TranslationProvider>
            <QueryClientProvider client={queryClient}>
              <DndProvider
                backend={isTouchDevice() ? TouchBackend : HTML5Backend}
                options={dndOptions}
              >
                <OfflineNotificationProvider>
                  <PwaInstallProvider>
                    <AuthenticationProvider value={auth}>
                      <AuthSubscriber>
                        <NotificationProvider>
                          <RootSubscriber>
                            <Switch>
                              <PrivateRoute exact path="/" component={TenantRoute} />
                              <OpenRouteAutoRedirect
                                path="/oauth/callback"
                                component={OauthCallback}
                              />
                              <OpenRouteAutoRedirect
                                path="/login"
                                component={LoginPage}
                              />
                              <OpenRouteAutoRedirect
                                path="/logout"
                                component={LogoutPage}
                              />
                              <OpenRouteAutoRedirect
                                path="/signup"
                                component={SignUpPage}
                              />
                              <Route
                                path="/share/:templateId"
                                component={ShareRedirectorPage}
                              />
                              <Route
                                path="/join/:invitationCode"
                                component={JoinTenantPage}
                              />
                              <Route
                                path="/fromtemplate"
                                component={CopyGameTemplatePage}
                              />
                              <OpenRouteAutoRedirect
                                path="/register"
                                component={LoginPage}
                              />
                              <PrivateRoute path="/:tenantId" component={MainAppRoutes} />
                            </Switch>
                          </RootSubscriber>
                        </NotificationProvider>
                      </AuthSubscriber>
                    </AuthenticationProvider>
                  </PwaInstallProvider>
                </OfflineNotificationProvider>
              </DndProvider>
              <ReactQueryDevtools />
            </QueryClientProvider>
          </TranslationProvider>
        </FlagProvider>
      </BrowserRouter>
    </Suspense>
  );
};

export default App;
