import React from 'react';

export const CopySvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.41602 15.334H8.91602C9.37994 15.3333 9.82465 15.1487 10.1527 14.8206C10.4807 14.4926 10.6653 14.0479 10.666 13.584V5.08399C10.6653 4.62007 10.4807 4.17536 10.1527 3.84733C9.82465 3.51929 9.37994 3.33469 8.91602 3.33398H2.41602C1.9521 3.33469 1.50739 3.51929 1.17936 3.84733C0.851322 4.17536 0.666721 4.62007 0.666016 5.08399V13.584C0.666721 14.0479 0.851322 14.4926 1.17936 14.8206C1.50739 15.1487 1.9521 15.3333 2.41602 15.334ZM1.99935 5.08399C1.99935 4.97348 2.04325 4.8675 2.12139 4.78936C2.19953 4.71122 2.30551 4.66732 2.41602 4.66732H8.91602C9.02653 4.66732 9.13251 4.71122 9.21065 4.78936C9.28879 4.8675 9.33269 4.97348 9.33269 5.08399V13.584C9.33269 13.6945 9.28879 13.8005 9.21065 13.8786C9.13251 13.9568 9.02653 14.0007 8.91602 14.0007H2.41602C2.30551 14.0007 2.19953 13.9568 2.12139 13.8786C2.04325 13.8005 1.99935 13.6945 1.99935 13.584V5.08399Z"
      fill="currentColor"
    />
    <path
      d="M13.58 0.666992H7.0867C6.74193 0.667318 6.40491 0.769323 6.11783 0.96024C5.83074 1.15116 5.60634 1.42251 5.4727 1.74033C5.40375 1.90317 5.4023 2.08674 5.46869 2.25064C5.53508 2.41455 5.66386 2.54537 5.8267 2.61433C5.98955 2.68328 6.17311 2.68473 6.33702 2.61834C6.50093 2.55195 6.63175 2.42317 6.7007 2.26033C6.73225 2.18376 6.78572 2.11823 6.8544 2.07197C6.92308 2.02571 7.0039 2.00079 7.0867 2.00033H13.58C13.6914 2.0005 13.7981 2.04481 13.8768 2.12354C13.9556 2.20226 13.9999 2.30899 14 2.42033V10.9137C13.9999 11.025 13.9556 11.1317 13.8768 11.2105C13.7981 11.2892 13.6914 11.3335 13.58 11.3337H12C11.8232 11.3337 11.6537 11.4039 11.5286 11.5289C11.4036 11.654 11.3334 11.8235 11.3334 12.0003C11.3334 12.1771 11.4036 12.3467 11.5286 12.4717C11.6537 12.5968 11.8232 12.667 12 12.667H13.58C14.0449 12.6665 14.4906 12.4816 14.8193 12.1529C15.148 11.8242 15.3329 11.3785 15.3334 10.9137V2.42033C15.3329 1.95548 15.148 1.50982 14.8193 1.18112C14.4906 0.852417 14.0449 0.667522 13.58 0.666992Z"
      fill="currentColor"
    />
  </svg>
);

export default CopySvg;
