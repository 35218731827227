import React from 'react';

export const AnswerSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M16.4283 10C16.0338 10 15.7141 10.3198 15.7141 10.7143V17.8571C15.7141 18.2515 15.3943 18.5713 14.9998 18.5713H2.14283C1.74833 18.5713 1.42854 18.2515 1.42854 17.8571V3.57154C1.42854 3.17704 1.74833 2.85725 2.14283 2.85725H10.7141C11.1086 2.85725 11.4284 2.53746 11.4284 2.14296C11.4284 1.74846 11.1086 1.42871 10.7141 1.42871H2.14283C0.959376 1.42871 0 2.38809 0 3.57154V17.8571C0 19.0405 0.959376 19.9999 2.14283 19.9999H14.9998C16.1833 19.9999 17.1426 19.0405 17.1426 17.8571V10.7143C17.1426 10.3198 16.8228 10 16.4283 10Z"
        fill="currentColor"
      />
      <path
        d="M19.1994 0.801221C18.6865 0.288282 17.9909 0.000171737 17.2655 0.000255441C16.5398 -0.00183717 15.8434 0.286775 15.332 0.801682L5.92351 10.2094C5.84545 10.288 5.78657 10.3836 5.75137 10.4886L4.32283 14.7743C4.19815 15.1486 4.40051 15.553 4.77479 15.6777C4.8474 15.7019 4.92345 15.7142 4.99996 15.7143C5.07663 15.7142 5.15284 15.7019 5.22567 15.6779L9.51133 14.2494C9.61659 14.2142 9.71222 14.155 9.79061 14.0765L19.1991 4.66806C20.2669 3.60033 20.2671 1.86908 19.1994 0.801221ZM18.1891 3.65876L8.90347 12.9444L6.12921 13.8708L7.05277 11.1001L16.3419 1.8145C16.8526 1.30483 17.6798 1.30567 18.1895 1.81634C18.433 2.0603 18.5702 2.39056 18.5712 2.73521C18.5721 3.0817 18.4345 3.41418 18.1891 3.65876Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default AnswerSvg;
