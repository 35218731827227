import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import useTenants from './useTenants';

function useTenantValidator() {
  const { tenantId } = useParams<{ tenantId: string }>();
  const { data } = useTenants();
  useEffect(() => {
    if (tenantId && data) {
      if (!data.user.availableTenants.some(t => t.tenantId === tenantId)) {
        window.location.href = window.location.href.replace(
          tenantId,
          data.user.activeTenantId,
        );
      }
    }
  }, [tenantId, data]);
}

export default useTenantValidator;
