export const FETCH_USERS = 'ADMIN/FETCH_USERS';

export const FETCH_USER_FEEDBACKS = 'ADMIN/FETCH_USER_FEEDBACKS';
export const RECEIVE_USERS = 'ADMIN/RECEIVE_USERS';
export const RECEIVE_USERTYPES = 'ADMIN/RECEIVE_USERTYPES';
export const RECEIVE_USER_FEEDBACKS = 'ADMIN/RECEIVE_USER_FEEDBACKS';
export const CONVERT_TO_MENTOR = 'ADMIN/CONVERT_TO_MENTOR';
export const CONVERT_TO_MENTOR_SUCCESS = 'ADMIN/CONVERT_TO_MENTOR_SUCCESS';
export const CONVERT_TO_MENTOR_FAILURE = 'ADMIN/CONVERT_TO_MENTOR_FAILURE';

export const SAVE_DEFAULT_LANGUAGE = 'ADMIN/SAVE_DEFAULT_LANGUAGE';

export const SAVE_SERVICE_NAME = 'ADMIN/SAVE_SERVICE_NAME';

export const ADMIN_DELETE_USER = 'ADMIN/DELETE_USER';
export const ADMIN_DELETE_USER_SUCCESS = 'ADMIN/DELETE_USER_SUCCESS';
export const ADMIN_DELETE_USER_ERROR = 'ADMIN/DELETE_USER_ERROR';
