import React from 'react';

export const FollowSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.27415 21.9514C7.05571 21.9514 8.49995 20.5071 8.49995 18.7256C8.49995 16.944 7.05571 15.4998 5.27415 15.4998C3.49258 15.4998 2.04834 16.944 2.04834 18.7256C2.04834 20.5071 3.49258 21.9514 5.27415 21.9514Z"
      fill="currentColor"
    />
    <path
      d="M12.8065 20.371C12.8065 21.2581 13.5323 21.9839 14.4194 21.9839C15.3065 21.9839 16.0323 21.2581 16.0323 20.371C16.0323 13.5323 10.4677 7.95166 3.6129 7.95166C2.72581 7.95166 2 8.67747 2 9.56456C2 10.4517 2.72581 11.1775 3.6129 11.1775C8.69355 11.1775 12.8065 15.3065 12.8065 20.371Z"
      fill="currentColor"
    />
    <path
      d="M20.3871 21.9839C21.2742 21.9839 22 21.2581 22 20.371C22 10.2419 13.758 2 3.62902 2C2.74192 2 2.01611 2.72581 2.01611 3.6129C2.01611 4.5 2.74192 5.22581 3.62902 5.22581C11.9839 5.22581 18.7742 12.0161 18.7742 20.371C18.7742 21.2581 19.4839 21.9839 20.3871 21.9839Z"
      fill="currentColor"
    />
  </svg>
);

export default FollowSvg;
