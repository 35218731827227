export const SET_USER = 'USER/SET_USER';
export const LOAD_AUTH = 'USER/LOAD_AUTH';
export const AUTH_LOADED_NO_USER = 'USER/AUTH_LOADED_NO_USER';
export const SIGN_OUT = 'USER/SIGN_OUT';
export const RESET = 'RESET_APP';

export const GOT_ID_TOKEN_SUCCESS = 'AUTH/GOT_ID_TOKEN_SUCCESS';
export const AZURE_LOAD_END = 'AUTH/AZURE_LOAD_END';

export const TENANTS_RECEIVED = 'AUTH/TENANTS_RECEIVED';
