import { combineReducers } from 'redux';

import { UserRole } from '../../services/database/types';
import { RootAction } from '..';
import { AppState } from '../rootReducer';
import { PUBLIC_DATA_RECEIVED } from './actionTypes';

export type LoginProviders = {
  google: boolean;
  feide: boolean;
  email: boolean;
};

export const fallbackProviders: LoginProviders = {
  google: false,
  feide: false,
  email: true,
};

export type PublicData = {
  loginProviders?: LoginProviders;
  custom?: {
    logo: boolean;
    name: string;
    billingEnabled?: boolean;
    customLogoEnabled?: boolean;
    externalApiEnabled?: boolean;
    meteredDiscussionEnabled?: boolean;
    knowledgeEnabled?: boolean;
    terms?: string;
    loginDisclaimer?: string;
  };
  defaultLanguage?: { lang: string };
  defaultRole?: { role: UserRole };
};

export const publicReducer = (state = {}, action: RootAction): PublicData => {
  switch (action.type) {
    case PUBLIC_DATA_RECEIVED:
      return action.payload || {};
    default:
      return state;
  }
};

export const serverReducer = combineReducers({
  public: publicReducer,
});

// selectors
export const getPublicData = (state: AppState) => state.server.public;

export const getDefaultLanguage = (state: AppState) =>
  getPublicData(state).defaultLanguage?.lang;
