import React from 'react';

export const PencilSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9.82975 5.35111L10.6474 6.16889L2.5952 14.2222H1.77753V13.4044L9.82975 5.35111ZM13.0293 0C12.8071 0 12.576 0.0888889 12.4072 0.257778L10.7807 1.88444L14.1136 5.21778L15.74 3.59111C16.0867 3.24444 16.0867 2.68444 15.74 2.33778L13.6603 0.257778C13.4826 0.08 13.2604 0 13.0293 0ZM9.82975 2.83556L0 12.6667V16H3.33287L13.1626 6.16889L9.82975 2.83556Z" />
  </svg>
);

export default PencilSvg;
