import React from 'react';

export const SettingsSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.139 14.1917L19.3007 12.7542C19.324 12.5325 19.344 12.2734 19.344 11.9992C19.344 11.725 19.3248 11.4659 19.3007 11.2442L21.1407 9.80586C21.4823 9.53586 21.5757 9.05668 21.3582 8.65754L19.4465 5.35C19.2423 4.97668 18.7948 4.785 18.3407 4.9525L16.1715 5.82332C15.7557 5.52332 15.3198 5.27 14.869 5.0675L14.539 2.76668C14.4848 2.33 14.1048 2 13.6557 2H9.82235C9.37317 2 8.99403 2.33 8.94067 2.76L8.60985 5.06918C8.17317 5.26586 7.74403 5.51586 7.30985 5.825L5.13485 4.95168C4.72731 4.79418 4.23981 4.97082 4.03731 5.3425L2.12317 8.65418C1.89735 9.03586 1.99067 9.53168 2.33985 9.80836L4.17817 11.2459C4.14899 11.5267 4.13485 11.7709 4.13485 12C4.13485 12.2292 4.14903 12.4734 4.17817 12.755L2.33817 14.1934C1.99649 14.4642 1.90399 14.9434 2.12149 15.3417L4.03317 18.6492C4.23735 19.0217 4.68067 19.215 5.13899 19.0467L7.30817 18.1759C7.72317 18.475 8.15899 18.7284 8.60985 18.9317L8.93985 21.2317C8.99399 21.67 9.37317 22 9.82317 22H13.6565C14.1057 22 14.4857 21.67 14.539 21.24L14.8698 18.9317C15.3065 18.7342 15.7348 18.485 16.1698 18.175L18.3448 19.0483C18.4498 19.0891 18.559 19.11 18.6715 19.11C18.9948 19.11 19.2923 18.9333 19.4423 18.6583L21.3623 15.3333C21.5757 14.9433 21.4823 14.4642 21.139 14.1917ZM11.739 15.3333C9.90067 15.3333 8.40567 13.8383 8.40567 12C8.40567 10.1617 9.90067 8.66668 11.739 8.66668C13.5773 8.66668 15.0723 10.1617 15.0723 12C15.0723 13.8383 13.5773 15.3333 11.739 15.3333Z"
      stroke="currentColor"
      strokeWidth="2"
    />
  </svg>
);

export default SettingsSvg;
