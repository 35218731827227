import { useCallback, useRef, useState } from 'react';

interface HookProps {
  onLongPress?: (
    event: React.MouseEvent | React.TouchEvent,
    currentTarget: HTMLElement,
  ) => void;
  onClick?: () => void;
  shouldPreventDefault: boolean;
  delay: number;
}

export const useLongPress = (props: HookProps) => {
  const { onLongPress, onClick, shouldPreventDefault = true, delay = 300 } = props;

  const [longPressTriggered, setLongPressTriggered] = useState(false);
  const timeout = useRef<NodeJS.Timeout>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const target = useRef<any>();

  const start = useCallback(
    event => {
      if (shouldPreventDefault && event.target) {
        event.target.addEventListener('touchend', preventDefault, {
          passive: false,
        });
        target.current = event.target;
      }

      const currentTarget = event.currentTarget;

      timeout.current = setTimeout(() => {
        onLongPress && onLongPress(event, currentTarget);
        setLongPressTriggered(true);
      }, delay);
    },
    [delay, onLongPress, shouldPreventDefault],
  );

  const clear = useCallback(
    (event, shouldTriggerClick = true) => {
      timeout.current && clearTimeout(timeout.current);
      shouldTriggerClick && !longPressTriggered && onClick && onClick();
      setLongPressTriggered(false);

      if (shouldPreventDefault && target.current) {
        target !== undefined &&
          target.current &&
          target.current.removeEventListener('touchend', preventDefault);
      }
    },
    [longPressTriggered, onClick, shouldPreventDefault],
  );

  return {
    onMouseDown: (e: React.MouseEvent) => start(e),
    onTouchStart: (e: React.TouchEvent) => start(e),
    onMouseUp: (e: React.MouseEvent) => clear(e),
    onMouseLeave: (e: React.MouseEvent) => clear(e, false),
    onTouchEnd: (e: React.TouchEvent) => clear(e),
  };
};

const isTouchEvent = (event: React.TouchEvent | React.MouseEvent) => {
  return 'touches' in event;
};

const preventDefault = (event: React.TouchEvent | React.MouseEvent) => {
  if (isTouchEvent(event)) {
    if ((event as React.TouchEvent).touches.length < 2 && event.preventDefault)
      event.preventDefault();
  }
};
