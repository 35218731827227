import React, { Component } from 'react';
import styled from 'styled-components';

import Background from '../assets/images/background_grey.png';
import { BaseButton } from '../styled';
import theme from '../theme';

interface ErrorState {
  hasError: boolean;
}
interface ErrorProps {
  children: React.ReactChild;
}

const clearAllCookies = function() {
  const cookies = document.cookie.split('; ');
  for (let c = 0; c < cookies.length; c++) {
    const d = window.location.hostname.split('.');
    while (d.length > 0) {
      const cookieBase =
        encodeURIComponent(cookies[c].split(';')[0].split('=')[0]) +
        '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' +
        d.join('.') +
        ' ;path=';
      const p = window.location.pathname.split('/');
      document.cookie = cookieBase + '/';
      while (p.length > 0) {
        document.cookie = cookieBase + p.join('/');
        p.pop();
      }
      d.shift();
    }
  }
};

class ErrorBoundary extends Component<ErrorProps, ErrorState> {
  constructor(props: ErrorProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    // Likely we fetched a bundle from an old version. By this time, the SW should be active and we can safely refresh the browser for the user.
    if (error.message.includes('Loading chunk')) {
      window.location.reload();
      return { hasError: false };
    }
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch() {
    if (process.env.REACT_APP_ENV !== 'development') {
    }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Container>
          <h2 style={{ color: 'white' }}>Whoops, an error occurred.</h2>
          <StyledButton
            onClick={() => {
              window.location.reload();

              //Clear local storage to start completely fresh
              localStorage.clear();
              try {
                clearAllCookies();
              } catch (e) {}
            }}
          >
            Click to reload the page. If the problem persist, press CTRL/CMD+SHIFT+R
          </StyledButton>
        </Container>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;

const StyledButton = styled(BaseButton)`
  padding: 20px;
  &:hover {
    background-color: ${theme.colors.orange};
    color: ${theme.colors.white};
  }
`;

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;
  background-image: url(${Background});
  background-color: ${theme.colors.orange};
`;
