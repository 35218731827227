import React from 'react';

export const ExitSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.9668 18.3057H2.49168C2.0332 18.3057 1.66113 17.9336 1.66113 17.4751V2.52492C1.66113 2.06645 2.03324 1.69438 2.49168 1.69438H9.9668C10.4261 1.69438 10.7973 1.32313 10.7973 0.863828C10.7973 0.404531 10.4261 0.0332031 9.9668 0.0332031H2.49168C1.11793 0.0332031 0 1.15117 0 2.52492V17.4751C0 18.8488 1.11793 19.9668 2.49168 19.9668H9.9668C10.4261 19.9668 10.7973 19.5955 10.7973 19.1362C10.7973 18.6769 10.4261 18.3057 9.9668 18.3057Z"
      fill="#282F33"
    />
    <path
      d="M19.7525 9.40855L14.7027 4.42515C14.3771 4.10288 13.8505 4.10706 13.5282 4.43347C13.206 4.75988 13.2093 5.28562 13.5366 5.60788L17.1454 9.16933H7.47508C7.01578 9.16933 6.64453 9.54058 6.64453 9.99988C6.64453 10.4592 7.01578 10.8305 7.47508 10.8305H17.1454L13.5366 14.3919C13.2093 14.7142 13.2068 15.2399 13.5282 15.5663C13.691 15.7308 13.9053 15.8138 14.1196 15.8138C14.3306 15.8138 14.5415 15.7341 14.7027 15.5746L19.7525 10.5912C19.9103 10.4351 20 10.2224 20 9.99984C20 9.77734 19.9111 9.56554 19.7525 9.40855Z"
      fill="#282F33"
    />
  </svg>
);

export default ExitSvg;
