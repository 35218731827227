import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';

import { receiveUsers } from '../modules';
import { BackendService } from '../services/backendService';
export const usersCacheKey = 'users';
function useUsers() {
  const dispatch = useDispatch();

  const { data, isFetching, error } = useQuery(
    [usersCacheKey],
    () => BackendService.instance.users.listUsersById(),
    {
      staleTime: 1000 * 60 * 15,
    },
  );

  useEffect(() => {
    if (data) {
      dispatch(receiveUsers(data.users, data.byId));
    }
  }, [data, dispatch]);

  return {
    users: data?.users,
    usersById: data?.byId || {},
    error,
    isLoading: isFetching,
  };
}

export default useUsers;
