import React from 'react';

export const FilterSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 6C7.10457 6 8 5.10457 8 4C8 2.89543 7.10457 2 6 2C4.89543 2 4 2.89543 4 4C4 5.10457 4.89543 6 6 6ZM6 0C7.86384 0 9.42994 1.27477 9.87398 3H17H18V5H17H9.87398C9.42994 6.72523 7.86384 8 6 8C4.13616 8 2.57006 6.72523 2.12602 5H1H0V3H1H2.12602C2.57006 1.27477 4.13616 0 6 0ZM12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14ZM12 8C13.8638 8 15.4299 9.27477 15.874 11H17H18V13H17H15.874C15.4299 14.7252 13.8638 16 12 16C10.1362 16 8.57006 14.7252 8.12602 13H1H0V11H1H8.12602C8.57006 9.27477 10.1362 8 12 8Z"
    />
  </svg>
);

export default FilterSvg;
