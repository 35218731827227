import { InteractionStatus } from '@azure/msal-browser';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from '@azure/msal-react';
import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router';

import MainLoader from '../components/Loader';
import useIsSignedIn from '../hooks/useIsSignedIn';

interface PrivateRouteProps extends RouteProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: any;
  redirect?: string;
}

const AssignedRouteKeeperRedirector = ({ redirect }: { redirect: string }) => {
  return (
    <Redirect
      to={{
        pathname: redirect,
        state: { from: window.location.href },
      }}
    />
  );
};

const Authenticat = () => {
  return <></>;
};
const NotAuthenticat = () => {
  return <></>;
};

const PrivateRoute = ({
  component: Component,
  redirect = '/login',
  ...rest
}: PrivateRouteProps) => {
  const { isSignedIntoFirebase } = useIsSignedIn();

  const context = useMsal();

  return (
    <Route
      {...rest}
      render={props => {
        return (
          <>
            <AuthenticatedTemplate>
              <Authenticat />
              {isSignedIntoFirebase && <Component {...props} />}
              {!isSignedIntoFirebase && <MainLoader />}
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
              <NotAuthenticat />
              <MainLoader />
              {/* Make sure we dont redirect back to /login when we are specifically logging out */}
              {context.inProgress !== InteractionStatus.Logout && (
                <AssignedRouteKeeperRedirector redirect={redirect} />
              )}
            </UnauthenticatedTemplate>
          </>
        );
      }}
    />
  );
};

export default PrivateRoute;
