import { TopicRole } from '../services/backendService';

export const getViewableRole = (role: TopicRole) => {
  if (role === 'owner') return 'admin';
  if (role === 'moderator') return 'mentor';
  if (role === 'follower') return 'student';
};

export function isTouchDevice() {
  return (
    'ontouchstart' in window ||
    navigator.maxTouchPoints > 0 ||
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    navigator.msMaxTouchPoints > 0
  );
}

export const isDevelopment = () => {
  return (
    process.env.REACT_APP_ENV === 'development' ||
    process.env.REACT_APP_ENV === 'staging' ||
    process.env.NODE_ENV === 'development'
  );
};
