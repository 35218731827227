import { InfiniteData, useInfiniteQuery, useMutation, useQueryClient } from 'react-query';

import { getUserId } from '../modules';
import {
  ArticleComment,
  BackendService,
  PaginatedFeedArticlesResponsePerformance,
} from '../services/backendService';
import { useTypedSelector } from '.';
import { useMetrics } from './useMetrics';
export const activityFeedCacheKey = 'activity-feed';

function useActivityFeed(topicId: string) {
  const client = useQueryClient();
  const userId = useTypedSelector(getUserId);

  const metrics = useMetrics();

  const fetchArticles = ({ pageParam = undefined }) =>
    BackendService.instance.topics.activityFeed(topicId, pageParam);

  const {
    data,
    error,
    isFetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery([activityFeedCacheKey, topicId], fetchArticles, {
    getNextPageParam: lastPage => lastPage.cursor,
    staleTime: 1000 * 30,
  });

  const { mutate: addComment } = useMutation<
    ArticleComment,
    string,
    {
      text: string;
      imageData?: string;
      articleId: string;
      disclaimer?: string;
      isAnonymous: boolean;
    }
  >(
    ({
      text,
      imageData,
      articleId,
      disclaimer,
      isAnonymous,
    }: {
      text: string;
      articleId: string;
      imageData?: string;
      disclaimer?: string;
      isAnonymous: boolean;
    }) => {
      metrics.logEvent('Feed.Snippet.Comment.Add', { articleId });
      return BackendService.instance.articles.addComment(
        { text, imageData, disclaimer, isAnonymous },
        articleId,
      );
    },
    {
      onSuccess: result => {
        client.invalidateQueries([activityFeedCacheKey, topicId]);
        client.setQueryData<
          InfiniteData<PaginatedFeedArticlesResponsePerformance> | undefined
        >([activityFeedCacheKey, topicId], groups => {
          if (!groups) return groups;
          return {
            ...groups,
            pages: groups.pages.map(group => {
              const article = group.byId[result.articleId];
              if (!article) return group;
              return {
                byId: {
                  ...group.byId,
                  [result.articleId]: {
                    ...group.byId[result.articleId],
                    comments: article.comments
                      ? article.comments.map(c => (c.id === 'newComment' ? result : c))
                      : [result],
                  },
                },
                items: group.items,
                cursor: group.cursor,
                count: group.count,
              };
            }),
          };
        });
      },
      onMutate: s => {
        client.cancelQueries([activityFeedCacheKey, topicId]);
        const previousFeed = client.getQueryData<
          InfiniteData<PaginatedFeedArticlesResponsePerformance>
        >([activityFeedCacheKey, topicId]);
        if (!previousFeed) {
          // eslint-disable-next-line no-console
          console.error('Mutating before possible mutation');
        }
        const optimisticComment: ArticleComment = {
          text: s.text,
          imageId: s.imageData && new Date().getTime().toString(),
          createdAt: new Date().getTime(),
          updatedAt: new Date().getTime(),
          createdBy: userId!,
          articleId: s.articleId,
          id: 'newComment',
          isAnonymous: s.isAnonymous,
          disclaimer: s.disclaimer,
        };
        client.setQueryData<
          InfiniteData<PaginatedFeedArticlesResponsePerformance> | undefined
        >([activityFeedCacheKey, topicId], groups => {
          if (!groups) return groups;
          return {
            ...groups,
            pages: groups.pages.map(group => {
              const article = group.byId[s.articleId];
              if (!article) return group;
              return {
                byId: {
                  ...group.byId,
                  [s.articleId]: {
                    ...group.byId[s.articleId],
                    comments: article.comments
                      ? article.comments.concat(optimisticComment)
                      : [optimisticComment],
                  },
                },
                items: group.items,
                cursor: group.cursor,
                count: group.count,
              };
            }),
          };
        });

        // Return the snapshotted value
        return () => client.setQueryData([activityFeedCacheKey, topicId], previousFeed);
      },
      onError: (err, newTodo, rollback) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        return rollback();
      },
    },
  );

  const { mutate: editComment } = useMutation<
    ArticleComment,
    string,
    {
      text: string;
      commentId: string;
      articleId: string;
      disclaimer?: string;
      isAnonymous: boolean;
    }
  >(
    ({
      text,
      articleId,
      commentId,
      disclaimer,
      isAnonymous,
    }: {
      text: string;
      articleId: string;
      commentId: string;
      disclaimer?: string;
      isAnonymous: boolean;
    }) => {
      metrics.logEvent('Feed.Snippet.Comment.Edit', { articleId, commentId });
      return BackendService.instance.articles.editComment({
        text,
        commentId,
        articleId,
        disclaimer,
        isAnonymous,
      });
    },
    {
      onSuccess: result => {
        client.invalidateQueries([activityFeedCacheKey, topicId]);
        client.setQueryData<
          InfiniteData<PaginatedFeedArticlesResponsePerformance> | undefined
        >([activityFeedCacheKey, topicId], groups => {
          if (!groups) return groups;
          return {
            ...groups,
            pages: groups.pages.map(group => {
              const article = group.byId[result.articleId];
              if (!article) return group;
              return {
                byId: {
                  ...group.byId,
                  [result.articleId]: {
                    ...group.byId[result.articleId],
                    comments: article.comments
                      ? article.comments.map(c => (c.id === result.id ? result : c))
                      : [result],
                  },
                },
                items: group.items,
                cursor: group.cursor,
                count: group.count,
              };
            }),
          };
        });
      },
      onMutate: s => {
        client.cancelQueries([activityFeedCacheKey, topicId]);
        const previousFeed = client.getQueryData<
          PaginatedFeedArticlesResponsePerformance[]
        >([activityFeedCacheKey, topicId]);
        if (!previousFeed) {
          // eslint-disable-next-line no-console
          console.error('Mutating before possible mutation');
        }

        client.setQueryData<
          InfiniteData<PaginatedFeedArticlesResponsePerformance> | undefined
        >([activityFeedCacheKey, topicId], groups => {
          if (!groups) return groups;
          return {
            ...groups,
            pages: groups.pages.map(group => {
              const article = group.byId[s.articleId];
              if (!article) return group;
              return {
                byId: {
                  ...group.byId,
                  [s.articleId]: {
                    ...group.byId[s.articleId],
                    comments: article.comments
                      ? article.comments.map(c =>
                          c.id === s.commentId
                            ? {
                                ...c,
                                text: s.text,
                                disclaimer: s.disclaimer,
                                isAnonymous: s.isAnonymous,
                                updatedAt: new Date().getTime(),
                              }
                            : c,
                        )
                      : article.comments,
                  },
                },
                items: group.items,
                cursor: group.cursor,
                count: group.count,
              };
            }),
          };
        });

        // Return the snapshotted value
        return () => client.setQueryData([activityFeedCacheKey, topicId], previousFeed);
      },
      onError: (err, newTodo, rollback) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        return rollback();
      },
    },
  );

  const { mutate: deleteComment } = useMutation<
    unknown,
    string,
    { commentId: string; articleId: string }
  >(
    ({ articleId, commentId }: { articleId: string; commentId: string }) => {
      metrics.logEvent('Feed.Snippet.Comment.Delete', { articleId, commentId });
      return BackendService.instance.articles.deleteComment(commentId, articleId);
    },
    {
      onMutate: s => {
        client.cancelQueries([activityFeedCacheKey, topicId]);
        const previousFeed = client.getQueryData<
          PaginatedFeedArticlesResponsePerformance
        >([activityFeedCacheKey, topicId]);
        if (!previousFeed) {
          // eslint-disable-next-line no-console
          console.error('Mutating before possible mutation');
        }
        client.cancelQueries([activityFeedCacheKey, topicId]);
        client.setQueryData<
          InfiniteData<PaginatedFeedArticlesResponsePerformance> | undefined
        >([activityFeedCacheKey, topicId], groups => {
          if (!groups) return groups;
          return {
            ...groups,
            pages: groups.pages.map(group => {
              const article = group.byId[s.articleId];
              if (!article) return group;
              return {
                byId: {
                  ...group.byId,
                  [s.articleId]: {
                    ...group.byId[s.articleId],
                    comments: article.comments
                      ? article.comments.filter(c => c.id !== s.commentId)
                      : article.comments,
                  },
                },
                items: group.items,
                cursor: group.cursor,
                count: group.count,
              };
            }),
          };
        });

        // Return the snapshotted value
        return () => client.setQueryData([activityFeedCacheKey, topicId], previousFeed);
      },
      onError: (err, newTodo, rollback) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        return rollback();
      },
    },
  );

  return {
    groups: data,
    isFetchingMore: isFetchingNextPage,
    canFetchMore: hasNextPage,
    fetchMore: fetchNextPage,
    addComment,
    editComment,
    deleteComment,
    isFetching,
    error,
  };
}

export default useActivityFeed;
