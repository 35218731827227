import React from 'react';

export const HeartEmptySvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.5408 2.90123C17.5543 1.78193 16.1859 1.16553 14.6875 1.16553C12.5802 1.16553 11.246 2.42412 10.4979 3.47998C10.3038 3.75397 10.1386 4.02869 10 4.28721C9.86137 4.02869 9.69629 3.75397 9.50215 3.47998C8.75402 2.42412 7.41984 1.16553 5.3125 1.16553C3.81414 1.16553 2.44566 1.78197 1.45918 2.90127C0.518242 3.969 0 5.399 0 6.92783C0 8.59201 0.649727 10.1399 2.04473 11.7989C3.29148 13.2817 5.08512 14.8102 7.16211 16.5801C7.93605 17.2396 8.73641 17.9216 9.58848 18.6671L9.61406 18.6896C9.72453 18.7863 9.86227 18.8346 10 18.8346C10.1377 18.8346 10.2755 18.7862 10.3859 18.6896L10.4115 18.6671C11.2636 17.9216 12.0639 17.2396 12.838 16.58C14.9149 14.8102 16.7085 13.2818 17.9553 11.7989C19.3503 10.1398 20 8.59201 20 6.92783C20 5.399 19.4818 3.969 18.5408 2.90123ZM12.0779 15.6881C11.4107 16.2566 10.724 16.8417 10 17.4712C9.27602 16.8418 8.58934 16.2567 7.92203 15.688C3.85723 12.2242 1.17188 9.93592 1.17188 6.92783C1.17188 5.68447 1.58613 4.52963 2.33836 3.67607C3.09922 2.81283 4.15543 2.3374 5.3125 2.3374C6.9191 2.3374 7.95766 3.32717 8.54594 4.15748C9.07363 4.90217 9.34898 5.65287 9.44289 5.94104C9.52145 6.18225 9.74633 6.34549 10 6.34549C10.2537 6.34549 10.4786 6.18225 10.5571 5.94104C10.651 5.65287 10.9264 4.90217 11.4541 4.15744C12.0423 3.32717 13.0809 2.3374 14.6875 2.3374C15.8446 2.3374 16.9008 2.81283 17.6616 3.67607C18.4139 4.52963 18.8281 5.68447 18.8281 6.92783C18.8281 9.93592 16.1428 12.2242 12.0779 15.6881Z"
      fill="currentColor"
    />
  </svg>
);

export default HeartEmptySvg;
