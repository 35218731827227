import ClipboardJS from 'clipboard';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { useMetrics } from '../../../hooks';
import useInvitation from '../../../hooks/useInvitation';
import { TopicInvitation } from '../../../services/backendService';
import { Button } from '../../_atoms/_Button';
import { Input } from '../../_atoms/_Input';

export const InvitationInputWithButton = ({
  role,
  topic,
  isSignup,
  isFetching,
}: {
  role: 'student' | 'mentor';
  topic?: TopicInvitation;
  isSignup?: boolean;
  isFetching?: boolean;
}) => {
  const ref = useRef(new ClipboardJS('#copy_url'));
  const { url } = useInvitation(role, topic);
  const [copySuccess, setCopySuccess] = useState(false);

  useEffect(() => {
    const clip = ref.current;

    clip.on('success', () => {
      setCopySuccess(true);
    });

    return () => {
      clip.destroy();
    };
  }, []);

  useEffect(() => {
    const ref = setTimeout(() => {
      if (copySuccess) {
        setCopySuccess(false);
      }
    }, 3000);
    return () => {
      clearTimeout(ref);
    };
  }, [copySuccess]);

  const validUrl = !isSignup ? url : `${window.origin}/signup`;

  return (
    <Container id="copy_url" data-clipboard-text={validUrl}>
      <Input style={{ flex: '1' }} fluid value={validUrl} />
      <Button disabled={isFetching} loading={isFetching} styles={{ marginLeft: 10 }}>
        {copySuccess ? 'Copied!' : 'Copy'}
      </Button>
    </Container>
  );
};

export const InvitationButton = ({
  isSignup,
  url,
  isFetching,
}: {
  isSignup?: boolean;
  url?: string;
  isFetching?: boolean;
}) => {
  const ref = useRef(new ClipboardJS('#copy_url'));
  const [copySuccess, setCopySuccess] = useState(false);
  const metrics = useMetrics();
  useEffect(() => {
    const clip = ref.current;

    clip.on('success', () => {
      setCopySuccess(true);
      metrics.logEvent('Invite.Copy.Link.Success');
    });

    return () => {
      clip.destroy();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const ref = setTimeout(() => {
      if (copySuccess) {
        setCopySuccess(false);
      }
    }, 3000);
    return () => {
      clearTimeout(ref);
    };
  }, [copySuccess]);

  const validUrl = !isSignup ? url : `${window.origin}/signup`;
  return (
    <div
      style={{ display: 'flex', alignItems: 'center' }}
      id="copy_url"
      data-clipboard-text={validUrl}
    >
      <Button
        disabled={isFetching}
        loading={isFetching}
        type={copySuccess ? 'primary' : 'tertiary'}
        styles={{ marginLeft: 10 }}
      >
        {copySuccess ? 'Copied!' : 'Copy'}
      </Button>
    </div>
  );
};

const InvitationLink = ({
  role,
  isSignup,
}: {
  role: 'student' | 'mentor';
  isSignup?: boolean;
}) => {
  return (
    <div>
      <p>Invite a {role}</p>
      <InvitationInputWithButton role={role} isSignup={isSignup} />
    </div>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export default InvitationLink;
