import Axios from 'axios-observable';
import { TFunction } from 'react-i18next';
import { empty } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { isOfType } from 'typesafe-actions';

import { Round, RoundOptions, RoundType, SlideType } from '../services/backendService';
import { Metrics } from '../services/metrics';
import theme from '../theme';
import { RootAction } from '.';
import { SIGN_OUT } from './User';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const catchHandler = (error: any) => {
  // eslint-disable-next-line no-console
  console.error(error);
  Metrics.getLogger().logEvent('error', error.message);

  return empty();
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const unSubscribeOnSignOut = (action$: any) =>
  takeUntil<RootAction>(action$.pipe(filter(isOfType(SIGN_OUT))));

export type APIState = {
  isLoading: boolean;
  error?: string;
};

export const DEFAULT_API_STATE: APIState = {
  isLoading: false,
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const errorAction = <T extends Function>(action: T) => (error: string) =>
  action({ error });

export const fetchBlob = (url: string) =>
  Axios.request({
    method: 'get',
    url,
    responseType: 'blob',
  }).pipe(map(res => res.data));

export function findRoundsByReferencesReverse(startRoundId: string, rounds: Round[]) {
  const startRound = rounds.find(r => r.id === startRoundId);
  if (!startRound) {
    return [];
  }

  const tempRounds = [startRound];

  while (tempRounds.length <= 3) {
    const nextRound = rounds.find(r => r.dataReferenceRoundId === tempRounds[0].id);
    if (nextRound) {
      tempRounds.unshift(nextRound);
    } else {
      break;
    }
  }
  return tempRounds.reverse();
}

export function listRoundsByReferences(startRoundId: string, rounds: Round[]) {
  const startRound = rounds.find(r => r.id === startRoundId);

  if (!startRound) {
    return [];
  }

  let dataReferenceRoundId = startRound.dataReferenceRoundId;
  const tempRounds = [startRound] as Round[];
  while (dataReferenceRoundId) {
    // eslint-disable-next-line no-loop-func
    const relatedRound = rounds.find(r => r.id === dataReferenceRoundId);
    if (relatedRound) {
      tempRounds.unshift(relatedRound);
      dataReferenceRoundId = relatedRound.dataReferenceRoundId;
    } else {
      dataReferenceRoundId = undefined;
    }
  }
  return tempRounds;
}

export function findRelatedRounds(startRoundId: string, rounds: Round[]) {
  const referenceRounds = findRelatedRoundsWithoutIndex(startRoundId, rounds);

  return referenceRounds.map(r => ({
    ...r,
    index: rounds.findIndex(round => round.id === r.id) + 1,
  }));
}

export function findRelatedRoundsWithoutIndex(startRoundId: string, rounds: Round[]) {
  const reverseRounds = findRoundsByReferencesReverse(startRoundId, rounds);
  if (reverseRounds.length === 0) {
    return [];
  }
  const last = reverseRounds[reverseRounds.length - 1];

  const referenceRounds = listRoundsByReferences(last.id, rounds);
  return referenceRounds;
}

export function generateUniqueId() {
  return Math.floor(Math.random() * Date.now());
}

export function areAllRoundsValid(rounds: Round[]) {
  if (rounds.length === 0) return false;
  return true;
}

export type DurationMapperType = {
  voting: number[];
  answers: number[];
  questions: number[];
  arguments: number[];
  ideas: number[];
  podium: number[];
  slide: number[];
  canvas: number[];
  responsegrid: number[];
  mindmap: number[];
  poll: number[];
  relations: number[];
  wordcloud: number[];
  network: number[];
};

export const CreatorDurationMapper: DurationMapperType = {
  voting: [20, 30, 60, 90, 120, 180, 240, 300, 600],
  relations: [20, 30, 60, 120, 180, 240, 300, 600],
  answers: [10, 20, 30, 60, 90, 120, 180, 300, 600, 1200],
  mindmap: [10, 20, 30, 60, 90, 120, 180, 300],
  questions: [10, 20, 30, 60, 90, 120, 180, 300],
  arguments: [60, 90, 120, 180, 300],
  ideas: [10, 20, 30, 60, 90, 120, 180, 300, 600],
  wordcloud: [10, 20, 30, 60, 90, 120, 180, 300],
  poll: [10, 20, 30, 60, 90, 120, 180, 300],
  podium: [] as number[],
  canvas: [30, 60, 120, 300, 600, 1200, 1800] as number[],
  responsegrid: [] as number[],
  slide: [] as number[],
  network: [] as number[],
};

export const creatorResponseTypePlaceholders = (
  t: TFunction<'Host'>,
  type?: RoundType,
) => {
  if (!type) return '';
  if (type === 'questions') return t('Ask me anything');
  if (type === 'relations') return t('Make connections from topic');
  if (type === 'poll') return t('Your question');
  if (type === 'mindmap' || type === 'wordcloud')
    return t('realateToNorway', 'e.g. what do you relate to Norway?');
  if (type === 'answers') return t('Your open ended question');
  if (type === 'ideas')
    return t(
      'realateToIndustrial',
      'e.g. what do you relate to the industrial revolution?',
    );
  if (type === 'canvas') return t('Draw a line chart');
  return '';
};
export const creatorResponseTypeTooltip = (t: TFunction<'Host'>, type?: RoundType) => {
  if (!type) return '';
  if (type === 'questions') return t('Enter the topic you want questions about');
  if (type === 'poll') return t('Enter the topic you want participants to answer');
  if (type === 'mindmap' || type === 'wordcloud')
    return t('Enter the topic you want assosiations about');
  if (type === 'answers')
    return t('Enter the question you want to ask your participants');
  if (type === 'ideas') return t('e.g. what do you relate to the industrial revolution?');
  if (type === 'canvas') return t('Enter the question you want to ask your participants');
  return '';
};

export const creatorSlideTypePlaceholders = (t: TFunction<'Host'>, type?: SlideType) => {
  if (!type) return '';
  if (type === 'header') return t('writeTitle', 'e.g. write your title');
  if (type === 'bullets')
    return t('addBullet', 'e.g. Add some bullets to aid your activity');
  if (type === 'image') return t('needAImageTitle', 'e.g. need a title for the image?');
  return '';
};

export type RoundCategory = 'response' | 'collaboration' | 'results' | 'slide';

export const getRoundCategory = (type?: RoundType): RoundCategory | undefined => {
  if (!type) return 'response';
  switch (type) {
    case 'answers':
    case 'canvas':
    case 'ideas':
    case 'mindmap':
    case 'poll':
    case 'wordcloud':
    case 'questions':
      return 'response';
    case 'voting':
    case 'relations':
      return 'collaboration';
    case 'network':
    case 'podium':
    case 'responsegrid':
      return 'results';
    case 'slide':
      return 'slide';
  }
};

const INDEX_COLORS = [
  theme.colors.orange,
  theme.colors.green,
  theme.colors.purple,
  theme.colors.blue,
  theme.colors.red,
  theme.colors.yellow,
  theme.colors.greyLight,
  theme.colors.aqua,
  theme.colors.black,
  theme.colors.pink,
];
export const getIndexToColor = (index?: number) => {
  if (index === undefined) return '';
  if (index > INDEX_COLORS.length - 1) return INDEX_COLORS[0];
  return INDEX_COLORS[index];
};

export const generateRoundOptionColor = (existingColors: string[]) => {
  const availableColors = INDEX_COLORS.filter(color => !existingColors.includes(color));

  if (availableColors.length === 0) {
    throw new Error('Not any more colors');
  }
  return availableColors[0];
};

export const generateDefaultRoundOptions = (count: number) => {
  return new Array(count)
    .fill(0)
    .reduce<string[]>(acc => [...acc, generateRoundOptionColor(acc)], [])
    .map(
      color =>
        ({
          id: generateUniqueId().toString(),
          displayName: ``,
          color,
        } as RoundOptions),
    );
};

export const hasSlideTitle = (type?: SlideType) => {
  if (!type) return false;
  switch (type) {
    case 'bullets':
    case 'header':
    case 'image':
      return true;
    default:
      return false;
  }
};
export const hasSlideMedia = (type?: SlideType) => {
  if (!type) return false;
  switch (type) {
    case 'fullscreen_media':
    case 'image':
      return true;
    default:
      return false;
  }
};

export const isSlideType = (type?: string) => {
  if (!type) return false;
  switch (type) {
    case 'fullscreen_media':
    case 'image':
    case 'bullets':
    case 'header':
      return true;
    default:
      return false;
  }
};

export const hasVoting = (type?: string) => {
  if (!type) return false;
  switch (type) {
    case 'canvas':
    case 'answers':
    case 'questions':
      return true;
    default:
      return false;
  }
};
