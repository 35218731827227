import React from 'react';

export const CuripodNormal: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="59"
    height="59"
    viewBox="0 0 59 59"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M58.03 29.0168C58.0302 32.8269 57.28 36.5996 55.8221 40.1197C54.3643 43.6398 52.2273 46.8383 49.5333 49.5324C46.8394 52.2266 43.6411 54.3637 40.1211 55.8217C36.6012 57.2798 32.8285 58.0301 29.0186 58.03C24.9468 58.03 20.1469 57.826 16.8939 55.0637C13.1799 51.9122 12.6594 46.9054 13.676 42.3894C14.1365 40.1281 15.1381 38.0121 16.5951 36.2225C18.052 34.4329 19.9208 33.023 22.0417 32.1135C23.9408 31.3627 25.9671 30.986 28.0092 31.0042C32.0746 31.0042 34.5867 32.2001 36.6551 33.1848C38.1026 33.8739 39.3329 34.4595 40.7261 34.4595C43.0011 34.4595 44.9849 33.1364 44.9849 30.4191C44.9849 25.9354 37.271 21.8904 28.4514 21.8904C14.1597 21.8904 5.06503 31.0995 3.65653 43.1166C1.50333 39.2441 0.265605 34.93 0.0382327 30.5049C-0.18914 26.0799 0.599899 21.6615 2.34484 17.5887C4.08978 13.5159 6.74423 9.89685 10.1047 7.00906C13.4652 4.12127 17.4423 2.04146 21.7312 0.929151C26.0201 -0.183158 30.5067 -0.298429 34.847 0.59218C39.1873 1.48279 43.266 3.35563 46.7704 6.06705C50.2747 8.77848 53.1115 12.2565 55.0632 16.2343C57.015 20.2121 58.0299 24.5841 58.03 29.015V29.0168ZM23.815 50.8913C26.2912 50.8913 28.2985 48.8839 28.2985 46.4077C28.2985 43.9314 26.2912 41.9241 23.815 41.9241C21.3389 41.9241 19.3315 43.9314 19.3315 46.4077C19.3315 48.8839 21.3389 50.8913 23.815 50.8913ZM41.313 46.4074C41.313 48.8836 39.3057 50.891 36.8296 50.891C34.3534 50.891 32.3461 48.8836 32.3461 46.4074C32.3461 43.9311 34.3534 41.9238 36.8296 41.9238C39.3057 41.9238 41.313 43.9311 41.313 46.4074Z"
      fill="currentColor"
    />
  </svg>
);

export default CuripodNormal;
