import { combineEpics } from 'redux-observable';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { isOfType } from 'typesafe-actions';

import i18n, { getDefaultLanguageFromLocalStorage } from '../../singletons/i18n';
import { RootEpic } from '..';
import { catchHandler } from '../utils';
import { publicDataReceived } from './actions';
import { PUBLIC_DATA_SUBSCRIBED } from './actionTypes';

export const publicSubscribed: RootEpic = (action$, _, { database }) =>
  action$.pipe(
    filter(isOfType(PUBLIC_DATA_SUBSCRIBED)),
    switchMap(() =>
      database.getPublic().pipe(
        map(value => publicDataReceived(value)),
        tap(action => {
          if (
            action.payload.defaultLanguage?.lang &&
            getDefaultLanguageFromLocalStorage() === null
          ) {
            const language = action.payload.defaultLanguage?.lang;
            i18n.changeLanguage(language);
          }
        }),
        catchError(catchHandler),
      ),
    ),
  );

export const serverRootEpic = combineEpics(publicSubscribed);
