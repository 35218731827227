import React from 'react';

export const ArrowDownSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="10"
    height="5"
    viewBox="0 0 10 5"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 0L5 5L10 0H0Z" />
  </svg>
);

export default ArrowDownSvg;
