import React from 'react';

export const SlideBulletsSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="31"
    height="31"
    viewBox="0 0 31 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2812_50017)">
      <path
        d="M28.3125 0.5H2.6875C1.48125 0.5 0.5 1.48125 0.5 2.6875V5.8125C0.5 7.01875 1.48125 8 2.6875 8H28.3125C29.5187 8 30.5 7.01875 30.5 5.8125V2.6875C30.5 1.48125 29.5187 0.5 28.3125 0.5Z"
        fill="currentColor"
      />
      <path
        d="M29.5625 13H11.4375C10.92 13 10.5 13.42 10.5 13.9375C10.5 14.455 10.92 14.875 11.4375 14.875H29.5625C30.08 14.875 30.5 14.455 30.5 13.9375C30.5 13.42 30.08 13 29.5625 13Z"
        fill="currentColor"
      />
      <path
        d="M29.5625 16.75H11.4375C10.92 16.75 10.5 17.17 10.5 17.6875C10.5 18.205 10.92 18.625 11.4375 18.625H29.5625C30.08 18.625 30.5 18.205 30.5 17.6875C30.5 17.17 30.08 16.75 29.5625 16.75Z"
        fill="currentColor"
      />
      <path
        d="M29.5625 24.25H11.4375C10.92 24.25 10.5 24.67 10.5 25.1875C10.5 25.705 10.92 26.125 11.4375 26.125H29.5625C30.08 26.125 30.5 25.705 30.5 25.1875C30.5 24.67 30.08 24.25 29.5625 24.25Z"
        fill="currentColor"
      />
      <path
        d="M29.5625 28H11.4375C10.92 28 10.5 28.42 10.5 28.9375C10.5 29.455 10.92 29.875 11.4375 29.875H29.5625C30.08 29.875 30.5 29.455 30.5 28.9375C30.5 28.42 30.08 28 29.5625 28Z"
        fill="currentColor"
      />
      <path
        d="M4.25 19.25C6.32107 19.25 8 17.5711 8 15.5C8 13.4289 6.32107 11.75 4.25 11.75C2.17893 11.75 0.5 13.4289 0.5 15.5C0.5 17.5711 2.17893 19.25 4.25 19.25Z"
        fill="currentColor"
      />
      <path
        d="M4.25 30.5C6.32107 30.5 8 28.8211 8 26.75C8 24.6789 6.32107 23 4.25 23C2.17893 23 0.5 24.6789 0.5 26.75C0.5 28.8211 2.17893 30.5 4.25 30.5Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default SlideBulletsSvg;
