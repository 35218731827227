import React from 'react';

export const DisabledSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 9.99994C0 15.5139 4.48593 19.9999 9.99994 19.9999C15.5139 19.9999 19.9999 15.5139 19.9999 9.99994C19.9999 4.48593 15.5141 0 9.99994 0C4.48582 0 0 4.48593 0 9.99994ZM9.99994 18.2116C5.47204 18.2116 1.78837 14.528 1.78837 9.99994C1.78837 8.05754 2.46771 6.27156 3.5995 4.864L15.1359 16.4004C13.7283 17.5322 11.9425 18.2116 9.99994 18.2116ZM18.2115 9.99994C18.2115 11.9423 17.5322 13.7283 16.4005 15.1359L4.864 3.5995C6.27156 2.46771 8.05754 1.78837 9.99994 1.78837C14.5278 1.78837 18.2115 5.47204 18.2115 9.99994Z"
      fill="currentColor"
    />
  </svg>
);

export default DisabledSvg;
