import styled from 'styled-components';

import theme from '../theme';

export const UnreadTag = styled.div`
  height: 14px;
  width: 14px;
  border-radius: 14px;
  background-color: ${theme.colors.green};
`;
