import { ExcalidrawElement } from '@excalidraw/excalidraw-next/types/element/types';
import Axios from 'axios';
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
  uploadString,
} from 'firebase/storage';

const IMAGES_PATH = 'images';
const SNIPPET_IMAGES_PATH = 'snippet_images';
const COMMENT_IMAGES_PATH = 'comment_images';
const GAME_FILES_PATH = 'game_files';
const GAME_RESPONSES_PATH = 'game_responses';

const PROFILE_IMAGES_PATH = 'profileAvatars';

export default class Files {
  static instance: Files;
  private tenantId: string;
  constructor() {
    const tenantId = 'not-initialised';
    this.tenantId = tenantId;

    if (Files.instance) {
      return Files.instance;
    }
    Files.instance = this;
    return this;
  }

  public setTenantId(tenantId: string) {
    this.tenantId = tenantId;
  }

  private rootRef(path: string) {
    return ref(getStorage(), `tenants/${this.tenantId}/${path}`);
    // return firebase
    //   .storage()
    //   .ref('tenants')
    //   .child(this.tenantId);
  }

  private uploadFileFromPath = async (path: string, dataurl: string) => {
    const res = await uploadString(this.rootRef(path), dataurl, 'data_url');
    return res;
  };

  public uploadExcalidrawasJSONFile = async (
    fileId: string,
    elements: readonly ExcalidrawElement[],
  ) => {
    const jsonString = JSON.stringify(elements);
    const blob = new Blob([jsonString], { type: 'application/json' });
    const res = await uploadBytes(this.rootRef(`${GAME_FILES_PATH}/${fileId}`), blob);
    return res;
  };

  public uploadDataUrlImage = async (
    imageName: string,
    chatRoomId: string,
    dataurl: string,
  ) => {
    return this.uploadFileFromPath(`${IMAGES_PATH}/${chatRoomId}/${imageName}`, dataurl);
    // const snapshot = await this.rootRef()
    //   .child(IMAGES_PATH)
    //   .child(chatRoomId)
    //   .child(imageName)
    //   .putString(dataurl, 'data_url');
    // return snapshot;
  };

  public uploadGameFile = async (fileId: string, dataurl: string) => {
    return this.uploadFileFromPath(`${GAME_FILES_PATH}/${fileId}`, dataurl);
    // const snapshot = await this.rootRef()
    //   .child(GAME_FILES_PATH)
    //   .child(fileId)
    //   .putString(dataurl, 'data_url');
    // return snapshot;
  };

  public importPresentation = async (fileId: string, file: File) => {
    const res = await uploadBytes(this.rootRef(`${GAME_FILES_PATH}/${fileId}`), file);
    return res;
  };

  public uploadSnippetImage = async (
    imageName: string,
    snippetId: string,
    dataurl: string,
  ) => {
    return this.uploadFileFromPath(
      `${SNIPPET_IMAGES_PATH}/${snippetId}/${imageName}`,
      dataurl,
    );
    // const snapshot = await this.rootRef()
    //   .child(SNIPPET_IMAGES_PATH)
    //   .child(snippetId)
    //   .child(imageName)
    //   .putString(dataurl, 'data_url');
    // return snapshot;
  };

  public uploadCommentImage = async (
    imageName: string,
    commentId: string,
    dataurl: string,
  ) => {
    return this.uploadFileFromPath(
      `${COMMENT_IMAGES_PATH}/${commentId}/${imageName}`,
      dataurl,
    );
    // const snapshot = await this.rootRef()
    //   .child(COMMENT_IMAGES_PATH)
    //   .child(commentId)
    //   .child(imageName)
    //   .putString(dataurl, 'data_url');
    // return snapshot;
  };

  public getSnippetImageDownloadUrl = async (snippetId: string, imageId: string) => {
    const url = await getDownloadURL(
      this.rootRef(`${SNIPPET_IMAGES_PATH}/${snippetId}/${imageId}`),
    );
    return url;
    // const imageRef = this.rootRef()
    //   .child(SNIPPET_IMAGES_PATH)
    //   .child(snippetId)
    //   .child(imageId);
    // return imageRef.getDownloadURL();
  };

  public getCommentImageDownloadUrl = async (commentId: string, imageId: string) => {
    const url = await getDownloadURL(
      this.rootRef(`${COMMENT_IMAGES_PATH}/${commentId}/${imageId}`),
    );
    return url;
    // const imageRef = this.rootRef()
    //   .child(COMMENT_IMAGES_PATH)
    //   .child(commentId)
    //   .child(imageId);
    // return imageRef.getDownloadURL();
  };

  public getGameFileDownloadUrl = async (gameFileId: string) => {
    const url = await getDownloadURL(this.rootRef(`${GAME_FILES_PATH}/${gameFileId}`));
    return url;
    // const imageRef = this.rootRef()
    //   .child(GAME_FILES_PATH)
    //   .child(gameFileId);
    // return imageRef.getDownloadURL();
  };

  public getImageDownloadUrl = async (chatRoomId: string, imageId: string) => {
    const url = await getDownloadURL(
      this.rootRef(`${IMAGES_PATH}/${chatRoomId}/${imageId}`),
    );
    return url;
    // const imageRef = this.rootRef()
    //   .child(IMAGES_PATH)
    //   .child(chatRoomId)
    //   .child(imageId);
    // return imageRef.getDownloadURL();
  };

  public getDrawingFileDownloadUrl = async (responseId: string) => {
    const url = await getDownloadURL(
      this.rootRef(`${GAME_RESPONSES_PATH}/${responseId}`),
    );
    return url;
    // const imageRef = this.rootRef()
    //   .child(GAME_RESPONSES_PATH)
    //   .child(responseId);
    // return imageRef.getDownloadURL();
  };

  private async fetchExcalidrawJSONElements(url: string) {
    const res = await Axios.request<ExcalidrawElement[]>({
      method: 'get',
      url,
      responseType: 'json',
    });
    const elements = res.data;
    return elements;
  }

  public getArticleDownloadUrl = async (path: string) => {
    const url = await getDownloadURL(this.rootRef(`${path}`));
    return url;
    // const imageRef = this.rootRef().child(path);
    // const url = await imageRef.getDownloadURL();

    // return url;
  };

  game = {
    getGameMediaJSON: async (fileId: string) => {
      const url = await this.getGameFileDownloadUrl(fileId);
      const elements = await this.fetchExcalidrawJSONElements(url);
      return elements;
    },
    get: async (fileId: string) => {
      try {
        const url = await this.getGameFileDownloadUrl(fileId);

        const objUrl = await this.fetchBlob(url);
        return objUrl;
      } catch (e) {
        return undefined;
      }
    },
  };

  fetchBlob = async (url: string) => {
    const res = await Axios.request<Blob>({
      method: 'get',
      url,
      responseType: 'blob',
    });
    const objUrl = URL.createObjectURL(res.data);
    return objUrl;
  };

  profilePicture = {
    upload: async (imgData: string, uid: string) => {
      return this.uploadFileFromPath(`${PROFILE_IMAGES_PATH}/${uid}`, imgData);
      // const snapshot = this.rootRef()
      //   .child(PROFILE_IMAGES_PATH)
      //   .child(uid)
      //   .putString(imgData, 'data_url');
      // return snapshot;
    },
    get: async (uid: string) => {
      try {
        const url = await getDownloadURL(this.rootRef(`${PROFILE_IMAGES_PATH}/${uid}`));
        const objUrl = await this.fetchBlob(url);
        return objUrl;
      } catch (e) {
        return undefined;
      }
    },
  };
}
