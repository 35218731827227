import React, { CSSProperties } from 'react';

import useMe from '../../../hooks/useMe';
import { useProfilePhoto } from '../../../hooks/useProfilePhoto';
import Profile from '../../_atoms/_Profile';

const SmartProfile = ({
  style,
  radius,
  onClick,
  onImageLoaded,
  backgroundColor,
}: {
  style?: CSSProperties;
  radius?: number;
  onClick?: () => void;
  onImageLoaded?: () => void;
  backgroundColor?: string;
}) => {
  const { firstLetter } = useMe();
  const { photo } = useProfilePhoto();
  return (
    <Profile
      radius={radius}
      style={style}
      img={photo}
      onClick={onClick}
      firstLetter={firstLetter}
      onImageLoaded={onImageLoaded}
      backgroundColor={backgroundColor}
    />
  );
};

export default SmartProfile;
