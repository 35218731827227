import ClipboardJS from 'clipboard';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { Button } from '../components/_atoms/_Button';
import { BrainExplosionIcon, StarStruckIcon } from '../components/_atoms/_Icons';
import { useMetrics } from '../hooks';
import { IconWrapper } from '../styled';
import { isUnSupportedBrowser } from '../utils/navigatorUtils';

const UnSupportedBrowserHandler = () => {
  const ref = useRef(new ClipboardJS('#copy_url'));

  const [copySuccess, setCopySuccess] = useState(false);

  useEffect(() => {
    const clip = ref.current;

    clip.on('success', () => {
      setCopySuccess(true);
    });

    return () => {
      clip.destroy();
    };
  }, []);
  return (
    <MessageContainer>
      {!copySuccess && (
        <>
          <IconWrapper style={{ marginBottom: 50 }} height="100px" width="100px">
            <BrainExplosionIcon />
          </IconWrapper>
          <p style={{ fontSize: 18 }}>Curipod is working in most browsers</p>
          <p style={{ fontSize: 18 }}>but not this one...</p>
          <p style={{ fontSize: 18 }}>To use Curipod: </p>
          <div
            style={{ marginBottom: 20 }}
            id="copy_url"
            data-clipboard-text={`${window.location.href}`}
          >
            <Button type="primary">Click me</Button>
          </div>
          <p style={{ fontSize: 18 }}> to copy the url</p>
        </>
      )}
      {copySuccess && (
        <>
          <IconWrapper style={{ marginBottom: 50 }} height="100px" width="100px">
            <StarStruckIcon />
          </IconWrapper>
          <p style={{ fontSize: 22 }}>Great job!</p>
          <p style={{ fontSize: 18, marginBottom: 2 }}>Now open your favourite browser</p>
          <p style={{ fontSize: 14 }}>(Chrome, Safari or Firefox)</p>
          <p style={{ fontSize: 18 }}>and paste the url you just copied!</p>
          <p style={{ fontSize: 22 }}>Good luck!</p>
        </>
      )}
    </MessageContainer>
  );
};

const SupportedBrowserProvider = ({
  children,
}: {
  children: React.ReactChildren | React.ReactChild;
}) => {
  const metrics = useMetrics();
  useEffect(() => {
    if (navigator.userAgent) {
      metrics.logEvent('USERAGENT', { agent: navigator.userAgent });
    }
  }, [metrics]);

  if (isUnSupportedBrowser()) {
    return <UnSupportedBrowserHandler />;
  }

  return <>{children}</>;
};

const MessageContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  p {
    text-align: center;
  }
`;

export default SupportedBrowserProvider;
