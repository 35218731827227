import { createAction } from 'typesafe-actions';

import * as ActionTypes from './actionTypes';

export interface NotificationPayload {
  title: string;
  body: string;
  data: { [key: string]: string };
}

export const receiveNotification = createAction(
  ActionTypes.NOTIFICATION_RECEIVED,
  action => (data: NotificationPayload) => action(data),
);

export const removeNotification = createAction(ActionTypes.NOTIFICATION_REMOVED);

export const subscribeToNotifications = createAction(ActionTypes.NOTIFICATION_SUBSCRIBE);

export const updateServiceWorker = createAction(ActionTypes.UPDATE_SERVICE_WORKER);
export const updateServiceWorkerSuccess = createAction(
  ActionTypes.UPDATE_SERVICE_WORKER_SUCCESS,
);
export const updateServiceWorkerError = createAction(
  ActionTypes.UPDATE_SERVICE_WORKER_ERROR,
  action => (error: string) => action({ error }),
);
export const alertNewVersionAvailable = createAction(
  ActionTypes.NEW_VERSION_AVAILABLE,
  action => (reg: ServiceWorkerRegistration) => action({ reg }),
);
