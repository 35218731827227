import React from 'react';

export const PictureSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="31"
    height="31"
    viewBox="0 0 31 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2812_50027)">
      <path
        d="M27.6133 4.07422H4.05859C3.20418 4.07515 2.38502 4.41498 1.78086 5.01914C1.1767 5.6233 0.836868 6.44246 0.835938 7.29688V23.7031C0.836868 24.5575 1.1767 25.3767 1.78086 25.9809C2.38502 26.585 3.20418 26.9249 4.05859 26.9258H27.6133C28.4677 26.9249 29.2869 26.585 29.891 25.9809C30.4952 25.3767 30.835 24.5575 30.8359 23.7031V7.29688C30.835 6.44246 30.4952 5.6233 29.891 5.01914C29.2869 4.41498 28.4677 4.07515 27.6133 4.07422ZM2.59375 7.29688C2.59375 6.90837 2.74808 6.53579 3.02279 6.26107C3.2975 5.98636 3.67009 5.83203 4.05859 5.83203H27.6133C28.0018 5.83203 28.3744 5.98636 28.6491 6.26107C28.9238 6.53579 29.0781 6.90837 29.0781 7.29688V23.7031C29.0781 23.7424 29.0781 23.7805 29.0729 23.8203L21.8072 16.0719C21.7253 15.9842 21.6264 15.9143 21.5164 15.8663C21.4065 15.8183 21.2879 15.7934 21.168 15.793C21.0478 15.793 20.9289 15.8176 20.8187 15.8654C20.7084 15.9132 20.6091 15.9831 20.527 16.0707L18.874 17.8338L14.084 12.565C14.0016 12.4744 13.9012 12.402 13.7892 12.3524C13.6772 12.3029 13.5561 12.2773 13.4336 12.2773C13.3111 12.2773 13.19 12.3029 13.078 12.3524C12.966 12.402 12.8656 12.4744 12.7832 12.565L2.59375 23.7717C2.59375 23.7486 2.59375 23.7258 2.59375 23.7031V7.29688ZM4.05859 25.168C3.94958 25.1679 3.84091 25.1557 3.73457 25.1316L13.4336 14.4629L17.6676 19.1205L15.7709 21.1438C15.6901 21.2276 15.6268 21.3267 15.5847 21.4352C15.5426 21.5438 15.5224 21.6596 15.5254 21.776C15.5284 21.8924 15.5545 22.007 15.6021 22.1132C15.6498 22.2195 15.7181 22.3151 15.8031 22.3948C15.888 22.4744 15.988 22.5363 16.0971 22.577C16.2062 22.6176 16.3222 22.6362 16.4386 22.6316C16.5549 22.627 16.6692 22.5994 16.7748 22.5503C16.8803 22.5011 16.9751 22.4315 17.0535 22.3455L21.168 17.9568L27.9016 25.1393C27.8067 25.1583 27.7101 25.1679 27.6133 25.168H4.05859Z"
        fill="currentColor"
      />
      <path
        d="M23.8047 14.6211C24.5 14.6211 25.1797 14.4149 25.7579 14.0286C26.336 13.6423 26.7866 13.0932 27.0527 12.4508C27.3188 11.8084 27.3884 11.1016 27.2527 10.4196C27.1171 9.73764 26.7823 9.11121 26.2906 8.61955C25.7989 8.12788 25.1725 7.79305 24.4905 7.6574C23.8086 7.52175 23.1017 7.59137 22.4593 7.85746C21.8169 8.12354 21.2679 8.57415 20.8816 9.15229C20.4952 9.73043 20.2891 10.4101 20.2891 11.1055C20.2901 12.0375 20.6609 12.9311 21.32 13.5902C21.979 14.2493 22.8726 14.62 23.8047 14.6211ZM23.8047 9.34766C24.1523 9.34766 24.4922 9.45075 24.7813 9.6439C25.0703 9.83705 25.2956 10.1116 25.4287 10.4328C25.5617 10.754 25.5965 11.1074 25.5287 11.4484C25.4609 11.7894 25.2935 12.1026 25.0476 12.3484C24.8018 12.5943 24.4886 12.7617 24.1476 12.8295C23.8066 12.8973 23.4532 12.8625 23.132 12.7295C22.8108 12.5964 22.5363 12.3711 22.3431 12.0821C22.15 11.793 22.0469 11.4531 22.0469 11.1055C22.0469 10.6393 22.2321 10.1922 22.5617 9.8625C22.8914 9.53285 23.3385 9.34766 23.8047 9.34766Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default PictureSvg;
