import { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { selectSnapmentorSchool } from '../modules';
import { BackendService, UserTenant } from '../services/backendService';
import { isLaunchedInPWA } from '../utils/pwaUtils';
import { useTypedSelector } from '.';
import useIsSignedIn from './useIsSignedIn';

function useTenants(openNewTab?: boolean) {
  const tenantsCache = 'tenants';
  const client = useQueryClient();
  const snapmentorSchoolTenantId = useTypedSelector(selectSnapmentorSchool);
  const { isSignedIntoFirebase } = useIsSignedIn();
  const { data, isFetching, error } = useQuery(
    tenantsCache,
    () => {
      if (!isSignedIntoFirebase) {
        return Promise.resolve(undefined);
      }
      return BackendService.instance.retrieveCurrentTenants(true);
    },
    { staleTime: 1000 * 60 * 5 },
  );

  const { mutate } = useMutation<UserTenant, void, string>(
    (tenantId: string) => BackendService.instance.switchTenant(tenantId),
    {
      onSuccess: res => {
        client.invalidateQueries(tenantsCache);
        if (isLaunchedInPWA()) {
          window.location.replace(`${window.location.origin}/${res.activeTenantId}`);
          return;
        }
        if (openNewTab) {
          window.open(`${window.location.origin}/${res.activeTenantId}`, '__blank');
        } else {
          window.open(`${window.location.origin}/${res.activeTenantId}`, '__blank');
        }
      },
    },
  );

  const filteredTenants = useMemo(() => {
    if (data) {
      return {
        ...data,
        user: {
          ...data.user,
          availableTenants: data.user.availableTenants.filter(
            t => t.tenantId !== snapmentorSchoolTenantId,
          ),
        },
      };
    }
  }, [data, snapmentorSchoolTenantId]);

  return {
    data,
    filteredTenants,
    error,
    isLoading: isFetching,
    switch: mutate,
  };
}

export default useTenants;
