import React from 'react';

export const UserSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M13.0157 9.77363C14.2954 8.84031 15.1288 7.32984 15.1288 5.6282C15.1288 2.80051 12.8283 0.5 10.0006 0.5C7.17289 0.5 4.87238 2.80051 4.87238 5.6282C4.87238 7.32984 5.7057 8.84031 6.98547 9.77363C3.80422 10.991 1.53906 14.0754 1.53906 17.6795C1.53906 19.2347 2.80434 20.5 4.35957 20.5H15.6416C17.1968 20.5 18.4621 19.2347 18.4621 17.6795C18.4621 14.0754 16.197 10.991 13.0157 9.77363ZM6.41086 5.6282C6.41086 3.64883 8.02121 2.03848 10.0006 2.03848C11.98 2.03848 13.5903 3.64883 13.5903 5.6282C13.5903 7.60758 11.98 9.21797 10.0006 9.21797C8.02121 9.21797 6.41086 7.60758 6.41086 5.6282ZM15.6416 18.9615H4.35957C3.65266 18.9615 3.07754 18.3864 3.07754 17.6795C3.07754 13.862 6.18316 10.7564 10.0006 10.7564C13.8181 10.7564 16.9237 13.862 16.9237 17.6795C16.9237 18.3864 16.3486 18.9615 15.6416 18.9615Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default UserSvg;
