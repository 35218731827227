import React from 'react';

export const ClapsSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="61"
    height="62"
    viewBox="0 0 61 62"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.2319 12.3416C23.7691 11.41 24.1492 10.2797 25.0807 9.81698C26.0123 9.35424 27.1426 9.7343 27.6053 10.6659L33.7747 23.0855C34.4732 22.9647 35.177 22.9402 35.8681 23.0058L31.0915 13.3898C30.6287 12.4583 31.0088 11.328 31.9403 10.8652C32.8719 10.4025 34.0022 10.7825 34.4649 11.7141L42.4296 27.7481C42.4557 27.8007 42.4792 27.8538 42.5 27.9075L43.7395 30.4029L44.2095 29.0723L44.2095 23.8084C44.2095 23.8084 44.5772 19.9802 47.0041 18.7746C48.7 17.9322 52.0071 28.8462 49.7354 36.4556C47.6767 43.3517 38.2238 46.0219 38.2238 46.0219L38.4935 45.2582C35.5073 45.3274 32.6071 43.7011 31.1904 40.8491L28.0111 34.4488C27.3909 33.2003 27.1297 31.8672 27.188 30.5667L21.1168 18.3446C20.6541 17.4131 21.0341 16.2828 21.9657 15.82C22.8972 15.3573 24.0275 15.7373 24.4903 16.6689L29.0332 25.8145C29.4089 25.3654 29.8378 24.954 30.3166 24.5909L24.2319 12.3416Z"
      fill="#D84C42"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.1203 27.6105C12.3848 26.875 12.3848 25.6825 13.1203 24.947C13.8558 24.2115 15.0483 24.2115 15.7838 24.947L25.5364 34.6996C25.7831 34.9463 26.1575 35.0122 26.4846 34.8909C27.1183 34.656 27.36 33.8216 26.8821 33.3437L19.7693 26.2309C19.0338 25.4954 19.0338 24.3029 19.7694 23.5674C20.5049 22.8319 21.6973 22.8319 22.4328 23.5674L33.7513 34.8859C33.8323 34.9669 33.9258 35.0292 34.0312 35.0743C34.8472 35.4231 36.0253 34.5568 35.7366 33.7176L35.3378 32.5586C35.3284 32.5315 35.3209 32.5071 35.3139 32.4792C35.1965 32.0121 34.1052 27.4351 35.9225 25.6177C37.2615 24.2787 43.3477 33.0429 43.3477 40.7075C43.3477 46.1044 38.5038 50.7061 36.3431 52.4902C32.5275 56.0051 26.6768 56.0701 22.7859 52.6853C22.7037 52.6263 22.6253 52.5599 22.5514 52.486L10.9071 40.8417C10.1716 40.1061 10.1716 38.9137 10.9071 38.1782C11.6426 37.4427 12.8351 37.4427 13.5706 38.1782L18.2219 42.8295C18.6689 43.2765 19.4584 43.0077 19.6197 42.3965C19.691 42.1263 19.6222 41.8346 19.4246 41.637L11.8939 34.1063C11.1584 33.3708 11.1584 32.1783 11.8939 31.4428C12.6294 30.7073 13.8219 30.7073 14.5574 31.4428L20.9531 37.8385C21.3128 38.1982 21.9021 38.1685 22.2618 37.8088C22.5536 37.5169 22.5536 37.0438 22.2618 36.752L13.1203 27.6105Z"
      fill="#FFA55B"
    />
    <path
      d="M10.122 14.174L15.2661 20.9691L8.17445 16.2422C7.15142 15.5603 7.39777 13.9927 8.58065 13.6575C9.15177 13.4957 9.76369 13.7007 10.122 14.174Z"
      fill="#282F33"
    />
    <path
      d="M8.50649 21.5473L13.0392 23.3422L8.17304 23.6386C7.47917 23.6808 6.9252 23.0687 7.03632 22.3824C7.14709 21.6984 7.86217 21.2922 8.50649 21.5473Z"
      fill="#282F33"
    />
    <path
      d="M18.1109 15.2049L18.3388 19.6007L16.2685 15.7163C15.9732 15.1624 16.2679 14.4777 16.8731 14.3113C17.4764 14.1454 18.0785 14.58 18.1109 15.2049Z"
      fill="#282F33"
    />
    <path
      d="M13.1029 24.9001C13.1637 25.2773 13.3395 25.6396 13.6303 25.9304L23.2207 35.5208C23.7605 36.0605 23.7605 36.9357 23.2207 37.4754C23.1002 37.5959 22.5778 37.5959 22.4573 37.4754C22.4573 37.4754 22.4931 37.3135 22.4817 37.21C22.4573 36.9896 22.3566 36.8413 22.2155 36.7002L13.1113 27.5986C12.3849 26.8722 12.3945 25.6376 13.1029 24.9001Z"
      fill="#D84C42"
    />
    <path
      d="M27.1323 33.9652C27.3475 34.0454 27.5528 34.0313 27.7731 33.9399C28.383 33.6865 28.6031 32.8745 28.1361 32.4075L20.2794 24.5507C19.9886 24.2599 19.8127 23.8977 19.7519 23.5205C19.0435 24.2579 19.0478 25.5111 19.7742 26.2375L26.8298 33.2823C27.0336 33.486 27.1645 33.7058 27.1323 33.9652Z"
      fill="#D84C42"
    />
    <path
      d="M40.3331 48.3886C40.3331 48.3886 31.2456 57.5741 23.2207 50.9651C23.1841 50.9284 23.1478 50.8915 23.1118 50.8544C23.0948 50.8386 23.078 50.8224 23.0614 50.8059L11.4171 39.1615C11.1263 38.8707 10.9505 38.5879 10.8896 38.2107C10.1812 38.9482 10.1632 40.0961 10.8896 40.8225L22.5526 52.4806C22.6257 52.5536 22.6829 52.6097 22.7641 52.6682C26.7586 56.1487 32.8916 55.9715 36.6944 52.1687L37.4908 51.4964C38.6221 50.5815 40.3331 48.3886 40.3331 48.3886Z"
      fill="#D84C42"
    />
    <path
      d="M19.6389 42.0531C20.0795 42.2619 20.6811 41.964 20.8339 41.4572C20.9176 41.18 20.8522 40.8744 20.6475 40.6697L12.4039 32.4261C12.1131 32.1353 11.9373 31.7731 11.8765 31.3959C11.1681 32.1333 11.1743 33.3747 11.9006 34.1011L19.402 41.6075C19.5232 41.7287 19.6173 41.8877 19.6389 42.0531Z"
      fill="#D84C42"
    />
  </svg>
);

export default ClapsSvg;
