import { useIsAuthenticated } from '@azure/msal-react';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useTypedSelector } from '../hooks';
import useHeartbeat from '../hooks/useHeartbeat';
import useIsSignedIn from '../hooks/useIsSignedIn';
import {
  fetchUsers,
  getIsTenantAdmin,
  getUserId,
  subscribeToPublicData,
  subscribeToUserType,
} from '../modules';

const RootSubscriber = ({ children }: { children: React.ReactChild }) => {
  const dispatch = useDispatch();
  const isAuthenticated = useIsAuthenticated();
  const { isSignedIntoFirebase } = useIsSignedIn();
  const uid = useTypedSelector(getUserId);
  const isAdmin = useTypedSelector(getIsTenantAdmin);
  useHeartbeat();

  useEffect(() => {
    if (isAuthenticated && uid && isSignedIntoFirebase) {
      dispatch(subscribeToUserType(uid));
      dispatch(subscribeToPublicData());
    }
  }, [uid, isAuthenticated, dispatch, isSignedIntoFirebase]);
  useEffect(() => {
    if (isAdmin) {
      dispatch(fetchUsers());
    }
  }, [dispatch, isAdmin]);
  return <>{children}</>;
};

export default RootSubscriber;
