import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import theme from '../theme';

const OfflineNotificationProvider = ({ children }: { children: React.ReactChild }) => {
  const { t } = useTranslation('Host');
  const [status, setStatus] = useState<boolean>(true);

  const checkStatus = () => {
    setStatus(window.navigator.onLine);
  };

  useEffect(() => {
    checkStatus();
    window.addEventListener('online', checkStatus);
    window.addEventListener('offline', checkStatus);
  }, []);

  return (
    <>
      {children}
      <AnimatePresence>
        {!status && (
          <motion.div
            key="pwaInstallModal"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <InstallContainer>
              <ExclamationContainer>
                <span>!</span>
              </ExclamationContainer>
              <p style={{ whiteSpace: 'break-spaces' }}>
                {t('It seems you are offline. Trying to reconnect...')}
              </p>
            </InstallContainer>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

const ExclamationContainer = styled.div`
  color: ${theme.colors.white};
  border-radius: 50px;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.colors.red};
  flex: none;
  margin-right: 10px;
  span {
    font-weight: bold;
  }
`;
const InstallContainer = styled.div`
  position: fixed;
  bottom: 2%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1001;
  max-width: 90%;
  border-left: 5px solid ${theme.colors.red};
  background-color: ${theme.colors.white};
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  display: flex;
  align-items: center;
  padding: 15px 15px 15px 10px;
  border-radius: 5px;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);
  @media screen and (max-width: 600px) {
    width: 90%;
  }
  @media screen and (min-width: 500px) {
    max-width: 600px;
    bottom: 2%;
    p {
      white-space: nowrap;
    }
  }

  p {
    color: ${theme.colors.black};
    font-weight: bold;
    flex: 1;
    margin-bottom: 0px;
    word-break: break-word;
  }
`;

export default OfflineNotificationProvider;
