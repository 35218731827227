import { useEffect, useMemo } from 'react';

import { useIsDesktop } from './useIsDesktop';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useOnOutsideClick = (ref: any, onOutSideClick: () => void) => {
  const isDesktop = useIsDesktop();
  const eventName = useMemo(() => (isDesktop ? 'mousedown' : 'touchend'), [isDesktop]);
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        onOutSideClick();
      }
    }

    // Bind the event listener
    document.addEventListener(eventName, handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener(eventName, handleClickOutside);
    };
  }, [onOutSideClick, ref, eventName]);
};
