import { useMemo } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { DiscoverSearchQuery } from '../services/backendService.type';
import { useMetrics, useQuery } from '.';
import { BASE_TEMPLATE_SEARCH_LIMIT } from './useTemplateSearch';

const toSearchQuery = (query: URLSearchParams): DiscoverSearchQuery | undefined => {
  const cursor = query.get('cursor');
  const searchPhrase = query.get('search');
  const subjects = query.get('subjects');
  const languages = query.get('languages');
  const grades = query.get('grades');
  const filters: DiscoverSearchQuery['filters'] =
    subjects || languages || grades
      ? {
          grades: grades ? grades.split(',').sort() : undefined,
          languages: languages ? languages.split(',').sort() : undefined,
          subjects: subjects ? subjects.split(',').sort() : undefined,
        }
      : undefined;
  return {
    cursor: cursor ? Number(cursor) : undefined,
    searchPhrase: searchPhrase ? searchPhrase : undefined,
    filters,
    limit: BASE_TEMPLATE_SEARCH_LIMIT,
  };
};

export const toSearchParams = (query: DiscoverSearchQuery) => {
  const searchParams = new URLSearchParams();

  if (query.searchPhrase) {
    searchParams.set('search', query.searchPhrase);
  }
  if (query.limit) {
    searchParams.set('limit', query.limit.toString());
  }
  if (query.cursor) {
    searchParams.set('cursor', query.cursor.toString());
  }
  if (query.filters?.grades) {
    searchParams.set('grades', query.filters?.grades.sort().join(','));
  }
  if (query.filters?.subjects) {
    searchParams.set('subjects', query.filters?.subjects.sort().join(','));
  }
  if (query.filters?.languages) {
    searchParams.set('languages', query.filters?.languages.sort().join(','));
  }

  return searchParams;
};

export const useDiscoverSearchQueryState = () => {
  const { query: rawQuery } = useQuery();
  const history = useHistory();
  const match = useRouteMatch('/:tenantId/discover');
  const state = useMemo(() => toSearchQuery(rawQuery), [rawQuery]);
  const metrics = useMetrics();

  const addFilter = ({ type, value }: { type: string; value: string }) => {
    metrics.logEvent('DiscoverFilters.AddFilter', { type, value });
    const existing = rawQuery.get(type);
    if (!existing) {
      rawQuery.set(type, value);
    } else {
      const array = existing.split(',');
      rawQuery.set(
        type,
        array
          .concat([value])
          .sort()
          .join(','),
      );
    }
    history.push(`${match?.url}?${rawQuery.toString()}`);
  };

  const removeFilter = ({ type, value }: { type: string; value?: string }) => {
    metrics.logEvent('DiscoverFilters.RemoveFilter', { type, value });
    const existing = rawQuery.get(type);
    if (!existing) {
      return;
    }
    const array = existing.split(',').filter(v => v !== value);
    if (array.length === 0) {
      rawQuery.delete(type);
    } else {
      rawQuery.set(type, array.sort().join(','));
    }
    history.push(`${match?.url}?${rawQuery.toString()}`);
  };

  const setSearchPhrase = (value: string) => {
    if (value.length === 0) {
      rawQuery.delete('search');
    } else {
      metrics.logEvent('DiscoverFilters.Search', { value });
      rawQuery.set('search', value);
    }
    history.push(`${match?.url}?${rawQuery.toString()}`);
  };

  const clearAllFilters = () => {
    metrics.logEvent('DiscoverFilters.Clear');
    history.push(`${match?.url}`);
  };

  return {
    rawQuery,
    state,
    addFilter,
    removeFilter,
    setSearchPhrase,
    clearAllFilters,
  };
};
