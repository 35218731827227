import React from 'react';

export const FoldedHandsSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="151"
    height="150"
    fill="none"
    viewBox="0 0 151 150"
  >
    <ellipse
      fill="#F99746"
      rx="51.219"
      ry="50.808"
      transform="rotate(-.458 9842.329 -9350.959) skewX(-.142)"
    ></ellipse>
    <ellipse
      cx="87.695"
      cy="33.519"
      fill="#F99746"
      rx="11.418"
      ry="6.185"
      transform="rotate(-20.128 87.695 33.519)"
    ></ellipse>
    <ellipse
      cx="70.624"
      cy="30.087"
      fill="#F99746"
      rx="15.671"
      ry="8.543"
      transform="rotate(-59.777 70.624 30.087)"
    ></ellipse>
    <path
      fill="#D84C42"
      fillRule="evenodd"
      d="M78.54 16.592c4.078 2.375 3.85 10.363-.506 17.841-2.025 3.477-4.586 6.231-7.157 7.945 1.599-1.55 3.116-3.481 4.413-5.708 4.357-7.478 4.584-15.466.507-17.841-2.182-1.271-5.154-.677-8.113 1.296 3.77-3.657 7.994-5.2 10.857-3.533zM95.73 28.398c1.175 3.208-2.671 7.567-8.593 9.737-3.287 1.205-6.524 1.482-8.953.94 2.26 1.796 6.896 1.99 11.64.251 5.921-2.17 9.769-6.53 8.593-9.737-.523-1.426-1.95-2.384-3.896-2.818.56.445.975.99 1.209 1.627zM72.654 123.537c28.287-.228 51.091-23.159 50.935-51.219a50.211 50.211 0 00-4.346-20.224 50.172 50.172 0 017.522 26.22c.156 28.061-22.649 50.992-50.935 51.219-21.028.17-39.166-12.255-47.154-30.17 9.056 14.629 25.388 24.323 43.978 24.174z"
      clipRule="evenodd"
      opacity="0.9"
    ></path>
    <ellipse cx="59.308" cy="83.185" fill="#F9F3EB" rx="17.5" ry="17.5"></ellipse>
    <path
      stroke="#282F33"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M68.27 82.756c0 5.186-4.013 9.39-8.963 9.39-4.95 0-8.963-4.204-8.963-9.39"
    ></path>
    <ellipse cx="90.89" cy="83.185" fill="#F9F3EB" rx="17.5" ry="17.5"></ellipse>
    <path
      stroke="#282F33"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M99.852 82.756c0 5.186-4.013 9.39-8.963 9.39-4.95 0-8.963-4.204-8.963-9.39"
    ></path>
    <path
      stroke="#282F33"
      strokeLinecap="round"
      d="M48.706 62.932s2.533-2.157 4.264-2.807c3.09-1.16 5.854-.626 5.854-.626M101.923 62.932s-2.533-2.157-4.264-2.807c-3.09-1.16-5.854-.626-5.854-.626"
    ></path>
    <path
      fill="#DB5442"
      d="M76.326 118.14c-.038-4.325 1.203-9.797-3.424-10.768-8.444-1.771-9.79 10.768-9.79 10.768s.201 3.781-5.38 7.025c-3.219 1.871.265 7.014.265 7.014s7.709 1.698 12.766.33c6.32-1.711 5.614-8.459 5.563-14.369z"
    ></path>
    <path
      fill="#FFA254"
      d="M75.054 120.313c-.032-3.675 1.783-10.985-5.513-9.723-2.187.378-3.7 7.555-3.7 7.555s-.521 4.384-6.414 6.197c-5.893 1.813-3.981 8.128-.22 8.69 6.095.91 6.324.525 10.714-.218 6.918-1.171 5.176-7.479 5.133-12.501z"
    ></path>
    <path
      fill="#DB5442"
      d="M75.975 118.138c.038-4.325-1.656-9.676 2.97-10.646 8.445-1.772 9.84 9.502 9.84 9.502s.203 4.926 5.783 8.169c3.22 1.872-.264 7.015-.264 7.015s-7.709 1.698-12.766.329c-6.32-1.71-5.614-8.459-5.563-14.369z"
    ></path>
    <path
      fill="#FFA254"
      d="M77.255 120.313c.032-3.675-1.784-10.985 5.513-9.723 2.187.378 3.7 7.555 3.7 7.555s.52 4.384 6.413 6.197c5.893 1.813 3.982 8.128.22 8.69-6.094.91-6.323.525-10.713-.218-6.919-1.171-5.177-7.479-5.133-12.501z"
    ></path>
    <path
      fill="#61B195"
      d="M63.8 127.373c-1.733-1.86-2.383-5.174-2.383-5.174s-3.842.154-6.744 3.372c-2.19 2.429-3.087 5.627.809 9.251 3.651 3.396 6.147 2.9 9.133 2.9 6.046 0 9.534-6.919 9.534-6.919s-7.15 0-10.348-3.43zM88.41 127.373c1.734-1.86 2.384-5.174 2.384-5.174s3.842.154 6.744 3.372c2.19 2.429 3.087 5.627-.809 9.251-3.651 3.396-6.147 2.9-9.133 2.9-6.046 0-9.534-6.919-9.534-6.919s7.15 0 10.348-3.43z"
    ></path>
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeWidth="3"
      d="M61.379 122.598s.686 2.92 2.42 4.781c3.198 3.43 10.825 3.349 10.825 3.349M90.832 122.598s-.686 2.92-2.42 4.781c-3.198 3.43-10.825 3.349-10.825 3.349"
    ></path>
  </svg>
);

export default FoldedHandsSvg;
