import React from 'react';

export const PadlockSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M16.125 7.54688H15.5V5.04688C15.5 2.28937 13.2575 0.046875 10.5 0.046875C7.7425 0.046875 5.5 2.28937 5.5 5.04688V7.54688H4.875C3.84167 7.54688 3 8.38771 3 9.42188V18.1719C3 19.206 3.84167 20.0469 4.875 20.0469H16.125C17.1583 20.0469 18 19.206 18 18.1719V9.42188C18 8.38771 17.1583 7.54688 16.125 7.54688ZM7.16667 5.04688C7.16667 3.20854 8.66167 1.71354 10.5 1.71354C12.3383 1.71354 13.8333 3.20854 13.8333 5.04688V7.54688H7.16667V5.04688ZM11.3333 13.9819V15.8802C11.3333 16.3402 10.9608 16.7135 10.5 16.7135C10.0392 16.7135 9.66667 16.3402 9.66667 15.8802V13.9819C9.17083 13.6927 8.83333 13.161 8.83333 12.5469C8.83333 11.6277 9.58083 10.8802 10.5 10.8802C11.4192 10.8802 12.1667 11.6277 12.1667 12.5469C12.1667 13.161 11.8292 13.6927 11.3333 13.9819Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default PadlockSvg;
