import { Input as AntInput } from 'antd';
import { LiteralUnion } from 'antd/lib/_util/type';
import { joinShortcodes } from 'emojibase';
import data from 'emojibase-data/en/compact.json';
import githubShortCodes from 'emojibase-data/en/shortcodes/github.json';
import EMOTICON_REGEX from 'emojibase-regex/emoticon';
import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { useMetrics } from '../../../hooks';
import theme from '../../../theme';
import { CrossIcon } from '../_Icons';

const emojisList = joinShortcodes(data, [githubShortCodes]);

interface InputProps {
  className?: string;

  disabled?: boolean;
  fluid?: boolean;
  clearable?: boolean;

  placeholder?: string;
  label?: string;
  error?: string;
  help?: string;
  id?: string;
  maxLength?: number;

  borderLess?: boolean;
  autoFocus?: boolean;
  icon?: React.ReactElement;

  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onPressEnter?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onClear?: () => void;
  style?: CSSProperties;
  inputStyle?: CSSProperties;
  type?: LiteralUnion<
    | 'button'
    | 'checkbox'
    | 'color'
    | 'date'
    | 'datetime-local'
    | 'email'
    | 'file'
    | 'hidden'
    | 'image'
    | 'month'
    | 'number'
    | 'password'
    | 'radio'
    | 'range'
    | 'reset'
    | 'search'
    | 'submit'
    | 'tel'
    | 'text'
    | 'time'
    | 'url'
    | 'week',
    string
  >;
}

export const Input: React.FC<InputProps> = props => {
  const {
    className,
    disabled,
    fluid,
    label,
    error,
    help,
    placeholder,
    icon,
    clearable,
    value,
    onChange,
    onPressEnter,
    onClear,
    borderLess = false,
    style,
    inputStyle,
    type,
    autoFocus,
    id,
    maxLength,
  } = props;

  const ref = useRef<AntInput>(null);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setInputValue(e.target.value);
    onChange && onChange(e);
    const el = document.activeElement;
    if (el !== ref.current?.input) return;
    findCaretPosition();
  };
  const [inputValue, setInputValue] = useState<string>('');
  const metrics = useMetrics();
  const [caretPosition, setCaretPosition] = useState<number>(1);
  //const disabledValue = disabled !== undefined && disabled;
  const fluidValue = fluid !== undefined && fluid;
  const errorValue = error !== undefined;
  const clearableValue = clearable !== undefined && clearable;
  const withIcon = icon !== undefined;

  useEffect(() => {
    if (value !== undefined) setInputValue(value);
  }, [value]);

  const suffixProps =
    clearableValue && value !== undefined && value
      ? { suffix: <StyledClearIcon onClick={onClear} /> }
      : {};

  function repositionCaret() {
    if (ref && 'current' in ref && ref.current) {
      const textInput = ref.current;
      if (textInput.input.setSelectionRange) {
        setTimeout(() => {
          textInput.input.setSelectionRange(caretPosition, caretPosition);
          textInput.input.focus();
        }, 0.00001);
        textInput.input.blur();
      }
    }
  }

  function findCaretPosition() {
    if (ref && 'current' in ref && ref.current) {
      const textInput = ref.current;
      setCaretPosition(textInput.input.selectionStart || 0);
    }
  }

  useEffect(() => {
    const el = document.activeElement;
    if (el !== ref.current?.input) return;
    // Created a synthetic event for the inputElement and dispatches it.
    // The onChange callback will be inoved when the event is dispatched
    // reference for the code: https://newbedev.com/how-do-i-programmatically-trigger-an-input-event-without-jquery
    const emoticon = inputValue.match(EMOTICON_REGEX);
    const emoticonIsFollowedBySpace = emoticon && inputValue.includes(emoticon[0] + ' ');
    if (emoticonIsFollowedBySpace) {
      if (emoticon && emoticon.length) {
        emojisList.forEach(emoji => {
          if (emoji.emoticon && emoji.emoticon === emoticon[0]) {
            const newVal = inputValue.replace(emoticon[0], emoji.unicode);
            const inputRef = ref.current?.input;
            if (inputRef !== null && inputRef !== undefined) {
              const valueSetter = Object.getOwnPropertyDescriptor(inputRef, 'value')?.set;
              const prototype = Object.getPrototypeOf(inputRef);

              const prototypeValueSetter = Object.getOwnPropertyDescriptor(
                prototype,
                'value',
              )?.set;
              if (
                valueSetter &&
                valueSetter !== prototypeValueSetter &&
                prototypeValueSetter
              ) {
                prototypeValueSetter.call(inputRef, newVal);
              } else if (valueSetter) {
                valueSetter.call(inputRef, newVal);
              }
              inputRef.dispatchEvent(new Event('input', { bubbles: true }));
            }
            repositionCaret();
            metrics.logEvent('Emoji.Added', { type: 'written', emoji: emoji.emoticon });
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  return (
    <Wrapper $fluid={fluidValue} style={style} className={className} id={id}>
      {label && <Label>{label}</Label>}
      <StyledInput
        ref={ref}
        style={inputStyle}
        $borderLess={borderLess}
        disabled={disabled}
        $fluid={fluidValue}
        $clearable={clearableValue}
        prefix={icon}
        placeholder={placeholder}
        $error={errorValue}
        $withIcon={withIcon}
        value={inputValue}
        type={type}
        autoFocus={autoFocus}
        onChange={handleChange}
        onPressEnter={onPressEnter}
        maxLength={maxLength}
        {...suffixProps}
      />
      {error && <ErrorLabel>{error}</ErrorLabel>}
      {help && !error && <Label>{help}</Label>}
    </Wrapper>
  );
};

interface StyledInputProps {
  $error: boolean;
  $withIcon: boolean;
  $fluid: boolean;
  $clearable: boolean;
  $borderLess: boolean;
}

const StyledInput = styled(AntInput)<StyledInputProps>`
  // border: ${props => (props.$borderLess ? 'none' : '1px solid #d9d9d9')};
  border-radius: 5px;




  ${props =>
    props.$borderLess &&
    css`
      &.ant-input-affix-wrapper {
        box-shadow: 0px 2px 20px rgba(222, 157, 163, 0.15);
        border-radius: 10px;
        border: none;
        padding-left: 25px;
      }
    `}

  &,
  & > input {
    color: ${theme.colors.black};
    font-family: 'Nunito';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 130%;
    padding: 10px 15px;
    mix-blend-mode: normal;
  }

  &:hover {
    border-color: ${theme.colors.green};
    box-shadow: none;
  }

  &:focus {
    background-color: #fff;
    border-color: #d9d9d9;
    box-shadow: none;
  }

  &::placeholder,
  & > input::placeholder {
    color: #a5a5a5;
  }


  ${props =>
    props.$error &&
    css`
      border-color: ${theme.colors.red};

      &:hover,
      &:focus {
        border-color: ${theme.colors.red};
      }
    `}

  ${props =>
    props.$withIcon &&
    css`
      & .ant-input-prefix {
        margin-right: 13px;
      }

      &.ant-input-affix-wrapper-focused {
        background-color: #fbfbfc;
        border-color: #d9d9d9;
        box-shadow: none;

        input {
          background-color: #fbfbfc;
        }
      }

      & .anticon {
        color: #a5a5a5;
      }
    `}

    ${props =>
      props.$fluid &&
      css`
        height: 100%;
      `}
      ${props =>
        props.$clearable &&
        css`
          & .ant-input-suffix {
            margin-right: 10px;
          }
        `}
`;

const StyledClearIcon = styled(CrossIcon)`
  &:hover {
    color: #000000;
  }
`;

interface WrapperProps {
  $fluid: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  ${props =>
    props.$fluid &&
    css`
      height: 100%;
    `}
`;

const Label = styled.label`
  color: #a5a5a5;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
`;

const ErrorLabel = styled.label`
  color: ${theme.colors.red};
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
`;

export const { Search } = AntInput;
