import React from 'react';

export const PodiumSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2359:49538)">
      <path
        d="M0.166016 8C0.166016 6.89543 1.06145 6 2.16602 6H5.36601C6.47058 6 7.36602 6.89543 7.36602 8V24H0.166016V8Z"
        fill="currentColor"
      />
      <path
        d="M8.56641 2C8.56641 0.89543 9.46184 0 10.5664 0H13.7664C14.871 0 15.7664 0.895431 15.7664 2V24H8.56641V2Z"
        fill="currentColor"
      />
      <path
        d="M16.9668 14C16.9668 12.8954 17.8622 12 18.9668 12H22.1668C23.2714 12 24.1668 12.8954 24.1668 14V24H16.9668V14Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_2359:49538">
        <rect width="24" height="24" fill="white" transform="translate(0.166016)" />
      </clipPath>
    </defs>
  </svg>
);

export default PodiumSvg;
