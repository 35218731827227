import React, { CSSProperties } from 'react';
import styled from 'styled-components';

import { CrossIcon } from '../_Icons';
const Label = ({
  text,
  color,
  backgroundColor,
  closable = false,
  onClose,
  onClick,
  icon,
  style,
}: {
  style?: CSSProperties;
  text: string;
  color: string;
  backgroundColor: string;
  closable?: boolean;
  icon?: React.ReactElement;
  onClose?: () => void;
  onClick?: () => void;
}) => {
  return (
    <LabelContainer
      style={style}
      $color={color}
      $backgroundColor={backgroundColor}
      $clickable={!!onClick}
      $withIcon={!!icon}
      onClick={onClick}
    >
      {icon && icon}
      <p>{text}</p>
      {closable && <CrossIcon onClick={onClose} />}
    </LabelContainer>
  );
};

const LabelContainer = styled.span<{
  $clickable?: boolean;
  $color: string;
  $withIcon: boolean;
  $backgroundColor: string;
}>`
  width: fit-content;
  padding: 5px 15px;
  color: ${({ $color }) => $color};
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  border-radius: 20px;
  white-space: nowrap;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  cursor: ${({ $clickable }) => ($clickable ? 'pointer' : 'inherit')};
  svg {
    cursor: pointer;
    ${({ $withIcon }) => ($withIcon ? 'margin-left: 0' : 'margin-left: 5px')};
    height: 12px;
  }

  p {
    margin: 0;
    ${({ $withIcon }) => ($withIcon ? 'margin-left: 5px' : 'margin-left: 0')};
  }
`;
export default Label;
