import React from 'react';

export const ArticlePDFSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="30"
    height="40"
    viewBox="0 0 30 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 5C0 2.23858 2.23858 0 5 0H21.1111L30 9.27536V35C30 37.7614 27.7614 40 25 40H5C2.23858 40 0 37.7614 0 35V5Z"
      fill="#33A0DD"
    />
    <path
      d="M15.086 23.7433C8.53144 26.2964 6.85291 27.3744 8.00051 29.0225C8.99951 30.4572 11.4667 26.7447 13.1947 21.6094C14.9227 16.4742 16.3203 11.8402 14.3727 12.0042C12.0416 12.2005 13.9796 16.1909 16.7504 20.3397C19.5212 24.4885 21.3393 25.1789 22.669 24.0466C23.8012 22.799 22.2066 21.3353 15.086 23.7433Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      opacity="0.5"
      d="M21.6111 1.24441L28.8283 8.77539H23.1111C22.2827 8.77539 21.6111 8.10382 21.6111 7.27539V1.24441Z"
      fill="white"
      stroke="white"
    />
  </svg>
);

export default ArticlePDFSvg;
