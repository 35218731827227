import React from 'react';

export const EmailSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.75781 2.38281H18.2422C19.2102 2.38281 20 3.17172 20 4.14062V15.8594C20 16.825 19.2137 17.6172 18.2422 17.6172H1.75781C0.792188 17.6172 0 16.8309 0 15.8594V4.14062C0 3.175 0.78625 2.38281 1.75781 2.38281ZM2.00055 3.55469L9.96273 11.5169L17.9938 3.55469H2.00055ZM18.8281 15.6167V4.3777L13.1844 9.97301L18.8281 15.6167ZM17.9995 16.4453L12.3522 10.798L10.3734 12.7598C10.1444 12.987 9.77473 12.9862 9.5466 12.758L7.61719 10.8286L2.00051 16.4453H17.9995ZM1.17188 15.6167L6.78855 10L1.17188 4.38328V15.6167Z"
      fill="currentColor"
    />
  </svg>
);

export default EmailSvg;
