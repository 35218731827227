import { getOrigin } from '../utils/navigatorUtils';

export const config = {
  firebase: {
    apiKey: 'AIzaSyAr-SmbEaGTpovygy6QVpAp0SYQtxeKKm0',
    authDomain: 'snapmentor-1.firebaseapp.com',
    databaseURL: 'https://snapmentor-1.firebaseio.com',
    projectId: 'snapmentor-1',
    storageBucket: 'snapmentor-1.appspot.com',
    messagingSenderId: '302280329608',
    appId: '1:302280329608:web:e2582bd8070065b7',
    measurementId: 'G-YRRK3ZBXHL',
  },
  cloudMessaging: {
    vapidKey:
      'BFXyM2M44MjtaQSH4B3s1jycvLnm9v3DcWgqwC1JoqDnvX0I0dW-8BvDZ4s4OuR1v31pduColECCdI99_jv-E1A',
  },
  backend: {
    externalApiUrl: 'https://api-test.curipod.com',
  },
  cdn: {
    url: 'https://images-cdn-staging.curipod.com',
  },
  appVersion: process.env.REACT_APP_VERSION,
  azure: {
    tenant: 'snapmentordev.onmicrosoft.com',
    tenantName: 'snapmentordev',
    clientId: 'cfb60a72-57fd-4cdc-b3f0-10eec6dcfff6',
    signInPolicy: 'B2C_1A_signin',
    joinPolicy: 'B2C_1A_join',
    signUpPolicy: 'B2C_1A_signup',
    enterpriseSignInPolicy: 'B2C_1A_enterprise_signin',
    enterpriseJoinPolicy: 'B2C_1A_enterprise_join',
    scopes: [
      'https://snapmentordev.onmicrosoft.com/cfb60a72-57fd-4cdc-b3f0-10eec6dcfff6/User.Read',
    ],
    enterpriseRedirectUri: 'https://id-dev.curipod.com',
    prRedirectUri: 'https://id-dev.curipod.com',
    redirectUri: `${getOrigin()}/oauth/callback`,
  },
  onboarding: {
    version: 24,
  },
  whatsNew: {
    version: 24,
  },
  game: {
    url: 'dev.curi.live',
  },
  externalLinks: {
    howItWorks: 'https://test-landing.curipod.com/how-it-works',
    snapmentorSchool: 'help-dev',
  },
  unleash: {
    url: 'https://unleash-proxy-7epohblsoa-ey.a.run.app/proxy',
    clientKey: '7epohblsoa',
    appName: 'app-curipod-dev',
    refreshInterval: 60 * 5,
  },
};

export type APPConfig = typeof config;
