import { useMutation, useQuery, useQueryClient } from 'react-query';

import { getUserId } from '../modules';
import {
  ArticleComment,
  BackendService,
  FeedArticle,
  Tag,
} from '../services/backendService';
import { useTypedSelector } from '.';
import { useMetrics } from './useMetrics';

export const articleCacheKey = `article`;
function useArticle(articleId?: string, isEdit?: boolean) {
  const client = useQueryClient();
  const metrics = useMetrics();
  const userId = useTypedSelector(getUserId);

  const { data, isFetching, error } = useQuery<
    FeedArticle | undefined,
    string | undefined
  >(
    [articleCacheKey, articleId],
    () => {
      if (!articleId) return Promise.resolve(undefined);
      return BackendService.instance.articles.retrievePromise(articleId);
    },
    {
      onSuccess: d => {
        if (d?.status === 'parsing') {
          setTimeout(() => {
            client.invalidateQueries([articleCacheKey, articleId]);
          }, 1000);
        }
      },
      retry: false,
      refetchOnWindowFocus: isEdit ? false : true,
    },
  );

  const {
    mutate: update,
    isLoading: updateIsLoading,
    isSuccess: updateIsSuccess,
  } = useMutation(
    ({
      title,
      articleId,
      tags,
      topicId,
    }: {
      topicId: string;
      title: string;
      articleId: string;
      tags?: { tagsToRemove: string[]; tagsToCreate: Tag[]; tagsToAdd: Tag[] };
    }) =>
      BackendService.instance.articles.updateSnippet({ title, tags, topicId }, articleId),
    {
      onSuccess: () => client.invalidateQueries([articleCacheKey, articleId]),
    },
  );

  const { mutate: applaud } = useMutation<
    void,
    string,
    { claps: number; articleId: string }
  >(
    ({ claps, articleId }: { claps: number; articleId: string }) =>
      BackendService.instance.articles.applaud(claps, articleId),
    {
      onSuccess: () => client.invalidateQueries([articleCacheKey, articleId]),
    },
  );

  const { mutate: addComment } = useMutation<
    unknown,
    string,
    {
      text: string;
      imageData?: string;
      articleId: string;
      disclaimer?: string;
      isAnonymous: boolean;
    }
  >(
    ({
      text,
      articleId,
      imageData,
      disclaimer,
      isAnonymous,
    }: {
      text: string;
      articleId: string;
      imageData?: string;
      disclaimer?: string;
      isAnonymous: boolean;
    }) => {
      metrics.logEvent('Feed.Snippet.Comment.Add', { articleId });
      return BackendService.instance.articles.addComment(
        { text, imageData, disclaimer, isAnonymous },
        articleId,
      );
    },
    {
      onSettled: () => client.invalidateQueries([articleCacheKey, articleId]),
      onError: (err, newTodo, rollback) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        return rollback();
      },
      onMutate: s => {
        client.cancelQueries([articleCacheKey, articleId]);
        const optimisticComment: ArticleComment = {
          text: s.text,
          imageId: s.imageData && new Date().getTime().toString(),
          createdAt: new Date().getTime(),
          updatedAt: new Date().getTime(),
          createdBy: userId!,
          id: '0',
          articleId: s.articleId,
          isAnonymous: s.isAnonymous,
          disclaimer: s.disclaimer,
        };
        const previousArticle = client.getQueryData<FeedArticle>([
          articleCacheKey,
          articleId,
        ]);
        if (!previousArticle) {
          // eslint-disable-next-line no-console
          console.error('Mutating before possible mutation');
        }

        client.setQueryData<FeedArticle | undefined>(
          [articleCacheKey, articleId],
          article => {
            if (!article) return article;
            return {
              ...article,
              comments: article.comments
                ? [...article.comments, optimisticComment]
                : [optimisticComment],
            };
          },
        );
        // Return the snapshotted value
        return () => client.setQueryData([articleCacheKey, articleId], previousArticle);
      },
    },
  );

  const { mutate: editComment } = useMutation<
    unknown,
    string,
    {
      text: string;
      commentId: string;
      articleId: string;
      disclaimer?: string;
      isAnonymous: boolean;
    }
  >(
    ({
      text,
      articleId,
      commentId,
      disclaimer,
      isAnonymous,
    }: {
      text: string;
      articleId: string;
      commentId: string;
      disclaimer?: string;
      isAnonymous: boolean;
    }) => {
      metrics.logEvent('Feed.Snippet.Comment.Edit', { articleId, commentId });
      return BackendService.instance.articles.editComment({
        text,
        commentId,
        disclaimer,
        articleId,
        isAnonymous,
      });
    },
    {
      onSettled: () => client.invalidateQueries([articleCacheKey, articleId]),
      onError: (err, newTodo, rollback) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        return rollback();
      },
      onMutate: s => {
        client.cancelQueries([articleCacheKey, articleId]);
        const previousArticle = client.getQueryData<FeedArticle>([
          articleCacheKey,
          articleId,
        ]);
        if (!previousArticle) {
          // eslint-disable-next-line no-console
          console.error('Mutating before possible mutation');
        }

        client.setQueryData<FeedArticle | undefined>(
          [articleCacheKey, articleId],
          article => {
            if (!article) return article;
            return {
              ...article,
              comments: article.comments
                ? article.comments.map(c =>
                    c.id === s.commentId
                      ? {
                          ...c,
                          text: s.text,
                          disclaimer: s.disclaimer,
                          isAnonymous: s.isAnonymous,
                          updatedAt: new Date().getTime(),
                        }
                      : c,
                  )
                : article.comments,
            };
          },
        );
        // Return the snapshotted value
        return () => client.setQueryData([articleCacheKey, articleId], previousArticle);
      },
    },
  );

  const { mutate: deleteComment } = useMutation<
    unknown,
    string,
    { commentId: string; articleId: string }
  >(
    ({ articleId, commentId }: { articleId: string; commentId: string }) => {
      metrics.logEvent('Feed.Snippet.Comment.Delete', { articleId, commentId });
      return BackendService.instance.articles.deleteComment(commentId, articleId);
    },
    {
      onSettled: () => client.invalidateQueries([articleCacheKey, articleId]),
      onError: (err, newTodo, rollback) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        return rollback();
      },
      onMutate: s => {
        client.cancelQueries([articleCacheKey, articleId]);
        const previousArticle = client.getQueryData<FeedArticle>([
          articleCacheKey,
          articleId,
        ]);
        if (!previousArticle) {
          // eslint-disable-next-line no-console
          console.error('Mutating before possible mutation');
        }

        client.setQueryData<FeedArticle | undefined>(
          [articleCacheKey, articleId],
          article => {
            if (!article) return article;
            return {
              ...article,
              comments: article.comments
                ? article.comments.filter(c => c.id !== s.commentId)
                : article.comments,
            };
          },
        );
        // Return the snapshotted value
        return () => client.setQueryData([articleCacheKey, articleId], previousArticle);
      },
    },
  );

  return {
    article: data,
    error,
    isLoading: isFetching,
    addComment,
    editComment,
    deleteComment,
    applaud,
    update,
    updateIsLoading,
    updateIsSuccess,
  };
}

export default useArticle;
