import { combineEpics } from 'redux-observable';

import { rootAdminEpic } from './Admin';
import { articlesRootEpic } from './Articles';
import { notificationRootEpic } from './Notifications';
import { serverRootEpic } from './Server';
import { rootUserEpic } from './User';
import { userEpic } from './UserType';

export const rootEpic = combineEpics(
  notificationRootEpic,
  userEpic,
  rootUserEpic,
  serverRootEpic,
  rootAdminEpic,
  articlesRootEpic,
);
