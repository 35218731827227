export const isLaunchedInPWA = () => {
  try {
    // Android
    if (window.matchMedia('(display-mode: standalone)').matches) {
      return true;
    }
    // iOS
    if (window.navigator.standalone === true) {
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
};
