import React from 'react';
import styled from 'styled-components';

import { ProfileLogoConfig } from '../../../services/backendService';
import { ProfileCircle } from '../../../styled/ProfileCircle';
import {
  CuripodHeartsIcon,
  CuripodNormalIcon,
  CuripodStarsIcon,
  CuripodThanksIcon,
} from '../_Icons';
import ProfileDefault from '../ProfileDefault';

const defaultFallbackConfig: ProfileLogoConfig = {
  color: '#535454',
  logo: ':curipod_normal:',
};

const configMapToIcon: { [id: string]: React.ReactNode } = {
  ':curipod_normal:': <CuripodNormalIcon />,
  ':curipod_stars:': <CuripodStarsIcon />,
  ':curipod_hearts:': <CuripodHeartsIcon />,
  ':curipod_thanks:': <CuripodThanksIcon />,
};

const fallbackProfileColors = {
  text: 'white',
  background: '#535454',
};

const Profile = ({
  style,
  radius,
  img,
  onClick,
  onImageLoaded,
  fallbackLogoConfig,
  backgroundColor,
  firstLetter,
}: {
  style?: React.CSSProperties;
  radius?: number;
  img?: string;
  fallbackLogoConfig?: ProfileLogoConfig;
  backgroundColor?: string;
  onClick?: () => void;
  onImageLoaded?: () => void;
  firstLetter?: string;
}) => {
  return (
    <ProfileWrapper style={style} onClick={onClick}>
      {img && (
        <ProfileCircle $radius={radius}>
          <Imgstyled
            src={img}
            alt="profile"
            onLoad={() => onImageLoaded && onImageLoaded()}
            style={{ borderRadius: (radius || 40) / 1 }}
          />
        </ProfileCircle>
      )}
      {!img && firstLetter && (
        <ProfileCircle
          $radius={radius}
          $color={fallbackProfileColors.text}
          $background={fallbackProfileColors.background}
        >
          <span style={{ fontWeight: 'bold', color: fallbackProfileColors.text }}>
            {firstLetter.toUpperCase()}
          </span>
        </ProfileCircle>
      )}
      {!img && !firstLetter && (
        <ProfileDefault
          radius={radius || 40}
          color={fallbackLogoConfig?.color || defaultFallbackConfig.color}
          icon={configMapToIcon[(fallbackLogoConfig || defaultFallbackConfig).logo]}
          backgroundColor={backgroundColor}
        />
      )}
    </ProfileWrapper>
  );
};

const Imgstyled = styled.img`
  user-select: none;
  height: 100%;
  width: 100%;
`;
const ProfileWrapper = styled.div`
  height: 100%;
  display: flex;
  user-select: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export default Profile;
