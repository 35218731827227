import { useHistory, useLocation } from 'react-router-dom';

export const useQuery = () => {
  const { search, pathname } = useLocation();
  const history = useHistory();
  return {
    query: new URLSearchParams(search),
    search,
    clear: () => history.replace(pathname),
    setOnLoginRedirect: (from: string) =>
      history.replace(`${pathname}?${search}&from=${from}`),
  };
};
