import { config } from '../../config/environments.production';
import { getConfig } from '../../store';
import { RootAction } from '..';
import { AppState } from '../rootReducer';
import { INIT_ENVIROMENT } from './actionTypes';

export type EnvironmentConfig = typeof config;
export type EnvironmentState = {
  config: EnvironmentConfig;
  vippsRedirectUrl: string;
};

const init: EnvironmentState = {
  config: getConfig(),
  vippsRedirectUrl: '',
};

export const environmentReducer = (
  state: EnvironmentState = init,
  action: RootAction,
): EnvironmentState => {
  switch (action.type) {
    case INIT_ENVIROMENT:
      return { ...state, config: action.payload as EnvironmentConfig };

    default:
      return state;
  }
};

export const getAppVersion = (state: AppState) =>
  state.env.config.appVersion?.substring(0, 7);

export const selectOnboardingVersion = (state: AppState) =>
  state.env.config.onboarding.version;

export const selectWhatsNewVersion = (state: AppState) =>
  state.env.config.whatsNew.version;

export const selectGameUrl = (state: AppState) => state.env.config.game.url;
export const selectHowItWorks = (state: AppState) =>
  state.env.config.externalLinks.howItWorks;
export const selectSnapmentorSchool = (state: AppState) =>
  state.env.config.externalLinks.snapmentorSchool;
