import React from 'react';

export const DrawingsSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.8297 9.35111L14.6474 10.1689L6.5952 18.2222H5.77753V17.4044L13.8297 9.35111V9.35111ZM17.0293 4C16.8071 4 16.576 4.08889 16.4072 4.25778L14.7807 5.88444L18.1136 9.21778L19.74 7.59111C20.0867 7.24444 20.0867 6.68444 19.74 6.33778L17.6603 4.25778C17.4826 4.08 17.2604 4 17.0293 4V4ZM13.8297 6.83556L4 16.6667V20H7.33287L17.1626 10.1689L13.8297 6.83556V6.83556Z"
      fill="currentColor"
    />
  </svg>
);

export default DrawingsSvg;
