import React from 'react';

export const RadioSelectedSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" />
    <path
      d="M8.06057 11.6431L7.88861 11.4791L7.71608 11.6425L6.82808 12.4837L6.63715 12.6645L6.82744 12.8461L10.3232 16.1809L10.4957 16.3455L10.6683 16.1809L18.1726 9.02205L18.3622 8.84116L18.1726 8.66027L17.2908 7.81911L17.1183 7.65457L16.9458 7.81903L10.4958 13.9663L8.06057 11.6431Z"
      fill="white"
      stroke="white"
      strokeWidth="0.5"
    />
  </svg>
);
